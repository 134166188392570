const env = {
  REACT_APP_OIDC_AUTH_ENDPOINT: process.env.REACT_APP_OIDC_AUTH_ENDPOINT!,
  REACT_APP_OIDC_REDIRECT_URI: process.env.REACT_APP_OIDC_REDIRECT_URI!,
  REACT_APP_OIDC_CLIENT_ID: process.env.REACT_APP_OIDC_CLIENT_ID!,
  REACT_APP_API_TOKEN_ENDPOINT: process.env.REACT_APP_API_TOKEN_ENDPOINT!,
  REACT_APP_OIDC_LOGOUT_URI: process.env.REACT_APP_OIDC_LOGOUT_URI!,
  REACT_APP_API_USER_ENDPOINT: process.env.REACT_APP_API_USER_ENDPOINT!,
  REACT_APP_ACTIVITY_TIMEOUT_DURATION:
    process.env.REACT_APP_ACTIVITY_TIMEOUT_DURATION!
};

export default env;
