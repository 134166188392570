//This helper function takes in the homepage resource arrays, and reorders them to sequence any files that don't start with a numeric value as if they were a 0, and place them at the start of the array. This is to match this AC:

/*
The file name in the folder determines the sequence of files in the the display, and the values for tag, title, and description

1.Filename format: <sequence>_<tag>_<title>_<description>.<filetype>

A.Example: 1_Calculator_Beer_Effective 01 APR 2023.xlsx

2.The <tag>, <title> and <description> fields can contain spaces

3.<sequence> indicates the sort order of the files within that section

a.The sequence value is not displayed.

b.Sequence value must be numeric.

i.If the first filed in the filename is not numeric, it is assumed to be the <tag> and the sequence value will default to zero. */
import { FileData } from '../types/HomeTypes';

export const reorderResourceFiles = (filesArray: FileData[]) => {
  if (filesArray === undefined || filesArray.length === 0) {
    return [];
  }
  // Separate files into two arrays: one for non-numeric filenames and one for numeric filenames
  const nonNumericFiles: FileData[] = [];
  const numericFiles: FileData[] = [];

  filesArray.forEach((file: FileData) => {
    const fileName = file.fileName;

    if (/^[^\d]/.test(fileName)) {
      nonNumericFiles.push(file);
    } else if (/^\d/.test(fileName)) {
      numericFiles.push(file);
    }
  });

  // Sort non-numeric files alphabetically
  nonNumericFiles.sort((a, b) => a.fileName.localeCompare(b.fileName));

  // Sort numeric files in ascending order (as strings)
  numericFiles.sort((a, b) => a.fileName.localeCompare(b.fileName));

  // Concatenate the two sorted arrays
  const reorderedArray = nonNumericFiles.concat(numericFiles);

  return reorderedArray;
};
