import { UserTypeEnum } from './userType';
import { PriceRequestStatusEnum } from './priceRequestStatus';

import copy from '../config/translations/en-CA.json';

const valueToLabelMap = new Map([
  ['PCR', 'Supplier Price Change'],
  ['DISTR CH CHG', 'Dist. Channel Change'],
  ['CONTAINER TYPE', 'Cont. Type Change'],
  ['ABV CHG', 'Alc. Percentage Change'],
  ['CATEGORY CHG', 'Category Change'],
  ['PRICE FORMULA OVERRIDE', 'Pricing Formula Change'],
  ['CASE CONFIG WAS', 'Case Config Change'],
  ['BEER PROD', 'Beer Production Change'],
  ['CETA', 'CETA'],
  ['CPTPP', 'CPTPP'],
  ['CUSMA', 'CUSMA'],
  ['SIMA', 'SIMA'],
  ['CUKTA', 'CUKTA'],
  ['GLOBAL EXCISE', 'Excise Rate Change'],
  ['GLOBAL CRF', 'CRF Change'],
  ['GLOBAL CUSTOMS', 'Customs Duty Change'],
  ['REACT', 'Product Reactivation'],
  ['NEW', 'New Product'],
  ['OTHER', 'Other']
]);

// Create labelToValue map programmatically by reversing valueToLabel
const labelToValueMap = new Map();

for (const [key, value] of valueToLabelMap.entries()) {
  labelToValueMap.set(value, key);
}

const isOtherReason = (reasonValue: string | undefined) => {
  if (!reasonValue) return false;
  return reasonValue.toLowerCase().includes('other');
};

export const mapPriceRequestReason = (
  term: string | undefined,
  returnType: 'label' | 'value',
  valueOverride?: string
) => {
  if (!term || typeof term !== 'string') return '';

  if (isOtherReason(term)) {
    if (returnType === 'label') {
      return 'Other';
    } else {
      return 'OTHER';
    }
  }

  if (returnType === 'label') return term;
  return labelToValueMap.get(term) || valueOverride ? valueOverride : term;
};

export const extractOtherReason = (otherString: string | undefined) => {
  if (!otherString || !otherString.startsWith('Other-')) return;
  return otherString.substring(7);
};

export const getReasonObjectByValue = (reason?: any) => {
  if (!reason?.value) return null;

  if (isOtherReason(reason.value)) {
    return { value: 'OTHER', label: 'Other' };
  }

  const label = valueToLabelMap.get(reason.value) || reason.label;

  return { value: reason.value, label };
};

export const appendReasonCostingComment = (
  user: string | undefined,
  isPriceChange: boolean,
  status: string | undefined,
  reason: string,
  inputtedOtherReason: string | undefined,
  costingEventComment: string
) => {
  const APPEND_TEXT_REASON = 'Supplier Price Change';
  const APPEND_TEXT_COMMENT = 'PCR';
  const { DRAFT, QUEUED, AS_REVIEW } = PriceRequestStatusEnum;

  const userIsExternal = user === UserTypeEnum.EXTERNAL;
  const statusMatches = [DRAFT, QUEUED, AS_REVIEW].includes(
    (status as PriceRequestStatusEnum) || ''
  );
  const reasonNotLike = !reason.includes(APPEND_TEXT_REASON);
  const commentNotLike = !costingEventComment.includes(APPEND_TEXT_COMMENT);
  const isOther = isOtherReason(reason);
  let updatedReason = isOther ? `${reason}- ${inputtedOtherReason}` : reason;
  let updatedCostingEventComment = costingEventComment;

  if (userIsExternal && isPriceChange && statusMatches) {
    if (reasonNotLike) {
      if (reason.trim() === '') {
        updatedReason = APPEND_TEXT_REASON;
      } else {
        if (isOther) {
          updatedReason = `${reason}- ${inputtedOtherReason}, ${APPEND_TEXT_REASON}`;
        } else {
          updatedReason = `${reason}, ${APPEND_TEXT_REASON}`;
        }
      }
    }

    if (commentNotLike) {
      if (costingEventComment.trim() === '') {
        updatedCostingEventComment = APPEND_TEXT_COMMENT;
      } else {
        updatedCostingEventComment = `${costingEventComment}, ${APPEND_TEXT_COMMENT}`;
      }
    }
  }

  return {
    updatedReason,
    updatedCostingEventComment
  };
};

export const getReasonOptions = (isNewProductPR: boolean, reason?: any) => {
  const options = isNewProductPR
    ? copy.priceRequest.reasonOptionsNP
    : copy.priceRequest.reasonOptions;

  const appendedOption = labelToValueMap.get(reason.label)
    ? []
    : [{ label: reason.label, value: reason.value }];

  return [...appendedOption, ...options].filter((option) => !!option.label);
};
