import React from 'react';

import TextLink from '../TextLink';

import styles from './Header.module.scss';

type PropsType = {
  children: React.ReactNode;
};

const HeaderUserLink = ({ children }: PropsType) => {
  const userDetailsUrl = process.env.REACT_APP_USER_DETAILS_LINK || '';
  return (
    <TextLink
      route={userDetailsUrl}
      ariaLabel="User Details"
      external
      className={styles.userLink}
    >
      {children}
    </TextLink>
  );
};

export default HeaderUserLink;
