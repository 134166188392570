import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';
import {
  ProductDetailsResponse,
  ProductDetailsType
} from '../types/productTypes';
import {
  ProductSupplierResponseType,
  ProductPriceHistoryResponse
} from '../types/productSearchTypes';

type QueryParams = { queryParams: string; token: string };

type SupplierQueryParams = { token: string };

type mutateSinglePayload = {
  requestBody: {
    sku: number | undefined;
  };
  token: string | null;
};

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getProducts: builder.query<ProductDetailsResponse, QueryParams>({
      query: ({ queryParams, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `products/search?${queryParams}`
      })
    }),
    getProductDetails: builder.query<ProductDetailsType, QueryParams>({
      query: ({ queryParams, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `products/details/${queryParams}`
      })
    }),
    getProductSuppliersList: builder.query<
      ProductSupplierResponseType,
      SupplierQueryParams
    >({
      query: ({ token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `/products/suppliers`
      })
    }),
    getProductPriceHistory: builder.mutation<
      ProductPriceHistoryResponse,
      mutateSinglePayload
    >({
      query: ({ requestBody, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: '/products/price-history',
        body: requestBody
      })
    })
  })
});

export const {
  useGetProductsQuery,
  useGetProductDetailsQuery,
  useGetProductSuppliersListQuery,
  useGetProductPriceHistoryMutation
} = productsApi;
