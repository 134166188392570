import React from 'react';

const Cancel = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon-cancel">
        <path
          id="Vector"
          d="M11.1857 12.483L3.51725 4.81454C2.87559 5.71116 2.50033 6.81116 2.50033 7.99996C2.50033 11.0364 4.96387 13.5 8.00033 13.5C9.18913 13.5 10.2891 13.1247 11.1857 12.483ZM12.4834 11.1854C13.1251 10.2888 13.5003 9.18876 13.5003 7.99996C13.5003 4.9635 11.0368 2.49996 8.00033 2.49996C6.81152 2.49996 5.71152 2.87522 4.81491 3.51689L12.4834 11.1854ZM0.666992 7.99996C0.666992 6.05504 1.43961 4.18978 2.81488 2.81451C4.19014 1.43924 6.0554 0.666626 8.00033 0.666626C9.94525 0.666626 11.8105 1.43924 13.1858 2.81451C14.561 4.18978 15.3337 6.05504 15.3337 7.99996C15.3337 9.94488 14.561 11.8101 13.1858 13.1854C11.8105 14.5607 9.94525 15.3333 8.00033 15.3333C6.0554 15.3333 4.19014 14.5607 2.81488 13.1854C1.43961 11.8101 0.666992 9.94488 0.666992 7.99996Z"
          fill="#AF2841"
        />
      </g>
    </svg>
  );
};

export default Cancel;
