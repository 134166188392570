import authHelpers from './authHelpers';
import { fixedDecimalPlaces } from './calculateFunctions';
// Get the API URL for searching products from the .env file for multiple API calls below
export const API_URL = process.env.REACT_APP_API_URL || '';
export const headers: HeadersInit = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache'
};

//helper function to pull out api calls from throughout the applicate to reduce code.
export async function fetchApiData<T>(
  apiEndpoint: string,
  accessToken: string | null,
  method: 'GET' | 'POST',
  body?: T,
  signal?: AbortSignal
) {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Headers': '*'
  };

  if (accessToken) {
    authHelpers.checkTokenExpiry(accessToken);
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const requestOptions: RequestInit = {
    method,
    headers,
    ...(method === 'POST' && body && { body: JSON.stringify(body) }),
    signal
  };

  const result = await fetch(`${API_URL}/${apiEndpoint}`, requestOptions);

  return result;
}

export async function handleApiResponse(response: Response) {
  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 401) {
      authHelpers.clearUuid();
      sessionStorage.clear();
      window.location.href = '/';
    }
    throw new Error(await response.text());
  }
}

export async function getWholesalePrice({
  accessToken,
  data,
  callbackFunction,
  loadingCallbackFunction
}: {
  accessToken: string | null;
  data: any;
  callbackFunction: (arg: string | undefined) => void;
  loadingCallbackFunction: (error?: string) => void;
}): Promise<void> {
  try {
    const result = await fetchApiData('getPrice', accessToken, 'POST', data);
    const wholesalePriceResponse = await handleApiResponse(result);
    // if there is a response, update the date state with the response.
    if (wholesalePriceResponse) {
      const pricePerSU = wholesalePriceResponse.prices[0].pricePerSU;
      const promotionPrice =
        wholesalePriceResponse.prices[0].promoValuePerSU ?? 0;
      if (pricePerSU === null && promotionPrice === 0) {
        loadingCallbackFunction('Product Not Priced');
        return;
      }
      callbackFunction(fixedDecimalPlaces(pricePerSU + promotionPrice, 2));
      loadingCallbackFunction();
    }
  } catch (error) {
    console.log(error);
    loadingCallbackFunction(
      'Error fetching Wholesale Price :' + error?.toString()
    );
  }
}
