import React, { useEffect } from 'react';
import { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';

import { getSessionToken } from '../../../helpers';
import { useGetProductDetailsQuery } from '../../../services/Products';
import { setProductDetailsData } from '../../../reducers/PriceRequestSlice';
import { useGetProductPriceHistoryMutation } from '../../../services/Products';
import { setProductPriceHistory } from '../../../reducers/ProductPriceHistorySlice';

import Heading from '../../Heading';
import ProductDetailsHeader from '../../ProductDetailsHeader';
import Tabs from '../../Tabs';
import PriceBreakdownTab from './PriceBreakdownTab';
import PriceHistoryTab from './PriceHistoryTab';
import Text from '../../Text';
import LoadingOverlay from '../../LoadingOverlay';

import styles from './ProductDetailsModal.module.scss';

import copy from '../../../config/translations/en-CA.json';
const modalCopy = copy.modals[0].productDetails;

const ProductDetailsModal = () => {
  const dispatch = useDispatch();
  const token = getSessionToken();

  const { sku } = useSelector((state: RootState) => state.priceRequest);

  const productDetailsQueryParams = sku ? sku.toString() : '';

  const { data, isSuccess, isLoading, isError } = useGetProductDetailsQuery(
    {
      queryParams: productDetailsQueryParams,
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(setProductDetailsData(data));
    }
  }, [isSuccess]);

  const [
    getProductPriceHistory,
    {
      isSuccess: priceHistoryIsSuccess,
      data: priceHistoryData,
      isLoading: priceHistoryLoading
    }
  ] = useGetProductPriceHistoryMutation();

  const requestBody = {
    sku
  };

  useEffect(() => {
    getProductPriceHistory({
      requestBody,
      token
    });
  }, [getProductPriceHistory]);

  useEffect(() => {
    if (priceHistoryIsSuccess && priceHistoryData) {
      dispatch(setProductPriceHistory(priceHistoryData.priceHistory));
    }
  }, [priceHistoryIsSuccess, priceHistoryData]);

  const tabs = [
    {
      title: modalCopy.tabs[0].label,
      content: <PriceBreakdownTab />
    },
    {
      title: modalCopy.tabs[1].label,
      content: <PriceHistoryTab loading={priceHistoryLoading} />
    }
  ];

  if (isError) {
    return (
      <div className={styles.productDetailsModalContainer}>
        <Text element="p">{modalCopy.error}</Text>
      </div>
    );
  }

  return (
    <div className={styles.productDetailsModalContainer}>
      {isLoading || priceHistoryLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <div className={styles.contentContainer}>
            <Heading element="h3" heading3 productBlockTitle>
              {modalCopy.title}
            </Heading>
            <ProductDetailsHeader />
          </div>
          <div className={styles.tabsContainer}>
            <div className={styles.tabsWrapper}>
              <Tabs tabValues={tabs} ariaLabel="Product Details Tabs" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetailsModal;
