import React from 'react';

export default function ArrowUp() {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-arrow-up">Arrow Up</title>
      <path
        d="M4.50176 2.84782L4.50176 10.8125C4.50176 11.1928 4.1666 11.5 3.75176 11.5C3.33691 11.5 3.00176 11.1928 3.00176 10.8125L3.00176 2.84782L1.28145 4.42485C0.988477 4.69342 0.512695 4.69342 0.219727 4.42485C-0.0732422 4.15628 -0.0732422 3.72013 0.219727 3.45156L3.21973 0.701426C3.5127 0.432858 3.98848 0.432858 4.28145 0.701426L7.28144 3.45156C7.57441 3.72013 7.57441 4.15628 7.28144 4.42485C6.98848 4.69342 6.51269 4.69342 6.21973 4.42485L4.50176 2.84782Z"
        fill="currentColor"
      />
    </svg>
  );
}
