import React from 'react';
import { useDispatch } from 'react-redux';

import { resetState } from '../../../reducers/CSVBulkUploadSlice';

import Button from '../../Button';
import Heading from '../../Heading';
import InfoCircle from '../../Icons/InfoCircle';
import Text from '../../Text';

import styles from './ParseCSVModal.module.scss';

import copy from '../../../config/translations/en-CA.json';
const modalCopy = copy.modals[0].parseCSVModal;

interface ProgressPaneProps {
  handleCancelToggle: (value: boolean) => void;
  progressTotal: number | undefined;
  progressAmount: number;
  totalRows: number;
}

const ProgressPane: React.FC<ProgressPaneProps> = ({
  handleCancelToggle,
  progressTotal,
  progressAmount,
  totalRows
}) => {
  const dispatch = useDispatch();

  const progressWithInvalidRows =
    progressTotal && progressAmount > 0
      ? totalRows - progressTotal + progressAmount
      : 0;

  return (
    <>
      <div
        className={`${styles.parseCSVModalIcon} ${styles.parseCSVModalIcon__info}`}
      >
        <InfoCircle />
      </div>
      <Heading element="h3" heading3>
        {modalCopy.title}
      </Heading>

      {progressTotal ? (
        <>
          <Text element="p">{modalCopy.subtitle}</Text>
          <Text element="p" bold>
            {modalCopy.warning}
          </Text>
          <div className={styles.parseCSVModalProgressContainer}>
            <div className={styles.parseCSVModalProgressBar}>
              <span
                className={styles.parseCSVModalProgressFill}
                style={{
                  width: `${(progressAmount / progressTotal) * 100}%`
                }}
              />
            </div>
            <span>
              {progressWithInvalidRows} of {totalRows} {modalCopy.progress}
            </span>
          </div>
        </>
      ) : totalRows ? (
        <></>
      ) : (
        modalCopy.noData
      )}

      <div className={styles.parseCSVModalActions}>
        {progressAmount === progressTotal && progressAmount > 0 ? ( //should not show the Close modal when initial load
          <Button link onClick={() => dispatch(resetState())}>
            Close modal
          </Button>
        ) : (
          <Button link onClick={() => handleCancelToggle(true)}>
            {modalCopy.cancel}
          </Button>
        )}
      </div>
    </>
  );
};

export default ProgressPane;
