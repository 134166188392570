import React from 'react';
import { useAuth } from '../../contexts/AuthenticationProvider';
import classNames from 'classnames';

import Heading from '../Heading';
import NavigationTab from '../NavigationTabs';
import SignInButton from '../SignInButton';
import SignOutButton from '../SignOutButton';
import LDBLogo from '../../assets/ldbWholesaleLogo.png';
import HeaderUserLink from './_HeaderUserLink';

import styles from './Header.module.scss';

const Header = () => {
  const { authState } = useAuth();
  const { user, isAuthenticated, isError } = authState;
  const { firstName, lastName, email } = user;

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerTitle}>
          <div className={styles.headerLogoContainer}>
            <img
              className={styles.logo}
              src={LDBLogo}
              alt="LDB Wholesale Operations"
            />
            <Heading element="h1" title>
              Wholesale Price Management
            </Heading>
          </div>
          {isAuthenticated && (
            <div className={styles.headerUserContainer}>
              <div className={styles.headerUserAvatar}>
                <HeaderUserLink>
                  <span className={styles.headerUserAvatar}>
                    {firstName.at(0)}
                    {lastName.at(0)}
                  </span>
                </HeaderUserLink>
              </div>
              <div className={styles.headerUserDetails}>
                <HeaderUserLink>
                  <span>
                    {firstName} {lastName}
                  </span>
                </HeaderUserLink>
                <HeaderUserLink>
                  <span className={styles.bold}>{email}</span>
                </HeaderUserLink>
              </div>
            </div>
          )}
        </div>
        <div className={styles.navContainer}>
          <nav className={styles.navWrapper}>
            <NavigationTab route={'/'}>Home</NavigationTab>
            <NavigationTab route={'/price-request-list'}>
              My Price Requests
            </NavigationTab>
            <NavigationTab route={'/price-request'}>
              New Price Requests
            </NavigationTab>
            <NavigationTab route={'/product-search'}>
              Product Search
            </NavigationTab>
          </nav>
          <div
            className={classNames(styles.authenticationContainer, {
              [styles.authenticated]: isAuthenticated
            })}
          >
            {isAuthenticated || isError ? <SignOutButton /> : <SignInButton />}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
