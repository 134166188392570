export enum PriceRequestStatusEnum {
  DRAFT = 'Draft',
  PROCESSING = 'Processing',
  LDB_REVIEW = 'LDB Review',
  AS_REVIEW = '! A/S Review',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  PENDING = 'Pending',
  QUEUED = 'Queued',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined'
}

export const {
  DRAFT,
  PROCESSING,
  LDB_REVIEW,
  AS_REVIEW,
  COMPLETED,
  CANCELLED,
  PENDING,
  QUEUED,
  ACCEPTED,
  DECLINED
} = PriceRequestStatusEnum;

export type PriceRequestStatus = string;

export const PRICE_REQUEST_STATUS_MAP: Map<PriceRequestStatus, string> =
  new Map<PriceRequestStatus, string>([
    [DRAFT, 'Draft'],
    [PROCESSING, 'Processing'],
    [LDB_REVIEW, 'LDB Review'],
    [AS_REVIEW, '! A/S Review'],
    [COMPLETED, 'Completed'],
    [CANCELLED, 'Cancelled'],
    [PENDING, 'Pending'],
    [QUEUED, 'Queued'],
    [ACCEPTED, 'Accepted'],
    [DECLINED, 'Declined']
  ]);
