//Function that takes in a status number returned from an API call and converts it into its matching string description. For example for populating pill tags with Active instead of 2

// just in case the correct long form string version of the status is passed in through the product information from the API call, return the status if not 1 - 4.

import { IStatusMap } from '../types/productSearchTypes';

export const productStatusSearch = (status: string) => {
  const statusMap: IStatusMap = {
    '1': 'Pending Listing',
    '2': 'Active',
    '3': 'Pending Delist',
    '4': 'Delisted'
  };

  return statusMap[status] || status;
};
