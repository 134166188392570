import React from 'react';

import Text from '../Text';

import copy from '../../config/translations/en-CA.json';
import styles from './Footer.module.scss';

const Footer = () => {
  const footerCopy = copy.footer;
  const copyright = '\u00A9 2024';
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerWrapper}>
        <Text
          element="p"
          small
          light
        >{`${copyright} ${footerCopy.content}`}</Text>
      </div>
    </footer>
  );
};

export default Footer;
