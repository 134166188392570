import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { SearchFormDataType } from '../types/productSearchTypes';

import { defaultSelectNumber, defaultSelectString } from '../helpers';

const initialFormState = {
  sku: null,
  product: '',
  hierarchyClass: defaultSelectNumber,
  status: defaultSelectString,
  listingAgent: defaultSelectNumber,
  pricingAgent: defaultSelectNumber,
  category: defaultSelectNumber,
  subCategory: defaultSelectNumber,
  domesticImportFlag: defaultSelectString
};

interface ProductSearchState {
  persistedSearch: boolean;
  confirmSearchModalOpen: boolean;
  productSearchFilters: SearchFormDataType;
}

const initialState: ProductSearchState = {
  persistedSearch: false,
  confirmSearchModalOpen: false,
  productSearchFilters: initialFormState
};

export const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    setProductSearchFilters: (
      state,
      action: PayloadAction<Partial<SearchFormDataType>>
    ) => {
      state.productSearchFilters = {
        ...state.productSearchFilters,
        ...action.payload
      };
    },
    setPersistedSearch: (state, action: PayloadAction<boolean>) => {
      state.persistedSearch = action.payload;
    },
    setConfirmSearchModalOpen: (state, action: PayloadAction<boolean>) => {
      state.confirmSearchModalOpen = action.payload;
    },
    resetProductSearch: (state) => {
      state = initialState;
    },
    resetProductSearchFilters: (state) => {
      state.productSearchFilters = initialFormState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setProductSearchFilters,
  resetProductSearchFilters,
  setPersistedSearch,
  setConfirmSearchModalOpen
} = productSearchSlice.actions;

export default productSearchSlice.reducer;
