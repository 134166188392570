import React, { useRef, useState } from 'react';
import Randomstring from 'randomstring';

import styles from './index.module.scss';

import ChevronRight from '../Icons/ChevronRight';

type PropTypes = {
  children: React.ReactNode;
  heading: string;
};

const generateRandomId = (length = 4): string => {
  return Randomstring.generate(length);
};

function Collapse({ children, heading }: PropTypes) {
  const contentRef = useRef(null);
  const [isContentOpen, setIsContentOpen] = useState(false);
  const uniqueId = generateRandomId();

  return (
    <div className={styles.collapseContainer}>
      <div>
        <button
          aria-expanded={isContentOpen}
          aria-controls={uniqueId}
          onClick={() => setIsContentOpen(!isContentOpen)}
          className={styles.collapseButton}
        >
          <span>{heading}</span>
          <ChevronRight />
        </button>
      </div>
      <div
        id={uniqueId}
        aria-hidden={!isContentOpen}
        ref={contentRef}
        className={styles.collapseContent}
      >
        {children}
      </div>
    </div>
  );
}

export default Collapse;
