import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

import Papa from 'papaparse';

import {
  clearUploadErrors,
  resetState,
  setUploadErrors,
  setData,
  transitionToParseCSVModal,
  resetValidation
} from '../../reducers/CSVBulkUploadSlice';

import { getHeaders, UserTypeEnum } from '../../helpers';

import Text from '../Text';
import GarbageCan from '../Icons/GarbageCan';
import Button from '../Button';
import Document from '../Icons/Document';

import styles from './CSVUpload.module.scss';
import copy from '../../config/translations/en-CA.json';

const csvHeaders = getHeaders(UserTypeEnum.INTERNAL);

interface UploadConfirmationProps {
  setUserUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
  userUploadedFile: File | null;
}

const UploadConfirmation: React.FC<UploadConfirmationProps> = ({
  setUserUploadedFile,
  userUploadedFile
}) => {
  const modalCopy = copy.modals[0].uploadCSVModal.confirmation;
  const dispatch = useDispatch();

  const { uploadErrors } = useSelector(
    (state: RootState) => state.csvBulkUpload
  );

  const addRowNumbers = useCallback((data: any) => {
    const dataWithRowNumbers = [...data];

    dataWithRowNumbers.forEach((obj, index) => {
      obj.row = index + 1;
    });

    return dataWithRowNumbers;
  }, []);

  const handleUploadClick = async () => {
    if (!userUploadedFile) return;

    dispatch(resetValidation());

    const errors: { title: string; description: string }[] = [];

    if (userUploadedFile.type !== 'text/csv') {
      errors.push({
        title: modalCopy.errors.filetype.title,
        description: modalCopy.errors.filetype.description
      });
    }

    if (userUploadedFile.size > 31457280) {
      errors.push({
        title: modalCopy.errors.filesize.title,
        description: modalCopy.errors.filesize.description
      });
    }

    let parsedCSVData: any = [];

    try {
      parsedCSVData = await parseCSV();
    } catch (error) {
      errors.push({
        title: modalCopy.errors.parse.title,
        description: modalCopy.errors.parse.description
      });
    }

    if (parsedCSVData.length > 300) {
      errors.push({
        title: modalCopy.errors.rowLength.title,
        description: modalCopy.errors.rowLength.description
      });
    }

    if (errors.length === 0) {
      const parsedDataWithRows = addRowNumbers(parsedCSVData);
      dispatch(setData(parsedDataWithRows));
      dispatch(transitionToParseCSVModal());
    } else {
      dispatch(setUploadErrors(errors));
    }
  };

  const parseCSV = () => {
    return new Promise((resolve, reject) => {
      Papa.parse(userUploadedFile as File, {
        header: true,
        transformHeader: (header, idx) => {
          return csvHeaders[idx]?.key;
        },
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results.data);
        },
        error: (error) => {
          console.error(modalCopy.errors.parse.console, error);
          reject(error);
        }
      });
    });
  };

  return (
    <>
      <div className={styles.userUploadDisplay}>
        <Document />
        {userUploadedFile?.name}
        <Button
          icon={<GarbageCan />}
          iconLeft
          link
          small
          onClick={() => {
            setUserUploadedFile(null);
            dispatch(clearUploadErrors());
          }}
        />
      </div>
      <div className={styles.userUploadActions}>
        <Button
          primary
          onClick={handleUploadClick}
          disabled={!!uploadErrors.length}
          data-testid="upload-modal-button"
        >
          {modalCopy.upload}
        </Button>
        <Button
          link
          onClick={() => {
            setUserUploadedFile(null);
            dispatch(resetState());
          }}
          data-testid="cancel-modal-button"
        >
          {modalCopy.cancel}
        </Button>
      </div>
    </>
  );
};

export default UploadConfirmation;
