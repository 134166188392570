import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';

import { ResourceTypes } from '../types/HomeTypes';

type RequestPayload = {
  token: string | null;
};

export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getResources: builder.query<ResourceTypes, RequestPayload>({
      query: ({ token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `getResources`
      })
    }),
    getAnnouncement: builder.query<any, RequestPayload>({
      query: ({ token }) => ({
        headers: {
          Authorization: `Bearer ${token}`
        },
        url: `getAnnouncement`,
        responseHandler: (response) => response.text()
      })
    })
  })
});

export const { useGetResourcesQuery, useGetAnnouncementQuery } = homeApi;
