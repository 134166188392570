import {
  currencyCellText,
  dateFormat,
  CSV_VALID_REQUEST_TYPES,
  textCelloutput
} from '../../helpers';

import { CellPropsContent } from '../../types/productSearchTypes';

import Pill from '../Pill';
import Text from '../Text';

export const csvErrorTableColumns = [
  {
    Header: 'CSV Row',
    accessor: 'row',
    Cell: (props: CellPropsContent) => {
      return (
        <Text element="p" bold small tableCell>
          {props.value}
        </Text>
      );
    }
  },
  {
    Header: 'SKU #',
    accessor: 'sku',
    Cell: (props: CellPropsContent) => {
      return (
        <Text
          element="p"
          bold
          small
          tableCell
          tooltip={props.value?.toString()}
        >
          {props.value}
        </Text>
      );
    }
  },
  {
    Header: 'Product Name',
    accessor: 'product_name',
    Cell: (props: CellPropsContent) => {
      return (
        <Text element="p" bold dark tableCell tooltip={props.value}>
          {props.value}
        </Text>
      );
    }
  },

  {
    Header: 'PR Type',
    accessor: 'request_type',
    Cell: (props: CellPropsContent) => {
      const prType = props.value?.toUpperCase();
      const displayPrType = CSV_VALID_REQUEST_TYPES.includes(prType)
        ? prType
        : 'Invalid';

      return (
        <Pill type="neutral" secondary>
          {displayPrType}
        </Pill>
      );
    },
    tipText: 'Price Request Type'
  },

  {
    Header: 'Effective Date',
    accessor: 'effective_date',
    Cell: (props: CellPropsContent) => {
      return <Pill type="date">{dateFormat(props.value)}</Pill>;
    }
  },
  {
    Header: 'Prime Cost',
    accessor: 'prime_cost_per_case',
    Cell: (props: CellPropsContent) => currencyCellText(props.value),
    tipText: 'Prime Cost Per Case'
  },
  {
    Header: 'Domestic Charges',
    accessor: 'domestic_charges_per_case',
    Cell: (props: CellPropsContent) => currencyCellText(props.value),
    tipText: 'Domestic Charges Per Case'
  },
  {
    Header: 'Duty Paid Cost',
    accessor: 'duty_paid_cost_per_case',
    Cell: (props: CellPropsContent) => currencyCellText(props.value),
    tipText: 'Duty Paid Cost Per Case'
  },
  {
    Header: 'Currency Code',
    accessor: 'currency_label',
    Cell: (props: CellPropsContent) => {
      return (
        <Text element="p" tableCell>
          {props.value}
        </Text>
      );
    }
  },
  {
    Header: 'FX Rate Date',
    accessor: 'currency_effective_date',
    Cell: (props: CellPropsContent) => textCelloutput(props.value, 'date')
  },
  {
    Header: 'Exchange Rate',
    accessor: 'exchange_rate',
    Cell: (props: CellPropsContent) => textCelloutput(props.value, 'currency')
  },
  {
    Header: 'Foreign Prime',
    accessor: 'foreign_prime_cost_per_case',
    Cell: (props: CellPropsContent) => currencyCellText(props.value)
  },
  {
    Header: 'Promotion Amount',
    accessor: 'promotion_amount_per_su',
    Cell: (props: CellPropsContent) => currencyCellText(props.value)
  },
  {
    Header: 'Error Message',
    accessor: 'error_message',
    Cell: (props: CellPropsContent) => {
      return (
        <Text element="p" bold action tableCell tooltip={props.value}>
          {props.value}
        </Text>
      );
    }
  }
];
