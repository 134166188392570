import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { dateFormat, convertToPSTDateObject, getPSTDate } from '../../helpers';

import Text from '../Text';
import LegendCircle from '../Icons/LegendCircle';
import Label from '../Label';
import Calendar from '../Icons/Calendar';

import 'react-datepicker/dist/react-datepicker.css'; // out of the box styles from react datepicker
import styles from './CalendarDatePicker.module.scss';
import './index.scss'; // For react datepicker styles
import classNames from 'classnames';

type PropsType = {
  label: string;
  id: string;
  legend?: string;
  required?: boolean;
  helperText?: string;
  minDate?: string;
  value: string;
  onChange: (event: any) => void;
  highlightedDates?: string[] | null;
  placeholderText?: string;
  disabled?: boolean;
  helper?: boolean;
  size?: 'large' | 'small';
  dateShift?: number;
  isError?: boolean;
  showDropdowns?: boolean;
};

const CalendarDatePicker = ({
  label,
  id,
  legend,
  required,
  helperText,
  minDate,
  value,
  highlightedDates,
  onChange,
  disabled,
  placeholderText,
  helper,
  size,
  dateShift,
  isError,
  showDropdowns
}: PropsType) => {
  const methods = useFormContext();
  const [inputError, setInputError] = useState(false);
  const name = id;

  useEffect(() => {
    if (methods.formState.errors[id] || isError) {
      setInputError(true);
      console.log(methods.formState.errors[id]);
    } else {
      setInputError(false);
    }
  }, [methods.formState.errors, id, setInputError, inputError, isError]);

  // if highlightedDates prop is included, we want to add circles to those dates provided in the dropdown
  // React DatePicker is expecting either a Date or an array of Dates passed into highlightDates prop, so
  // map through the highlightedDates props if exists and create array of dates
  let highlight: Date[] = [];
  if (highlightedDates) {
    highlight = highlightedDates.map(
      (highlightedDate: string) => new Date(highlightedDate)
    );
  }

  const [minimumDateAllowed, setMinimumDateAllowed] = useState<Date>(
    new Date()
  );

  useEffect(() => {
    if (minDate) {
      const additionalDays = dateShift ? dateShift : 1;
      const nextDay = convertToPSTDateObject(
        getPSTDate(minDate, additionalDays)
      );
      setMinimumDateAllowed(nextDay);
    } else {
      setMinimumDateAllowed(new Date(+0));
    }
  }, [minDate, dateShift]);

  const selectedDateValue = dateFormat(value, false);
  return (
    <div
      className={classNames(styles.calendarInputContainer, {
        [styles.calendarInputContainerSmall]: size === 'small',
        [styles.calendarInputContainerLarge]: size === 'large',
        [styles.calendarInputContainerError]: isError
      })}
    >
      <Label htmlFor={id} error={inputError}>
        {label}
      </Label>
      <Controller
        name={id}
        control={methods.control}
        render={({ field, fieldState: { error } }) => (
          <>
            <DatePicker
              todayButton={showDropdowns ? 'Today' : ''}
              showMonthDropdown={!!showDropdowns}
              showYearDropdown={!!showDropdowns}
              id={id}
              showPopperArrow={false}
              wrapperClassName={styles.datePicker}
              className={styles.datepicker}
              calendarClassName={styles.calendarContainer}
              value={selectedDateValue}
              selected={selectedDateValue ? new Date(selectedDateValue) : null}
              onChange={onChange}
              formatWeekDay={(nameOfDay: string) =>
                nameOfDay.toString().substring(0, 3)
              }
              minDate={minimumDateAllowed}
              highlightDates={highlight}
              disabled={disabled}
              autoComplete="off"
              placeholderText={placeholderText}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              disabledKeyboardNavigation
            >
              {legend && (
                <span className={styles.legendContainer}>
                  <LegendCircle />
                  <Text element="p" tiny>
                    {legend}
                  </Text>
                </span>
              )}
            </DatePicker>
            <span className={styles.iconContainer}>
              <Calendar />
            </span>
            {helper && (
              <div className={styles.textContainer}>
                {methods.formState.errors[id] ? (
                  <ErrorMessage
                    errors={methods.formState.errors}
                    name={name}
                    render={({ message }) => (
                      <Text
                        element="p"
                        small
                        className={styles.helperTextError}
                      >
                        {message}
                      </Text>
                    )}
                  />
                ) : helperText ? (
                  <Text element="p" small className={styles.helperText}>
                    {helperText}
                  </Text>
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default CalendarDatePicker;
