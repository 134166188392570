import { useEffect, useState, useContext, useMemo, ChangeEvent } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { CurrencyContext } from '../../../contexts/CurrencyProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import {
  setRefreshDrafts,
  setOpenPRModal,
  setLoadingState,
  setRecentPriceRequestType,
  setPrevSaveFunction
} from '../../../reducers/PriceRequestSlice';

import { triggerActivityMonitorReset } from '../../../reducers/ActivityMonitorSlice';

import {
  useCreateDraftPriceRequestMutation,
  useDeleteSinglePriceRequestMutation,
  useUpdateDraftPriceRequestMutation,
  useValidatePromotionRequestMutation
} from '../../../services/PriceRequest';

import { useCalculateMutation } from '../../../services/Calculate';
import { mapCalculateResponse } from '../../../helpers/mapCalculateResponse';
import { useGetPriceComponentsMutation } from '../../../services/PriceComponents';

import {
  OptionsType,
  SelectInputType,
  ValidatePromotion,
  ValidationMessage,
  WorkingGroupPrDetails,
  PriceRequest,
  CalendarDate,
  Product,
  ValidationMessageType,
  SaveFunctionality
} from '../../../types';

import {
  fetchApiData,
  handleApiResponse,
  getWholesalePrice,
  getSessionToken,
  formatNumericDate,
  priceChangeModalCheck,
  wholesalePromotionModalCheck,
  errorMapping,
  fixedDecimalPlaces,
  mapPriceRequestReason,
  extractOtherReason,
  categorySearch,
  productStatusSearch,
  hiddenFieldCheck,
  decodeSanitizedValue
} from '../../../helpers';

import Heading from '../../Heading';
import Text from '../../Text';
import Pill from '../../Pill';
import Button from '../../Button';
import Banner from '../../Banner';
import TextInput from '../../TextInput';
import CurrencyInput from '../../CurrencyInput';
import SelectInput from '../../SelectInput';
import Calculator from '../../Icons/Calculator';
import Checkmark from '../../Icons/CheckMark';
import ErrorAlert from '../../Icons/ErrorAlert';
import CalendarDatePicker from '../../CalendarDatePicker';
import Checkbox from '../../Checkbox';
import LoadingOverlay from '../../LoadingOverlay';
import TextArea from '../../TextArea';
import ExclamationPoint from '../../Icons/ExclamationPoint';
import NextArrow from '../../Icons/NextArrow';
import GarbageCan from '../../Icons/GarbageCan';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import styles from './index.module.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { schema, FormData } from './_schema';

import copy from '../../../config/translations/en-CA.json';

const modalCopy = copy.modals[0].addNewPriceRequestLDB;

const COST_FIELDS: (keyof FormData)[] = [
  'primeCostPerCase',
  'dutyPaidCost',
  'domesticChargesPerCase',
  'promotionAmount',
  'estWholesalePrice',
  'wholesalePriceAsOfEffectiveDate'
];

type PropsType = {
  product: Product;
  onRequestClose: () => void;
  dates: CalendarDate[];
  prDetails?: WorkingGroupPrDetails;
  fullData: PriceRequest[] | undefined;
};

const AddNewPRLDBModal = ({
  product,
  onRequestClose,
  dates,
  prDetails,
  fullData
}: PropsType) => {
  const token = getSessionToken();
  const dispatch = useDispatch();

  //Pull in currency context to be used in the foreign costs calculation section
  const { currencies, addCurrencies } = useContext(CurrencyContext);

  const {
    product_name,
    sku,
    status_code,
    category,
    alcohol_percentage,
    unit_description,
    country_of_export,
    is_cost_dpc,
    prime_cost_per_case,
    domestic_charges_per_case,
    duty_paid_cost_per_case,
    regular_price_per_su,
    final_price_per_su,
    promotion_amount_per_su,
    foreign_prime_cost
  } = product;

  const isDomesticProduct = country_of_export === 'CA';

  const [formData, setFormData] = useState<WorkingGroupPrDetails | undefined>(
    prDetails
  );

  //state used to determine if the PR should use updatePriceRequest or createPriceRequest
  const [usePrDetails, toggleUsePrDetails] = useState<boolean>(
    prDetails && prDetails.id ? true : false
  );

  useEffect(() => {
    if (usePrDetails) {
      setFormData(prDetails);
    } else {
      setFormData(undefined);
      resetModal();
    }
  }, [usePrDetails]);

  const [updateCreatePR, setUpdateCreatePR] = useState<boolean>(false);

  const [priceRequestBodyCalculate, setPriceRequestBodyCalculate] = useState<
    PriceRequest | undefined
  >(undefined);

  const [
    createPriceRequest,
    {
      isError: createIsError,
      isSuccess: createIsSuccess,
      error: createError,
      isLoading: createLoading
    }
  ] = useCreateDraftPriceRequestMutation();

  const [
    updatePriceRequest,
    {
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      isLoading: updateLoading
    }
  ] = useUpdateDraftPriceRequestMutation();

  const [
    validatePromotionRequest,
    {
      isSuccess: validatePromotionIsSuccess,
      isLoading: validatePromotionLoading,
      isError: validatePromotionIsError,
      data: validatePromotionResponse
    }
  ] = useValidatePromotionRequestMutation();

  const [
    deleteSinglePriceRequest,
    { isSuccess: deleteSingleIsSuccess, isLoading: deleteSingleIsLoading }
  ] = useDeleteSinglePriceRequestMutation();

  const deleteSinglePayload = {
    prID: Number(formData?.id),
    token
  };

  useEffect(() => {
    if (deleteSingleIsSuccess) {
      dispatch(setOpenPRModal(false));
      dispatch(setRefreshDrafts(true));
    }
  }, [deleteSingleIsSuccess, dispatch]);

  const { loadingState, recentPriceRequestType, prevSaveFunction } =
    useSelector((state: RootState) => state.priceRequest);

  const [interfaceWarnings, setInterfaceWarnings] = useState<
    {
      title: string;
      description: string;
      exceptionReason?: string;
      justificationRequired: boolean;
    }[]
  >([]);

  // base empty states for calculation warnings and errors, this will be updated and filled when the calculate API is run
  const [calculateWarnings, setCalculateWarnings] = useState<
    ValidationMessage[]
  >([]);
  const [calculateErrors, setCalculateErrors] = useState<ValidationMessage[]>(
    []
  );
  //check that no form errors, or calculate API errors  exist in the modal
  const [formErrorsExist, setFormErrorsExist] = useState(false);
  const [calculateErrorsExist, setCalculateErrorsExist] = useState(false);

  //disable the entire form inputs if warnings are present, until the user has entered justification and clicked
  const [disableForm, toggleDisableForm] = useState(false);

  const [justificationRequired, toggleJustificationRequired] = useState(false);
  const [justification, setjustification] = useState(
    formData?.justification || ''
  );

  useEffect(() => {
    setCalculateErrorsExist(calculateErrors.length > 0);
  }, [calculateErrors]);

  //base state for if the price request was saved using the Confirm & Save button
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);

  const intialPrTypeLabel: Record<string, string> = {
    NP: 'New Product Pricing/ Reactivation',
    PC: 'Price Change (PC)',
    WPP: 'Wholesale Price Promotion (WPP)'
  };

  const intialPrTypeValue: Record<string, string> = {
    NP: 'New Product Pricing/ Reactivation',
    PC: 'Supplier Price Change',
    WPP: 'Wholesale Price Promotion'
  };

  //base states for select inputs
  //check to see if pr details exist and apply they for an edit modal, then check
  //to see if the most recent PR type has been set and apply that
  //otherwise use PC default type
  const defaultPRType =
    formData?.['PR Type'] !== undefined
      ? {
          label:
            formData?.['PR Type'] && intialPrTypeLabel[formData?.['PR Type']],
          value:
            formData?.['PR Type'] && intialPrTypeValue[formData?.['PR Type']]
        }
      : recentPriceRequestType !== undefined
      ? {
          label: recentPriceRequestType.label,
          value: recentPriceRequestType.value
        }
      : {
          label: intialPrTypeLabel['PC'],
          value: intialPrTypeValue['PC']
        };

  const [selectedRequestType, setSelectedRequestType] = useState<{
    label: string;
    value: string;
  }>(defaultPRType);

  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState(
    (usePrDetails && formData?.['Effective Date']) || ''
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    (usePrDetails && formData?.['End Date']) || ''
  );

  const initialEndDate = new Date();
  const [minEndDate, setMinEndDate] = useState(initialEndDate);

  const [selectedFxRateDate, setSelectedFxRateDate] = useState(
    (usePrDetails && formData?.fx_rate_date) || ''
  );

  const [selectedReason, setSelectedReason] = useState<OptionsType>({
    label: mapPriceRequestReason(formData?.reason, 'label'),
    value: mapPriceRequestReason(formData?.reason, 'value')
  });

  const [reasonOptions, setReasonOptions] = useState(modalCopy.reasonOptions);

  const [inputtedOtherReason, setInputtedOtherReason] = useState(
    usePrDetails ? extractOtherReason(formData?.reason) : ''
  );

  const [selectedCostingEvent, setSelectedCostingEvent] = useState(
    formData?.costing_event_comment
  );

  const [calculatedExchangeRate, setCalculatedExchangeRate] = useState<
    number | undefined
  >(formData?.exchange_rate);
  //creating base state for the wholesalePriceEffectiveDate value that is passed into the wholesalePriceAsOfEffectiveDate input that is disabled and uses an api call to retrieve the data.
  const [wholesalePriceEffectiveDate, setWholesalePriceEffectiveDate] =
    useState<string | undefined>(undefined);

  // Filter out all the calendar dates that have a type of PRICE for Price Change and a subType of REGULAR.
  const priceDates = dates.filter(
    (date: CalendarDate) => date.type === 'PRICE' && date.subType === 'REGULAR'
  );
  //Filter out all of the calendar dates that have a type of PROMOTION for WPP and a subType of REGULAR.
  const promotionDates = dates.filter(
    (date: CalendarDate) =>
      date.type === 'PROMOTION' && date.subType === 'REGULAR'
  );

  //Create the price and promotion label value pairs for all of the appropriate dates to creat the select options array for the DateSelectMenu input labelled Effective Date in the form below.
  const priceCalendarHighlightedDates = priceDates?.map(
    (date: CalendarDate) => {
      return date.effectiveDate;
    }
  );

  const promotionCalendarHighlightedDates = promotionDates?.map(
    (date: CalendarDate) => {
      return date.effectiveDate;
    }
  );

  const defaultFormValues = {
    requestType: defaultPRType,
    effectiveDate: (usePrDetails && formData?.['Effective Date']) || '',
    endDate: (usePrDetails && formData?.['End Date']) || '',
    dutyPaidCost:
      (usePrDetails &&
        formData?.duty_paid_cost_per_case &&
        formData?.duty_paid_cost_per_case?.toFixed(2).toString()) ||
      undefined,
    estWholesalePrice:
      (usePrDetails &&
        formData?.['Est. WS Price'] &&
        formData?.['Est. WS Price']?.toFixed(2).toString()) ||
      undefined,
    primeCostPerCase:
      (usePrDetails &&
        formData?.prime_cost_per_case &&
        formData?.prime_cost_per_case.toFixed(2).toString()) ||
      undefined,
    wholesalePriceAsOfEffectiveDate: undefined,
    promotionAmount:
      (usePrDetails &&
        formData?.['Promotion Amount'] &&
        formData?.['Promotion Amount'].toFixed(2).toString()) ||
      undefined,
    domesticChargesPerCase:
      (usePrDetails &&
        formData?.domestic_charges_per_case &&
        formData?.domestic_charges_per_case?.toFixed(2).toString()) ||
      undefined,
    currencyCode: { label: '', value: '' },
    fxRateDate: undefined,
    exchangeRate: undefined,
    foreignPrimeCost: undefined,
    agentSupplierReview: false,
    costOnly: false,
    reason: undefined,
    costingEventComment: '',
    addNewPRJustification: usePrDetails ? formData?.justification : undefined,
    email:
      usePrDetails && formData?.email && formData?.email.length > 0
        ? formData?.email
        : undefined
  };

  //pass in the schema defined above to our useForm so react hook form has access to it along with the validation modes (when to apply validation) and the default values so the form has a reference to detect changes
  const methods = useForm<FormData>({
    resolver: yupResolver<any>(schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues
  });

  const formValues = methods.getValues();

  const currencyErrors = [
    methods.getFieldState('foreignPrimeCost').invalid,
    methods.getFieldState('exchangeRate').invalid,
    methods.getFieldState('currencyCode').invalid,
    methods.getFieldState('fxRateDate').invalid
  ];

  const currencyErrorExists = currencyErrors.includes(true);
  //State to indicate the Automatically Update of FxDate : avoid extra calls
  const [currencyFxDateAutoUpdated, setCurrencyFxDateAutoUpdated] =
    useState(false);
  // Variables outlined below to hold different form input values and or incoming product data
  // Request type is Supplier Price Change
  const priceChangeRequest =
    formValues.requestType.value === 'Supplier Price Change' ||
    formValues.requestType.value === 'New Product Pricing/ Reactivation';

  const isNewProduct =
    selectedRequestType.value === 'New Product Pricing/ Reactivation';

  const wholesalePromotionRequest =
    formValues.requestType.value === 'Wholesale Price Promotion';

  // Product is "Domestic" AND NOT on the list of the products requiring Duty Paid cost as indicated by flag in product data API
  const priceChangeDomesticNotDPCProduct =
    priceChangeRequest && isDomesticProduct && is_cost_dpc === 'NO';
  // Product is Import
  const priceChangeImportProduct = priceChangeRequest && !isDomesticProduct;
  // Product is "Domestic" AND on the list of the products requiring Duty Paid cost as indicated by flag in product data API
  const priceChangeDomesticDPCProduct =
    priceChangeRequest && isDomesticProduct && is_cost_dpc === 'YES';

  //Calculate button states and functionality
  // base state for if the calculate button is disabled, set to true to start
  const [disableCalculateBtn, setDisableCalculateBtn] = useState(true);

  // Track invalid calculate inputs or response for error handling
  const [invalidCalculateResponse, setInvalidCalculateResponse] =
    useState(false);

  // base state for the type of scenario this is for (options 1 - 4). This is determined by the product skus data (ie if the product is import);
  const [scenarioType, setScenarioType] = useState(1);

  //create base states for the cost inputs if the values are available and passed into the modal
  const [inputtedPrimeCostValue, setInputtedPrimeCostValue] = useState<
    string | undefined
  >(
    usePrDetails &&
      typeof formData?.prime_cost_per_case !== 'undefined' &&
      formData?.prime_cost_per_case !== null
      ? `${fixedDecimalPlaces(formData?.prime_cost_per_case, 2)}`
      : undefined
  );

  const [inputtedDutyPaidCostValue, setInputtedDutyPaidCostValue] = useState<
    string | undefined
  >(
    usePrDetails &&
      typeof formData?.duty_paid_cost_per_case !== 'undefined' &&
      formData?.duty_paid_cost_per_case !== null
      ? `${fixedDecimalPlaces(formData?.duty_paid_cost_per_case, 2)}`
      : undefined
  );

  const [inputtedDomesticChargesValue, setInputtedDomesticChargesValue] =
    useState<string | undefined>(
      usePrDetails &&
        typeof formData?.domestic_charges_per_case !== 'undefined' &&
        formData?.domestic_charges_per_case !== null
        ? `${fixedDecimalPlaces(formData?.domestic_charges_per_case, 2)}`
        : undefined
    );

  const [inputtedPromotionAmountValue, setInputtedPromotionAmountValue] =
    useState<string | undefined>(
      usePrDetails && formData?.promotion_amount_per_su
        ? fixedDecimalPlaces(formData?.promotion_amount_per_su, 2)
        : undefined
    );

  const [inputtedEstWholesalePriceValue, setInputtedEstWholesalePriceValue] =
    useState<string | undefined>(
      usePrDetails && formData?.['Est. WS Price']
        ? fixedDecimalPlaces(formData?.['Est. WS Price'], 2).toString()
        : undefined
    );

  const [inputtedForeignPrimeCost, setInputtedForeignPrimeCost] = useState<
    string | undefined
  >(
    usePrDetails
      ? formData?.foreign_prime_cost !== undefined
        ? fixedDecimalPlaces(formData?.foreign_prime_cost, 2)?.toString()
        : undefined
      : undefined
  );

  const [inputtedEmail, setInputtedEmail] = useState(
    usePrDetails && formData?.email ? formData?.email : undefined
  );
  // Exception reasons are created based on the various warnings.
  // These are held within the en-CA.json file and need to be
  // concatenated into one string with a semicolon in between.
  const [exceptionOtherReason, setExceptionOtherReason] = useState('');

  const [floorPrice, setFloorPrice] = useState(0);
  // When Cost Only Check box is checked (i.e.: True), disable the following
  // Est. WS Price /SU & Calculate button
  const [costOnlyCheck, setCostOnlyCheck] = useState(
    usePrDetails ? formData?.const_only_fl : false
  );
  const [agentSupplierCheck, setAgentSupplierCheck] = useState(
    usePrDetails ? formData?.a_s_review_fl : false
  );

  const [
    calculate,
    {
      isLoading: isLoadingCalculate,
      isSuccess: isSuccessCalculate,
      isError: isErrorCalculate,
      data: calculateCostsResponse
    }
  ] = useCalculateMutation();

  const [updatedEstWSPrice, setUpdatedEstWSPrice] = useState(false);

  // if the product is an import product, we need to call the /currency GET api to retrieve all currency code information
  useEffect(() => {
    //create an API call to fetch currency code data using the /currency GET endpoint
    // needs the token stored in sessionStorage for the Authorization Bearer token
    async function getCurrencyData(accessToken: string | null) {
      try {
        dispatch(triggerActivityMonitorReset(true));
        const result = await fetchApiData('currency', accessToken, 'GET');
        const currencyDataResponse = await handleApiResponse(result);
        // if there is a response, update the currency context with the response.
        if (currencyDataResponse) {
          addCurrencies(currencyDataResponse);
        }
      } catch (error) {
        setCalculateErrors([
          {
            type: 'error',
            code: modalCopy.apiValidation.priceChange.errors.generic.title,
            message:
              modalCopy.apiValidation.priceChange.errors.generic.description
          }
        ]);
      }
    }
    if (token) {
      getCurrencyData(token);
    }
  }, [addCurrencies, token]);

  // create options list for the currency code dropdown if the currencies context has been filled
  const [currencyOptions, setCurrencyOptions] = useState<SelectInputType[]>([]);
  useEffect(() => {
    if (currencies.length > 0) {
      const currencyList = currencies.map((currency) => {
        return { label: currency.currency_id, value: currency.currency_id };
      });

      //sort list so select options are alphabetically ordered
      currencyList.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      setCurrencyOptions(currencyList);
    }
  }, [currencies, country_of_export]);

  const cachedCurrencyOptions = useMemo(
    () => currencyOptions,
    [currencyOptions]
  );

  const [selectedCurrency, setSelectedCurrency] = useState({
    label: '',
    value: ''
  });

  useEffect(() => {
    if (currencyOptions && currencyOptions.length > 0) {
      const initialCurrency = currencyOptions.find(
        (currenctOpt: SelectInputType) =>
          currenctOpt.value === formData?.currency_id
      );

      setSelectedCurrency(initialCurrency || { label: '', value: '' });
      methods.setValue(
        'currencyCode',
        initialCurrency || { label: '', value: '' }
      );
    }
  }, [currencyOptions]);

  // api call to get the wholesale price based on pr type of Wholesale Price Promotion and an effective date being selected. Only for WPP preice requests
  useEffect(() => {
    if (
      wholesalePromotionRequest &&
      selectedEffectiveDate &&
      selectedEffectiveDate.length > 0
    ) {
      const dateFormat = formatNumericDate(selectedEffectiveDate);
      const priceBody = {
        transactionDate: dateFormat,
        sku: [sku]
      };

      if (token) {
        dispatch(setLoadingState(true));
        //pass in the token, price body and callback fxn to the getWholesalePrice fxn to make the api call
        getWholesalePrice({
          accessToken: token,
          data: priceBody,
          callbackFunction: setWholesalePriceEffectiveDate,
          loadingCallbackFunction: (error) => {
            dispatch(setLoadingState(false));
            if (error === 'Product Not Priced') {
              setCalculateErrors([
                {
                  title:
                    modalCopy.apiValidation.promotionValidation.errors[3].title,
                  code: '3',
                  message:
                    modalCopy.apiValidation.promotionValidation.errors[3]
                      .description,
                  type: 'ERROR'
                }
              ]);
            } else if (error) {
              setCalculateErrors([
                {
                  code: 'error',
                  message: 'An Error has occurred please try again.',
                  type: 'ERROR'
                }
              ]);
            }
          }
        });
      }
    }
  }, [wholesalePromotionRequest, selectedEffectiveDate, sku, token]);

  // Set Reason drop down options based on Request Type (PC or New Product)
  useEffect(() => {
    if (isNewProduct) {
      setReasonOptions(modalCopy.reasonOptionsNP);
    } else {
      setReasonOptions(modalCopy.reasonOptions);
    }
  }, [isNewProduct]);

  const handleSelectInputChange = (
    event: SelectInputType,
    inputId: 'requestType' | 'reason' | 'currencyCode'
  ) => {
    if (event !== null) {
      methods.setValue(
        inputId,
        {
          value: event.value,
          label: event.label
        },
        { shouldValidate: true }
      );

      if (inputId === 'currencyCode') {
        setSelectedCurrency({
          value: event.value,
          label: event.label
        });
        setCalculateErrors([]);
        return;
      }

      if (inputId === 'requestType') {
        setInputtedEstWholesalePriceValue('');
        setSelectedRequestType({
          value: event.value,
          label: event.label
        });
        //clear both the effective and end date because these values should be coming from a different calendar (promotion vs price), and clear the wholesalePriceEffectiveDate since it relies on these dates to be determined;
        setSelectedEffectiveDate('');
        setSelectedReason({
          value: '',
          label: ''
        });
        setAgentSupplierCheck(false);
        setInputtedEmail(undefined);
        setCostOnlyCheck(false);
        setSelectedCostingEvent('');
        setSelectedEndDate('');
        setSelectedCurrency({ label: '', value: '' });
        setSelectedFxRateDate('');
        setCalculatedExchangeRate(NaN);
        setWholesalePriceEffectiveDate('');
        setInputtedForeignPrimeCost('');
        setCalculateErrors([]);
        setCalculateWarnings([]);

        COST_FIELDS.forEach((fieldName) => {
          setCostFieldValue(fieldName, '');
        });

        setInvalidCalculateResponse(false);
        methods.clearErrors();
      } else if (inputId === 'reason') {
        setSelectedReason({
          value: event.value,
          label: event.label
        });
        setSelectedCostingEvent(event.value);
        setInputtedOtherReason('');
      } else {
        setSelectedCurrency({
          value: event.value,
          label: event.label
        });
      }
    } else if (event == null) {
      if (inputId === 'reason') {
        setSelectedReason({
          label: '',
          value: ''
        });
        setSelectedCostingEvent('');
        setInputtedOtherReason('');

        methods.setValue('reason', {
          value: undefined,
          label: undefined
        });
      }
    }
  };
  const handleTextInputBlur = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputId:
      | 'primeCostPerCase'
      | 'dutyPaidCost'
      | 'domesticChargesPerCase'
      | 'promotionAmount'
      | 'estWholesalePrice'
      | 'foreignPrimeCost'
  ) => {
    let { value } = event.target;
    let editValue = null;
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numberValue)) {
      return;
    } else {
      editValue = fixedDecimalPlaces(numberValue, 2);
    }

    if (editValue !== null && editValue?.length > 0) {
      value = editValue;
    } else {
      return;
    }

    if (inputId === 'primeCostPerCase') {
      setInputtedPrimeCostValue(value);
    } else if (inputId === 'dutyPaidCost') {
      setInputtedDutyPaidCostValue(value);
    } else if (inputId === 'domesticChargesPerCase') {
      setInputtedDomesticChargesValue(value);
    } else if (inputId === 'promotionAmount') {
      setInputtedPromotionAmountValue(value);
      setInvalidCalculateResponse(false);
    } else if (inputId === 'estWholesalePrice') {
      setInputtedEstWholesalePriceValue(value);
    } else if (inputId === 'foreignPrimeCost') {
      setInputtedForeignPrimeCost(value);
      methods.setValue('foreignPrimeCost', value.toString());
    }

    if (COST_FIELDS.includes(inputId as any)) {
      COST_FIELDS.forEach((fieldName) => {
        const fieldValue = methods.getValues(fieldName);
        if (Number(fieldValue) < 0) {
          setCostFieldValue(fieldName, undefined);
          setInvalidCalculateResponse(false);
        }
      });
      methods.clearErrors();
    }
  };

  const handleTextInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputId:
      | 'primeCostPerCase'
      | 'dutyPaidCost'
      | 'domesticChargesPerCase'
      | 'promotionAmount'
      | 'estWholesalePrice'
      | 'foreignPrimeCost'
      | 'otherReason'
      | 'email'
      | 'addNewPRJustification'
  ) => {
    let { value } = event.target;

    //Allows "." to be enter at the front of value, and adding 0 to it to avoid Warning message on example like ".75"
    if (
      value.charAt(0) === '.' &&
      event.target.className?.includes('CurrencyInput')
    ) {
      methods.setValue(inputId as any, value.replace('.', '0.'));
      value = value.replace('.', '0.');
    }

    if (inputId === 'primeCostPerCase') {
      setInputtedPrimeCostValue(value);
    } else if (inputId === 'dutyPaidCost') {
      setInputtedDutyPaidCostValue(value);
    } else if (inputId === 'domesticChargesPerCase') {
      setInputtedDomesticChargesValue(value);
    } else if (inputId === 'promotionAmount') {
      setInputtedPromotionAmountValue(value);
      setInvalidCalculateResponse(false);
    } else if (inputId === 'estWholesalePrice') {
      setInputtedEstWholesalePriceValue(value);
    } else if (inputId === 'foreignPrimeCost') {
      setInputtedForeignPrimeCost(value);
    } else if (inputId === 'otherReason') {
      setInputtedOtherReason(value);
    } else if (inputId === 'email') {
      if (value === '') {
        //email cannot be am empty string as per the create API error: errors: ["each value in email must be an email"]
        // setting the input value to be undefined instead of ""
        setInputtedEmail(undefined);
      } else setInputtedEmail(value);
    } else if (inputId === 'addNewPRJustification') {
      setjustification(value);
      methods.setValue('addNewPRJustification', value);
      setCalculateErrors([]);
    }

    if (COST_FIELDS.includes(inputId as any)) {
      COST_FIELDS.forEach((fieldName) => {
        const fieldValue = methods.getValues(fieldName);
        if (Number(fieldValue) < 0) {
          setCostFieldValue(fieldName, undefined);
          setInvalidCalculateResponse(false);
        }
      });
      methods.clearErrors();
    }
  };

  const setCostFieldValue = (
    fieldName: keyof FormData,
    value?: number | string | undefined
  ) => {
    const formattedValue = value ? fixedDecimalPlaces(value, 2) : '';
    switch (fieldName) {
      case 'primeCostPerCase':
        setInputtedPrimeCostValue(formattedValue);
        break;
      case 'dutyPaidCost':
        setInputtedDutyPaidCostValue(formattedValue);
        break;
      case 'domesticChargesPerCase':
        setInputtedDomesticChargesValue(formattedValue);
        break;
      case 'promotionAmount':
        setInputtedPromotionAmountValue(formattedValue);
        break;
      case 'estWholesalePrice':
        setInputtedEstWholesalePriceValue(formattedValue);
        break;
      case 'wholesalePriceAsOfEffectiveDate':
        setWholesalePriceEffectiveDate(formattedValue);
        break;
      case 'foreignPrimeCost':
        setInputtedForeignPrimeCost(formattedValue);
        break;
    }

    methods.setValue(fieldName, formattedValue);
  };

  const priceRequestBody: PriceRequest = {
    sku: sku,
    type:
      selectedRequestType.value === 'Supplier Price Change' ||
      selectedRequestType.value === 'Price Change (PC)'
        ? 'PC'
        : selectedRequestType.value === 'Wholesale Price Promotion' ||
          selectedRequestType.value === 'Wholesale Price Promotion (WPP)'
        ? 'WPP'
        : 'NP',
    effective_date: selectedEffectiveDate !== '' ? selectedEffectiveDate : '',
    effective_end_date:
      wholesalePromotionRequest && selectedEndDate !== ''
        ? selectedEndDate
        : null,
    promotion_amount_per_su: Number(inputtedPromotionAmountValue),
    domestic_charges_per_case: wholesalePromotionRequest
      ? undefined
      : Number(inputtedDomesticChargesValue),
    prime_cost_per_case: wholesalePromotionRequest
      ? undefined
      : Number(inputtedPrimeCostValue),
    duty_paid_cost_per_case: wholesalePromotionRequest
      ? undefined
      : Number(inputtedDutyPaidCostValue),
    reason: `${String(selectedReason.label) || ''}${
      inputtedOtherReason ? `- ${inputtedOtherReason}` : ''
    }`,
    costing_event_comment: selectedCostingEvent,
    const_only_fl: costOnlyCheck,
    fx_rate_date: wholesalePromotionRequest ? undefined : selectedFxRateDate,
    currency_id: selectedCurrency.value || null,
    exchange_rate: Number(calculatedExchangeRate),
    foreign_prime_cost: wholesalePromotionRequest
      ? undefined
      : Number(formValues.foreignPrimeCost),
    a_s_review_fl: agentSupplierCheck,
    estimated_price_per_su: Number(wholesalePriceEffectiveDate),
    price_per_su: Number(inputtedEstWholesalePriceValue),
    warning_json: JSON.stringify(calculateWarnings),
    justification_required_fl: justificationRequired,
    justification: formValues.addNewPRJustification || undefined,
    other_reason: exceptionOtherReason,
    email: inputtedEmail === '' ? undefined : inputtedEmail
  };

  if (isDomesticProduct || wholesalePromotionRequest) {
    delete priceRequestBody.currency_id;
    delete priceRequestBody.currencyId;
  }

  // Calculate Exchange Rate functionality - for import products only that are requestType Price Change
  // based on the currency code and the fx rate date selected, only trigger the API call once both of these inputs are filled in
  useEffect(() => {
    //create an API call to fetch currency code data using the /currency GET endpoint
    // needs the token stored in sessionStorage for the Authorization Bearer token
    async function getExchangeRate(
      accessToken: string | null,
      queryParams: string
    ) {
      try {
        dispatch(setLoadingState(true));
        dispatch(triggerActivityMonitorReset(true));
        const result = await fetchApiData(
          `currency-fxrate?${queryParams}`,
          accessToken,
          'GET'
        );
        const exchangeRateResponse = await handleApiResponse(result);
        // if there is a response, update the currency context with the response.

        //ab10797 , change according to data of new service response
        if (exchangeRateResponse) {
          const rate = exchangeRateResponse.currencyValue[0]?.currency.value;
          setCalculatedExchangeRate(Number(rate));
          //#ab10797 , updating the exchange rate date by the returned data of new service response
          const rateDate = exchangeRateResponse.currencyValue[0]?.effectiveDate;
          const d = formatNumericDate(rateDate);
          setCurrencyFxDateAutoUpdated(true);
          setSelectedFxRateDate(d);
          setCalculateErrors([]);
        }
      } catch (error) {
        setCalculateErrors([
          {
            type: 'error',
            code: 'exchangeRate',
            message:
              modalCopy.apiValidation.priceChange.errors.exchangeRate
                .description
          }
        ]);
        setCalculatedExchangeRate(NaN);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
    if (
      formValues.currencyCode.value &&
      selectedFxRateDate &&
      !currencyFxDateAutoUpdated
    ) {
      const currencyArray = [];
      currencyArray.push(formValues.currencyCode.value);
      const queryParams = `effectiveDate=${selectedFxRateDate}&currencyLabel=${currencyArray}`;

      if (token) {
        getExchangeRate(token, queryParams);
      }
      setCurrencyFxDateAutoUpdated(false);
    } else {
      setCurrencyFxDateAutoUpdated(false);
    }
  }, [formValues.currencyCode.value, selectedFxRateDate, token]);

  //onClick functionality for the calculator button as part of the Import product currency information
  // Prime Cost/ Case = Exchange Rate * Foreign Prime Cost
  const handleCalculatePrimeCost = () => {
    const foreignPrimeCostValue = methods.getValues('foreignPrimeCost');
    if (calculatedExchangeRate && foreignPrimeCostValue) {
      const primeCost = calculatedExchangeRate * Number(foreignPrimeCostValue);
      //EPP-1366 - missed logic which hook form value 'primeCostPerCase' should be set with updated value , synchronize with handleCalculatePrimeCost in PriceRequestDetailForm
      methods.setValue('primeCostPerCase', fixedDecimalPlaces(primeCost, 2), {
        shouldValidate: false
      });
      //end EPP-1366
      return setInputtedPrimeCostValue(fixedDecimalPlaces(primeCost, 2));
    }
  };

  // EPP-1366
  // Get and set default values of the prime cost / duty paid cost/ domestic charge functionality - values retrieved from LDB API /price/${sku}/components?transactionDate=${selectedEffectiveDate}
  // input parameters : sku ,selectedEffectiveDate
  // expected response : priceComponents object with prime cost / duty paid cost/ domestic charge values as of selected sku & selectedEffectiveDate
  // According to refined requirements, prime cost / duty paid cost/ domestic charge values will be update/overridden by the returned values.
  // this task will change the default values in the above 3 cost fields every time the Effective Date is changed when the user is an LDB user.
  // for any returned values are zero should be displayed as zero, null or empty should display as blank

  const [
    getPriceComponents,
    {
      isLoading: isLoadingGetPriceComponents,
      isSuccess: isSuccessGetPriceComponents,
      isError: isErrorGetPriceComponents,
      error: errorGetPriceComponents,
      data: PriceComponentsResponse
    }
  ] = useGetPriceComponentsMutation();

  useEffect(() => {
    if (isSuccessGetPriceComponents && PriceComponentsResponse) {
      //Start of ab#8591 - NEW PR: Default ForEx Values as of Effective Date in ab#3669 story
      const {
        foreignPrimeCostPerCase,
        currencyLabel,
        currencyRate,
        currencyEffectiveDate
      } = PriceComponentsResponse;

      if (currencyLabel !== 'CAD') {
        setSelectedCurrency({ label: currencyLabel, value: currencyLabel });
        methods.setValue('currencyCode', {
          value: currencyLabel,
          label: currencyLabel
        });

        if (foreignPrimeCostPerCase) {
          setInputtedForeignPrimeCost(foreignPrimeCostPerCase.toString());
          methods.setValue(
            'foreignPrimeCost',
            foreignPrimeCostPerCase.toString()
          );
        }
        if (currencyRate) {
          setCalculatedExchangeRate(currencyRate);
        }
        if (currencyEffectiveDate) {
          const d = formatNumericDate(String(currencyEffectiveDate));
          setSelectedFxRateDate(d);
        }
      } else {
        //clean out the FX rate info. if it is in CAD
        setInputtedForeignPrimeCost('');
        methods.setValue('foreignPrimeCost', '');
        setSelectedCurrency({ label: '', value: '' });
        methods.setValue('currencyCode', { label: '', value: '' });
        setSelectedFxRateDate('');
        setCalculatedExchangeRate(NaN);
      }

      //end of ab#8591 - NEW PR: Default ForEx Values as of Effective Date - ab#3669 story
      const primeCost = PriceComponentsResponse.primeCostPerCase;
      const dutyPaidCost = PriceComponentsResponse.dutyPaidCostPerCase;
      const domesticChargesPerCase =
        PriceComponentsResponse.domesticChargesPerCase;
      if (!wholesalePromotionRequest) {
        if (!priceChangeDomesticDPCProduct) {
          if (primeCost === null || primeCost === undefined) {
            setInputtedPrimeCostValue('');
          } else if (primeCost >= 0) {
            setInputtedPrimeCostValue(fixedDecimalPlaces(primeCost, 2));
          }
        }

        if (priceChangeDomesticDPCProduct) {
          if (dutyPaidCost === null || dutyPaidCost === undefined) {
            setInputtedDutyPaidCostValue('');
          } else if (dutyPaidCost >= 0) {
            setInputtedDutyPaidCostValue(fixedDecimalPlaces(dutyPaidCost, 2));
          }
        }

        if (!priceChangeDomesticDPCProduct) {
          if (
            domesticChargesPerCase === null ||
            domesticChargesPerCase === undefined
          ) {
            setInputtedDomesticChargesValue('');
          } else if (domesticChargesPerCase >= 0) {
            setInputtedDomesticChargesValue(
              fixedDecimalPlaces(domesticChargesPerCase, 2)
            );
          }
        }
      }
    }

    if (isErrorGetPriceComponents) {
      if (
        errorGetPriceComponents != undefined &&
        'data' in errorGetPriceComponents
      ) {
        // TypeScript will handle it as `FetchBaseQueryError` from now on.
        if (errorGetPriceComponents?.status === 404) {
          return; // for priceComponentsApi, 404 not found means no query result found, so it is normal, don't need to prompt warning / error message on UI
        } else {
          //set the error banner content to the show error messaging
          setCalculateErrors([
            {
              code: 'error',
              message: 'An Error has occurred please try again.',
              type: 'ERROR'
            }
          ]);
        }
      }
    }
  }, [
    priceChangeDomesticDPCProduct,
    wholesalePromotionRequest,
    isSuccessGetPriceComponents,
    isErrorGetPriceComponents,
    errorGetPriceComponents,
    PriceComponentsResponse
  ]);

  // get the PriceComponents data when new effectiveDate is selected and it is not wholesalePromotionRequest
  useEffect(() => {
    if (
      formValues.effectiveDate !== selectedEffectiveDate && //compare with the default form value of effectiveDate, avoid trigger update for initial load of drafted PC
      selectedEffectiveDate !== '' &&
      !wholesalePromotionRequest
    ) {
      if (token) {
        getPriceComponents({
          sku,
          selectedEffectiveDate,
          token: String(token)
        });
      }
    }
  }, [
    getPriceComponents,
    selectedEffectiveDate,
    sku,
    token,
    wholesalePromotionRequest
  ]);

  //Get access to the list of errors on the form.
  const modalFormErrors = methods.formState.errors;

  useEffect(() => {
    if (modalFormErrors.constructor !== Object) return;
    setFormErrorsExist(Object.keys(modalFormErrors).length > 0);
  }, [Object.keys(modalFormErrors).length]);

  // confirm whether or not the calculate prices button should be disabled.
  useEffect(() => {
    //perform checks to see if the Price Change PC or New Product Pricing/ Reactivation request types have the correct values needed based on the sku data.
    // first check the value of priceRequestType variable that was watching the requestType input for changes.

    if (priceChangeRequest) {
      //use the priceChangeModalCheck helper function to enable or disable the calculate button and assign what type of scenario
      priceChangeModalCheck(
        selectedEffectiveDate,
        country_of_export,
        is_cost_dpc,
        inputtedPrimeCostValue,
        inputtedEstWholesalePriceValue,
        inputtedDomesticChargesValue,
        inputtedDutyPaidCostValue,
        setScenarioType,
        setDisableCalculateBtn
      );
    } else if (wholesalePromotionRequest) {
      //use the wholesalePromotionModalCheckhelper function to enable or disable the calculate button and assign scenario type 3
      const promoAmount = Number(inputtedPromotionAmountValue) || undefined;
      wholesalePromotionModalCheck(
        promoAmount,
        selectedEffectiveDate,
        setScenarioType,
        setDisableCalculateBtn
      );
    }
  }, [
    country_of_export,
    inputtedDomesticChargesValue,
    inputtedDutyPaidCostValue,
    inputtedEstWholesalePriceValue,
    inputtedPrimeCostValue,
    inputtedPromotionAmountValue,
    is_cost_dpc,
    priceChangeRequest,
    selectedEffectiveDate,
    wholesalePriceEffectiveDate,
    wholesalePromotionRequest
  ]);

  //get the hierarchy data saved to sessionStorage to retrieve the correct category description using the categorySearch helper below
  const hierachyData = sessionStorage.getItem('hierachyData');

  const handleUpdateCreatePR = (
    additionalPRdata: any,
    forceUpdate: boolean
  ) => {
    if (!updateCreatePR && !forceUpdate) return;

    // Ensure Cost Field or Promotion Amount values are not included in PR Create/Update when not needed
    // These values may be set if the PR type is changed while creating or editing
    const prBodyOverrides = wholesalePromotionRequest
      ? {
          prime_cost_per_case: null,
          domestic_charges_per_case: null,
          in_bond_cost_per_case: null,
          excise_amount_per_case: null,
          customsAmountPerCase: null,
          duty_paid_cost_per_case: null,
          duty_paid_cost_per_su: null,
          beer_distro_charge_per_su: null,
          markup_per_su: null,
          crf_per_su: null,
          price_per_case: null
        }
      : {
          promotion_amount_per_su: null
        };

    const requestBody = {
      ...priceRequestBody,
      ...priceRequestBodyCalculate,
      ...additionalPRdata,
      ...prBodyOverrides
    };

    // Check to see if we should use the existing prDetails to UPDATE a PR using
    // the usePrDetails boolean or CREATE a brand new one.
    if (prDetails && usePrDetails) {
      requestBody.id = Number(prDetails.id);
      updatePriceRequest({
        requestBody,
        token
      });
    } else {
      requestBody.id = null;
      createPriceRequest({
        requestBody,
        token
      });
    }
  };

  const handleCalculateButtonClick = () => {
    setUpdateCreatePR(false);
    handleCalculate();
    methods.trigger();
  };

  // This function is for the calculate button.
  const handleCalculate = () => {
    setInterfaceWarnings([]);
    setCalculateErrors([]);
    setCalculateWarnings([]);

    // if the request type is WPP, we are just calculating the subtraction of wholesalePriceEffectiveDate - promotionAmount to populate Est. WS  Promotional Price
    if (wholesalePromotionRequest) {
      if (
        !wholesalePriceEffectiveDate ||
        !inputtedPromotionAmountValue ||
        !selectedEffectiveDate
      )
        return;

      const estimatedWPP =
        Number(wholesalePriceEffectiveDate) -
        Number(inputtedPromotionAmountValue);

      const formattedEstWsPrice = fixedDecimalPlaces(estimatedWPP, 2);

      // Check if current EstWSPrice matches returned value
      // Used to trigger EstWSPrice Change Warning in validatePromotionIsSuccess
      const originalEstWsPrice = methods.getValues('estWholesalePrice');

      // If the calculated value is different from the original value, set the updatedEstWSPrice state to true
      setUpdatedEstWSPrice(formattedEstWsPrice !== originalEstWsPrice);

      // Set new values and trigger validation
      setCostFieldValue('estWholesalePrice', formattedEstWsPrice);

      methods.trigger();

      if (estimatedWPP < 0) {
        setInvalidCalculateResponse(true);
      }

      setPriceRequestBodyCalculate({
        ...priceRequestBody,
        price_per_su: Number(formattedEstWsPrice)
      });

      const promoPeriod = {
        dateFrom: selectedEffectiveDate,
        dateTo: selectedEndDate
      };

      const validatePromotionPayload: ValidatePromotion = {
        sku,
        promotionAmountPerSU: Number(inputtedPromotionAmountValue),
        promoPeriod: promoPeriod
      };

      validatePromotionRequest({ body: validatePromotionPayload, token });
    } else {
      // if the request type is not WPP, we need to use the /calculate api to retrieve one of several different numbers
      // this is based on the direction of the calculation as well as the scenario type and the inputted values
      // first create an object holding all potential inputted values that exist on the form, along with the sku and scenario type number
      const requestProperties = {
        sku: sku,
        effectiveDate: selectedEffectiveDate,
        dutyPaidCostPerCase: inputtedDutyPaidCostValue,
        primeCostPerCase: inputtedPrimeCostValue,
        pricePerSU: inputtedEstWholesalePriceValue,
        domesticChargesPerCase: inputtedDomesticChargesValue,
        scenario: scenarioType
      };

      if (token) {
        calculate({ requestBody: requestProperties, token });
      }
    }
  };

  // This function is used to check if there are any calculation warnings which require
  // justification based on their warning codes.
  const checkJustificationRequired = (warningCode: string) =>
    warningMessageContent[warningCode]?.justificationRequired ?? false;

  useEffect(() => {
    if (calculateWarnings.length > 0 && updateCreatePR) {
      const warningJustificationStatus = [];
      for (const warning of calculateWarnings) {
        const warningRequiresJustification = checkJustificationRequired(
          warning.code
        );
        warningJustificationStatus.push(warningRequiresJustification);
      }
      const warningsRequireJustification = warningJustificationStatus.some(
        (item) => item
      );
      toggleJustificationRequired(warningsRequireJustification);
      toggleDisableForm(true);
    }
  }, [calculateWarnings, updateCreatePR]);

  useEffect(() => {
    if (
      (interfaceWarnings.length > 0 || calculateErrorsExist) &&
      updateCreatePR
    ) {
      toggleDisableForm(true);
    }
  }, [interfaceWarnings, updateCreatePR, calculateErrorsExist]);

  useEffect(() => {
    if (isSuccessCalculate && calculateCostsResponse) {
      const {
        pricePerSU,
        dutyPaidCostPerCase,
        primeCostPerCase,
        domesticChargesPerCase,
        validationMessage
      } = calculateCostsResponse;

      const newCalculateCostResponse = mapCalculateResponse(
        calculateCostsResponse
      );

      const priceRequestBodyCalculateResponse: PriceRequest = {
        ...priceRequestBody,
        ...newCalculateCostResponse,
        sku: sku,
        effective_date: selectedEffectiveDate,
        promotion_amount_per_su: null,
        estimated_price_per_su: wholesalePriceEffectiveDate
          ? Number(wholesalePriceEffectiveDate)
          : undefined,
        reason: `${String(selectedReason.label) || ''}${
          inputtedOtherReason ? `- ${inputtedOtherReason}` : ''
        }`,
        costing_event_comment: selectedCostingEvent,
        exchange_rate: Number(calculatedExchangeRate),
        warning_json: JSON.stringify(validationMessage),
        justification_required_fl: justificationRequired,
        justification: formValues.addNewPRJustification || undefined,
        other_reason: exceptionOtherReason
      };

      setPriceRequestBodyCalculate(priceRequestBodyCalculateResponse);

      setCostFieldValue('estWholesalePrice', fixedDecimalPlaces(pricePerSU, 2));

      setCostFieldValue(
        'domesticChargesPerCase',
        fixedDecimalPlaces(domesticChargesPerCase, 2)
      );

      setCostFieldValue(
        'dutyPaidCost',
        fixedDecimalPlaces(dutyPaidCostPerCase, 2)
      );

      setCostFieldValue(
        'primeCostPerCase',
        fixedDecimalPlaces(primeCostPerCase, 2)
      );

      methods.trigger();

      const calculatedValues = [
        pricePerSU,
        domesticChargesPerCase,
        dutyPaidCostPerCase,
        primeCostPerCase
      ];

      if (calculatedValues.some((value) => value < 0)) {
        setInvalidCalculateResponse(true);
        return;
      }

      const estWSPriceUpdated =
        pricePerSU !== Number(inputtedEstWholesalePriceValue);

      if (validationMessage?.length > 0 || estWSPriceUpdated) {
        setFloorPrice(pricePerSU);

        // if there are validation messages, filter by WARNING and ERROR types, and update the calculateWarnings and calculateErrors arrays;
        // These validation messages are only displayed on save, not the calculate button onclick functionality
        const validationWarnings =
          calculateCostsResponse.validationMessage?.filter(
            (message: ValidationMessageType) => message.type === 'WARNING'
          ) || [];

        const validationErrors =
          calculateCostsResponse.validationMessage?.filter(
            (message: ValidationMessageType) => message.type === 'ERROR'
          ) || [];

        const uiWarnings = estWSPriceUpdated
          ? modalCopy.uiValidationWarnings.estimatedWSPriceChange
          : undefined;

        //only assign the errors and warnings to their respective arrays if the updateCreatePR param is true.
        // this is to avoid showing api calculation warnings after the calculator button click and only through the save button functionality.
        if (updateCreatePR) {
          setCalculateWarnings(validationWarnings);
          setCalculateErrors(validationErrors);

          if (uiWarnings) {
            setInterfaceWarnings((prevWarnings) => [
              ...prevWarnings,
              uiWarnings
            ]);
          }
        }
        return;
      }

      handleUpdateCreatePR(priceRequestBodyCalculateResponse, false);
    }
  }, [isSuccessCalculate, calculateCostsResponse]);

  const [saveFunctionType, setSaveFunctionType] = useState<
    SaveFunctionality | undefined
  >(undefined);

  // Complete reset of modal functionality back to base state
  const resetModal = () => {
    toggleUsePrDetails(false);
    setUpdateCreatePR(false);
    setSaveFunctionType(undefined);
    setInputtedDomesticChargesValue(undefined);
    setInputtedPrimeCostValue(undefined);
    setSelectedEffectiveDate('');
    setInputtedEstWholesalePriceValue(undefined);
    setInputtedDutyPaidCostValue(undefined);
    setInputtedPromotionAmountValue(undefined);
    setSelectedEndDate('');
    setAgentSupplierCheck(false);
    setCostOnlyCheck(false);
    setSelectedCostingEvent(undefined);
    setInputtedEmail(undefined);
    setSelectedReason({
      value: '',
      label: ''
    });
    setInputtedOtherReason('');
    setWholesalePriceEffectiveDate(undefined);
    setCalculateErrors([]);
    setCalculateWarnings([]);
    setInterfaceWarnings([]);
    toggleJustificationRequired(false);
    methods.reset(defaultFormValues);
    methods.setValue('requestType', selectedRequestType);
    methods.setValue('effectiveDate', '');
    methods.setValue('estWholesalePrice', '');
    methods.setValue('promotionAmount', '');
    methods.setValue('dutyPaidCost', '');
    methods.setValue('domesticChargesPerCase', '');
    methods.setValue('primeCostPerCase', '');
    methods.setValue('email', '');
    methods.setValue('wholesalePriceAsOfEffectiveDate', '');
    methods.setValue('costingEventComment', '');
    toggleDisableForm(false);
    setInvalidCalculateResponse(false);
  };

  // Save Button Functionality
  // Pass in the type of save based on whether the Save & Close or Save & Add Another PR button is clicked.
  // Save Button Functionality
  // Pass in the type of save based on whether the Save & Close or Save & Add Another PR button is clicked.
  const handleSaveButton = (saveFunctionRequestType: SaveFunctionality) => {
    if (formErrorsExist) return;
    methods.trigger();
    setUpdateCreatePR(true);
    //Clear any warnings for fresh validation
    setInterfaceWarnings([]);
    setCalculateErrors([]);
    setCalculateWarnings([]);

    //first set the saveFunctionType variable to the passed in button type.
    setSaveFunctionType(saveFunctionRequestType);

    const updateHiddenFields = hiddenFieldCheck(
      selectedEffectiveDate,
      selectedRequestType.value,
      fullData
    );

    if (updateHiddenFields) {
      setSelectedReason({
        label: '',
        value: ''
      });
      setSelectedCostingEvent('');
    }

    handleCalculate();
  };

  useEffect(() => {
    if (validatePromotionIsSuccess) {
      const validationErrors: ValidationMessage[] | undefined =
        validatePromotionResponse &&
        validatePromotionResponse?.validationMessage &&
        validatePromotionResponse?.validationMessage.length > 0
          ? validatePromotionResponse?.validationMessage.filter(
              (message: ValidationMessage) => message.type === 'ERROR'
            )
          : [];

      const validationWarnings: ValidationMessage[] | undefined =
        validatePromotionResponse &&
        validatePromotionResponse?.validationMessage &&
        validatePromotionResponse?.validationMessage.length > 0
          ? validatePromotionResponse?.validationMessage.filter(
              (message: ValidationMessage) => message.type === 'WARNING'
            )
          : [];

      dispatch(setLoadingState(false));
      if (
        validationErrors &&
        validationErrors.length === 0 &&
        !updatedEstWSPrice &&
        !invalidCalculateResponse &&
        updateCreatePR
      ) {
        if (!wholesalePromotionRequest) {
          delete priceRequestBody.effective_end_date;
        }

        if (wholesalePromotionRequest && interfaceWarnings?.length != 0) {
          return; //the estimate WS price change warning message will be prompted, and user need to confirm&save
        }
        handleUpdateCreatePR(priceRequestBody, false);
        return;
      }
      if (updateCreatePR) {
        validationErrors &&
          validationErrors.length > 0 &&
          setCalculateErrors(validationErrors);

        validationWarnings &&
          validationWarnings.length > 0 &&
          setCalculateWarnings(validationWarnings);

        if (updatedEstWSPrice) {
          const estWsPriceOverwriteMessage =
            modalCopy.uiValidationWarnings.estimatedWSPriceChangeWPP;
          setInterfaceWarnings((prevWarnings) => [
            ...prevWarnings,
            estWsPriceOverwriteMessage
          ]);
        }
      }
    }
  }, [validatePromotionIsSuccess, updatedEstWSPrice]);

  //Confirm and Save button functionality - pass in the type of save function previous set when save was originall triggered ('saveAndClose' or 'saveAndNext').
  const handleConfirmSave = () => {
    const concatExceptionOtherReason: any = [];
    const isSaveAndNext = saveFunctionType === 'saveAndNext';

    setSaveFunctionType(isSaveAndNext ? 'confirmAndNext' : 'confirmAndSave');

    if (interfaceWarnings.length > 0 || calculateWarnings.length > 0) {
      if (justification === '' && justificationRequired) {
        const errorsJustification = [...calculateErrors];
        errorsJustification.push({
          code: modalCopy.uiValidationWarnings.missingJustification.title,
          message:
            modalCopy.uiValidationWarnings.missingJustification.description,
          type: 'ERROR'
        });
        setCalculateErrors(errorsJustification);
        return;
      }

      interfaceWarnings?.forEach((warning) => {
        const { exceptionReason } = warning;
        exceptionReason && concatExceptionOtherReason.push(exceptionReason);
      });

      calculateWarnings?.forEach((warning) => {
        const { code } = warning;
        const { exceptionReason } = warningMessageContent[code];
        exceptionReason && concatExceptionOtherReason.push(exceptionReason);
      });

      setExceptionOtherReason(concatExceptionOtherReason.join('; '));
    }

    if (!wholesalePromotionRequest) {
      delete priceRequestBody.effective_end_date;
    }

    const additionalRequestBody = {
      justification: justification,
      other_reason: concatExceptionOtherReason.filter(Boolean).join('; ')
    };

    handleUpdateCreatePR(additionalRequestBody, true);
  };

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess) {
      toggleUsePrDetails(false);
      resetModal();

      // Save & Close / Confirm & Save workflow
      if (
        saveFunctionType === 'saveAndClose' ||
        saveFunctionType === 'confirmAndSave'
      ) {
        dispatch(
          setRecentPriceRequestType({
            label: 'Price Change (PC)',
            value: 'Supplier Price Change'
          })
        );
        onRequestClose();
        return;
      }

      // Save & Next / Confirm & Next workflow
      setShowSuccessBanner(true);
      toggleUsePrDetails(false);
      setFormData(undefined);

      COST_FIELDS.forEach((fieldName) => {
        setCostFieldValue(fieldName, undefined);
      });

      dispatch(setRecentPriceRequestType(selectedRequestType));
      dispatch(setPrevSaveFunction(saveFunctionType));

      // Clear the success banner and the number of products added after 3 seconds
      setTimeout(() => {
        setShowSuccessBanner(false);
      }, 3000);
    }
  }, [createIsSuccess, updateIsSuccess]);

  useEffect(() => {
    if (
      createIsError ||
      updateIsError ||
      isErrorCalculate ||
      validatePromotionIsError
    ) {
      setCalculateErrors([
        {
          type: 'error',
          code: modalCopy.apiValidation.priceChange.errors.generic.title,
          message: modalCopy.apiValidation.priceChange.errors.generic.title
        }
      ]);
    }
  }, [
    isErrorCalculate,
    createIsError,
    createError,
    updateIsError,
    validatePromotionIsError
  ]);

  // Go Back button functionaliity, clears warnings but doesnt clear/reset the input values
  const handleClearModal = () => {
    setShowSuccessBanner(false);
    setCalculateErrors([]);
    setCalculateWarnings([]);
    toggleJustificationRequired(false);
    setInterfaceWarnings([]);
    toggleDisableForm(false);
    setUpdateCreatePR(false);
    setInvalidCalculateResponse(false);
    setUpdatedEstWSPrice(false);
  };

  //creating booleans to determine what warnings (if any) should be shown to the user
  const showUIWarnings =
    !formErrorsExist && !calculateErrorsExist && interfaceWarnings.length > 0;
  const showCalculateWarnings =
    !formErrorsExist && !calculateErrorsExist && calculateWarnings.length > 0;

  // creating booleans to determine if any error exists , only Error message should be
  // shown to the user and suppressed other warnings (if any)
  const hasErrors =
    Object.values(modalFormErrors).length > 0 ||
    calculateErrors.length > 0 ||
    formErrorsExist;

  const showWarnings = !hasErrors && (showUIWarnings || showCalculateWarnings);

  //the below variables pull in the appropriate warning and errors copy from the translations file based on whether it is a price change request or a wpp
  const warningMessageContent: {
    [key: string]: {
      title: string;
      description: string;
      type: string;
      justificationRequired: boolean;
      exceptionReason?: string;
    };
  } = priceChangeRequest
    ? modalCopy.apiValidation.priceChange.warnings
    : modalCopy.apiValidation.promotionValidation.warnings;

  const errorMessageContent: {
    [key: string]: {
      title: string;
      description: string;
      type: string;
      justificationRequired: boolean;
    };
  } = priceChangeRequest
    ? modalCopy.apiValidation.priceChange.errors
    : modalCopy.apiValidation.promotionValidation.errors;

  const uiIsLoading =
    loadingState ||
    isLoadingGetPriceComponents ||
    isLoadingCalculate ||
    createLoading ||
    validatePromotionLoading ||
    deleteSingleIsLoading ||
    updateLoading;

  return (
    <>
      {uiIsLoading && <LoadingOverlay />}
      <div className={styles.addNewModalContainer}>
        <Heading element="h3" heading3 productBlockTitle>
          {modalCopy.title}
        </Heading>
        <div className={styles.addNewModalWrapper}>
          <div className={styles.productDetailsWrapper}>
            <div className={styles.row}>
              <Text element="p" bold>
                {sku}
              </Text>
              <Pill type={status_code}>{productStatusSearch(status_code)}</Pill>
            </div>
            <Heading element="h3" heading3 action>
              {product_name}
            </Heading>
            <div className={styles.row}>
              <Text element="p" small>
                {categorySearch(hierachyData, category)}
              </Text>
            </div>
            <div className={styles.row}>
              <Text element="p" small>
                {alcohol_percentage ? `${alcohol_percentage}%` : ''}
              </Text>
              <Text element="p" small>
                {unit_description}
              </Text>
            </div>
          </div>
          <div className={styles.formWrapper}>
            <FormProvider {...methods}>
              <form className={styles.filterContainer}>
                {/* Show various banners based on if there is a form error or the pr was successfully saved*/}
                {modalFormErrors &&
                  Object.values(modalFormErrors).map(
                    (error: any, idx: number) => {
                      let errorType;
                      if (error.type) {
                        errorType = error.type;
                      } else {
                        errorType = error.value.type;
                      }
                      const errorCopy = errorMapping(
                        errorType,
                        modalCopy.validations
                      );
                      return (
                        <Banner
                          key={`${idx}-error`}
                          type="error"
                          icon={<ErrorAlert />}
                          title={errorCopy.title}
                          description={errorCopy.description}
                        />
                      );
                    }
                  )}
                {calculateErrorsExist &&
                  calculateErrors?.map((error, index) => {
                    const {
                      title: defaultTitle,
                      description: defaultDescription
                    } = errorMessageContent[error?.code] || {};

                    const title = defaultTitle || 'An error has occurred';
                    let description = defaultDescription || error.message;

                    if (description.includes('{pricePerSU}')) {
                      description = description.replaceAll(
                        '{pricePerSU}',
                        floorPrice.toString()
                      );
                    }

                    return (
                      <Banner
                        type="error"
                        icon={<ExclamationPoint />}
                        title={title}
                        description={description}
                        key={`${index}-error`}
                      />
                    );
                  })}
                {showSuccessBanner && (
                  <Banner
                    type="success"
                    icon={<Checkmark />}
                    title="Price Request Saved"
                  />
                )}
                <div className={styles.formRow}>
                  <SelectInput
                    id="requestType"
                    options={modalCopy.requestOptions}
                    label="Request Type"
                    ariaLabelledBy="requestType"
                    columnWidth="double"
                    onChange={(event: SelectInputType) =>
                      handleSelectInputChange(event, 'requestType')
                    }
                    value={selectedRequestType}
                    required
                    disabled={disableForm}
                  />
                  {/* Standard calendar control regardless of request type, allowing selection of any future date*/}
                  <CalendarDatePicker
                    label="Effective Date"
                    id="effectiveDate"
                    value={selectedEffectiveDate}
                    onChange={(date: string) => {
                      const d = formatNumericDate(date);
                      setSelectedEffectiveDate(d);
                      setSelectedEndDate('');
                      methods.setValue('endDate', '');
                      setMinEndDate(new Date(date));
                    }}
                    highlightedDates={priceCalendarHighlightedDates}
                    legend="Pricing Calendar Effective Dates"
                    disabled={disableForm}
                    minDate={new Date().toString()}
                    size="large"
                  />
                  {/*read only End Date should only be displayed  if the Request Type is WPP and an Effective Date has been selected */}
                  {wholesalePromotionRequest && (
                    <CalendarDatePicker
                      label="End Date"
                      id="endDate"
                      minDate={minEndDate.toString()}
                      dateShift={2}
                      value={selectedEndDate}
                      onChange={(date: string) => {
                        const d = formatNumericDate(date);
                        methods.clearErrors();
                        methods.setValue('endDate', d);
                        setSelectedEndDate(d);
                        methods.trigger('endDate');
                      }}
                      highlightedDates={promotionCalendarHighlightedDates}
                      legend="Pricing Calendar Effective Dates"
                      disabled={selectedEffectiveDate === '' || disableForm}
                      size="large"
                    />
                  )}
                </div>
                {/* Foreign exchange rate currency conversion inputs only shown if product is import product based on the flag and the request Type is not WPP*/}
                {priceChangeImportProduct && (
                  <div
                    className={classNames(styles.formRowCurrency, {
                      [styles.formRowCurrencyError]: currencyErrorExists
                    })}
                  >
                    <SelectInput
                      label="Currency Code"
                      id="currencyCode"
                      options={cachedCurrencyOptions}
                      ariaLabelledBy="currencyCode"
                      value={selectedCurrency}
                      onChange={(event: SelectInputType) => {
                        setCurrencyFxDateAutoUpdated(false);
                        handleSelectInputChange(event, 'currencyCode');
                      }}
                      disabled={disableForm}
                      helper
                    />
                    <CalendarDatePicker
                      label="FX Rate Date"
                      id="fxRateDate"
                      value={selectedFxRateDate}
                      minDate={new Date(+0).toString()}
                      onChange={(date: string) => {
                        setCurrencyFxDateAutoUpdated(false);
                        const d = formatNumericDate(date);
                        setSelectedFxRateDate(d);
                      }}
                      disabled={disableForm}
                      helper
                      size="small"
                      showDropdowns={true}
                    />
                    {/*Exchange Rate is Read Only and comes from API GET call to /currency-fxrate */}
                    {/* Based on the currency code selected and the FX rate date selected*/}
                    {/*No limitation on decimals */}
                    <TextInput
                      label="Exchange Rate"
                      id="exchangeRate"
                      type="number"
                      disabled
                      value={calculatedExchangeRate}
                      helper
                    />
                    <CurrencyInput
                      label="Foreign Prime Cost"
                      id="foreignPrimeCost"
                      type="number"
                      value={inputtedForeignPrimeCost?.toString()}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputChange(event, 'foreignPrimeCost');
                      }}
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputBlur(event, 'foreignPrimeCost');
                      }}
                      cost
                      disabled={disableForm}
                      helper
                      helperText={
                        foreign_prime_cost
                          ? `Current: $${fixedDecimalPlaces(
                              foreign_prime_cost,
                              2
                            )}`
                          : ''
                      }
                    />
                    <Button
                      secondary
                      iconLeft
                      icon={<Calculator />}
                      onClick={() => handleCalculatePrimeCost()}
                      ariaLabel={'Calculate foreign prime cost'}
                      disabled={disableForm || formErrorsExist}
                    >
                      Prime
                    </Button>
                  </div>
                )}
                <div className={styles.formRow}>
                  {/* PRIME COST/ CASE - CURRENCY INPUT */}
                  {/*Only displayed if Request Type === "Price Change" AND 
              (Product = "Import") or (Product = "Domestic" AND (not on the list of the products requiring Duty Paid cost as indicated by flag in product data API))
              */}
                  {/*Field validation: Only accept numbers, should allow values up to 2 decimaly places only*/}
                  {/*If Current value exists, pass it into helper text*/}
                  {(!wholesalePromotionRequest && priceChangeImportProduct) ||
                  (!wholesalePromotionRequest &&
                    priceChangeDomesticNotDPCProduct) ? (
                    <CurrencyInput
                      label="Prime Cost/ Case"
                      aria-labelledby="primeCostPerCase"
                      id="primeCostPerCase"
                      columnWidth="single"
                      cost
                      type="number"
                      placeholder="$"
                      value={inputtedPrimeCostValue?.toString()}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputChange(event, 'primeCostPerCase');
                      }}
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputBlur(event, 'primeCostPerCase');
                      }}
                      disabled={
                        Number(inputtedForeignPrimeCost) > 0 || disableForm
                      }
                      helper
                      helperText={
                        prime_cost_per_case
                          ? `Current: $${fixedDecimalPlaces(
                              prime_cost_per_case,
                              2
                            )}`
                          : ''
                      }
                    />
                  ) : null}
                  {/* DOMESTIC CHARGES/ CASE - CURRENCY INPUT */}
                  {/* Field is ONLY displayed and available for input if product is Import  */}
                  {/*Field validation: Only accept numbers, should allow values up to 2 decimaly places only*/}
                  {/*If Current value exists, pass it into helper text*/}
                  {priceChangeImportProduct && !wholesalePromotionRequest && (
                    <CurrencyInput
                      label="Domestic Charges / Case"
                      aria-labelledby="domesticChargesPerCase"
                      id="domesticChargesPerCase"
                      columnWidth="single"
                      type="number"
                      placeholder="$"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputChange(event, 'domesticChargesPerCase');
                      }}
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputBlur(event, 'domesticChargesPerCase');
                      }}
                      cost
                      value={inputtedDomesticChargesValue?.toString()}
                      helper
                      helperText={
                        domestic_charges_per_case != null
                          ? `Current: $${fixedDecimalPlaces(
                              domestic_charges_per_case,
                              2
                            )}`
                          : ''
                      }
                      disabled={disableForm}
                    />
                  )}
                  {/* DUTY PAID COST/ CASE - CURRENCY INPUT */}
                  {/* Field is only displayed and available for input if product is Domestic AND on the list of the products requiring Duty Paid Cost (indicated by flag in product data API)  */}
                  {/*Field validation: Only accept numbers, should allow values up to 2 decimaly places only*/}
                  {/*If Current value exists, pass it into helper text*/}
                  {priceChangeDomesticDPCProduct &&
                    !wholesalePromotionRequest && (
                      <CurrencyInput
                        label="Duty Paid Cost/ Case"
                        aria-labelledby="dutyPaidCost"
                        id="dutyPaidCost"
                        columnWidth="single"
                        type="number"
                        placeholder="$"
                        value={inputtedDutyPaidCostValue?.toString()}
                        cost
                        helper
                        helperText={
                          duty_paid_cost_per_case
                            ? `Current: $${fixedDecimalPlaces(
                                duty_paid_cost_per_case,
                                2
                              )}`
                            : ''
                        }
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          handleTextInputChange(event, 'dutyPaidCost');
                        }}
                        onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                          handleTextInputBlur(event, 'dutyPaidCost');
                        }}
                        disabled={disableForm}
                      />
                    )}
                  {/* WHOLESALE PRICE AS OF THE EFFECTIVE DATE - CURRENCY INPUT */}
                  {/* Field is only displayed if Request Type === WPP */}
                  {/* Read Only field - value retrieved from API Call, value is wholesale price as of the effective date */}
                  {wholesalePromotionRequest && (
                    <div>
                      <CurrencyInput
                        label="Wholesale Price / Selling Unit as of the Effective Date" //EPP_1108
                        aria-labelledby="wholesalePriceAsOfEffectiveDate"
                        id="wholesalePriceAsOfEffectiveDate"
                        columnWidth="single"
                        type="number"
                        readOnly
                        value={wholesalePriceEffectiveDate?.toString()}
                        placeholder="$"
                        helper
                        helperText={
                          regular_price_per_su
                            ? `Current: $${fixedDecimalPlaces(
                                regular_price_per_su,
                                2
                              )}`
                            : ''
                        }
                        disabled={disableForm}
                      />
                      <ReactTooltip
                        anchorId="wholesalePriceAsOfEffectiveDate"
                        aria-live="polite"
                        place="bottom"
                      >
                        <Text element="p" small>
                          {
                            modalCopy.toolTips.wholesalePriceAsOfEffectiveDate //'The Wholesale Price in this field is exclusive of any active promotions'
                          }
                        </Text>
                      </ReactTooltip>
                    </div>
                  )}
                  {/*PROMOTION AMOUNT - CURRENCY INPUT */}
                  {/* Displayed if Request Type = "WPP" */}
                  {/* Field validation: Only accept numbers, should allow values up to 2 decimaly places only */}
                  {wholesalePromotionRequest && (
                    <CurrencyInput
                      label="Promotion Amount"
                      aria-labelledby="promotionAmount"
                      id="promotionAmount"
                      columnWidth="single"
                      type="number"
                      placeholder="$"
                      value={inputtedPromotionAmountValue?.toString()}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputChange(event, 'promotionAmount');
                      }}
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputBlur(event, 'promotionAmount');
                      }}
                      cost
                      helper
                      helperText={
                        promotion_amount_per_su
                          ? `Current: $${fixedDecimalPlaces(
                              promotion_amount_per_su,
                              2
                            )}`
                          : ''
                      }
                      disabled={disableForm}
                    />
                  )}
                  {/* EST. WHOLESALE PRICE / SELLING UNIT OR PROMOTIONAL WS PRICE (IF WPP) - CURRENCY INPUT */}
                  {/* IF Request Type === "Price Change": Displayed as an input field, Field validation: Only accept numbers, should allow values up to 2 decimaly places only, if Current value exists, pass it into helper text */}
                  {/* IF Request Type === "Wholesale Price Promotion": Displayed as Read Only Field, calculated as (Wholesale Price as of the Effective Date - Promotion Amount) */}
                  <CurrencyInput
                    label="Est. WS Price /Selling Unit (Promo. WS Price if WPP)"
                    aria-labelledby="estWholesalePrice"
                    id="estWholesalePrice"
                    columnWidth="single"
                    type="number"
                    cost
                    placeholder="$"
                    helperText={
                      final_price_per_su
                        ? `Current: $${fixedDecimalPlaces(
                            final_price_per_su,
                            2
                          )}`
                        : ''
                    }
                    helper
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleTextInputChange(event, 'estWholesalePrice');
                    }}
                    onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                      handleTextInputBlur(event, 'estWholesalePrice');
                    }}
                    readOnly={wholesalePromotionRequest || costOnlyCheck}
                    value={inputtedEstWholesalePriceValue?.toString()}
                    disabled={disableForm}
                    labelDisabled={costOnlyCheck}
                  />
                  <Button
                    secondary
                    icon={<Calculator />}
                    iconLeft
                    className={styles.buttonCalculate}
                    disabled={
                      costOnlyCheck ||
                      disableForm ||
                      disableCalculateBtn ||
                      formErrorsExist ||
                      invalidCalculateResponse
                    }
                    ariaLabel={'Calculate prices'}
                    onClick={() => handleCalculateButtonClick()}
                  />
                </div>
                {invalidCalculateResponse && (
                  <div className={styles.formRow}>
                    <Text element="p" small action bold>
                      {
                        modalCopy.uiValidationErrors.invalidCalculateResponse
                          .description
                      }
                    </Text>
                  </div>
                )}
                <div className={styles.formRowCopy}>
                  <Text element="p" small>
                    {modalCopy.note}
                  </Text>
                </div>
                <div className={styles.formRowCheckbox}>
                  {/*Agent/ Supplier Review Checkbox */}
                  <Checkbox
                    label="Agent/ Supplier Review"
                    id="agentSupplierReview"
                    isChecked={agentSupplierCheck}
                    disabled={disableForm}
                    onChange={() => {
                      setAgentSupplierCheck(!agentSupplierCheck);
                    }}
                  />
                  {/*Cost Only Checkbox - disabled if Request Type === Wholesale Price Promotion */}
                  <Checkbox
                    label="Cost Only"
                    id="costOnly"
                    isChecked={costOnlyCheck}
                    disabled={disableForm || wholesalePromotionRequest}
                    onChange={() => {
                      setCostOnlyCheck(!costOnlyCheck);
                    }}
                  />
                  {/*Reason select input and Costing Event Comments text box should only be displayed for PR and New Product Pricing/ Reactivation request types */}
                  {!wholesalePromotionRequest && (
                    <>
                      <div className={styles.otherColumn}>
                        <SelectInput
                          label="Reason"
                          id="reason"
                          options={reasonOptions}
                          ariaLabelledBy="reason"
                          onChange={(event: SelectInputType) =>
                            handleSelectInputChange(event, 'reason')
                          }
                          value={selectedReason}
                          disabled={disableForm}
                          isClearable
                        />
                        {selectedReason && selectedReason.label === 'Other' && (
                          <span className={styles.otherRow}>
                            <NextArrow />
                            <TextInput
                              type="text"
                              id="otherReasonSpecification"
                              ariaLabelledBy="Specify reason"
                              placeholder="Specify reason..."
                              value={decodeSanitizedValue(inputtedOtherReason)}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                handleTextInputChange(event, 'otherReason');
                              }}
                              disabled={disableForm}
                            />
                          </span>
                        )}
                      </div>
                      <div id="costingEventCommentContainer">
                        <TextInput
                          type="text"
                          label="Costing Event Comment"
                          id="costingEventComment"
                          disabled={disableForm || !selectedReason?.value}
                          value={decodeSanitizedValue(selectedCostingEvent)}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setSelectedCostingEvent(event.target.value);
                          }}
                          labelDisabled={disableForm || !selectedReason?.value}
                        />
                        <ReactTooltip
                          anchorId="costingEventCommentContainer"
                          aria-live="polite"
                          place="bottom"
                        >
                          <Text element="p" small>
                            {modalCopy.toolTips.costingEventComment}
                          </Text>
                        </ReactTooltip>
                      </div>
                    </>
                  )}
                  <div className={styles.emailContainer}>
                    <TextInput
                      type="text"
                      label="Email Notification Override"
                      id="email"
                      ariaLabelledBy="Email Notification Override"
                      value={inputtedEmail}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputChange(event, 'email');
                      }}
                      helper
                      disabled={disableForm}
                    />
                  </div>
                </div>
                {!showWarnings && (
                  <div className={styles.buttonContainer}>
                    <Button
                      primary
                      onClick={() => handleSaveButton('saveAndClose')}
                      disabled={
                        formErrorsExist ||
                        disableCalculateBtn ||
                        calculateErrorsExist
                      }
                    >
                      {modalCopy.buttons[0].label}
                    </Button>
                    <Button
                      secondary
                      onClick={() => handleSaveButton('saveAndNext')}
                      disabled={
                        formErrorsExist ||
                        disableCalculateBtn ||
                        calculateErrorsExist
                      }
                    >
                      {modalCopy.buttons[1].label}
                    </Button>
                    {!usePrDetails &&
                    !formErrorsExist &&
                    calculateErrors.length === 0 ? (
                      <Button
                        link
                        onClick={() => {
                          dispatch(setRefreshDrafts(true));
                          onRequestClose();
                        }}
                        className={styles.discardButton}
                      >
                        {modalCopy.buttons[2].label}
                      </Button>
                    ) : null}
                    {hasErrors && !formErrorsExist && (
                      <Button
                        link
                        onClick={() => handleClearModal()}
                        className={styles.buttonErrors}
                      >
                        Go Back
                      </Button>
                    )}
                    {prDetails && prDetails?.id && usePrDetails && (
                      <Button
                        link
                        iconLeft
                        icon={<GarbageCan />}
                        onClick={() =>
                          deleteSinglePriceRequest(deleteSinglePayload)
                        }
                        className={styles.deleteButton}
                      >
                        {modalCopy.buttons[5].label}
                      </Button>
                    )}
                  </div>
                )}

                {showWarnings && (
                  <div className={styles.buttonWarnings}>
                    <div className={styles.warningContainer}>
                      {calculateWarnings.map((warning, index) => {
                        const code = warning.code;
                        if (!warningMessageContent[code]) return null;
                        const description = warningMessageContent[
                          code
                        ].description.includes('{pricePerSU}')
                          ? warningMessageContent[code].description.replaceAll(
                              '{pricePerSU}',
                              floorPrice.toString()
                            )
                          : warningMessageContent[code].description;
                        return (
                          <Banner
                            type="warning"
                            icon={<ExclamationPoint />}
                            title={warning?.title || undefined}
                            description={description}
                            key={`${index}-warning`}
                          />
                        );
                      })}
                      {showUIWarnings &&
                        interfaceWarnings.map(
                          (warning: {
                            title: string;
                            description: string;
                            justificationRequired: boolean;
                          }) => {
                            return (
                              <Banner
                                type="warning"
                                icon={<ExclamationPoint />}
                                title={warning?.title || undefined}
                                description={warning.description}
                              />
                            );
                          }
                        )}
                      {justificationRequired && (
                        <TextArea
                          id="addNewPRJustification"
                          label={modalCopy.justificationRequired.label}
                          required
                          maxLength={2500}
                          value={decodeSanitizedValue(justification)}
                          onChange={(
                            event: ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleTextInputChange(
                              event,
                              'addNewPRJustification'
                            );
                          }}
                        />
                      )}
                    </div>
                    <div className={styles.buttonContainer}>
                      <Button primary onClick={() => handleConfirmSave()}>
                        Confirm & Save
                      </Button>
                      <Button link onClick={() => handleClearModal()}>
                        Go Back
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewPRLDBModal;
