import React from 'react';
import { CSVLink } from 'react-csv';

import { useAuth } from '../../contexts/AuthenticationProvider';

import { getHeaders, getCSVTimestamp, getData } from '../../helpers';

import { ProductType } from '../../types/productTypes';

import Button from '../Button';
import DownloadReverse from '../Icons/DownloadReverse';

import styles from './index.module.scss';

interface CSVDownloadProps {
  productData: ProductType[] | undefined;
  isDisabled: boolean;
}

const CSVDownload: React.FC<CSVDownloadProps> = ({
  productData,
  isDisabled = true
}) => {
  const { user } = useAuth().authState;

  return (
    <CSVLink
      data={getData(productData) || []}
      headers={getHeaders(user.type)}
      filename={`BCLDB_price_request_template_${getCSVTimestamp()}.csv`}
      className={`${styles.csvDownload} ${isDisabled ? styles.disabled : ''}`}
    >
      <Button
        secondary
        icon={<DownloadReverse />}
        iconLeft
        disabled={isDisabled}
      >
        Download Template
      </Button>
    </CSVLink>
  );
};

export default CSVDownload;
