import React, { useState } from 'react';
import classNames from 'classnames';

import ErrorAlert from '../Icons/ErrorAlert';
import TableTooltip from '../TableTooltip';

import styles from './index.module.scss';
import { LocationType } from '../../types/productTypes';

type PropsType = {
  children: string | React.ReactNode;
  htmlFor: string;
  className?: string;
  ariaLabel?: string;
  error?: boolean;
  id?: string;
  disabled?: boolean;
  tooltip?: string;
};

const Label = ({
  children,
  htmlFor,
  className,
  ariaLabel,
  error,
  id,
  disabled,
  tooltip
}: PropsType) => {
  //create location state used to determine if the tooltips should render below or above the
  // text based on mouse location in the screen
  const [location, setLocation] = useState<LocationType>('bottom');
  // this callback will be passed into the tooltip component to bring the state from child to parent
  const callback = (state: LocationType) => {
    setLocation(state);
  };
  return (
    <label
      className={classNames(className, styles.label, {
        [styles.labelError]: error,
        [styles.labelDisabled]: disabled
      })}
      id={id}
      htmlFor={htmlFor}
    >
      {tooltip && (
        <span
          className={classNames(styles.tooltipContainer, {
            [styles.tooltipContainerTop]: location === 'top',
            [styles.tooltipContainerBottom]: location === 'bottom'
          })}
        >
          <TableTooltip label={tooltip} callback={callback} />
        </span>
      )}
      {error && (
        <span aria-hidden className={styles.errorIcon}>
          <ErrorAlert />
        </span>
      )}
      {children}
    </label>
  );
};

export default Label;
