import { ClipboardEvent, KeyboardEvent } from 'react';

export const preventIllegalCharactersHandleKeyDown = (event: KeyboardEvent) => {
  const illegalCharactersSet = /[^a-zA-Z0-9 ,.?]+/g;

  if (illegalCharactersSet.test(event.key)) {
    event.preventDefault();
  }
};

export const preventIllegalCharacterHandlePaste = (event: ClipboardEvent) => {
  const clipboardData = event.clipboardData;
  const text = clipboardData?.getData('text');
  const illegalCharactersSet = /[^a-zA-Z0-9 ,.?]+/g;

  if (illegalCharactersSet.test(text)) {
    event.preventDefault();
  }
};
