// import env from '../components/Environment';
import Text from '../components/Text';
import { currencyFormat } from './';

/**
 * Handles rendering Text component for Currency cells.
 *
 * @param cellValue - the value of the currency cell.
 * @returns A Text component formatted to the Table Cell currency standards.
 *
 */

export const currencyCellText = (cellValue: string | number | undefined) => {
  const currencyValue = currencyFormat(cellValue);
  const hasCurrency =
    typeof currencyValue == 'string' && currencyValue.indexOf('-- . --') < 0
      ? true
      : false;
  return (
    <Text element="p" tableCell alignRight disabled={!hasCurrency}>
      {currencyValue}
    </Text>
  );
};
