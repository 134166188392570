import React from 'react';

export default function ErrorAlert() {
  return (
    <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title id="icon-error-alert">Error Alert</title>
      <path
        d="M17.0643 12.7465C17.593 13.6369 16.9293 14.75 15.8733 14.75H2.12655C1.06846 14.75 0.407834 13.6352 0.935547 12.7465L7.809 1.16755C8.338 0.276676 9.66296 0.27829 10.191 1.16755L17.0643 12.7465ZM9.00001 10.3525C8.27226 10.3525 7.6823 10.9257 7.6823 11.6328C7.6823 12.3399 8.27226 12.9131 9.00001 12.9131C9.72775 12.9131 10.3177 12.3399 10.3177 11.6328C10.3177 10.9257 9.72775 10.3525 9.00001 10.3525ZM7.74896 5.75062L7.96145 9.53578C7.97139 9.7129 8.12213 9.85156 8.30469 9.85156H9.69533C9.87789 9.85156 10.0286 9.7129 10.0386 9.53578L10.2511 5.75062C10.2618 5.55931 10.105 5.39844 9.90782 5.39844H8.09216C7.89496 5.39844 7.73821 5.55931 7.74896 5.75062Z"
        fill="currentColor"
      />
    </svg>
  );
}
