import React from 'react';

import ArrowUp from '../Icons/ArrowUp';

import styles from './index.module.scss';

import copy from '../../config/translations/en-CA.json';
const backToTopCopy = copy.backToTop;

function ScrollUpButton() {
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <button onClick={scrollUp} className={styles.scrollUpButton}>
      <span>{backToTopCopy}</span>
      <ArrowUp />
    </button>
  );
}

export default ScrollUpButton;
