import React, { FunctionComponent, LegacyRef } from 'react';
import classNames from 'classnames';

import styles from './Tab.module.scss';

interface TabProps {
  id: string;
  title: string;
  selectedTab: number;
  index: number;
  tabPanelId: string;
  handleChange: (index: number) => void;
  tabRef?: LegacyRef<HTMLButtonElement>;
}

const Tab: FunctionComponent<TabProps> = ({
  id,
  title,
  selectedTab,
  index,
  tabPanelId,
  handleChange,
  tabRef
}) => {
  const handleClick = () => handleChange(index);
  return (
    <li role="presentation" className={styles.tabContainer}>
      <button
        role="tab"
        id={id}
        aria-selected={selectedTab === index}
        aria-controls={tabPanelId}
        tabIndex={selectedTab === index ? 0 : -1}
        onClick={handleClick}
        className={classNames(styles.tabButton, {
          [styles.tabButtonActive]: selectedTab === index
        })}
      >
        {title}
      </button>
    </li>
  );
};
export default Tab;
