import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { AuthenticationContext } from '../../contexts/AuthenticationProvider';

import {
  extractAgentsFromUser,
  getSessionToken,
  priceRequestStatusSelectOptions,
  priceRequestTypeSelectOptions,
  UserTypeEnum,
  defaultSelectNumber,
  defaultSelectString,
  formatNumericDate
} from '../../helpers';

import { useEffectiveDates } from '../../hooks/useEffectiveDates';

import {
  setEffectiveDate,
  setFilters,
  setPriceRequestTable,
  setSubcategories
} from '../../reducers/PriceRequestSlice';
import { useGetPriceRequestCreatedByOptionsQuery } from '../../services/PriceRequest';
import { useGetHierachyQuery } from '../../services/Hierachy';
import { RootState } from '../../store';
import { SelectInputType } from '../../types';

import {
  HierarchyClassType,
  PRSearchFormDataType,
  SubCategoryType
} from '../../types/productSearchTypes';

import { CreatedByResponse } from '../../types';

import { defaultFormValues, getPriceRequestFilterConditions } from './utils';

import Button from '../Button';
import CalendarDatePicker from '../CalendarDatePicker/CalendarDatePicker';

import SelectInput from '../SelectInput/SelectInput';
import FilterTextInput from '../FilterTextInput';

import styles from './PriceRequestListTable.module.scss';

export default function PriceRequestTableFilters() {
  const dispatch = useDispatch();
  const token = getSessionToken();
  const { authState } = useContext(AuthenticationContext);

  const { user } = authState;
  const userType = user.type;
  const {
    effectiveDate,
    subCategories,
    priceRequestTable,
    productSuppliersList
  } = useSelector((state: RootState) => state.priceRequest);

  const { highlightedEffectiveDates } = useEffectiveDates(token);

  const methods = useForm<PRSearchFormDataType>({
    defaultValues: defaultFormValues
  });

  const { data } = useGetHierachyQuery(
    {
      azureUniqueID: user.userObjectID,
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const categorySelectOptions =
    (data &&
      data.map((option: HierarchyClassType) => {
        return {
          label: option.description,
          value: option.hierarchy_id
        };
      })) ||
    [];
  const subCategoriesByCategory =
    (data &&
      data.map((items: HierarchyClassType) => {
        return items.sub_categories;
      })) ||
    [];

  const subcategorySelectOptions = subCategoriesByCategory
    .flat(1)
    .map((subcat: SubCategoryType) => {
      return {
        label: subcat.description,
        value: subcat.hierarchy_id
      };
    });

  const getSubCategories = (parent_id: number) => {
    const subcategoriesByUpdatedCategory = subCategoriesByCategory
      .flat(1)
      .filter((subcat: SubCategoryType) => subcat.parent_id === parent_id);

    //map over the results and create an updated array of objects that hole the description and hierarchy id of all of these subcategories
    const updatedSubcategorySelectOptions: SelectInputType[] =
      subcategoriesByUpdatedCategory.map((subcat: SubCategoryType) => {
        return {
          label: subcat.description,
          value: String(subcat.hierarchy_id)
        };
      });
    dispatch(setSubcategories(updatedSubcategorySelectOptions));
  };

  const { data: CreatedByData } = useGetPriceRequestCreatedByOptionsQuery(
    {
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true,
      skip: user.type !== UserTypeEnum.INTERNAL
    }
  );

  const createdByOptions =
    (CreatedByData &&
      CreatedByData.map((option: CreatedByResponse) => {
        const labelSuffix = option.type == UserTypeEnum.INTERNAL ? '(LDB)' : '';
        return {
          label: `${option.first_name} ${option.last_name} ${labelSuffix}`,
          value: option.az_user_object_id
        };
      })) ||
    [];

  const listingAgentOptions = productSuppliersList
    ? [...productSuppliersList]
        .sort((a, b) => a.vendor_id - b.vendor_id)
        .map((vendor) => {
          const internalId = vendor.id;
          const externalId = vendor.vendor_id;
          const idAsString = internalId.toString();
          return {
            label: `${externalId} - ${vendor.name}`,
            value: idAsString
          };
        })
    : [
        {
          label: '',
          value: ''
        }
      ];

  const [selectedCategoryValue, setSelectedCategoryValue] =
    useState<SelectInputType>(defaultSelectNumber);

  const [selectedSubCategoryValue, setSelectedSubCategoryValue] =
    useState<SelectInputType>(defaultSelectNumber);

  const [selectedStatusValue, setSelectedStatusValue] =
    useState<SelectInputType>(defaultSelectNumber);

  const [selectedTypeValue, setSelectedTypeValue] =
    useState<SelectInputType>(defaultSelectString);

  const [selectedPricingAgentValue, setSelectedPricingAgentValue] =
    useState<SelectInputType>(defaultSelectNumber);

  const [selectedListingAgentValue, setSelectedListingAgentValue] =
    useState<SelectInputType>(defaultSelectNumber);

  const [selectedCreatedByValue, setSelectedCreatedByValue] =
    useState<SelectInputType>(defaultSelectNumber);

  function isSelectInputType(item: any): item is SelectInputType {
    return typeof item === 'object' && item !== null;
  }

  const handleChangeInput = (
    key: keyof PRSearchFormDataType,
    item: string | Date | SelectInputType
  ) => {
    let newValue = item;
    if (key === 'effectiveDate') {
      newValue = formatNumericDate(item?.toString());
      dispatch(setEffectiveDate(newValue));
    }
    if (key === 'category' && isSelectInputType(item)) {
      methods.setValue('subCategory', defaultSelectNumber);
      setSelectedCategoryValue(item);
      setSelectedSubCategoryValue(defaultSelectNumber);
      getSubCategories(Number((item as SelectInputType).value));
    }

    if (key === 'subCategory' && isSelectInputType(item)) {
      setSelectedSubCategoryValue(item);
    }

    if (key === 'pricingAgent' && isSelectInputType(item)) {
      setSelectedPricingAgentValue(item);
    }

    if (key === 'listingAgent' && isSelectInputType(item)) {
      setSelectedListingAgentValue(item);
    }

    if (key === 'type' && isSelectInputType(item)) {
      setSelectedTypeValue(item);
    }

    if (key === 'status' && isSelectInputType(item)) {
      setSelectedStatusValue(item);
    }

    if (key === 'created_by' && isSelectInputType(item)) {
      setSelectedCreatedByValue(item);
    }

    methods.setValue(key, newValue);
  };

  // Function used to clear the product search form input values
  const resetFilterValues = () => {
    methods.reset({ ...defaultFormValues });
    setSelectedTypeValue(defaultSelectString);
    setSelectedStatusValue(defaultSelectString);
    dispatch(setEffectiveDate(''));
    dispatch(setSubcategories([]));
    setSelectedCategoryValue(defaultSelectNumber);
    setSelectedSubCategoryValue(defaultSelectNumber);
    setSelectedPricingAgentValue(defaultSelectNumber);
    setSelectedListingAgentValue(defaultSelectNumber);
    setSelectedCreatedByValue(defaultSelectNumber);
    dispatch(setFilters([]));
  };

  // Reset Form and table sorting
  const clearForm = () => {
    dispatch(
      setPriceRequestTable({
        ...priceRequestTable,
        sortBy: 'status',
        sortType: 'asc'
      })
    );
    resetFilterValues();
  };

  const onSubmit = () => {
    const filters: unknown[] = [];
    getPriceRequestFilterConditions(methods.getValues()).forEach(
      ({ check, filter }) => {
        if (check) {
          filters.push(filter);
        }
      }
    );

    dispatch(setFilters(filters));
    dispatch(
      setPriceRequestTable({
        ...priceRequestTable,
        pageNumber: 0
      })
    );
  };

  //List of pricing agents
  const unorderedPricingAgentList = extractAgentsFromUser(
    user.vendorList,
    'AGENT'
  );

  // Custom function to compare pricing agent labels and sort numerically by the external vendor ID
  const compareLabels = (
    a: { label: string; value: string },
    b: { label: string; value: string }
  ) => {
    const numA = parseInt(a.label.split(' - ')[0]);
    const numB = parseInt(b.label.split(' - ')[0]);
    return numA - numB;
  };

  // Sort the array using compareLabels to use in Pricing Agent dropdown
  const sortedPricingAgentList = unorderedPricingAgentList
    .slice()
    .sort(compareLabels);

  // Clear filters on mount
  useEffect(() => resetFilterValues(), []);

  return (
    <FormProvider {...methods}>
      <form className={styles.filterContainer}>
        <div className={styles.input}>
          <SelectInput
            label="Request Type"
            ariaLabelledBy="type"
            id="type"
            options={priceRequestTypeSelectOptions}
            onChange={(val: SelectInputType) => handleChangeInput('type', val)}
            value={selectedTypeValue}
          />
        </div>
        <div className={styles.input}>
          <SelectInput
            label="Status"
            ariaLabelledBy="status"
            id="status"
            options={priceRequestStatusSelectOptions(userType)}
            onChange={(value: SelectInputType) =>
              handleChangeInput('status', value)
            }
            value={selectedStatusValue}
          />
        </div>
        <div className={`${styles.input} ${styles.inputcalendar}`}>
          <CalendarDatePicker
            label="Effective Date"
            id="effectiveDate"
            value={effectiveDate}
            onChange={(value: Date) =>
              handleChangeInput('effectiveDate', value)
            }
            highlightedDates={highlightedEffectiveDates}
            legend="Pricing Calendar Effective Dates"
            placeholderText="Select..."
          />
        </div>
        <div className={styles.input}>
          <SelectInput
            label="Pricing Agent"
            ariaLabelledBy="pricingAgent"
            id="pricingAgent"
            options={sortedPricingAgentList}
            onChange={(value: SelectInputType) =>
              handleChangeInput('pricingAgent', value)
            }
            value={selectedPricingAgentValue}
          />
        </div>
        <div className={styles.input}>
          <SelectInput
            label="Listing Agent"
            ariaLabelledBy="listingAgent"
            id="listingAgent"
            options={listingAgentOptions}
            onChange={(value: SelectInputType) =>
              handleChangeInput('listingAgent', value)
            }
            value={selectedListingAgentValue}
          />
        </div>
        <div className={styles.input}>
          <FilterTextInput
            label="SKU #"
            aria-labelledby="sku"
            id="sku"
            type="number"
            placeholder=""
            min="0"
            onChange={(value: ChangeEvent<HTMLInputElement>) => {
              handleChangeInput('sku', value.target.value);
            }}
          />
        </div>
        <div className={styles.input}>
          <FilterTextInput
            label="Product Name"
            aria-labelledby="product"
            id="product"
            type="text"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChangeInput('product', event.target.value);
            }}
          />
        </div>
        <div className={styles.input}>
          <SelectInput
            label="Category"
            ariaLabelledBy="category"
            id="category"
            options={categorySelectOptions}
            onChange={(value: SelectInputType) =>
              handleChangeInput('category', value)
            }
            value={selectedCategoryValue}
          />
        </div>
        <div className={styles.input}>
          <SelectInput
            label="Sub-Category"
            ariaLabelledBy="subCategory"
            id="subCategory"
            options={
              subCategories.length > 0
                ? subCategories
                : subcategorySelectOptions
            }
            onChange={(value: SelectInputType) =>
              handleChangeInput('subCategory', value)
            }
            value={selectedSubCategoryValue}
          />
        </div>
        {userType === UserTypeEnum.INTERNAL ? (
          <div className={styles.input}>
            <SelectInput
              label="Created By"
              ariaLabelledBy="created_by"
              id="created_by"
              options={createdByOptions}
              onChange={(value: SelectInputType) =>
                handleChangeInput('created_by', value)
              }
              value={selectedCreatedByValue}
            />
          </div>
        ) : null}
        <div
          className={`${styles.inputButtons} ${
            user.type === UserTypeEnum.INTERNAL
              ? styles.inputButtonsInternal
              : null
          }`}
        >
          <Button
            children="Apply Filters"
            submit
            primary
            id={'apply-filters-btn'}
            onClick={methods.handleSubmit(onSubmit)}
          />
          <Button
            className={`${styles.inputButtons} ${
              user.type === UserTypeEnum.INTERNAL
                ? styles.internalClearButtons
                : styles.externalClearButtons
            }`}
            link
            onClick={clearForm}
            id={'clear-btn'}
          >
            Clear
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
