import React from 'react';

export default function ArrowDown() {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-arrow-down">Arrow Down</title>
      <path
        d="M6.99648 17.3044L6.99648 1.37507C6.99648 0.614483 7.6668 0 8.49648 0C9.32617 0 9.99648 0.614483 9.99648 1.37507L9.99648 17.3044L13.4371 14.1503C14.023 13.6132 14.9746 13.6132 15.5605 14.1503C16.1465 14.6874 16.1465 15.5597 15.5605 16.0969L9.56055 21.5971C8.97461 22.1343 8.02305 22.1343 7.43711 21.5971L1.43711 16.0969C0.851172 15.5597 0.851172 14.6874 1.43711 14.1503C2.02305 13.6132 2.97461 13.6132 3.56055 14.1503L6.99648 17.3044Z"
        fill="currentColor"
      />
    </svg>
  );
}
