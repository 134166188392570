import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { UserTypeEnum, PriceRequestStatusEnum } from '../helpers';

import {
  SelectInputType,
  SelectInputDateType,
  PriceRequest,
  BannerType
} from '../types';

export interface PriceRequestDetailsFormStatus {
  user?: string;
  priceRequestType?: string;
  priceRequestStatus?: string;
  productSku?: number;
  productIsPromotion?: boolean;
  productIsDPC?: boolean;
  productIsImport?: boolean;
  isInit?: boolean;
}

export interface PriceRequestDetailsFormFieldValues {
  pricePerSU?: string;
  primeCostPerCase?: string;
  domesticChargesPerCase?: string;
  promotionAmount?: string;
  dutyPaidCostPerCase?: string;
  dutyPaidCostPerSU?: string;
  estWholesalePrice?: string;
  reason?: SelectInputType;
  otherReason?: string;
  exceptionReason?: string;
  exceptionJustification?: string;
  justification?: string;
  effectiveDate?: SelectInputDateType;
  endDate?: string;
  wholesalePriceEffectiveDate?: string;
  costingEventComment?: string;
  currencyCode?: SelectInputType;
  fxRateDate?: string;
  foreignPrimeCost?: string;
  calculatedExchangeRate?: number;
  aces_number?: number;
  asReview?: boolean;
  costOnly?: boolean;
  email?: string;
  inBondCostPerCase?: string;
  exciseAmountPerCase?: string;
  customsAmountPerCase?: string;
  beerDistroChargePerSU?: string;
  markupPerSU?: string;
  crfPerSU?: string;
  pricePerCase?: string;
  floorPrice?: string;
}

interface PriceRequestDetailsState {
  formStatus: PriceRequestDetailsFormStatus;
  fieldValues: PriceRequestDetailsFormFieldValues;
  priceRequest: PriceRequest;
  banner: BannerType | undefined;
}

const initialState: PriceRequestDetailsState = {
  formStatus: {
    user: undefined,
    priceRequestType: undefined,
    priceRequestStatus: undefined,
    productSku: undefined,
    productIsPromotion: undefined,
    productIsDPC: undefined,
    productIsImport: undefined,
    isInit: false
  },
  fieldValues: {
    pricePerSU: undefined,
    primeCostPerCase: undefined,
    domesticChargesPerCase: undefined,
    promotionAmount: undefined,
    dutyPaidCostPerCase: undefined,
    estWholesalePrice: undefined,
    reason: undefined,
    otherReason: undefined,
    exceptionReason: undefined,
    exceptionJustification: undefined,
    justification: undefined,
    effectiveDate: undefined,
    endDate: undefined,
    wholesalePriceEffectiveDate: undefined,
    costingEventComment: undefined,
    currencyCode: undefined,
    fxRateDate: undefined,
    foreignPrimeCost: undefined,
    calculatedExchangeRate: undefined,
    aces_number: undefined,
    asReview: undefined,
    costOnly: undefined,
    email: undefined,
    inBondCostPerCase: undefined,
    exciseAmountPerCase: undefined,
    customsAmountPerCase: undefined,
    beerDistroChargePerSU: undefined,
    markupPerSU: undefined,
    crfPerSU: undefined,
    pricePerCase: undefined,
    floorPrice: undefined
  },
  priceRequest: {},
  banner: undefined
};

const PriceRequestDetailsSlice = createSlice({
  name: 'priceRequestDetails',
  initialState,
  reducers: {
    setFormStatus: (
      state,
      action: PayloadAction<PriceRequestDetailsFormStatus>
    ) => {
      state.formStatus = { ...state.formStatus, ...action.payload };
    },
    setInputValue: (
      state,
      action: PayloadAction<{
        fieldName: keyof PriceRequestDetailsFormFieldValues;
        value: any;
      }>
    ) => {
      const { fieldName, value } = action.payload;
      state.fieldValues[fieldName] = value;
    },
    setInitialInputValues: (
      state,
      action: PayloadAction<PriceRequestDetailsFormFieldValues>
    ) => {
      state.fieldValues = action.payload;
    },
    setPriceRequest: (state, action: PayloadAction<PriceRequest>) => {
      state.priceRequest = action.payload;
    },
    resetPriceRequestDetails: () => initialState,
    setBannerDetails: (
      state,
      action: PayloadAction<BannerType | undefined>
    ) => {
      state.banner = action.payload;
    }
  }
});

export const {
  setFormStatus,
  setInputValue,
  setInitialInputValues,
  setPriceRequest,
  setBannerDetails,
  resetPriceRequestDetails
} = PriceRequestDetailsSlice.actions;

const selectFormStatus = (state: RootState) =>
  state.priceRequestDetails.formStatus;

const selectPrReason = (state: RootState) =>
  state.priceRequestDetails.priceRequest.reason;

export const selectIsDisabled = createSelector(
  selectFormStatus,
  selectPrReason,
  (formStatus, prReason) => {
    return (
      formStatus.user !== UserTypeEnum.INTERNAL &&
      ((formStatus.priceRequestStatus !== PriceRequestStatusEnum.AS_REVIEW &&
        formStatus.priceRequestStatus !== PriceRequestStatusEnum.DRAFT &&
        formStatus.priceRequestStatus !== PriceRequestStatusEnum.QUEUED) ||
        (prReason !== 'Supplier Price Change' &&
          formStatus.priceRequestType === 'PC') ||
        formStatus.priceRequestType === 'New Product Pricing / Reactivation')
    );
  }
);

export const prIsCancellable = createSelector(
  selectFormStatus,
  selectPrReason,
  (formStatus, prReason) => {
    if (
      formStatus.user === UserTypeEnum.EXTERNAL &&
      formStatus.priceRequestType === 'PC' &&
      prReason !== 'Supplier Price Change'
    )
      return false;
    return (
      (formStatus.user === UserTypeEnum.INTERNAL &&
        formStatus.priceRequestStatus === PriceRequestStatusEnum.ACCEPTED) ||
      formStatus.priceRequestStatus === PriceRequestStatusEnum.AS_REVIEW ||
      formStatus.priceRequestStatus === PriceRequestStatusEnum.LDB_REVIEW ||
      formStatus.priceRequestStatus === PriceRequestStatusEnum.QUEUED
    );
  }
);

export default PriceRequestDetailsSlice.reducer;
