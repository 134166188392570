export enum ColorToken {
  backgroundHighlight = '#E9EDF5',
  colorWhite = '#FFFFFF',
  textRed = '#D1293D',
  backgroundMediumGrey = '#BCC2CE',
  textParagraph = '#464F60',
  textBlack = '#212121',
  textPlaceholder = '#A1A9B8',
  borderFocus = '#687182'
}
