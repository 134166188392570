import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';

type TokenResponse = {
  token: string;
};

interface PKCErequest {
  code: string;
  code_verifier: string;
  session_uuid: string;
}

type TokenPayload = {
  requestBody: PKCErequest;
};

export const tokenApi = createApi({
  reducerPath: 'tokenApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  endpoints: (builder) => ({
    token: builder.mutation<TokenResponse, TokenPayload>({
      query: ({ requestBody }) => ({
        headers: {
          ...headers
        },
        body: requestBody,
        url: 'api/token',
        method: 'POST'
      })
    })
  })
});

export const { useTokenMutation } = tokenApi;
