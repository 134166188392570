import React from 'react';

export default function Lock() {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-lock">Lock box</title>
      <path
        d="M3.9165 3.9375V5.25H8.08317V3.9375C8.08317 2.72891 7.15088 1.75 5.99984 1.75C4.8488 1.75 3.9165 2.72891 3.9165 3.9375ZM2.24984 5.25V3.9375C2.24984 1.76367 3.92952 0 5.99984 0C8.07015 0 9.74984 1.76367 9.74984 3.9375V5.25H10.1665C11.0858 5.25 11.8332 6.03477 11.8332 7V12.25C11.8332 13.2152 11.0858 14 10.1665 14H1.83317C0.9139 14 0.166504 13.2152 0.166504 12.25V7C0.166504 6.03477 0.9139 5.25 1.83317 5.25H2.24984Z"
        fill="currentColor"
      />
    </svg>
  );
}
