export default function DownloadSimple() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          x="1.33301"
          y="14"
          width="13.3333"
          height="2"
          rx="1"
          fill="currentColor"
        />
        <path
          d="M9.00039 1C9.00039 0.446875 8.55352 0 8.00039 0C7.44727 0 7.00039 0.446875 7.00039 1V7.91771L4.70664 5.62396C4.31602 5.23333 3.68164 5.23333 3.29102 5.62396C2.90039 6.01458 2.90039 6.64896 3.29102 7.03958L7.29102 11.0396C7.68164 11.4302 8.31602 11.4302 8.70664 11.0396L12.7066 7.03958C13.0973 6.64896 13.0973 6.01458 12.7066 5.62396C12.316 5.23333 11.6816 5.23333 11.291 5.62396L9.00039 7.91771V1Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
