import React from 'react';
import { useTable } from 'react-table';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { dateFormat, createPriceBreakdownTable } from '../../../helpers';

import { priceBreakdownColumns } from './PriceBreakdownTableColumns';
import Text from '../../Text';

import styles from './ProductDetailsModal.module.scss';

import copy from '../../../config/translations/en-CA.json';

const modalCopy = copy.modals[0].productDetails.tabs[0];
const tabHeaders = modalCopy.table.rowHeaders;
const categoryHeaders = modalCopy.categoryHeaders;

const PriceBreakdownTab = () => {
  const { productDetailsData } = useSelector(
    (state: RootState) => state.priceRequest
  );

  const packPerCase = productDetailsData?.packPerCase || 0;

  const productData = createPriceBreakdownTable(
    tabHeaders,
    productDetailsData,
    packPerCase
  );

  const options = {
    data: productData,
    columns: priceBreakdownColumns
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options);

  const productHeadlines = [
    {
      title: categoryHeaders && categoryHeaders[0].label,
      value: productDetailsData?.currencyLabel
    },
    {
      title: categoryHeaders && categoryHeaders[1].label,
      value: dateFormat(productDetailsData?.currencyEffectiveDate, false)
    },
    {
      title: categoryHeaders && categoryHeaders[2].label,
      value: productDetailsData?.currencyRate
    }
  ];

  return (
    <div>
      <div className={styles.priceBreakdownUpper}>
        {productHeadlines.map((headline, index) => {
          const value = headline.value;
          if (value) {
            return (
              <div className={styles.priceBreakDownItem} key={`item-${index}`}>
                <Text element="span" tiny light upperCase>
                  {headline.title}
                </Text>
                <Text
                  element="p"
                  className={classNames(styles.headlineValue, {
                    [styles.headlineValueUppercase]:
                      headline.title === 'currency code'
                  })}
                >
                  {headline.value}
                </Text>
              </div>
            );
          }
        })}
      </div>
      <div className={styles.priceBreakdownLower}>
        <div className={styles.productTable}>
          <div data-testid={`price-breakdown-table`}>
            <table {...getTableProps()} className={styles.tableContainer}>
              <thead className={styles.tableHeader}>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className={styles.headerRow}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        className={styles.headerCells}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={styles.bodyRow}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.cellContainer}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceBreakdownTab;
