import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';

type PriceComponentsResponse = {
  sku: string;
  effectiveDate: Date;
  primeCostPerCase: number;
  foreignPrimeCostPerCase: number;
  currencyLabel: string;
  currencyRate: number;
  currencyEffectiveDate: Date;
  domesticChargesPerCase: number;
  inBondCostPerCase: number;
  exciseAmountPerCase: number;
  customsAmountPerCase: number;
  dutyPaidCostPerCase: number;
  crfPerSU: number;
};

type PriceComponentsParams = {
  sku: number;
  selectedEffectiveDate: string;
  token: string | null;
};

export const priceComponentsApi = createApi({
  reducerPath: 'priceComponentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  endpoints: (builder) => ({
    getPriceComponents: builder.mutation<
      PriceComponentsResponse,
      PriceComponentsParams
    >({
      query: ({ sku, selectedEffectiveDate, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `price/${sku}/components?transactionDate=${selectedEffectiveDate}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetPriceComponentsMutation } = priceComponentsApi;
