import React from 'react';

export default function DownloadReverse() {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-download-document">Download Document</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.215 1.20431L11.9183 3.90759C12.2083 4.19757 12.3726 4.59388 12.3726 5.00308V15.7034C12.3726 16.5573 11.6798 17.25 10.826 17.25H1.54657C0.692736 17.25 0 16.5573 0 15.7034V2.2998C0 1.44596 0.692736 0.753222 1.54657 0.75H8.12273C8.53193 0.75 8.92501 0.914323 9.215 1.20431ZM8.24836 4.87741H10.7003L8.24836 2.42545V4.87741ZM1.54637 15.7034V2.2998H6.70161V5.6507C6.70161 6.07923 7.04637 6.42399 7.4749 6.42399H10.8258V15.7034H1.54637ZM6.96094 12.2669V8.97656H6.95771C6.95771 8.54795 6.61289 8.20312 6.18427 8.20312C5.75566 8.20312 5.41084 8.54795 5.41084 8.97656V12.2669L4.41181 11.2679C4.11211 10.9649 3.62226 10.9682 3.31933 11.2679C3.0164 11.5676 3.0164 12.0574 3.31933 12.3604L5.63965 14.6807C5.93935 14.9836 6.4292 14.9836 6.73213 14.6807L9.05244 12.3604C9.35537 12.0606 9.35215 11.5708 9.05244 11.2679C8.75273 10.9649 8.26289 10.9649 7.95996 11.2679L6.96094 12.2669Z"
        fill="currentColor"
      />
    </svg>
  );
}
