import React, { useState, KeyboardEvent } from 'react';

import { TabItem } from '../../types/modalFormTypes';

import TabPanel from './TabPanel';
import Tab from './Tab';

import styles from './Tab.module.scss';

type PropsType = {
  tabValues: TabItem[];
  ariaLabel: string;
};

const Tabs = ({ tabValues, ariaLabel }: PropsType) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleClick = (index: number) => {
    setSelectedTab(index);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLUListElement>) => {
    const tabCount = Object.keys(tabValues).length;

    if (event.key === 'ArrowLeft') {
      const last = tabCount;
      const next = selectedTab - 1;
      handleNextTab(last, next, 1);
    }
    if (event.key === 'ArrowRight') {
      const first = 1;
      const next = selectedTab + 1;
      handleNextTab(first, next, tabCount);
    }
  };
  const handleNextTab = (
    firstTabInRound: number,
    nextTab: number,
    lastTabInRound: number
  ) => {
    const tabToSelect =
      selectedTab === lastTabInRound ? firstTabInRound : nextTab;
    setSelectedTab(tabToSelect);
  };

  return (
    <section className={styles.tabsWrapper}>
      <div className={styles.switcher}>
        <ul
          role="tablist"
          className={styles.tablist}
          aria-label={ariaLabel}
          onKeyDown={handleKeyPress}
        >
          {tabValues.map((tab, index) => {
            return (
              <Tab
                id={`tab-id-${index}`}
                tabPanelId={`${index}TabPanel`}
                index={index}
                handleChange={handleClick}
                selectedTab={selectedTab}
                title={tabValues[index].title}
                key={`${index}-tab`}
              />
            );
          })}
        </ul>
      </div>
      {tabValues.map((tab, index) => {
        return (
          <TabPanel
            id={`${index}TabPanel`}
            tabId={`${index}Tab`}
            tabIndex={index}
            selectedTab={selectedTab}
            key={`${index}-tabPanel`}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </section>
  );
};

export default Tabs;
