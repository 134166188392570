import React from 'react';
import { useTable } from 'react-table';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

import { createPriceBreakdownTable } from '../../helpers';

import { priceRequestBreakdownColumns } from './_PriceRequestBreakdownColumns';

import copy from '../../config/translations/en-CA.json';
import styles from './priceRequestBreakdownTab.module.scss';

const PriceRequestBreakdownTab = () => {
  const { priceRequest } = useSelector(
    (state: RootState) => state.priceRequestDetails
  );

  const modalCopy = copy.modals[0].priceRequestDetails.tabs.priceBreakDown;
  const tabHeaders = structuredClone(modalCopy.table.rowHeaders);
  const productData = priceRequest.productSku;
  const packPerCase: number = productData?.pack_per_case || 0;
  const { type: prType } = priceRequest;

  if (prType !== 'WPP') {
    // IF not WPP update 'Wholesale Price'
    // to use price_per_su/case as table data key
    tabHeaders.forEach((item: any) => {
      if (
        item.label === 'Wholesale Price' //EPP_1400  price_per_su should be show in 'Wholesale Price' column
      ) {
        item.perCase = 'price_per_case';
        item.perSu = 'price_per_su';
      }
    });
  }
  //EPP-1400, PR detail modal should only fills the table with Price Request information. No current price product data should be displayed there.
  //removed the productData as fallbacksource in the inputs of createPriceBreakdownTable, to prevent displaying productData when values not found in priceRequest data
  const priceRequestData = createPriceBreakdownTable(
    tabHeaders,
    priceRequest,
    packPerCase
  );

  const options = {
    data: priceRequestData,
    columns: priceRequestBreakdownColumns
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options);

  return (
    <div>
      <div className={styles.priceRequestBreakdownUpper}>
        <div className={styles.priceRequestBreakdownTable}>
          <div data-testid={`price-request-breakdown-table`}>
            <table {...getTableProps()} className={styles.tableContainer}>
              <thead className={styles.tableHeader}>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className={styles.headerRow}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        className={styles.headerCells}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={styles.bodyRow}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.cellContainer}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRequestBreakdownTab;
