import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import styles from './Tab.module.scss';

interface TabPanelProps {
  id: string;
  tabId: string;
  tabIndex: number;
  selectedTab: number;
  children: React.ReactNode;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({
  children,
  id,
  tabId,
  tabIndex,
  selectedTab
}) => (
  <section
    role="tabpanel"
    id={id}
    aria-labelledby={tabId}
    hidden={selectedTab !== tabIndex}
    tabIndex={0}
    className={classNames(styles.tabPanel, {
      [styles.tabPanelHidden]: selectedTab !== tabIndex
    })}
  >
    {children}
  </section>
);

export default TabPanel;
