import React from 'react';

export default function ChevronLeft() {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-chevron-left">Chevron Left</title>
      <path
        d="M1.06552 7.41691L7.48235 0.741392C7.79171 0.419536 8.29357 0.419536 8.60294 0.741392L9.35143 1.5201C9.66047 1.84161 9.6608 2.36236 9.35275 2.68456L4.26717 7.99983L9.35242 13.3154C9.6608 13.6376 9.66014 14.1584 9.3511 14.4799L8.60261 15.2586C8.29324 15.5805 7.79138 15.5805 7.48202 15.2586L1.06552 8.58274C0.756154 8.26089 0.756154 7.73877 1.06552 7.41691Z"
        fill="currentColor"
      />
    </svg>
  );
}
