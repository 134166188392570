import React from 'react';
import classnames from 'classnames';

import { renderTextWithLineBreaks } from '../../helpers';

import styles from './index.module.scss';

type PropsType = {
  element: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  title?: boolean;
  heading1?: boolean;
  heading2?: boolean;
  heading3?: boolean;
  heading4?: boolean;
  heading5?: boolean;
  children: string | React.ReactNode;
  action?: boolean;
  productBlockTitle?: boolean;
};

const Heading = ({
  element: Component,
  className,
  title,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  children,
  action,
  productBlockTitle
}: PropsType) => {
  return (
    <Component
      className={classnames(className, styles.heading, {
        [styles.headingTitle]: title,
        [styles.heading1]: heading1,
        [styles.heading2]: heading2,
        [styles.heading3]: heading3,
        [styles.heading4]: heading4,
        [styles.heading5]: heading5,
        [styles.headingAction]: action,
        [styles.headingProductBlockTitle]: productBlockTitle
      })}
    >
      {renderTextWithLineBreaks(children)}
    </Component>
  );
};

export default Heading;
