import React from 'react';
import { useDispatch } from 'react-redux';

import { triggerEmitLogout } from '../../reducers/ActivityMonitorSlice';

import Button from '../Button/index';

const SignOutButton = () => {
  const dispatch = useDispatch();

  return (
    <Button
      data-testif={'logout-btn'}
      onClick={() => dispatch(triggerEmitLogout(true))}
    >
      Log Out
    </Button>
  );
};

export default SignOutButton;
