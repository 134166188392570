import { LabelMappings } from '../types/TableTypes';
import Text from '../components/Text/Text';

export const textCelloutput = (value: string, type: 'date' | 'currency') => {
  const isDisabled = value === undefined || value === null;
  const placeHolder = type === 'date' ? '-- -- ----' : '--.--';

  return (
    <Text element="p" tableCell alignRight disabled={isDisabled}>
      {value ? value : <span className={'placeholder'}>{placeHolder}</span>}
    </Text>
  );
};

const labels: LabelMappings = {
  C: 'PC',
  PC: 'PC',
  NP: 'NEW',
  WPP: 'WPP',
  P: 'WPP'
};

const tooltips: LabelMappings = {
  C: 'Price Change',
  PC: 'Price Change',
  NP: 'New Product Pricing/ Reactivation',
  NEW: 'New Product Pricing/ Reactivation',
  WPP: 'Wholesale Price Promotion',
  P: 'Wholesale Price Promotion',
  D: 'Discount',
  A: 'Assigned Pricing'
};

export const labelString = (priceRequestString: string) => {
  return labels[priceRequestString] || priceRequestString;
};

export const tooltipString = (priceRequestString: string) => {
  return tooltips[priceRequestString] || priceRequestString;
};
