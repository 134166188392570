import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ActivityMonitorState {
  triggerReset: boolean;
  triggerEmitLogout: boolean;
}

const initialState: ActivityMonitorState = {
  triggerReset: false,
  triggerEmitLogout: false
};

export const activityMonitorSlice = createSlice({
  name: 'activityMonitor',
  initialState,
  reducers: {
    triggerActivityMonitorReset: (state, action: PayloadAction<boolean>) => {
      state.triggerReset = action.payload;
    },
    triggerEmitLogout: (state, action: PayloadAction<boolean>) => {
      state.triggerEmitLogout = action.payload;
    }
  }
});

export const { triggerActivityMonitorReset, triggerEmitLogout } =
  activityMonitorSlice.actions;

export default activityMonitorSlice.reducer;
