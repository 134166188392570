import { useAuth } from '../contexts/AuthenticationProvider';

import Layout from '../components/Layout';
import HelmetContainer from '../components/HelmetContainer/HelmetContainer';
import HomeContent from '../components/HomeContent';
import HomeHero from '../components/HomeHero';
import HomeContact from '../components/HomeContact';
import HomeAnnouncements from '../components/HomeAnnouncements';
import Resources from '../components/Resources';
import Heading from '../components/Heading';
import SignInButton from '../components/SignInButton';
import SignOutButton from '../components/SignOutButton';
import Card from '../components/Card';
import Text from '../components/Text';
import ScrollUpButton from '../components/ScrollUpButton';
import LoadingOverlay from '../components/LoadingOverlay';

import copy from '../config/translations/en-CA.json';
const homePageAuthCopy = copy.homePage.authentication;

const HomePage = () => {
  const authState = useAuth().authState;

  const headingContent = authState.isError
    ? homePageAuthCopy.errorHeading
    : homePageAuthCopy.heading;

  const pageCopy = copy.homePage;

  return (
    <Layout>
      <HelmetContainer title={pageCopy.pageTitle} />
      {authState.isAuthenticated ? (
        <>
          <HomeHero />
          <HomeContent>
            <HomeAnnouncements />
            <Resources />
            <ScrollUpButton />
          </HomeContent>
          <HomeContact />
        </>
      ) : (
        <>
          {!authState.isLoading ? (
            <LoadingOverlay content="Authenticating Application" />
          ) : (
            <Card>
              <Heading element="h4" heading4>
                {headingContent}
              </Heading>
              {authState.isError ? (
                <>
                  <SignOutButton />
                  {/* TODO ADD CONTACT SUPPORT LINK*/}
                  <Text element="p">{homePageAuthCopy.errorContent}</Text>
                </>
              ) : (
                <SignInButton />
              )}
            </Card>
          )}
        </>
      )}
    </Layout>
  );
};

export default HomePage;
