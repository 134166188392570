import React, { CSSProperties } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { StylesConfig, OptionProps } from 'react-select';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

import { ColumnWidthType, SelectInputDateType } from '../../types';

import Label from '../Label';
import Text from '../Text';
import Lock from '../Icons/Lock';
import Checkmark from '../Icons/CheckMark';

import { ColorToken } from '../../styles/designTokens';
import styles from './index.module.scss';
import { dateFormat } from '../../helpers';

type PropsType = {
  label: string;
  id: string;
  options: {
    label: string;
    value: string | number;
    disabled?: boolean;
  }[];
  required?: boolean;
  helperText?: string;
  ariaLabelledBy: string;
  value?: SelectInputDateType;
  onChange?: (event: any) => void;
  disabled?: boolean;
  columnWidth?: ColumnWidthType | null;
};

const DateSelectMenu = ({
  label,
  id,
  options,
  helperText,
  required,
  ariaLabelledBy,
  value,
  onChange,
  disabled,
  columnWidth
}: PropsType) => {
  const methods = useFormContext();
  const {
    formState: { errors }
  } = useFormContext();

  const name = id;

  const {
    colorWhite,
    textBlack,
    textParagraph,
    textRed,
    backgroundMediumGrey,
    textPlaceholder,
    backgroundHighlight,
    borderFocus
  } = ColorToken;

  const focusedStyle = '0px 0px 5px 0px #4a90e2';
  const customStyles: StylesConfig<CSSProperties, false> = {
    //TODO Validate types for these styles
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: disabled ? backgroundHighlight : colorWhite,
      borderColor: disabled
        ? backgroundHighlight
        : errors[id]
        ? textRed
        : state.isFocused
        ? borderFocus
        : backgroundMediumGrey,
      borderWidth: '1px',
      borderRadius: '2px',
      fontFamily: 'Helvetica Neue',
      color: disabled ? textParagraph : textBlack,
      minWidth: 'inherit',
      outline: 'none',
      boxShadow: state.isFocused ? focusedStyle : '0px',
      '&:hover': {
        borderColor: borderFocus
      }
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: disabled ? textPlaceholder : textParagraph,
      padding: '10px'
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '9px 10px',
      fontFamily: 'Helvetica Neue',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px'
    }),
    singleValue: (provided: any, state) => ({
      ...provided,
      color: disabled ? textParagraph : textBlack
    }),
    indicatorSeparator: (provided: any, state) => ({
      ...provided,
      backgroundColor: colorWhite
    }),
    menu: (provided: any, state) => ({
      ...provided,
      fontFamily: 'Helvetica Neue',
      zIndex: 1,
      top: 35,
      paddingRight: '4px',
      paddingLeft: '4px',
      width: '300px'
    }),
    menuList: (provided: any) => ({
      ...provided,
      zIndex: 1,
      padding: '0',
      margin: '0'
    }),
    input: (provided: any) => ({
      ...provided,
      paddingTop: '0',
      paddingBottom: '0',
      margin: '0'
    })
  };

  //Creating a custom Group component that is passed into the React-Select components prop so we can style and arrange the options to match design
  const GroupHeading = () => {
    return (
      <div className={styles.groupHeadingContainer}>
        <p className={styles.headingText}>Effective Date</p>
        <p className={styles.headingText}>Submission Deadline</p>
      </div>
    );
  };

  //Creating a custom Option component that is passed into the React-Select components prop so we can style and arrange the options to match design
  const Option = (props: OptionProps<any>) => {
    const { innerProps, innerRef, isDisabled, isFocused, isSelected, data } =
      props;
    return (
      <div
        className={classNames(styles.optionContainer, {
          [styles.optionContainerDisabled]: isDisabled,
          [styles.optionContainerFocused]: isFocused,
          [styles.optionContainerSelected]: isSelected
        })}
        ref={innerRef}
        {...innerProps}
      >
        <div className={styles.optionWrapper}>
          <p className={styles.optionText}>{data.label}</p>
          {data.label !== 'ASAP' && (
            <p className={styles.optionText}>
              {dateFormat(data.submissionDeadline)}
            </p>
          )}
        </div>
        {isDisabled ? (
          <span className={styles.icon}>
            <Lock />
          </span>
        ) : isSelected ? (
          <span className={styles.icon}>
            <Checkmark />
          </span>
        ) : null}
      </div>
    );
  };

  //Create a new options array for the custom GroupHeading component above
  const groupedOptions = [{ options }];

  return (
    <div
      className={classNames(styles.dateSelectMenuContainer, {
        [styles.dateSelectMenuContainerSingleColumn]: columnWidth === 'single',
        [styles.dateSelectMenuContainerDoubleColumn]: columnWidth === 'double'
      })}
      data-testid={`${id}-controller`}
    >
      <Label id={id} htmlFor={ariaLabelledBy} error={!!errors[id]}>
        {label}
      </Label>
      <Controller
        name={id}
        data-testid={`${id}-controller`}
        control={methods.control}
        rules={{ ...(required ? { required: 'This field is required' } : {}) }}
        render={({ fieldState: { error } }) => (
          <>
            <Select
              options={groupedOptions as any}
              classNamePrefix={styles.input}
              styles={customStyles}
              aria-labelledby={id}
              data-testid={id}
              id={`${id}-date-select`}
              inputId={ariaLabelledBy}
              value={value as any}
              onChange={onChange}
              components={{ GroupHeading, Option }}
              isDisabled={disabled}
            />
            {errors[id] ? (
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <Text element="p" small className={styles.helperTextError}>
                    {message}
                  </Text>
                )}
              />
            ) : helperText ? (
              <Text element="p" small className={styles.helperText}>
                {helperText}
              </Text>
            ) : (
              <></>
            )}
          </>
        )}
      />
    </div>
  );
};

export default DateSelectMenu;
