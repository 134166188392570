import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';

import { CalendarId } from '../types';

type QueryParams = { token: string | null };

export const calendarApi = createApi({
  reducerPath: 'calendarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  endpoints: (builder) => ({
    getFutureCalendarDates: builder.query<CalendarId[], QueryParams>({
      query: ({ token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `future-calendar-dates`
      })
    })
  })
});

export const { useGetFutureCalendarDatesQuery } = calendarApi;
