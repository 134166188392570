import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthenticationProvider';

const AuthRoutes = () => {
  const { authState } = useAuth();

  if (!authState.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default AuthRoutes;
