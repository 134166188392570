import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './index.module.scss';

type PropTypes = {
  children: string;
  route: string;
};

const NavigationTab = ({ children, route }: PropTypes) => {
  return (
    <div>
      <div className={styles.tabContainer}>
        <NavLink
          to={route}
          className={styles.navLink}
          style={({ isActive }) =>
            isActive ? { borderBottom: '1px solid white' } : {}
          }
          data-testid={`navigation-tab-${route}`.replace('/', '')}
        >
          {children}
        </NavLink>
      </div>
    </div>
  );
};

export default NavigationTab;
