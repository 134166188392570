import styles from './index.module.scss';

import Heading from '../Heading';
import Tag from './ResourceCardTag';
import DownloadSimple from '../Icons/DownloadSimple';
import ArrowRight from '../Icons/ArrowRight';

type PropsType = {
  children?: string | React.ReactNode;
  tag: string;
  title: string;
  description?: string;
  link: string;
  isDownload?: boolean;
  tagColor?: string;
};

const ResourceCardLink = ({
  tag,
  title,
  description,
  link,
  isDownload = false,
  tagColor
}: PropsType) => {
  return (
    <div className={styles.ResourceCard}>
      <div className={styles.ResourceCardHeader}>
        <Tag tagColor={tagColor}>{tag}</Tag>
        <span>{isDownload ? <DownloadSimple /> : <ArrowRight />}</span>
      </div>
      <Heading element="h4" heading4 className={styles.ResourceCardTitle}>
        {title}
      </Heading>
      <small className={styles.ResourceCardDescription}>{description}</small>
      <a href={link} download={isDownload} className={styles.coverLink}>
        <span className={styles.srOnly}>Download: {title}</span>
      </a>
    </div>
  );
};

export default ResourceCardLink;
