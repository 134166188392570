import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { uniqueArray } from '../helpers/uniqueArray';

export interface WorkingGroupState {
  workingGroupProducts: string[];
  selectedProducts: string[];
  workingGroupEmpty: boolean;
}

const initialState: WorkingGroupState = {
  workingGroupProducts: [],
  selectedProducts: [],
  workingGroupEmpty: true
};

export const workingGroupSlice = createSlice({
  name: 'workingGroup',
  initialState,
  reducers: {
    setWorkingGroupProducts: (state, action: PayloadAction<string[]>) => {
      state.workingGroupProducts = uniqueArray(action.payload);
    },
    setSelectedProducts: (state, action: PayloadAction<string[]>) => {
      state.selectedProducts = action.payload;
    },
    resetWorkingGroupProducts: (state) => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setWorkingGroupProducts,
  setSelectedProducts,
  resetWorkingGroupProducts
} = workingGroupSlice.actions;

export default workingGroupSlice.reducer;
