import { UserTypeEnum } from './index';
import { ProductType } from '../types/productTypes';

interface CSVHeaders {
  label: string;
  key: string;
}

const internalHeaders = [
  {
    label: 'Foreign Exchange Currency Code',
    key: 'currency_label'
  },
  {
    label: 'Exchange Rate Date',
    key: 'currency_effective_date'
  },
  {
    label: 'Foreign Prime Cost',
    key: 'foreign_prime_cost_per_case'
  },
  {
    label: 'Agent / Supplier Review (TRUE|<blank>)',
    key: 'a_s_review_fl'
  },
  {
    label: 'Cost Only (TRUE|<blank>)',
    key: 'const_only_fl'
  },
  {
    label: 'PR Reason',
    key: 'reason'
  },
  {
    label: 'Costing Event Comments',
    key: 'costing_event_comment'
  }
];

export const getHeaders = (userType: string) => {
  const csvDownloadHeaders: CSVHeaders[] = [
    {
      label: 'SKU (required)',
      key: 'sku'
    },
    {
      label: 'Product Name',
      key: 'product_name'
    },
    {
      label: 'Case Configuration',
      key: 'unit_description'
    },
    {
      label:
        userType === UserTypeEnum.INTERNAL
          ? 'Request Type (PC, WPP, NEW) (required)'
          : 'Request Type (PC, WPP) (required)',
      key: 'request_type'
    },
    {
      label:
        'Effective Date (YYYY-MM-DD format; see Dates & Deadlines Calendar) (required)',
      key: 'effective_date'
    },
    {
      label:
        'Prime Cost / Case (Imports & Domestic Spirits only. Amount only. No $ sign)',
      key: 'prime_cost_per_case'
    },
    {
      label: 'Domestic Charges / Case (Imports only. Amount only. No $ sign)',
      key: 'domestic_charges_per_case'
    },
    {
      label:
        'Duty Paid Cost / Case (Domestic Wine & Beer only. Amount only. No $ sign)',
      key: 'duty_paid_cost_per_case'
    },
    {
      label:
        'Promotion Amount / Selling Unit (WPP only. Amount only. No $ sign)',
      key: 'promotion_amount_per_su'
    }
  ];

  if (userType === UserTypeEnum.INTERNAL) {
    csvDownloadHeaders.push(...internalHeaders);
  }

  return csvDownloadHeaders;
};

interface ProductData {
  sku: number;
  product_name?: string;
  unit_description?: string;
  prime_cost_per_case?: string;
  domestic_charges_per_case?: string;
  duty_paid_cost_per_case?: string;
  category?: number;
}

export const getData = (products: ProductType[] | undefined) => {
  if (!products) return null;

  const csvData: ProductData[] = products.map((product) => {
    const {
      sku,
      product_name,
      unit_description,
      country_of_export,
      is_cost_dpc
    } = product;

    const isDPC = is_cost_dpc === 'YES';
    const isImport = country_of_export !== 'CA';

    const productData: ProductData = {
      sku,
      product_name,
      unit_description,
      prime_cost_per_case:
        isImport || (!isImport && !isDPC) ? undefined : 'N/A',
      domestic_charges_per_case: isImport ? undefined : 'N/A',
      duty_paid_cost_per_case: isDPC ? undefined : 'N/A'
    };

    return productData;
  });

  return csvData;
};

export const getCSVTimestamp = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}_${hours}-${minutes}`;
};

export const fieldIsNotApplicable = (
  fieldValue: string | number | undefined
) => {
  return fieldValue === 'N/A' || fieldValue === 'n/a';
};

export const fieldHasValue = (
  fieldValue: string | number | boolean | undefined
) => {
  return fieldValue !== null && fieldValue !== undefined && fieldValue !== '';
};
