export type PriceRequestKey = string;

export const CSV_VALID_REQUEST_TYPES = ['PC', 'WPP'];

export const PRICE_REQUEST_TYPE_MAP: Map<PriceRequestKey, string> = new Map<
  PriceRequestKey,
  string
>([['activation/reactivation', 'New']]);

export const PRICE_REQUEST_TYPE_ABBR_TO_NAME: Map<PriceRequestKey, string> =
  new Map<PriceRequestKey, string>([
    ['pc', 'Price Change'],
    ['wpp', 'Wholesale Price Promotion'],
    ['activation/reactivation', 'New Product Pricing / Reactivation'],
    ['np', 'New Product Pricing / Reactivation']
  ]);

export const mapPrType = (priceRequestKey: PriceRequestKey): string => {
  if (typeof priceRequestKey !== 'string') return 'Invalid';
  return (
    PRICE_REQUEST_TYPE_MAP.get(priceRequestKey?.toLowerCase()) ||
    priceRequestKey
  );
};

export const mapPrAbbreviationToName = (
  priceRequestKey: PriceRequestKey
): string | undefined => {
  return (
    PRICE_REQUEST_TYPE_ABBR_TO_NAME.get(priceRequestKey?.toLowerCase()) ||
    undefined
  );
};
