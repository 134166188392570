/**
 * Returns a string formatted for a local currency.
 *
 * @param value - the number to format
 * @param localeTag - a BCP 47 language tag such as `en-US` or `fr-CA`
 * @param style - The formatting style to use. "decimal" for plain number formatting. "currency" for currency formatting. "percent" for percent formatting. "unit" for unit formatting.
 * @param currency - The currency to use in currency formatting. Possible values are the ISO 4217 currency codes, such as "USD" for the US dollar or "CAD" for the Canadian Dollar
 * @returns A string formatted to the currency standards of the input
 *
 */

export const currencyFormat = (
  value: string | number | undefined,
  localeTag = 'en-CA',
  style = 'currency',
  currency = 'CAD'
) => {
  if (
    value === null ||
    value === undefined ||
    value === 'N/A' ||
    isNaN(Number(value))
  )
    return <span className={'placeholder'}>-- . --</span>;

  // some data returned from the APIs is string
  // check the type of the value prop, and if its a string, convert to number
  // if number, return value as is.
  let numberValue;
  if (typeof value === 'string') {
    numberValue = Number(value);
  } else {
    numberValue = value;
  }
  // pass numberValue through to the Intl.NumberFormat to set it as currency value
  return new Intl.NumberFormat(localeTag, {
    style,
    currency
  }).format(numberValue);
};
