import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';
import { ValidationMessageType } from '../types/productTypes';

export interface CalculateBody {
  sku: number;
  effectiveDate: string;
  dutyPaidCostPerCase: string | undefined;
  domesticChargesPerCase: string | undefined;
  scenario: number;
  primeCostPerCase: string | undefined;
  pricePerSU: string | undefined;
}

type CalculatePayload = {
  requestBody: CalculateBody;
  token: string | null;
};

export type CalculateResponse = {
  sku: string;
  effectiveDate: string;
  primeCostPerCase: number;
  domesticChargesPerCase: number;
  inBondCostPerCase: number;
  exciseAmountPerCase: number;
  customsAmountPerCase: number;
  dutyPaidCostPerCase: number;
  dutyPaidCostPerSU: number;
  beerDistroChargePerSU: number;
  markupPerSU: number;
  crfPerSU: number;
  pricePerCase: number;
  pricePerSU: number;
  floorPrice: string;
  validationMessage: ValidationMessageType[];
};

export const calculateApi = createApi({
  reducerPath: 'calculateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  endpoints: (builder) => ({
    calculate: builder.mutation<CalculateResponse, CalculatePayload>({
      query: ({ requestBody, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        body: requestBody,
        url: 'calculate',
        method: 'POST'
      })
    })
  })
});

export const { useCalculateMutation } = calculateApi;
