import React from 'react';

import { useGetResourcesQuery } from '../../services/Home';
import { getSessionToken } from '../../helpers';
import { reorderResourceFiles } from '../../helpers/reorderResourceFiles';

import Heading from '../Heading';
import ResourceGrid, { ResourceType } from './ResourceGrid';
import Text from '../Text';
import Collapse from '../Collapse';
import ResourceCardLink from './ResourceCardLink';
import Loader from '../Loader';

import styles from './index.module.scss';
import copy from '../../config/translations/en-CA.json';
const resourcesCopy = copy.homePage.resources;

const withTag = (resources: any[], color: string, tag?: string) => {
  if (!resources) {
    return [];
  }

  return resources.map((resource: ResourceType) => {
    return {
      ...resource,
      tagColor: color,
      tag: tag ? tag : null
    };
  });
};

const Resources: React.FC = () => {
  const token = getSessionToken();

  const { data, isLoading, isError } = useGetResourcesQuery(
    {
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  if (isLoading) {
    return (
      <div className={styles.resourceSections}>
        <Heading element="h2" heading2 className={styles.sectionHeading}>
          {resourcesCopy.headings.resources}
        </Heading>
        <div className={styles.noResults}>
          <Loader width="30px" />
        </div>
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className={styles.resourceSections}>
        <Heading element="h2" heading2 className={styles.sectionHeading}>
          {resourcesCopy.headings.resources}
        </Heading>
        <div className={styles.noResults}>
          <Text element="p" bold small action>
            {resourcesCopy.loadingError}
          </Text>
        </div>
      </div>
    );
  }

  const currentCalculators = withTag(
    reorderResourceFiles(data.Resources_CurrentCalculators),
    '',
    resourcesCopy.tags.calculators
  );
  const currentGuides = withTag(
    reorderResourceFiles(data.Resources_CurrentGuides),
    'blue',
    resourcesCopy.tags.userGuides
  );
  const futureCalculators = withTag(
    reorderResourceFiles(data.Resources_FutureCalculators),
    '',
    resourcesCopy.tags.calculators
  );
  const futureGuides = withTag(
    reorderResourceFiles(data.Resources_FutureGuides),
    'blue',
    resourcesCopy.tags.userGuides
  );

  return (
    <section className={styles.resourceSections}>
      <Heading element="h2" heading2 className={styles.sectionHeading}>
        {resourcesCopy.headings.resources}
      </Heading>
      <div>
        <Heading element="h4" heading4 className={styles.sectionSubHeading}>
          {resourcesCopy.headings.calendars}
        </Heading>
        <ResourceGrid
          resources={withTag(
            reorderResourceFiles(data.Resources_Calendars),
            'red'
          )}
        />
      </div>

      <div>
        <Heading element="h4" heading4 className={styles.sectionSubHeading}>
          {resourcesCopy.headings.calculators}
        </Heading>
        <div className={styles.gridContainer_2cols}>
          <ResourceGrid
            heading={resourcesCopy.headings.currentPricing}
            twoCols
            columnA={currentCalculators}
            columnB={currentGuides}
          />
          <ResourceGrid
            heading={resourcesCopy.headings.futurePricing}
            twoCols
            columnA={futureCalculators}
            columnB={futureGuides}
          />
        </div>
      </div>

      <div className={styles.disclaimers}>
        <Text element="p" small bold>
          {resourcesCopy.note}
        </Text>
      </div>
      <Collapse heading={resourcesCopy.collapse.heading}>
        <Text element="p" small>
          {resourcesCopy.disclaimer}
        </Text>
        <Text element="p" small>
          {resourcesCopy.liability}
        </Text>
        <Text element="p" small>
          {resourcesCopy.excel}
        </Text>
        <Text element="p" small>
          {resourcesCopy.update}
        </Text>
      </Collapse>
      <div className={styles.section}>
        <Heading element="h4" heading4 className={styles.sectionSubHeading}>
          {resourcesCopy.headings.training}
        </Heading>
        <ResourceGrid
          resources={withTag(
            reorderResourceFiles(data.Resources_Training),
            'green'
          )}
        />
      </div>

      <div className={styles.section}>
        <Heading element="h4" heading4 className={styles.sectionSubHeading}>
          {resourcesCopy.headings.otherPricing}
        </Heading>
        <ResourceGrid
          resources={withTag(
            reorderResourceFiles(data.Resources_OtherResources),
            'blue'
          )}
        >
          <ResourceCardLink
            tag="Reports"
            title="Wholesale Pricing Reports"
            link={process.env.REACT_APP_VENDOR_URL || '#'}
            tagColor="blue"
          />
        </ResourceGrid>
      </div>

      <div className={styles.section}>
        <Heading element="h4" heading4 className={styles.sectionSubHeading}>
          {resourcesCopy.headings.legal}
        </Heading>
        <ResourceGrid
          resources={withTag(
            reorderResourceFiles(data.Resources_LegalAndPrivacy),
            'orange'
          )}
        />
      </div>
    </section>
  );
};

export default Resources;
