import React from 'react';

import { currencyCellText } from '../../helpers';

import Text from '../Text/Text';

import copy from '../../config/translations/en-CA.json';

const columnContent =
  copy.modals[0].priceRequestDetails.tabs.priceBreakDown.table.columnHeaders;

export const priceRequestBreakdownColumns = [
  {
    Header: 'Value',
    accessor: 'label' as const,
    Cell: (cellInfo: any) => {
      return (
        <Text element="p" tableCell table>
          {cellInfo.row.original.label}
        </Text>
      );
    }
  },
  {
    Header: columnContent[0].label,
    accessor: 'perCase' as const,
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.row.original.perCase);
    }
  },
  {
    Header: columnContent[1].label,
    accessor: 'perSU' as const,
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.row.original.perSU);
    }
  }
];
