import React from 'react';

export default function Search() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-search">Search</title>
      <path
        d="M13.6562 7.45313C13.6562 8.93233 13.1761 10.2987 12.3672 11.4073L16.4471 15.4904C16.8499 15.8933 16.8499 16.5475 16.4471 16.9503C16.0442 17.3531 15.39 17.3531 14.9872 16.9503L10.9073 12.8672C9.79873 13.6793 8.43232 14.1563 6.95312 14.1563C3.25029 14.1563 0.25 11.156 0.25 7.45313C0.25 3.75029 3.25029 0.75 6.95312 0.75C10.656 0.75 13.6562 3.75029 13.6562 7.45313ZM6.95312 12.0938C9.51514 12.0938 11.5938 10.0151 11.5938 7.45313C11.5938 4.89111 9.51514 2.8125 6.95312 2.8125C4.39111 2.8125 2.3125 4.89111 2.3125 7.45313C2.3125 10.0151 4.39111 12.0938 6.95312 12.0938Z"
        fill="currentColor"
      />
    </svg>
  );
}
