import React from 'react';

import { currencyCellText } from '../../../helpers';

import Text from '../../Text/Text';

export const priceBreakdownColumns = [
  {
    Header: 'Value',
    accessor: 'label' as const,
    Cell: (cellInfo: any) => {
      return (
        <Text element="p" tableCell table>
          {cellInfo.row.original.label}
        </Text>
      );
    }
  },
  {
    Header: '$ Per Case',
    accessor: 'perCase' as const,
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.row.original.perCase);
    }
  },
  {
    Header: '$ Per Selling Unit',
    accessor: 'perSU' as const,
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.row.original.perSU);
    }
  }
];
