import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type PropsType = {
  children: string;
  orientation: 'above' | 'below';
};

const CallToAction = ({ children, orientation }: PropsType) => {
  return (
    <div className={styles.ctaContainer}>
      <div
        className={classNames(styles.ctaWrapper, {
          [styles.ctaWrapperAbove]: orientation === 'above',
          [styles.ctaWrapperBelow]: orientation === 'below'
        })}
      >
        <span className={styles.triangle} />
        <p className={styles.ctaContent}>{children}</p>
      </div>
    </div>
  );
};

export default CallToAction;
