import React from 'react';

export default function Delete() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-delete">Delete</title>
      <path
        d="M0.333496 3.07294L0.333496 2.27085C0.333496 1.88986 0.636026 1.58335 1.01207 1.58335L4.17873 1.58335L4.44451 1.04768C4.5576 0.812781 4.79228 0.666687 5.04957 0.666687L8.28126 0.666687C8.53856 0.666687 8.77323 0.812781 8.88915 1.04768L9.15492 1.58335L12.3216 1.58335C12.6976 1.58335 13.0002 1.88986 13.0002 2.27085V3.07294C13.0002 3.262 12.8475 3.41669 12.6609 3.41669L0.672782 3.41669C0.486175 3.41669 0.333496 3.262 0.333496 3.07294ZM12.0954 4.6771L12.0954 13.9584C12.0954 14.7175 11.4875 15.3334 10.7383 15.3334L2.5954 15.3334C1.84614 15.3334 1.23826 14.7175 1.23826 13.9584L1.23826 4.6771C1.23826 4.48804 1.39094 4.33335 1.57754 4.33335L11.7561 4.33335C11.9427 4.33335 12.0954 4.48804 12.0954 4.6771ZM4.40492 6.62502C4.40492 6.37294 4.20135 6.16669 3.95254 6.16669C3.70373 6.16669 3.50016 6.37294 3.50016 6.62502L3.50016 13.0417C3.50016 13.2938 3.70373 13.5 3.95254 13.5C4.20135 13.5 4.40492 13.2938 4.40492 13.0417L4.40492 6.62502ZM7.11921 6.62502C7.11921 6.37294 6.91564 6.16669 6.66683 6.16669C6.41802 6.16669 6.21445 6.37294 6.21445 6.62502L6.21445 13.0417C6.21445 13.2938 6.41802 13.5 6.66683 13.5C6.91564 13.5 7.11921 13.2938 7.11921 13.0417L7.11921 6.62502ZM9.8335 6.62502C9.8335 6.37294 9.62992 6.16669 9.38111 6.16669C9.13231 6.16669 8.92873 6.37294 8.92873 6.62502L8.92873 13.0417C8.92873 13.2938 9.13231 13.5 9.38111 13.5C9.62992 13.5 9.8335 13.2938 9.8335 13.0417L9.8335 6.62502Z"
        fill="currentColor"
      />
    </svg>
  );
}
