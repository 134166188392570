import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';
import { Comments } from '../types';

type QueryParams = { id: number | undefined; token: string | null };

type CommentPayload = {
  pr_id: number | undefined;
  comment_txt: string | undefined;
  token: string | null;
};

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  tagTypes: ['Comments'],
  endpoints: (builder) => ({
    getPriceRequestComments: builder.query<Comments, QueryParams>({
      query: ({ id, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `price-request/${id}/comments`
      }),
      providesTags: (result, error, { id }) => [{ type: 'Comments', id }]
    }),
    createComment: builder.mutation<void, CommentPayload>({
      query: ({ pr_id, comment_txt, token }) => ({
        url: 'price-request/comments/create',
        method: 'POST',
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        body: { pr_id, comment_txt }
      }),
      invalidatesTags: (result, error, { pr_id }) => [
        { type: 'Comments', id: pr_id }
      ]
    })
  })
});

export const { useGetPriceRequestCommentsQuery, useCreateCommentMutation } =
  commentsApi;
