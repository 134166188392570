import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { PriceHistoryType } from '../types/productSearchTypes';

export interface ProductPriceHistoryState {
  productPriceHistory: PriceHistoryType[];
}

const initialState: ProductPriceHistoryState = {
  productPriceHistory: []
};

export const productPriceHistorySlice = createSlice({
  name: 'productPriceHistory',
  initialState,
  reducers: {
    setProductPriceHistory: (
      state,
      action: PayloadAction<PriceHistoryType[]>
    ) => {
      //To fix the EPP-1230 for price History for products with promotions.
      //Add the pricePerSU to promoValuePerSU to return the Wholesale price
      //instead of promotion price returned from LDB api
      const productsWithWholeSalePrice = action.payload.map((product) => ({
        ...product,
        pricePerSU: (product.pricePerSU ?? 0) + (product.promoValuePerSU ?? 0)
      }));

      state.productPriceHistory = productsWithWholeSalePrice;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setProductPriceHistory } = productPriceHistorySlice.actions;

export default productPriceHistorySlice.reducer;
