import { MiddlewareAPI, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { triggerActivityMonitorReset } from '../reducers/ActivityMonitorSlice';

const ActivityMonitorMiddleWare =
  ({ dispatch }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: AnyAction) => {
    if (action.type.endsWith('/pending')) {
      dispatch(triggerActivityMonitorReset(true));
    }

    return next(action);
  };

export default ActivityMonitorMiddleWare;
