import React from 'react';
import styles from './index.module.scss';

type PropsType = {
  children: string | React.ReactNode;
};

const HomeContent = ({ children }: PropsType) => (
  <section className={styles.homeContentContainer}>{children}</section>
);

export default HomeContent;
