let skuCardLocation: any;
export const resetSkuCardLocation = () => {
  skuCardLocation = null;
};

export const updateSkuCardLocation = (sku: any) => {
  skuCardLocation = 'ProductCard-' + sku;
};

export const getSkuCardLocation = () => {
  const skuElement = document.getElementById(skuCardLocation);
  return skuElement;
};
