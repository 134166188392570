import React, { Fragment, ReactNode } from 'react';

export const renderTextWithLineBreaks = (children: ReactNode) => {
  const isStringWithMultipleLines =
    typeof children === 'string' && children.includes('\n');
  if (!isStringWithMultipleLines) return children;

  const lines: string[] = children.split('\n');

  return lines.map((line: string, index: number) => (
    <Fragment key={`${line}_${index}`}>
      {line}
      {index < lines.length - 1 && <br />}
    </Fragment>
  ));
};

export function decodeSanitizedValue(sanitizedString?: string): string {
  if (!sanitizedString) return '';
  const charMap: { [key: string]: string } = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'"
  };

  return sanitizedString.replace(
    /&amp;|&lt;|&gt;|&quot;|&#39;/g,
    (match) => charMap[match]
  );
}
