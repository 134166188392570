import React from 'react';
import classNames from 'classnames';

import Heading from '../Heading';
import Text from '../Text';
import CloseIcon from '../Icons/CloseIcon';

import styles from './index.module.scss';

type PropsType = {
  type: 'error' | 'success' | 'warning' | 'info' | 'errorOutline';
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  small?: boolean;
  alignment?: 'top' | 'center';
  closeable?: boolean;
  onClose?: () => void;
  calculator?: boolean;
};
const Banner = ({
  type,
  icon,
  title,
  description,
  small,
  alignment,
  closeable,
  onClose,
  calculator
}: PropsType) => {
  return (
    <div
      className={classNames(styles.bannerContainer, {
        [styles.bannerContainerError]: type === 'error',
        [styles.bannerContainerErrorOutline]: type === 'errorOutline',
        [styles.bannerContainerInfo]: type === 'info',
        [styles.bannerContainerSuccess]: type === 'success',
        [styles.bannerContainerWarning]: type === 'warning',
        [styles.bannerContainerSmall]: small,
        [styles.bannerContainerTopAlign]: alignment === 'top',
        [styles.bannerContainerCenterAlign]: alignment === 'center'
      })}
      id={`${type}-banner`}
    >
      {icon && (
        <div
          className={classNames(styles.bannerIcon, {
            [styles.bannerIconCalculator]: calculator
          })}
        >
          {icon}
        </div>
      )}
      <div className={styles.bannerContent}>
        {title ? (
          <Heading element="h3" heading3 className={styles.bannerHeading}>
            {title}
          </Heading>
        ) : null}
        {description && <Text element="p">{description}</Text>}
      </div>
      {closeable && (
        <span className={styles.closeContainer} onClick={onClose}>
          <Text element="p">Dismiss</Text>
          <CloseIcon />
        </span>
      )}
    </div>
  );
};

export default Banner;
