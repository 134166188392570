import React from 'react';

export default function ChevronRight() {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-chevron-right">Chevron Right</title>
      <path
        d="M9.35142 8.58293L2.93465 15.2585C2.62518 15.5805 2.12344 15.5805 1.814 15.2585L1.06559 14.4799C0.756648 14.1585 0.756054 13.6376 1.06427 13.3154L6.14967 7.99998L1.06427 2.68456C0.756054 2.36239 0.756648 1.84148 1.06559 1.52007L1.814 0.741469C2.12348 0.41951 2.62521 0.41951 2.93465 0.741469L9.35139 7.41707C9.66086 7.73899 9.66086 8.26097 9.35142 8.58293Z"
        fill="currentColor"
      />
    </svg>
  );
}
