import React from 'react';
import { Helmet } from 'react-helmet';

type PropsType = {
  title: string;
};

const HelmetContainer = ({ title }: PropsType) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default HelmetContainer;
