// variable for the maximum number of characters allowed in the override email notification text inputs
export const maximumEmailCharacters = 65000;

// create a regex for matching input values that need to validate email address but also allow for multiple email addresses separated by a semicolon.
// for example: user_name@email.com; user2@testaccount.com
export const emailRegex =
  /^(([a-zA-Z0-9_-][a-zA-Z0-9_\-.]*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;

// create a regex for matching input values that need to be only up to 2 decimal places.
export const twoDecimalPointsRegex = /^-?[0-9]+(?:\.[0-9]{1,2})?$/;
