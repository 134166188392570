import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import classNames from 'classnames';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

import { useGetPriceRequestsListQuery } from '../../services/PriceRequest';
import {
  getSessionToken,
  ISearchParams,
  searchObjectToParams
} from '../../helpers';
import { LocationType } from '../../types/productTypes';

import { PriceRequestPriceHistoryColumns } from './_PriceRequestPriceHistoryColumns';
import Button from '../Button';
import ChevronUp from '../Icons/ChevronUp';
import LoadingOverlay from '../LoadingOverlay';
import TableTooltip from '../TableTooltip';

import styles from './priceHistoryTab.module.scss';

const PriceRequestPriceHistoryTab = () => {
  const [showMore, setShowMore] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [priceHistoryData, setPriceHistoryData] = useState<any>([]);

  //create location state used to determine if the tooltips should render below or above the
  // text based on mouse location in the screen
  const [location, setLocation] = useState<LocationType>('');
  // this callback will be passed into the tooltip component to bring the state from child to parent
  const callback = (state: LocationType) => {
    setLocation(state);
  };

  const { priceRequest } = useSelector(
    (state: RootState) => state.priceRequestDetails
  );

  const token = getSessionToken();

  const mapObject: ISearchParams = {
    filters: [
      {
        field: 'sku',
        operator: '=',
        value: priceRequest.sku || 0
      },
      {
        field: 'status',
        operator: '=',
        value: 'Accepted'
      }
    ],
    orders: [
      {
        by: 'final_effective_date',
        type: 'desc'
      }
    ],
    limit: 500,
    offset: 0
  };

  const queryParams = searchObjectToParams(mapObject);

  const {
    data: getPriceRequestsQueryData,
    isSuccess,
    isLoading
  } = useGetPriceRequestsListQuery(
    {
      queryParams,
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (isSuccess && getPriceRequestsQueryData) {
      setPriceHistoryData(getPriceRequestsQueryData.items);
      const itemData = getPriceRequestsQueryData.items;
      if (itemData.length > 5) {
        const shortenedItemData = itemData.slice(0, 5);
        setTableData(shortenedItemData);
      } else {
        setTableData(itemData);
      }
    }
  }, [isSuccess, getPriceRequestsQueryData]);

  const shortenedArray = priceHistoryData.slice(0, 5);

  const options = {
    data: tableData,
    columns: PriceRequestPriceHistoryColumns,
    initialState: {
      sortBy: [
        {
          id: 'effective_date',
          desc: false
        }
      ]
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options);

  const toggleButtonDisplayed = priceHistoryData.length > 5;

  const toggleShowMore = () => {
    //if the toggle is closed on button click (showMore set to false), pass in the full array of price historys and toggle it to be open
    if (!showMore) {
      setTableData(priceHistoryData);
      setShowMore(true);
    } // if the toggle is open on button click (showMore set to true), close the toggle and reset the table data to just the first 5 price history rows
    else {
      setTableData(shortenedArray);
      setShowMore(false);
    }
  };

  return (
    <div>
      {isLoading && <LoadingOverlay />}
      <div className={styles.priceRequestPriceHistoryUpper}>
        <div className={styles.priceRequestPriceHistoryTable}>
          <div
            className={styles.tableContainer}
            data-testid={`price-history-table`}
          >
            <table {...getTableProps()} className={styles.table}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th {...column.getHeaderProps()} className={styles.th}>
                        <span className={styles.headerColumnWrapper}>
                          {column.render('Header')}
                          {(headerGroup.headers[index] as any).tipText && (
                            <span
                              className={classNames(styles.tooltipContainer, {
                                [styles.tooltipContainerTop]:
                                  location === 'top',
                                [styles.tooltipContainerBottom]:
                                  location === 'bottom'
                              })}
                            >
                              <TableTooltip
                                label={
                                  (headerGroup.headers[index] as any).tipText
                                }
                                callback={callback}
                              />
                            </span>
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={styles.bodyRow}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.cellContainer}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {toggleButtonDisplayed && (
              <div className={styles.buttonContainer}>
                <Button
                  link
                  small
                  iconRight
                  icon={<ChevronUp />}
                  onClick={() => toggleShowMore()}
                  className={classNames(styles.toggleButton, {
                    [styles.toggleButtonHidden]: !toggleButtonDisplayed,
                    [styles.toggleButtonIconTransform]: !showMore
                  })}
                >
                  {showMore ? 'Show Less' : 'Show More'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRequestPriceHistoryTab;
