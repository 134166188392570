import { checkTokenExpiry, redirectToLogin } from './authHelpers';

export const getSessionToken = () => {
  const token = sessionStorage.getItem('authToken');

  if (!token || checkTokenExpiry(token)) {
    redirectToLogin();
  }

  return token;
};
