import React from 'react';

export default function Edit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M12.2828 3.81329L14.8667 6.32423C14.9755 6.43002 14.9755 6.60261 14.8667 6.70839L8.61042 12.7881L5.95208 13.0748C5.59688 13.1138 5.29609 12.8215 5.3362 12.4763L5.63125 9.893L11.8875 3.81329C11.9964 3.70751 12.174 3.70751 12.2828 3.81329ZM16.9234 3.17582L15.5255 1.81735C15.0901 1.39422 14.3826 1.39422 13.9443 1.81735L12.9302 2.80279C12.8214 2.90858 12.8214 3.08117 12.9302 3.18695L15.5141 5.69789C15.6229 5.80367 15.8005 5.80367 15.9094 5.69789L16.9234 4.71244C17.3589 4.28653 17.3589 3.59895 16.9234 3.17582ZM11.75 11.1345V13.9684H2.58333V5.06041H9.16615C9.25781 5.06041 9.34375 5.02422 9.40964 4.96298L10.5555 3.84948C10.7732 3.63792 10.6185 3.27881 10.312 3.27881H2.125C1.36589 3.27881 0.75 3.87732 0.75 4.61501V14.4138C0.75 15.1515 1.36589 15.75 2.125 15.75H12.2083C12.9674 15.75 13.5833 15.1515 13.5833 14.4138V10.021C13.5833 9.72319 13.2138 9.57565 12.9961 9.78443L11.8503 10.8979C11.7872 10.962 11.75 11.0455 11.75 11.1345Z"
        fill="white"
      />
    </svg>
  );
}
