import React from 'react';

export default function ErrorAlertLarge() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-error-alert-large">Error Alert Large</title>
      <path
        d="M13.9989 2.3335C14.7108 2.3335 15.3676 2.7095 15.7285 3.32615L26.5574 21.7754C26.9234 22.397 26.9234 23.1641 26.5674 23.7857C26.2115 24.4074 25.5447 24.7934 24.8278 24.7934H3.17001C2.45309 24.7934 1.78631 24.4074 1.43036 23.7857C1.07441 23.1641 1.07943 22.392 1.44039 21.7754L12.2693 3.32615C12.6303 2.7095 13.287 2.3335 13.9989 2.3335ZM13.9989 8.75062C13.3321 8.75062 12.7957 9.28705 12.7957 9.95383V15.5688C12.7957 16.2356 13.3321 16.772 13.9989 16.772C14.6657 16.772 15.2021 16.2356 15.2021 15.5688V9.95383C15.2021 9.28705 14.6657 8.75062 13.9989 8.75062ZM15.6032 19.9806C15.6032 19.0932 14.8863 18.3763 13.9989 18.3763C13.1115 18.3763 12.3946 19.0932 12.3946 19.9806C12.3946 20.868 13.1115 21.5849 13.9989 21.5849C14.8863 21.5849 15.6032 20.868 15.6032 19.9806Z"
        fill="currentColor"
      />
    </svg>
  );
}
