import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';
import { Countries } from '../types/countryTypes';

type QueryParams = { token: string };

export const countryApi = createApi({
  reducerPath: 'countryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getCountries: builder.query<Countries, QueryParams>({
      query: ({ token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `countries`
      })
    })
  })
});

export const { useGetCountriesQuery } = countryApi;
