import React from 'react';

import Heading from '../Heading';
import Text from '../Text';
import styles from './index.module.scss';

import copy from '../../config/translations/en-CA.json';
const homePageCopy = copy.homePage;
const globalCopy = copy.global;

const HomeContact = () => (
  <section className={styles.homeContact}>
    <Heading element="h4" heading4 className={styles.homeContactTitle}>
      {homePageCopy.contactHeading}
    </Heading>

    <div className={styles.homeContactText}>
      <Text element="p">{globalCopy.orgName}</Text>
      <Text element="p">{globalCopy.fullAddress}</Text>
      <div className={styles.homeContactText}>
        <Text element="p">{globalCopy.orgPhone}</Text>
        <p>
          <a href={`mailto:${globalCopy.orgEmail}`}>{globalCopy.orgEmail}</a>
        </p>
      </div>
    </div>
  </section>
);

export default HomeContact;
