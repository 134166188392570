import {
  currencyCellText,
  dateFormat,
  mapPrStatus,
  mapPrType,
  DRAFT,
  labelString,
  decodeSanitizedValue,
  UserTypeEnum,
  PriceRequestStatusEnum,
  PriceRequestTypesEnum
} from '../../helpers';
import { CellPropsContent, vendorType } from '../../types/productSearchTypes';
import Pill from '../Pill';
import Text from '../Text';
import { useContext } from 'react';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';

export const priceRequestColumns = [
  {
    Header: 'SKU #',
    accessor: 'sku',
    Cell: (props: CellPropsContent) => {
      return (
        <Text element="p" bold small tableCell tooltip={props.value}>
          {props.value}
        </Text>
      );
    }
  },
  {
    Header: 'Product Name',
    accessor: 'productSku.product_name',
    Cell: (props: CellPropsContent) => {
      return (
        <Text element="p" bold dark tableCell tooltip={props.value}>
          {props.value}
        </Text>
      );
    },
    tipText: 'Product Name'
  },
  {
    Header: 'Case Config.',
    accessor: 'productSku.unit_description',
    Cell: (props: CellPropsContent) => {
      return (
        <Text element="p" tableCell tooltip={props.value}>
          {props.value}
        </Text>
      );
    },
    tipText: 'Case Configuration'
  },
  {
    Header: 'PR Status',
    accessor: 'status',
    Cell: (props: { value: string }) => {
      let statusDisplay = mapPrStatus(props.value);
      if (props.value === DRAFT) {
        statusDisplay = `● ${DRAFT}`;
      }
      return <Pill type={props.value}>{statusDisplay}</Pill>;
    },
    tipText: 'Price Request Status'
  },
  {
    Header: 'Pricing Agent',
    accessor: 'agentId',
    Cell: (props: { value: vendorType }) => {
      const concatenatedValue = `${props.value.vendor_id} - ${props.value.name}`;
      return (
        <Text element="p" tableCell tooltip={concatenatedValue}>
          {concatenatedValue}
        </Text>
      );
    },
    tipText: 'Pricing Agent'
  },
  {
    Header: 'Listing Agent',
    accessor: 'productSku.supplierVendor',
    Cell: (props: { value: vendorType }) => {
      const concatenatedValue = `${props.value.vendor_id} - ${props.value.name}`;
      return (
        <Text element="p" tableCell tooltip={concatenatedValue}>
          {concatenatedValue}
        </Text>
      );
    },
    tipText: 'Listing Agent'
  },
  {
    Header: 'PR Type',
    accessor: 'type',
    Cell: (props: CellPropsContent) => {
      const prType = props.value;
      const displayPrType = mapPrType(prType);

      return (
        <Pill type="neutral" secondary>
          {labelString(displayPrType)}
        </Pill>
      );
    },
    tipText: 'Price Request Type'
  },
  {
    Header: 'Effective Date',
    accessor: 'final_effective_date',
    Cell: (props: CellPropsContent) => {
      const rowID = props.row.id;
      const calendarSubtype = props.data[rowID]
        ? props.data[rowID].calendarId?.sub_type
        : null;
      const { authState } = useContext(AuthenticationContext);
      const { user } = authState;
      const rowStatus = props.data[rowID].status;
      const prType = props.data[rowID].type;
      const displayValue =
        calendarSubtype === 'AD_HOC' ||
        // #9016 As per requirement, for non-LDB users the effective_date field should
        //be shown 'ASAP' for when the PR Type is NEW (NP) and status is in 'A/S Review'.
        (prType === PriceRequestTypesEnum.NP &&
          user.type === UserTypeEnum.EXTERNAL &&
          rowStatus === PriceRequestStatusEnum.AS_REVIEW)
          ? 'ASAP'
          : dateFormat(props.value);
      return <Pill type="date">{displayValue}</Pill>;
    },
    tipText: 'Effective Date'
  },
  {
    Header: 'Submission Deadline',
    accessor: 'final_submission_deadline',
    Cell: (props: CellPropsContent) => {
      const { authState } = useContext(AuthenticationContext);
      const { user } = authState;
      const rowID = props.row.id;
      const rowStatus = props.data[rowID].status;
      const prType = props.data[rowID].type;
      const calendarId = props.data[rowID].calendarId;
      const calendarSubtype = props.data[rowID]
        ? props.data[rowID].calendarId?.sub_type
        : null;
      const displayValue =
        calendarSubtype === 'AD_HOC' ||
        calendarId === null ||
        // #13372 As per requirement, for non-LDB users the Submission Deadline field should
        //be shown '----' for when the PR Type is NEW (NP) and status is in 'A/S Review'.
        (prType === PriceRequestTypesEnum.NP &&
          user.type === UserTypeEnum.EXTERNAL &&
          rowStatus === PriceRequestStatusEnum.AS_REVIEW)
          ? '-- --- ----'
          : dateFormat(props.value);
      return <Pill type="date">{displayValue}</Pill>;
    },
    tipText: 'Submission Deadline'
  },
  {
    Header: 'Prime Cost',
    accessor: 'prime_cost_per_case',
    Cell: (props: CellPropsContent) => {
      return currencyCellText(props.value);
    }
  },
  {
    Header: 'Domestic Charges',
    accessor: 'domestic_charges_per_case',
    Cell: (props: CellPropsContent) => {
      return currencyCellText(props.value);
    },
    tipText: 'Domestic Charges'
  },
  {
    Header: 'Duty Paid Cost',
    accessor: 'duty_paid_cost_per_case',
    Cell: (props: CellPropsContent) => {
      return currencyCellText(props.value);
    }
  },
  {
    Header: 'Promo Amount',
    accessor: 'promotion_amount_per_su',
    Cell: (props: CellPropsContent) => {
      return currencyCellText(props.value);
    }
  },
  {
    Header: 'Est. WS Price',
    accessor: 'price_per_su',
    Cell: (props: CellPropsContent) => {
      return currencyCellText(props.value);
    },
    tipText: 'Estimated Wholesale Price'
  },
  {
    Header: 'Request Reason',
    accessor: 'reason',
    Cell: (props: CellPropsContent) => {
      const value =
        props.value !== 'undefined' && props.value ? props.value : '---';
      const valueShow = value
        .replaceAll('[object Object]null, ', '')
        .replaceAll('[object Object]', '')
        .replaceAll('null, ', '')
        .replaceAll('undefined', '')
        .replaceAll('Other-', '');
      const displayValue = decodeSanitizedValue(valueShow);
      return (
        <Text
          element="p"
          tableCell
          disabled={value === '---'}
          tooltip={displayValue}
        >
          {displayValue}
        </Text>
      );
    }
  },
  {
    Header: 'Exception Reason',
    accessor: 'other_reason',
    Cell: (props: CellPropsContent) => {
      const value = props.value ? `${props.value}` : '---';
      const displayValue = decodeSanitizedValue(value);
      return (
        <Text
          element="p"
          tableCell
          disabled={value === '---'}
          tooltip={displayValue}
        >
          {displayValue}
        </Text>
      );
    }
  }
];
