import React from 'react';

import styles from './ConfirmProductSearchModal.module.scss';
import Heading from '../../Heading';
import Text from '../../Text';
import Button from '../../Button';
import ErrorAlert from '../../Icons/ErrorAlert';

import copy from '../../../config/translations/en-CA.json';
const modalCopy = copy.modals[0].confirmProductSearchModal;

type ConfirmProductSearchModalProps = {
  onConfirmSearch: () => void;
  onCancelSearch: () => void;
};

const ConfirmProductSearchModal: React.FC<ConfirmProductSearchModalProps> = ({
  onConfirmSearch,
  onCancelSearch
}) => {
  return (
    <div className={styles.confirmProductSearch}>
      <div className={styles.confirmProductSearchIcon}>
        <ErrorAlert />
      </div>
      <Heading element="h3">{modalCopy.title}</Heading>
      <Text element="p">{modalCopy.body}</Text>
      <div className={styles.confirmProductSearchActions}>
        <Button link onClick={onCancelSearch}>
          {modalCopy.cancel}
        </Button>
        <Button primary onClick={onConfirmSearch}>
          {modalCopy.confirm}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmProductSearchModal;
