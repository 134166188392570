import { CalculateResponse } from '../services/Calculate';

export function mapCalculateResponse(
  calculateResponse: CalculateResponse
): CalculateResponse {
  const keysToRemove = ['effectiveDate', 'validationMessage'];
  const mappedCalculatedResponse: any = Object.keys(calculateResponse)
    .filter((item) => !keysToRemove.includes(item))
    .map((item) => {
      if (item === 'customsAmountPerCase') {
        return { [item]: calculateResponse[item as keyof unknown] };
      }

      return {
        [item.replace(
          /([a-z])([A-Z]+)|([A-Z]+)(?=[a-z])|([A-Z])/g,
          (match, p1, p2, p3, p4) => {
            if (p1 && p2) {
              // If an uppercase sequence is preceded by a lowercase letter
              return `${p1}_${p2.toLowerCase()}`;
            } else if (p3) {
              // If an uppercase sequence is followed by a lowercase letter
              return `_${p3.toLowerCase()}`;
            } else if (p4) {
              // If a standalone uppercase letter
              return `_${p4.toLowerCase()}`;
            }
            return match;
          }
        )]: calculateResponse[item as keyof unknown]
      };
    });

  const newcalculateCostsResponse = Object.assign(
    {},
    ...mappedCalculatedResponse
  );

  return newcalculateCostsResponse;
}
