import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

type PropsType = {
  children?: string;
  link?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  type?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  pagination?: boolean;
  active?: boolean;
  iconRight?: boolean;
  iconLeft?: boolean;
  submit?: boolean;
  ariaLabel?: string;
  action?: boolean;
  primary?: boolean;
  secondary?: boolean;
  small?: boolean;
  className?: string;
  warning?: boolean;
  warningDark?: boolean;
  success?: boolean;
  padHelper?: boolean;
  noPad?: boolean;
  id?: string;
};

const Button = ({
  id,
  children,
  link,
  disabled,
  icon,
  pagination,
  onClick,
  active,
  iconRight,
  iconLeft,
  submit,
  ariaLabel,
  action,
  secondary,
  primary,
  small,
  className,
  warning,
  warningDark,
  success,
  padHelper,
  noPad
}: PropsType) => {
  return (
    <button
      aria-label={ariaLabel}
      data-testid={id}
      type={submit ? 'submit' : 'button'}
      className={classNames(className, styles.button, {
        [styles.buttonPrimary]: primary,
        [styles.buttonWarning]: warning,
        [styles.buttonWarningDisabled]: warning && disabled,
        [styles.buttonWarningDark]: warningDark,
        [styles.buttonSuccess]: success,
        [styles.buttonSuccessDisabled]: success && disabled,
        [styles.buttonPrimaryDisabled]: primary && disabled,
        [styles.buttonWarningDarkDisabled]: warningDark && disabled,
        [styles.buttonLink]: link,
        [styles.buttonLinkSmall]: link && small,
        [styles.buttonDisabled]: disabled,
        [styles.buttonLinkDisabled]: link && disabled,
        [styles.buttonIconPagination]: icon && pagination,
        [styles.buttonIconPaginationDisabled]: icon && pagination && disabled,
        [styles.buttonPagination]: pagination && !icon,
        [styles.buttonPaginationActive]: pagination && active,
        [styles.buttonAction]: action,
        [styles.buttonSecondary]: secondary,
        [styles.buttonSecondaryDisabled]: secondary && disabled,
        [styles.buttonPadHelper]: padHelper,
        [styles.buttonNoPad]: noPad
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {' '}
      {icon && iconLeft && <span className={styles.iconRight}>{icon}</span>}
      {icon && pagination && icon}
      {children}
      {icon && iconRight && <span className={styles.iconLeft}>{icon}</span>}
    </button>
  );
};

export default Button;
