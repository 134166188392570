import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAuth } from '../../contexts/AuthenticationProvider';
import env from '../Environment';

import { triggerActivityMonitorReset } from '../../reducers/ActivityMonitorSlice';

const TIMEOUT_DURATION =
  parseInt(env.REACT_APP_ACTIVITY_TIMEOUT_DURATION) || 5_400_000; // Default 90 minutes

const ActivityMonitor: React.FC = () => {
  const { authState, handleLogout } = useAuth();
  const token = authState.token;
  const dispatch = useDispatch();

  const { triggerReset, triggerEmitLogout } = useSelector(
    (state: RootState) => state.activityMonitor
  );

  function onIdle() {
    message('emitLogout', true);
  }

  function onMessage(value: string) {
    if (value === 'emitLogout') {
      handleLogout(token!);
    }
  }

  const { reset, message } = useIdleTimer({
    onIdle,
    onMessage,
    name: 'activityMonitor',
    timeout: TIMEOUT_DURATION,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  });

  useEffect(() => {
    if (triggerReset) {
      reset();
      dispatch(triggerActivityMonitorReset(false));
    }
  }, [triggerReset, reset, dispatch]);

  useEffect(() => {
    if (triggerEmitLogout) {
      message('emitLogout', true);
    }
  }, [triggerEmitLogout, message]);

  return null;
};

export default ActivityMonitor;
