import React from 'react';

import Button from '../../Button';
import ErrorAlert from '../../Icons/ErrorAlert';
import Heading from '../../Heading';
import Text from '../../Text';

import styles from './ParseCSVModal.module.scss';

import copy from '../../../config/translations/en-CA.json';
const modalCopy = copy.modals[0].parseCSVModal;

interface CancelPaneProps {
  handleCancel: () => void;
  handleCancelToggle: (value: boolean) => void;
}

const CancelPane: React.FC<CancelPaneProps> = ({
  handleCancel,
  handleCancelToggle
}) => {
  return (
    <div>
      <div
        className={`${styles.parseCSVModalIcon} ${styles.parseCSVModalIcon__cancel}`}
      >
        <ErrorAlert />
      </div>
      <Heading element="h3" heading3>
        {modalCopy.cancelTitle}
      </Heading>
      <Text element="p">{modalCopy.cancelBody}</Text>
      <div className={styles.parseCSVModalActions}>
        <Button link onClick={() => handleCancelToggle(false)}>
          {modalCopy.continueUploading}
        </Button>
        <Button
          primary
          onClick={() => {
            handleCancel();
          }}
        >
          {modalCopy.cancel}
        </Button>
      </div>
    </div>
  );
};

export default CancelPane;
