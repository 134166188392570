import pkceChallenge from 'pkce-challenge';
import Randomstring from 'randomstring';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import env from '../components/Environment';

type JwtDecode = { sub: string; exp: number };

interface AuthHelpers {
  AUTH_ENDPOINT: string;
  REDIRECT_URI: string;
  CLIENT_ID: string;
  LOGOUT_URI: string;
  API_TOKEN_ENDPOINT: string;
  API_USER_ENDPOINT: string;
  clearUrlParams: () => void;
  createPKCEcodes: () => Record<string, string> | undefined;
  createPkceQueryParams: () => Record<string, string> | undefined;
  addSessionStorageItem: (key: string, value: string) => void;
  removeSessionStorageItem: (itemKey: string) => void;
  redirectToLogin: () => void;
  checkTokenExpiry: (token: string) => boolean;
  getUuid: () => string;
  clearUuid: () => void;
}

const generateRandomString = (length = 32): string => {
  return Randomstring.generate(length);
};

const authHelpers: AuthHelpers = {
  AUTH_ENDPOINT: env.REACT_APP_OIDC_AUTH_ENDPOINT,
  REDIRECT_URI: env.REACT_APP_OIDC_REDIRECT_URI,
  CLIENT_ID: env.REACT_APP_OIDC_CLIENT_ID,
  LOGOUT_URI: env.REACT_APP_OIDC_LOGOUT_URI,
  API_TOKEN_ENDPOINT: env.REACT_APP_API_TOKEN_ENDPOINT,
  API_USER_ENDPOINT: env.REACT_APP_API_USER_ENDPOINT,

  clearUrlParams: (): void => {
    const url = window.location.href.split('?')[0];
    window.history.replaceState(null, '', url);
  },

  createPKCEcodes: (): Record<string, string> => {
    const generatedPkce = pkceChallenge();

    authHelpers.addSessionStorageItem(
      'pkceInfo',
      JSON.stringify(generatedPkce)
    );

    return generatedPkce;
  },

  createPkceQueryParams: (): Record<string, string> | undefined => {
    const pkceInfo = authHelpers.createPKCEcodes();

    if (!pkceInfo) {
      throw new Error('PKCE info not found in session storage');
    }

    const { code_challenge } = pkceInfo;

    if (typeof code_challenge !== 'string') {
      throw new Error('Invalid PKCE code_challenge');
    }

    return {
      response_type: 'code',
      scope: 'openid profile email vendor_ids',
      client_id: authHelpers.CLIENT_ID,
      state: generateRandomString(),
      redirect_uri: authHelpers.REDIRECT_URI,
      nonce: generateRandomString(),
      code_challenge,
      code_challenge_method: 'S256'
    };
  },

  addSessionStorageItem: (key: string, value: string): void => {
    sessionStorage.setItem(key, value);
  },

  removeSessionStorageItem: (itemKey: string): void => {
    sessionStorage.removeItem(itemKey);
  },

  redirectToLogin: () => {
    sessionStorage.clear();
    window.location.href = '/';
  },

  checkTokenExpiry: (token: string) => {
    const decodedToken = jwt_decode<JwtDecode>(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    const isExpired = currentTime > expirationTime;

    return isExpired;
  },

  getUuid: () => {
    let localUuid = localStorage.getItem('session_uuid');

    if (!localUuid) {
      localUuid = uuidv4();
      localStorage.setItem('session_uuid', localUuid);
    }

    return localUuid;
  },

  clearUuid: () => localStorage.removeItem('session_uuid')
};

export const checkTokenExpiry = authHelpers.checkTokenExpiry;
export const redirectToLogin = authHelpers.redirectToLogin;

export default authHelpers;
