/* ------------------------------------ *\
#MODAL

We should leave only the bare minimum
of styling on this component to make
it reusable. ie. Modal box padding can
be applied to the child components.
\* ------------------------------------ */

import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import CloseIcon from '../Icons/CloseIcon';

import styles from './index.module.scss';

type PropsType = {
  isOpen: boolean;
  onRequestClose?: () => void;
  aria?: {
    labelledby?: string;
    describedby?: string;
  };
  closeable?: boolean;
  children: React.ReactNode;
  alert?: boolean;
  information?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
};

const Modal = ({
  isOpen,
  onRequestClose,
  aria,
  children,
  closeable = true,
  alert,
  information,
  shouldCloseOnOverlayClick = false,
  shouldCloseOnEsc = true
}: PropsType) => {
  useEffect(() => {
    const appElement = document.getElementById('root');
    if (appElement) ReactModal.setAppElement(appElement);
  }, []);

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className={classNames(styles.modalContainer, {
          [styles.modalContainerAlert]: alert,
          [styles.modalContainerInformation]: information
        })}
        overlayClassName={styles.overlayContainer}
        aria={aria}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
      >
        {closeable && (
          <span
            className={styles.closeContainer}
            onClick={onRequestClose}
            data-testid={'close-modal-btn'}
          >
            <CloseIcon />
          </span>
        )}
        {children}
      </ReactModal>
    </div>
  );
};

export default Modal;
