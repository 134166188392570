import React, { useEffect, useState, ChangeEvent, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { useAuth } from '../../../contexts/AuthenticationProvider';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
  setRefreshDrafts,
  setOpenPRModal,
  setLoadingState,
  setRecentPriceRequestType,
  setPrevSaveFunction
} from '../../../reducers/PriceRequestSlice';

import {
  useCreateDraftPriceRequestMutation,
  useDeleteSinglePriceRequestMutation,
  useUpdateDraftPriceRequestMutation,
  useValidatePromotionRequestMutation
} from '../../../services/PriceRequest';

import {
  CalculateBody,
  useCalculateMutation
} from '../../../services/Calculate';

import {
  categorySearch,
  dateFormat,
  errorMapping,
  fixedDecimalPlaces,
  formatNumericDate,
  getWholesalePrice,
  getSessionToken,
  hiddenFieldCheck,
  isPassedDeadline,
  mapCalculateResponse,
  priceChangeModalCheck,
  productStatusSearch,
  wholesalePromotionModalCheck,
  decodeSanitizedValue
} from '../../../helpers';

import Heading from '../../Heading';
import Text from '../../Text';
import Pill from '../../Pill';
import Button from '../../Button';
import Banner from '../../Banner';
import TextInput from '../../TextInput';
import CurrencyInput from '../../CurrencyInput';
import TextArea from '../../TextArea';
import SelectInput from '../../SelectInput';
import Calculator from '../../Icons/Calculator';
import Checkmark from '../../Icons/CheckMark';
import ErrorAlert from '../../Icons/ErrorAlert';
import DateSelectMenu from '../../DateSelectMenu';
import LoadingOverlay from '../../LoadingOverlay';
import ExclamationPoint from '../../Icons/ExclamationPoint';
import GarbageCan from '../../Icons/GarbageCan';

import copy from '../../../config/translations/en-CA.json';
import styles from './index.module.scss';

import { schema, FormData } from './_schema';

import {
  SelectInputDateType,
  SelectInputType,
  ValidatePromotion,
  ValidationMessage,
  WorkingGroupPrDetails,
  PriceRequest,
  CalendarWarning,
  CalendarDate,
  Product,
  ValidationMessageType,
  SaveFunctionality
} from '../../../types';

const modalCopy = copy.modals[0].addNewPriceRequestNonLDB;

const COST_FIELDS: (keyof FormData)[] = [
  'primeCostPerCase',
  'dutyPaidCost',
  'domesticChargesPerCase',
  'promotionAmount',
  'estWholesalePrice',
  'wholesalePriceAsOfEffectiveDate'
];

type PropsType = {
  product: Product;
  onRequestClose: () => void;
  dates: CalendarDate[];
  prDetails?: WorkingGroupPrDetails;
  fullData: PriceRequest[] | undefined;
};

const AddNewPRNonLDBModal = ({
  product,
  onRequestClose,
  dates,
  prDetails,
  fullData
}: PropsType) => {
  const token = getSessionToken();
  const { user } = useAuth().authState;
  const internalVendorId = Number(user.vendorList[0].internalVendorID);
  const internalVendorList = user.vendorList;
  const dispatch = useDispatch();

  const { loadingState, recentPriceRequestType, prevSaveFunction } =
    useSelector((state: RootState) => state.priceRequest);

  const {
    product_name,
    sku,
    status_code,
    category,
    alcohol_percentage,
    unit_description,
    country_of_export,
    is_cost_dpc,
    regular_price_per_su,
    prime_cost_per_case,
    domestic_charges_per_case,
    duty_paid_cost_per_case,
    final_price_per_su,
    promotion_amount_per_su,
    agent: product_agent
  } = product;

  const [formData, setFormData] = useState<WorkingGroupPrDetails | undefined>(
    prDetails
  );

  const [
    calculate,
    {
      isLoading: isLoadingCalculate,
      isSuccess: isSuccessCalculate,
      isError: isErrorCalculate,
      data: calculateCostsResponse
    }
  ] = useCalculateMutation();

  const [
    createPriceRequest,
    {
      isError: createIsError,
      isSuccess: createIsSuccess,
      error: createDraftError,
      isLoading: createLoading
    }
  ] = useCreateDraftPriceRequestMutation();

  const [
    updatePriceRequest,
    {
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      isLoading: updateLoading
    }
  ] = useUpdateDraftPriceRequestMutation();

  const [
    validatePromotionRequest,
    {
      isSuccess: validatePromotionIsSuccess,
      isError: validatePromotionIsError,
      isLoading: validatePromotionLoading,
      data: validatePromotionResponse
    }
  ] = useValidatePromotionRequestMutation();

  const [
    deleteSinglePriceRequest,
    { isSuccess: deleteSingleIsSuccess, isLoading: deleteSingleIsLoading }
  ] = useDeleteSinglePriceRequestMutation();

  const deleteSinglePayload = {
    prID: Number(formData?.id),
    token
  };

  useEffect(() => {
    if (deleteSingleIsSuccess) {
      dispatch(setOpenPRModal(false));
      dispatch(setRefreshDrafts(true));
    }
  }, [deleteSingleIsSuccess, dispatch]);

  // Determine if the PR should use updatePriceRequest or createPriceRequest
  const [usePrDetails, toggleUsePrDetails] = useState<boolean>(
    prDetails && prDetails.id ? true : false
  );

  useEffect(() => {
    if (usePrDetails) {
      setFormData(prDetails);
    } else {
      setFormData(undefined);
      resetModal();
    }
  }, [usePrDetails]);

  const [updateCreatePR, setUpdateCreatePR] = useState<boolean>(false);

  // Boolean to determine users intention - true if save button is clicked
  const [saveNotCalculate, toggleSaveNotCalculate] = useState<boolean>(false);

  // Disable the entire form inputs if warnings are present
  const [disableForm, toggleDisableForm] = useState(false);

  const [justificationRequired, toggleJustificationRequired] = useState(false);

  // base empty states for calculation/ui/validation warnings and errors, this will be updated and filled when the calculate API is ru
  const [calculateErrors, setCalculateErrors] = useState<ValidationMessage[]>(
    []
  );

  const [uiValidationErrors, setUiValidationErrors] = useState<
    CalendarWarning[]
  >([]);

  const [interfaceWarnings, setInterfaceWarnings] = useState<
    { title: string; description: string; exceptionReason?: string }[]
  >([]);

  const [calendarWarnings, setCalendarWarnings] = useState<
    { title: string; description: string; exceptionReason?: string }[]
  >([]);

  const [calculateWarnings, setCalculateWarnings] = useState<
    ValidationMessage[]
  >([]);

  const [calculateErrorsExist, setCalculateErrorsExist] = useState(false);

  useEffect(() => {
    setCalculateErrorsExist(calculateErrors.length > 0);
  }, [calculateErrors]);

  const [updatedEstWSPrice, setUpdatedEstWSPrice] = useState(false);
  const [floorPrice, setFloorPrice] = useState(0);

  //Calculate button states and functionality
  // base state for if the calculate button is disabled, set to true to start
  const [disableCalculateBtn, setDisableCalculateBtn] = useState(true);

  // base state for the type of scenario this is for (options 1 - 4). This is determined by the product skus data (ie if the product is import);
  const [scenarioType, setScenarioType] = useState(1);

  // Track invalid calculate inputs or response for error handling
  const [invalidCalculateResponse, setInvalidCalculateResponse] =
    useState(false);

  //base state for if the price request was saved using the Confirm & Save button
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);

  // Creating base state for the wholesalePriceEffectiveDate value that is
  // passed into the wholesalePriceAsOfEffectiveDate input that is disabled
  // and uses an api call to retrieve the data.
  const [wholesalePriceEffectiveDate, setWholesalePriceEffectiveDate] =
    useState<string | undefined>(undefined);

  const [priceRequestBodyCalculate, setPriceRequestBodyCalculate] = useState<
    PriceRequest | undefined
  >(undefined);

  const intialPrTypeLabel: Record<string, string> = {
    PC: 'Price Change (PC)',
    WPP: 'Wholesale Price Promotion (WPP)'
  };

  const intialPrTypeValue: Record<string, string> = {
    PC: 'Supplier Price Change',
    WPP: 'Wholesale Price Promotion'
  };

  //base states for select inputs
  //check to see if pr details exist and apply they for an edit modal, then check
  //to see if the most recent PR type has been set and apply that
  //otherwise use PC default type
  const defaultPRType =
    formData?.['PR Type'] !== undefined
      ? {
          label:
            formData?.['PR Type'] && intialPrTypeLabel[formData?.['PR Type']],
          value:
            formData?.['PR Type'] && intialPrTypeValue[formData?.['PR Type']]
        }
      : recentPriceRequestType !== undefined
      ? {
          label: recentPriceRequestType.label,
          value: recentPriceRequestType.value
        }
      : {
          label: intialPrTypeLabel['PC'],
          value: intialPrTypeValue['PC']
        };

  const [selectedRequestType, setSelectedRequestType] = useState<{
    label: string;
    value: string;
  }>(defaultPRType);

  // Variables outlined below to hold different form input values and or incoming product data
  // Product is “Domestic” AND NOT on the list of the products requiring Duty Paid cost as indicated by flag in product data API
  const priceChangeDomesticNotDPCProduct =
    selectedRequestType.value === 'Supplier Price Change' &&
    country_of_export === 'CA' &&
    is_cost_dpc === 'NO';
  // Product is Import
  const priceChangeImportProduct =
    selectedRequestType.value === 'Supplier Price Change' &&
    country_of_export !== 'CA';
  // Product is “Domestic” AND on the list of the products requiring Duty Paid cost as indicated by flag in product data API
  const priceChangeDomesticDPCProduct =
    selectedRequestType.value === 'Supplier Price Change' &&
    country_of_export === 'CA' &&
    is_cost_dpc === 'YES';
  // Request type is Supplier Price Change
  const priceChangeRequest =
    selectedRequestType.value === 'Supplier Price Change';
  // Request type is Supplier Price Change
  const wholesalePromotionRequest =
    selectedRequestType.value === 'Wholesale Price Promotion' ||
    selectedRequestType.value === 'Wholesale Price Promotion (WPP)';

  // Filter out all the calendar dates that have a type
  // of PRICE for Price Change and a subType of REGULAR.
  const priceDates = dates.filter(
    (date: CalendarDate) => date.type === 'PRICE' && date.subType === 'REGULAR'
  );

  //Create the price and promotion label value pairs for all of the appropriate dates to creat the select options array for the DateSelectMenu input labelled Effective Date in the form below.
  const priceSelectOptions = priceDates
    .sort((a: CalendarDate, b: CalendarDate) =>
      new Date(a.effectiveDate) > new Date(b.effectiveDate) ? 1 : -1
    )
    .map((date: CalendarDate) => {
      const effectiveDate = dateFormat(date.effectiveDate);
      const submissionDeadline = date.submissionDeadLine;
      return {
        label: effectiveDate,
        value: date.effectiveDate,
        submissionDeadline: submissionDeadline,
        disabled: false,
        calendarId: date.id
      };
    });

  //Filter out all of the calendar dates that have a type of PROMOTION for WPP and a subType of REGULAR.
  const promotionDates = dates.filter(
    (date: CalendarDate) =>
      date.type === 'PROMOTION' && date.subType === 'REGULAR'
  );

  const promotionSelectOptions = promotionDates
    .sort((a: CalendarDate, b: CalendarDate) =>
      new Date(a.effectiveDate) > new Date(b.effectiveDate) ? 1 : -1
    )
    .map((date: CalendarDate) => {
      const effectiveDate = dateFormat(date.effectiveDate);
      const submissionDeadline = date.submissionDeadLine;
      return {
        label: effectiveDate,
        value: date.effectiveDate,
        submissionDeadline: submissionDeadline,
        disabled: false,
        calendarId: date.id
      };
    });

  //FIND the ADHOC subType dates for both PC/Price and WPP/Promotion, these will be used for the asap options.
  const adhocPromotionOption = dates.filter(
    (date: CalendarDate) =>
      date.type === 'PROMOTION' && date.subType === 'AD_HOC'
  );

  const adhocPriceOption = dates.filter(
    (date: CalendarDate) => date.type === 'PRICE' && date.subType === 'AD_HOC'
  );

  //Assign the AD_HOC types for as a label ASAP and then push it on to the appropriate select options array so its the last option
  const asapPriceOption = {
    label: 'ASAP',
    value: adhocPriceOption[0]?.effectiveDate,
    submissionDeadline: adhocPriceOption[0]?.submissionDeadLine,
    disabled: false,
    calendarId: adhocPriceOption[0]?.id
  };

  const asapPromotionOption = {
    label: 'ASAP',
    value: adhocPromotionOption[0]?.effectiveDate,
    submissionDeadline: adhocPromotionOption[0]?.submissionDeadLine,
    disabled: false,
    calendarId: adhocPromotionOption[0]?.id
  };

  promotionSelectOptions.push(asapPromotionOption);
  priceSelectOptions.push(asapPriceOption);

  const defaultEffectiveDateOptions =
    formData?.['PR Type'] === 'WPP'
      ? promotionSelectOptions
      : priceSelectOptions;

  const defaultSelectedDate = defaultEffectiveDateOptions.find(
    (effectiveDateOption) =>
      effectiveDateOption.value === formData?.['Effective Date']
  );

  const defaultFormValues = {
    requestType: defaultPRType,
    effectiveDate: defaultSelectedDate || {
      label: '',
      value: '',
      calendarId: 0,
      submissionDeadline: ''
    },
    endDate: '',
    dutyPaidCost:
      usePrDetails && formData?.duty_paid_cost_per_case
        ? fixedDecimalPlaces(formData?.duty_paid_cost_per_case, 2).toString()
        : undefined,
    estWholesalePrice:
      usePrDetails && formData?.['Est. WS Price']
        ? fixedDecimalPlaces(formData?.['Est. WS Price'], 2).toString()
        : undefined,
    primeCostPerCase:
      usePrDetails && formData?.prime_cost_per_case
        ? fixedDecimalPlaces(formData?.prime_cost_per_case, 2).toString()
        : undefined,
    wholesalePriceAsOfEffectiveDate: undefined,
    promotionAmount:
      usePrDetails && formData?.['Promotion Amount']
        ? fixedDecimalPlaces(formData?.['Promotion Amount'], 2)?.toString()
        : undefined,
    domesticChargesPerCase:
      usePrDetails && formData?.domestic_charges_per_case
        ? fixedDecimalPlaces(formData?.domestic_charges_per_case, 2).toString()
        : undefined,
    reason: 'Supplier Price Change',
    costingEventComment: '',
    addNewPRJustification: usePrDetails ? formData?.justification : undefined
  };
  //pass in the schema defined above to our useForm so react hook form has access to it along with the validation modes (when to apply validation) and the default values so the form has a reference to detect changes
  const methods = useForm<FormData>({
    resolver: yupResolver<any>(schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues
  });

  const { control } = methods;

  //watch the requestType input, and effective date inout for changes to properly update the form between WPP and PC using react-hook-form watch method
  const priceRequestType = useWatch({ control, name: 'requestType' });
  const effectiveDateChange = useWatch({ control, name: 'effectiveDate' });

  //Get access to the list of errors on the form.
  const modalFormErrors = methods.formState.errors;

  //check that no form errors exist in the modal
  const [formErrorsExist, setFormErrorsExist] = useState(false);

  useEffect(() => {
    if (modalFormErrors.constructor !== Object) return;
    setFormErrorsExist(Object.keys(modalFormErrors).length > 0);
  }, [Object.keys(modalFormErrors).length]);

  //get access to form values using react-hook-form getValues();
  const formValues = methods.getValues();

  // the below variables pull in the appropriate warning and errors copy from the
  // translations file based on whether it is a price change request or a wpp
  const warningMessageContent: {
    [key: string]: {
      title: string;
      description: string;
      type: string;
      exceptionReason?: string;
      justificationRequired?: boolean;
    };
  } = priceChangeRequest
    ? modalCopy.apiValidation.priceChange.warnings
    : modalCopy.apiValidation.promotionValidation.warnings;

  const errorMessageContent: {
    [key: string]: {
      title: string;
      description: string;
      type: string;
    };
  } = priceChangeRequest
    ? modalCopy.apiValidation.priceChange.errors
    : modalCopy.apiValidation.promotionValidation.errors;

  // INPUT STATES
  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState<
    | {
        label: string;
        value: string;
        calendarId: number | undefined;
        submissionDeadline: string | undefined;
      }
    | undefined
  >(defaultSelectedDate);

  // set the values of the hidden inputs based on the request type
  const [inputtedReason, setInputtedReason] = useState('Supplier Price Change');
  const [justification, setjustification] = useState(
    formData?.justification || ''
  );
  const [inputtedCostingEventComment, setInputtedCostingEventComment] =
    useState('');

  const [inputtedPrimeCostValue, setInputtedPrimeCostValue] = useState<
    string | undefined
  >(
    usePrDetails &&
      typeof formData?.prime_cost_per_case !== 'undefined' &&
      formData?.prime_cost_per_case !== null
      ? `${fixedDecimalPlaces(formData?.prime_cost_per_case, 2)}`
      : undefined
  );

  const [inputtedDutyPaidCostValue, setInputtedDutyPaidCostValue] = useState<
    string | undefined
  >(
    usePrDetails &&
      typeof formData?.duty_paid_cost_per_case !== 'undefined' &&
      formData?.duty_paid_cost_per_case !== null
      ? `${fixedDecimalPlaces(formData?.duty_paid_cost_per_case, 2)}`
      : undefined
  );

  const [inputtedDomesticChargesValue, setInputtedDomesticChargesValue] =
    useState<string | undefined>(
      usePrDetails &&
        typeof formData?.domestic_charges_per_case !== 'undefined' &&
        formData?.domestic_charges_per_case !== null
        ? `${fixedDecimalPlaces(formData?.domestic_charges_per_case, 2)}`
        : undefined
    );

  const [inputtedPromotionAmountValue, setInputtedPromotionAmountValue] =
    useState<string | undefined>(
      usePrDetails && formData?.['Promotion Amount']
        ? fixedDecimalPlaces(formData?.['Promotion Amount'], 2)
        : undefined
    );

  const [inputtedEstWholesalePriceValue, setInputtedEstWholesalePriceValue] =
    useState<string | undefined>(
      usePrDetails && formData?.['Est. WS Price']
        ? fixedDecimalPlaces(formData?.['Est. WS Price'], 2)
        : undefined
    );

  const [exceptionOtherReason, setExceptionOtherReason] = useState('');

  const handleTextInputBlur = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputId:
      | 'primeCostPerCase'
      | 'dutyPaidCost'
      | 'domesticChargesPerCase'
      | 'promotionAmount'
      | 'estWholesalePrice'
  ) => {
    let { value } = event.target;
    let editValue = null;
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numberValue)) {
      return;
    } else {
      editValue = fixedDecimalPlaces(numberValue, 2);
    }

    if (editValue !== null && editValue?.length > 0) {
      value = editValue;
    } else {
      return;
    }

    if (inputId === 'primeCostPerCase') {
      setInputtedPrimeCostValue(value);
    } else if (inputId === 'dutyPaidCost') {
      setInputtedDutyPaidCostValue(value);
    } else if (inputId === 'domesticChargesPerCase') {
      setInputtedDomesticChargesValue(value);
    } else if (inputId === 'promotionAmount') {
      setInputtedPromotionAmountValue(value);
      setInvalidCalculateResponse(false);
    } else if (inputId === 'estWholesalePrice') {
      setInputtedEstWholesalePriceValue(value);
    }

    if (COST_FIELDS.includes(inputId as any)) {
      COST_FIELDS.forEach((fieldName) => {
        const fieldValue = methods.getValues(fieldName);
        if (Number(fieldValue) < 0) {
          setCostFieldValue(fieldName, undefined);
          setInvalidCalculateResponse(false);
        }
      });
      methods.clearErrors();
    }
  };

  const handleTextInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputId:
      | 'primeCostPerCase'
      | 'dutyPaidCost'
      | 'domesticChargesPerCase'
      | 'promotionAmount'
      | 'estWholesalePrice'
      | 'addNewPRJustification'
  ) => {
    let { value } = event.target;

    //Allows "." to be enter at the front of value, and adding 0 to it to avoid Warning message on example like ".75"
    if (
      value.charAt(0) === '.' &&
      event.target.className?.includes('CurrencyInput')
    ) {
      methods.setValue(inputId as any, value.replace('.', '0.'));
      value = value.replace('.', '0.');
    }

    if (inputId === 'primeCostPerCase') {
      setInputtedPrimeCostValue(value);
    } else if (inputId === 'dutyPaidCost') {
      setInputtedDutyPaidCostValue(value);
    } else if (inputId === 'domesticChargesPerCase') {
      setInputtedDomesticChargesValue(value);
    } else if (inputId === 'promotionAmount') {
      setInputtedPromotionAmountValue(value);
      setInputtedEstWholesalePriceValue(undefined);
      methods.setValue('estWholesalePrice', undefined);
      setInvalidCalculateResponse(false);
    } else if (inputId === 'estWholesalePrice') {
      setInputtedEstWholesalePriceValue(value);
    } else if (inputId === 'addNewPRJustification') {
      setCalculateErrors([]);
      setUiValidationErrors([]);
      setjustification(value);
    }

    if (COST_FIELDS.includes(inputId)) {
      COST_FIELDS.forEach((fieldName) => {
        const fieldValue = methods.getValues(fieldName);
        if (Number(fieldValue) < 0) {
          setCostFieldValue(fieldName, undefined);
          setInvalidCalculateResponse(false);
        }
      });

      methods.clearErrors();
    }
  };

  const setCostFieldValue = (
    fieldName: keyof FormData,
    value?: number | string | undefined
  ) => {
    const formattedValue = value ? fixedDecimalPlaces(value, 2) : '';
    switch (fieldName) {
      case 'primeCostPerCase':
        setInputtedPrimeCostValue(formattedValue);
        break;
      case 'dutyPaidCost':
        setInputtedDutyPaidCostValue(formattedValue);
        break;
      case 'domesticChargesPerCase':
        setInputtedDomesticChargesValue(formattedValue);
        break;
      case 'promotionAmount':
        setInputtedPromotionAmountValue(formattedValue);
        break;
      case 'estWholesalePrice':
        setInputtedEstWholesalePriceValue(formattedValue);
        break;
      case 'wholesalePriceAsOfEffectiveDate':
        setWholesalePriceEffectiveDate(formattedValue);
        break;
    }

    methods.setValue(fieldName, formattedValue);
  };

  // confirm whether or not the calculate prices button should be disabled.
  useEffect(() => {
    // first check the value of priceRequestType variable that was watching the requestType input for changes.
    if (priceChangeRequest) {
      //use the priceChangeModalCheck helper function to enable or disable the calculate button and assign what type of scenario
      priceChangeModalCheck(
        effectiveDateChange.value || selectedEffectiveDate?.value,
        country_of_export,
        is_cost_dpc,
        inputtedPrimeCostValue,
        inputtedEstWholesalePriceValue,
        inputtedDomesticChargesValue,
        inputtedDutyPaidCostValue,
        setScenarioType,
        setDisableCalculateBtn
      );
    } else if (wholesalePromotionRequest) {
      //use the wholesalePromotionModalCheckhelper function to enable or disable the calculate button and assign scenario type 3
      wholesalePromotionModalCheck(
        Number(inputtedPromotionAmountValue),
        selectedEffectiveDate?.value,
        setScenarioType,
        setDisableCalculateBtn
      );
    }
  }, [
    country_of_export,
    effectiveDateChange.value,
    inputtedDomesticChargesValue,
    inputtedDutyPaidCostValue,
    inputtedEstWholesalePriceValue,
    inputtedPrimeCostValue,
    inputtedPromotionAmountValue,
    is_cost_dpc,
    modalFormErrors,
    priceChangeRequest,
    wholesalePriceEffectiveDate,
    wholesalePromotionRequest,
    selectedEffectiveDate?.value
  ]);

  const handleSelectInputChange = (
    input: SelectInputDateType,
    inputId: 'effectiveDate' | 'requestType'
  ) => {
    const { value, label, calendarId, submissionDeadline } = input;

    methods.setValue(
      inputId,
      {
        value: value || 'ASAP',
        label: label
      },
      { shouldValidate: true }
    );

    if (inputId === 'effectiveDate') {
      setSelectedEffectiveDate({
        value: value,
        label: label,
        calendarId,
        submissionDeadline
      });

      handleClearModal();
    } else {
      setSelectedRequestType({
        value: value,
        label: label
      });
      // clear both the effective and end date because these values should be
      // coming from a different calendar (promotion vs price), as well as the
      // WholesalePriceEffectiveDate value since do calendar date is now selected;
      setSelectedEffectiveDate({
        value: '',
        label: '',
        calendarId: 0,
        submissionDeadline: ''
      });
      methods.setValue('effectiveDate', {
        value: '',
        label: '',
        calendarId: 0,
        submissionDeadline: ''
      });
      setEndDate('');

      COST_FIELDS.forEach((fieldName) => {
        setCostFieldValue(fieldName, undefined);
      });

      setInvalidCalculateResponse(false);
      handleClearModal();
      methods.clearErrors();
    }
  };

  useEffect(() => {
    if (priceChangeRequest) {
      setInputtedReason('Supplier Price Change');
      setInputtedCostingEventComment('PCR');
    } else {
      setInputtedReason('');
      setInputtedCostingEventComment('');
    }
  }, [priceChangeRequest]);

  //Find the respective end date to match the selected effective date.
  //First check if the label is ASAP, otherwise filter the dates array
  //to find the date whose effectDate matches the selectedEffectiveDate
  const findEndDate = (effectiveDateChosen: SelectInputType) => {
    const dateValue = [];
    if (effectiveDateChosen.label === 'ASAP') {
      dateValue.push(adhocPromotionOption);
    } else {
      dateValue.push(
        promotionDates.filter(
          (date: CalendarDate) =>
            selectedEffectiveDate &&
            date.effectiveDate === selectedEffectiveDate.value
        )
      );
    }

    return dateValue.flat(1);
  };

  //call the above function setting its return to the variable newDate.
  const newDate = selectedEffectiveDate && findEndDate(selectedEffectiveDate);
  const [endDate, setEndDate] = useState('');

  // if newDate has a value, create and set the endDate to that value, so it can populate the End Date Read Only text input for WPP Request Types only.
  useEffect(() => {
    if (newDate && newDate.length > 0) {
      setEndDate(dateFormat(newDate[0].effectiveEndDate));
    }
  }, [newDate]);

  const priceRequestBody: PriceRequest = {
    sku: sku,
    type: priceChangeRequest ? 'PC' : 'WPP',
    calendar_id: selectedEffectiveDate && selectedEffectiveDate.calendarId,
    promotion_amount_per_su: priceChangeRequest
      ? undefined
      : Number(inputtedPromotionAmountValue),
    domestic_charges_per_case: priceChangeRequest
      ? Number(inputtedDomesticChargesValue)
      : undefined,
    prime_cost_per_case: priceChangeRequest
      ? Number(inputtedPrimeCostValue)
      : undefined,
    duty_paid_cost_per_case: priceChangeRequest
      ? Number(inputtedDutyPaidCostValue)
      : undefined,
    reason: inputtedReason,
    costing_event_comment: inputtedCostingEventComment,
    estimated_price_per_su: Number(wholesalePriceEffectiveDate),
    price_per_su: Number(inputtedEstWholesalePriceValue),
    other_reason: exceptionOtherReason,
    warning_json: JSON.stringify(calculateWarnings),
    justification_required_fl: justificationRequired,
    justification: justification
  };

  // api call to get the wholesale price based on pr type of Wholesale Price Promotion
  // and an effective date being selected. Only for WPP preice requests
  useEffect(() => {
    if (wholesalePromotionRequest && effectiveDateChange.value) {
      if (selectedEffectiveDate?.value) {
        const dateFormat = formatNumericDate(selectedEffectiveDate.value, true);
        const priceBody = {
          transactionDate: dateFormat,
          sku: [sku]
        };
        if (token) {
          dispatch(setLoadingState(true));
          //pass in the token, price body and callback fxn to the getWholesalePrice fxn to make the api call
          getWholesalePrice({
            accessToken: token,
            data: priceBody,
            callbackFunction: setWholesalePriceEffectiveDate,
            loadingCallbackFunction: (error) => {
              dispatch(setLoadingState(false));
              if (error === 'Product Not Priced') {
                setCalculateErrors([
                  {
                    title:
                      modalCopy.apiValidation.promotionValidation.errors[3]
                        .title,
                    code: '3',
                    message:
                      modalCopy.apiValidation.promotionValidation.errors[3]
                        .description,
                    type: 'ERROR'
                  }
                ]);
              } else if (error) {
                setCalculateErrors([
                  {
                    code: 'error',
                    message: 'An Error has occurred please try again.',
                    type: 'ERROR'
                  }
                ]);
              }
            }
          });
        }
      }
    }
  }, [
    wholesalePromotionRequest,
    selectedEffectiveDate,
    sku,
    priceRequestType.value,
    effectiveDateChange.value,
    token
  ]);

  //get the hierarchy data saved to sessionStorage to retrieve the
  //correct category description using the categorySearch helper
  //below in the pill/badge in the product details (left hand side
  //of the modal card. ie turns a numeric category_id into Wine)
  const hierachyData = sessionStorage.getItem('hierachyData');

  const handleCalculateButtonClick = () => {
    setUpdateCreatePR(false);
    handleCalculate();
  };

  // This function is for the calculate button.
  const handleCalculate = () => {
    setInterfaceWarnings([]);
    setCalculateErrors([]);
    setCalculateWarnings([]);
    setUiValidationErrors([]);

    // destructure the values off of the form
    const { effectiveDate } = formValues;

    // Reformat the date YYYY-DD-MM to fit the structure of the api request body
    const formattedEffectiveDate = formatNumericDate(effectiveDate.value, true);
    const formattedEndDate = formatNumericDate(endDate);

    const isPastDeadline = isPassedDeadline(
      selectedEffectiveDate?.submissionDeadline || ''
    );

    const isASAP =
      selectedEffectiveDate && selectedEffectiveDate.label === 'ASAP';

    if (isPastDeadline && !isASAP) {
      toggleJustificationRequired(true);
      const deadlineError = modalCopy.uiValidationWarnings.lateSubmission;

      setCalendarWarnings((prevArray) => [
        {
          title: deadlineError.title,
          description: deadlineError.description,
          exceptionReason: deadlineError.exceptionReason
        }
      ]);
    }

    if (isASAP) {
      toggleJustificationRequired(true);
      const asapError = modalCopy.uiValidationWarnings.asap;

      setCalendarWarnings((prevArray) => [
        {
          title: asapError.title,
          description: asapError.description,
          exceptionReason: asapError.exceptionReason
        }
      ]);
    }

    if (justificationRequired && justification === '') {
      setUpdateCreatePR(false);
    }

    if (wholesalePromotionRequest) {
      if (!wholesalePriceEffectiveDate && !inputtedPromotionAmountValue) return;
      const estimatedWPP =
        Number(wholesalePriceEffectiveDate) -
        Number(inputtedPromotionAmountValue);
      const formattedEstWsPrice = fixedDecimalPlaces(estimatedWPP, 2);

      // Check if current EstWSPrice matches returned value
      // Used to trigger EstWSPrice Change Warning in validatePromotionIsSuccess
      const originalEstWsPrice = methods.getValues('estWholesalePrice');

      // If the calculated value is different from the original value, set the updatedEstWSPrice state to true
      setUpdatedEstWSPrice(formattedEstWsPrice !== originalEstWsPrice);

      // Set new values and trigger validation
      setCostFieldValue('estWholesalePrice', formattedEstWsPrice);
      methods.trigger();

      // Check return value for validity
      if (estimatedWPP < 0) {
        setInvalidCalculateResponse(true);
      }

      // Update PR Body with calculated values
      setPriceRequestBodyCalculate({
        ...priceRequestBody,
        price_per_su: Number(formattedEstWsPrice)
      });

      //If the type of request is WPP, call the /price-validation/validate-promotion API to validate the Estimated WS Price
      //Need to pass in the selected effective date and end date as the promotion period, along with product sku and promotion amount
      const promoPeriod = {
        dateFrom: formattedEffectiveDate,
        dateTo: formattedEndDate
      };

      setPriceRequestBodyCalculate({
        ...priceRequestBody,
        price_per_su: Number(fixedDecimalPlaces(estimatedWPP, 2))
      });

      if (inputtedPromotionAmountValue && promoPeriod.dateFrom) {
        const validatePromotionPayload: ValidatePromotion = {
          sku,
          promotionAmountPerSU: Number(inputtedPromotionAmountValue),
          promoPeriod: promoPeriod
        };
        validatePromotionRequest({ body: validatePromotionPayload, token });
      }
    } else {
      // if the request type is PC, we need to use the /calculate api to retrieve one of several different numbers
      // this is based on the direction of the calculation as well as the scenario type and the inputted values
      // first create an object holding all potential inputted values that exist on the form, along with the sku and scenario type number
      const requestProperties: CalculateBody = {
        sku: sku,
        effectiveDate: formattedEffectiveDate,
        dutyPaidCostPerCase: inputtedDutyPaidCostValue,
        primeCostPerCase: inputtedPrimeCostValue,
        pricePerSU: inputtedEstWholesalePriceValue,
        domesticChargesPerCase: inputtedDomesticChargesValue,
        scenario: scenarioType
      };

      if (token) {
        calculate({ requestBody: requestProperties, token });
      }
    }
  };

  const validateShowJustificationRequired = useCallback(() => {
    calculateWarnings.forEach((warning: ValidationMessage, idx: number) => {
      const code = warning.code;

      const justificationRequired =
        warningMessageContent[code].justificationRequired;
      justificationRequired &&
        toggleJustificationRequired(justificationRequired);
    });
  }, [calculateWarnings, warningMessageContent]);

  useEffect(() => {
    if (calculateWarnings.length > 0) {
      validateShowJustificationRequired();
    }
    if (
      (calculateWarnings.length > 0 ||
        calendarWarnings.length > 0 ||
        interfaceWarnings.length > 0 ||
        calculateErrors.length > 0 ||
        uiValidationErrors.length > 0) &&
      updateCreatePR
    ) {
      toggleDisableForm(true);
    } else {
      toggleDisableForm(false);
    }
  }, [
    calculateWarnings,
    calendarWarnings,
    updateCreatePR,
    calculateErrors,
    interfaceWarnings,
    formErrorsExist,
    uiValidationErrors,
    invalidCalculateResponse,
    validateShowJustificationRequired
  ]);

  const handleUpdateCreatePR = (
    additionalPRdata: any,
    forceUpdate: boolean
  ) => {
    if (!updateCreatePR && !forceUpdate) return;

    // Ensure Cost Field or Promotion Amount values are not included in PR Create/Update when not needed
    // These values may be set if the PR type is changed while creating or editing
    const prBodyOverrides = wholesalePromotionRequest
      ? {
          prime_cost_per_case: null,
          domestic_charges_per_case: null,
          in_bond_cost_per_case: null,
          excise_amount_per_case: null,
          customsAmountPerCase: null,
          duty_paid_cost_per_case: null,
          duty_paid_cost_per_su: null,
          beer_distro_charge_per_su: null,
          markup_per_su: null,
          crf_per_su: null,
          price_per_case: null
        }
      : {
          promotion_amount_per_su: null
        };

    const requestBody = {
      ...priceRequestBodyCalculate,
      ...additionalPRdata,
      ...prBodyOverrides
    };

    // Check to see if we should use the existing prDetails to UPDATE a PR using
    // the usePrDetails boolean or CREATE a brand new one.
    if (prDetails && usePrDetails) {
      requestBody.id = Number(prDetails.id);
      updatePriceRequest({
        requestBody,
        token
      });
    } else {
      requestBody.id = null;
      createPriceRequest({
        requestBody,
        token
      });
    }
  };

  const checkUiValidationErrors = () => {
    if (!updateCreatePR || !prDetails) return false;

    const validationErrorMessages = [];
    const productAgent = product_agent;

    const agentChangeErrorText = modalCopy.uiValidationErrors.agentChange;
    //find any correct agent ID in user's vendorList which match with product agent ID
    const findAgentID = internalVendorList?.find(
      (vendor) => Number(vendor.internalVendorID) === productAgent
    );
    const isAgentMatched =
      productAgent &&
      findAgentID !== undefined &&
      productAgent === Number(findAgentID.internalVendorID);

    if (!isAgentMatched) {
      validationErrorMessages.push(agentChangeErrorText);
    }

    setUiValidationErrors(validationErrorMessages);

    return validationErrorMessages.length > 0;
  };

  useEffect(() => {
    if (isSuccessCalculate && calculateCostsResponse) {
      const {
        pricePerSU,
        dutyPaidCostPerCase,
        primeCostPerCase,
        domesticChargesPerCase,
        validationMessage
      } = calculateCostsResponse;

      const newCalculateCostResponse = priceChangeRequest
        ? mapCalculateResponse(calculateCostsResponse)
        : {};

      const priceRequestBodyCalculateResponse: PriceRequest = {
        ...newCalculateCostResponse,
        sku: sku,
        type: priceChangeRequest ? 'PC' : 'WPP',
        calendar_id: selectedEffectiveDate && selectedEffectiveDate.calendarId,
        promotion_amount_per_su: null,
        reason: inputtedReason,
        costing_event_comment: inputtedCostingEventComment,
        estimated_price_per_su: wholesalePriceEffectiveDate
          ? Number(wholesalePriceEffectiveDate)
          : undefined,
        other_reason: exceptionOtherReason || undefined,
        warning_json: JSON.stringify([
          ...calculateWarnings,
          ...calendarWarnings
        ]),
        justification_required_fl: justificationRequired,
        justification: justification || undefined
      };

      setPriceRequestBodyCalculate(priceRequestBodyCalculateResponse);

      setCostFieldValue('estWholesalePrice', fixedDecimalPlaces(pricePerSU, 2));
      setCostFieldValue(
        'domesticChargesPerCase',
        fixedDecimalPlaces(domesticChargesPerCase, 2)
      );
      setCostFieldValue(
        'dutyPaidCost',
        fixedDecimalPlaces(dutyPaidCostPerCase, 2)
      );
      setCostFieldValue(
        'primeCostPerCase',
        fixedDecimalPlaces(primeCostPerCase, 2)
      );

      methods.trigger();

      const calculatedValues = [
        pricePerSU,
        domesticChargesPerCase,
        dutyPaidCostPerCase,
        primeCostPerCase
      ];

      if (calculatedValues.some((value) => value < 0)) {
        setInvalidCalculateResponse(true);
        handleClearModal();
        return;
      }

      const estWSPriceUpdated =
        pricePerSU !== Number(inputtedEstWholesalePriceValue);

      const hasValidationErrors: any = checkUiValidationErrors();

      if (
        updateCreatePR &&
        (validationMessage?.length > 0 ||
          calendarWarnings?.length > 0 ||
          estWSPriceUpdated ||
          hasValidationErrors)
      ) {
        if (validationMessage?.length > 0) {
          // if there are validation messages, filter by WARNING and ERROR types, and update the calculateWarnings and calculateErrors arrays;
          // These validation messages are only displayed on save, not the calculate button onclick functionality
          const validationWarnings =
            calculateCostsResponse.validationMessage.filter(
              (message: ValidationMessageType) => message.type === 'WARNING'
            );

          const validationErrors =
            calculateCostsResponse.validationMessage.filter(
              (message: ValidationMessageType) => message.type === 'ERROR'
            );
          setFloorPrice(pricePerSU);
          setCalculateWarnings(validationWarnings);
          setCalculateErrors(validationErrors);
        }

        if (calendarWarnings?.length > 0) {
          setInterfaceWarnings([...calendarWarnings]);
        }

        if (estWSPriceUpdated) {
          const estWSPriceUpdatedWarning =
            modalCopy.uiValidationWarnings.estimatedWSPriceChange;
          setInterfaceWarnings((prevWarnings) => [
            ...prevWarnings,
            estWSPriceUpdatedWarning
          ]);
        }

        return;
      }

      setCalculateWarnings([]);
      setCalculateErrors([]);
      setUiValidationErrors([]);

      handleUpdateCreatePR(priceRequestBodyCalculateResponse, false);
    }
  }, [isSuccessCalculate, calculateCostsResponse]);

  useEffect(() => {
    if (validatePromotionIsSuccess && validatePromotionResponse) {
      const validationErrors: ValidationMessage[] | undefined =
        validatePromotionResponse &&
        validatePromotionResponse?.validationMessage &&
        validatePromotionResponse?.validationMessage.length > 0
          ? validatePromotionResponse?.validationMessage.filter(
              (message: ValidationMessage) => message.type === 'ERROR'
            )
          : [];

      const validationWarnings: ValidationMessage[] | undefined =
        validatePromotionResponse &&
        validatePromotionResponse?.validationMessage &&
        validatePromotionResponse?.validationMessage.length > 0
          ? validatePromotionResponse?.validationMessage.filter(
              (message: ValidationMessage) => message.type === 'WARNING'
            )
          : [];
      if (updateCreatePR) {
        const hasValidationErrors: any = checkUiValidationErrors();

        validationErrors &&
          validationErrors.length > 0 &&
          setCalculateErrors(validationErrors);

        validationWarnings &&
          validationWarnings.length > 0 &&
          setCalculateWarnings(validationWarnings);

        if (!validationErrors.length && calendarWarnings?.length > 0) {
          setInterfaceWarnings([...calendarWarnings]);
        }

        if (updatedEstWSPrice) {
          const estWsPriceOverwriteMessage =
            modalCopy.uiValidationWarnings.estimatedWSPriceChangeWPP;
          setInterfaceWarnings((prevWarnings) => [
            ...prevWarnings,
            estWsPriceOverwriteMessage
          ]);
        }

        if (
          validationErrors.length === 0 &&
          validationWarnings.length === 0 &&
          calendarWarnings?.length === 0 &&
          interfaceWarnings?.length === 0 &&
          !hasValidationErrors &&
          !updatedEstWSPrice
        ) {
          const additionalRequestBody = {
            warning_json: JSON.stringify([
              ...calculateWarnings,
              ...calendarWarnings
            ]),
            justification: justification
          };

          handleUpdateCreatePR(additionalRequestBody, false);
        }
      }
    }
  }, [
    validatePromotionIsSuccess,
    validatePromotionResponse,
    updatedEstWSPrice
  ]);

  const [saveFunctionType, setSaveFunctionType] = useState<
    SaveFunctionality | undefined
  >(undefined);

  // Complete reset of modal functionality back to base state
  const resetModal = () => {
    toggleUsePrDetails(false);
    setFormData(undefined);
    const emptyEffectiveDate = {
      label: '',
      value: '',
      calendarId: 0,
      submissionDeadline: ''
    };
    setUpdateCreatePR(false);
    setSaveFunctionType(undefined);
    setInputtedDomesticChargesValue(undefined);
    setInputtedPrimeCostValue(undefined);
    setSelectedEffectiveDate(emptyEffectiveDate);
    setInputtedPromotionAmountValue(undefined);
    setInputtedEstWholesalePriceValue(undefined);
    setInputtedDutyPaidCostValue(undefined);
    setInputtedPromotionAmountValue(undefined);
    setEndDate('');
    setWholesalePriceEffectiveDate(undefined);
    setInterfaceWarnings([]);
    setCalculateErrors([]);
    setCalculateWarnings([]);
    setCalendarWarnings([]);
    setUiValidationErrors([]);
    toggleJustificationRequired(false);
    toggleSaveNotCalculate(false);
    setSelectedRequestType(selectedRequestType);
    methods.setValue('effectiveDate', emptyEffectiveDate);
    methods.setValue('estWholesalePrice', '');
    methods.setValue('promotionAmount', '');
    methods.setValue('dutyPaidCost', '');
    methods.setValue('domesticChargesPerCase', '');
    methods.setValue('primeCostPerCase', '');
    methods.setValue('wholesalePriceAsOfEffectiveDate', '');
    toggleDisableForm(false);
    setInvalidCalculateResponse(false);
    methods.clearErrors();
  };

  const handleSaveButton = (saveFunctionRequestType: SaveFunctionality) => {
    if (formErrorsExist) return;
    methods.trigger();
    //Clear any warnings for fresh validation
    setInterfaceWarnings([]);
    setCalculateErrors([]);
    setCalculateWarnings([]);
    setUiValidationErrors([]);
    // setCalendarWarnings([]);
    //first set the saveFunctionType variable to the passed in button type.
    setSaveFunctionType(saveFunctionRequestType);
    toggleSaveNotCalculate(true);
    handleCalculate();
    const updateHiddenFields = hiddenFieldCheck(
      selectedEffectiveDate?.value,
      selectedRequestType.value,
      fullData
    );

    if (updateHiddenFields) {
      setInputtedReason('');
      setInputtedCostingEventComment('');
    }
    setUpdateCreatePR(true);
  };

  //Confirm and Save button functionality - uses the saveFunctionType state set during the handleSaveButton function
  const handleConfirmSave = () => {
    const concatExceptionOtherReason: any = [];
    const isSaveAndNext = saveFunctionType === 'saveAndNext';

    setSaveFunctionType(isSaveAndNext ? 'confirmAndNext' : 'confirmAndSave');

    if (interfaceWarnings.length > 0 || calculateWarnings.length > 0) {
      if (justificationRequired && justification === '') {
        const errorsJustification = [...calculateErrors];
        errorsJustification.push({
          code: modalCopy.uiValidationWarnings.missingJustification.title,
          message:
            modalCopy.uiValidationWarnings.missingJustification.description,
          type: 'ERROR'
        });
        setCalculateErrors(errorsJustification);

        return;
      }

      interfaceWarnings?.forEach((warning) => {
        const { exceptionReason } = warning;
        exceptionReason && concatExceptionOtherReason.push(exceptionReason);
      });

      calculateWarnings?.forEach((warning) => {
        const { code } = warning;
        const { exceptionReason } = warningMessageContent[code];
        exceptionReason && concatExceptionOtherReason.push(exceptionReason);
      });

      setExceptionOtherReason(concatExceptionOtherReason.join('; '));
    }

    if (priceRequestBodyCalculate) {
      const additionalRequestBody = {
        justification: justification,
        other_reason: concatExceptionOtherReason.filter(Boolean).join('; '),
        warning_json: JSON.stringify([
          ...calculateWarnings,
          ...calendarWarnings
        ])
      };
      handleUpdateCreatePR(additionalRequestBody, true);
    }
  };

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess) {
      toggleUsePrDetails(false);
      setFormData(undefined);
      resetModal();
      toggleSaveNotCalculate(false);

      // Save and Close Flow
      if (
        saveFunctionType === 'saveAndClose' ||
        saveFunctionType === 'confirmAndSave'
      ) {
        dispatch(
          setRecentPriceRequestType({
            label: 'Price Change (PC)',
            value: 'Supplier Price Change'
          })
        );
        dispatch(setPrevSaveFunction(undefined));
        onRequestClose();
        return;
      }

      // Save and Next flow
      setShowSuccessBanner(true);
      toggleUsePrDetails(false);
      setFormData(undefined);

      COST_FIELDS.forEach((fieldName) => {
        setCostFieldValue(fieldName, undefined);
      });
      dispatch(setRecentPriceRequestType(selectedRequestType));
      dispatch(setPrevSaveFunction(saveFunctionType));
      setExceptionOtherReason('');

      // Clear the success banner and the number of products added after 3 seconds
      setTimeout(() => {
        setShowSuccessBanner(false);
      }, 3000);
    }
  }, [createIsSuccess, updateIsSuccess]);

  // Go Back button functionaliity, clears warnings but doesnt clear/reset the input values
  const handleClearModal = () => {
    setShowSuccessBanner(false);
    setCalculateErrors([]);
    setUiValidationErrors([]);
    setCalculateWarnings([]);
    toggleJustificationRequired(false);
    toggleSaveNotCalculate(false);
    setInterfaceWarnings([]);
    setExceptionOtherReason('');
    setCalendarWarnings([]);
    toggleDisableForm(false);
    setUpdateCreatePR(false);
    setUpdatedEstWSPrice(false);
  };

  // Handle setting generic error for CRUD and Calculate Operations
  useEffect(() => {
    if (
      createIsError ||
      updateIsError ||
      createDraftError ||
      validatePromotionIsError ||
      isErrorCalculate
    ) {
      const errorMessage =
        createIsError &&
        createDraftError != undefined &&
        'data' in createDraftError &&
        createDraftError?.status == 404
          ? 'The pricing agent assignment for this product has changed and the submitting agency is no longer authorized to price this product.'
          : modalCopy.apiValidation.priceChange.errors.generic.description;

      setCalculateErrors([
        {
          code: 'error',
          message: errorMessage,
          type: 'ERROR'
        }
      ]);
    }
  }, [
    createIsError,
    updateIsError,
    createDraftError,
    isErrorCalculate,
    validatePromotionIsError
  ]);

  // creating booleans to determine what warnings (if any) should be shown to the user
  // Warnings shoud never show if errors exist
  // whether that be form input errors or errors returned from the calculate API
  const showUIWarnings =
    !formErrorsExist &&
    calculateErrors.length === 0 &&
    interfaceWarnings.length > 0;
  const showCalculateWarnings =
    !formErrorsExist &&
    calculateErrors.length === 0 &&
    calculateWarnings.length > 0;
  const showCalendarWarnings =
    !formErrorsExist &&
    calendarWarnings.length > 0 &&
    saveNotCalculate &&
    calculateErrors.length === 0;
  const hasErrors =
    calculateErrorsExist || formErrorsExist || uiValidationErrors.length > 0;
  const showWarnings =
    !hasErrors &&
    (isSuccessCalculate || validatePromotionIsSuccess) &&
    (showUIWarnings || showCalculateWarnings || showCalendarWarnings);

  return (
    <>
      {(loadingState ||
        createLoading ||
        deleteSingleIsLoading ||
        validatePromotionLoading ||
        isLoadingCalculate ||
        updateLoading) && <LoadingOverlay />}
      <div className={styles.addNewModalContainer}>
        <Heading element="h3" heading3 productBlockTitle>
          {modalCopy.title}
        </Heading>
        <div className={styles.addNewModalWrapper}>
          <div className={styles.productDetailsWrapper}>
            <div className={styles.row}>
              <Text element="p" bold>
                {sku}
              </Text>
              <Pill type={status_code}>{productStatusSearch(status_code)}</Pill>
            </div>
            <Heading element="h3" heading3 action>
              {product_name}
            </Heading>
            <div className={styles.row}>
              <Text element="p" small>
                {categorySearch(hierachyData, category)}
              </Text>
            </div>
            <div className={styles.row}>
              <Text element="p" small>
                {alcohol_percentage ? `${alcohol_percentage}%` : ''}
              </Text>
              <Text element="p" small>
                {unit_description}
              </Text>
            </div>
          </div>
          <div className={styles.formWrapper}>
            <FormProvider {...methods}>
              <form className={styles.filterContainer}>
                {/* Show various banners based on if there is a form error or the pr was successfully saved*/}
                {modalFormErrors &&
                  Object.values(modalFormErrors).map(
                    (error: any, idx: number) => {
                      let errorType;
                      if (error.type) {
                        errorType = error.type;
                      } else {
                        errorType = error.value.type;
                      }
                      const errorCopy = errorMapping(
                        errorType,
                        modalCopy.validations
                      );
                      return (
                        <Banner
                          key={`${idx}-error`}
                          type="error"
                          icon={<ErrorAlert />}
                          title={errorCopy.title}
                          description={errorCopy.description}
                        />
                      );
                    }
                  )}
                {uiValidationErrors &&
                  uiValidationErrors.map((error, index) => {
                    return (
                      <Banner
                        key={`${index}-error`}
                        type="error"
                        icon={<ExclamationPoint />}
                        title={error.title}
                        description={error.description}
                      />
                    );
                  })}
                {calculateErrorsExist &&
                  calculateErrors?.map((error, index) => {
                    const {
                      title: defaultTitle,
                      description: defaultDescription
                    } = errorMessageContent[error?.code] || {};

                    const title = defaultTitle || 'An error has occurred';
                    let description = defaultDescription || error.message;

                    if (description.includes('{pricePerSU}')) {
                      description = description.replaceAll(
                        '{pricePerSU}',
                        fixedDecimalPlaces(floorPrice, 2).toString()
                      );
                    }

                    return (
                      <Banner
                        type="error"
                        icon={<ExclamationPoint />}
                        title={title}
                        description={description}
                        key={`${index}-error`}
                      />
                    );
                  })}
                {showSuccessBanner && (
                  <Banner
                    type="success"
                    icon={<Checkmark />}
                    title="Price Request Saved"
                  />
                )}
                <div className={styles.formRow}>
                  <SelectInput
                    id="requestType"
                    options={modalCopy.requestOptions}
                    label="Request Type"
                    ariaLabelledBy="requestType"
                    onChange={(event: SelectInputType) => {
                      handleSelectInputChange(event, 'requestType');
                    }}
                    value={selectedRequestType}
                    required
                    disabled={disableForm}
                    columnWidth="double"
                  />
                  {/* If Request Type = "Price Change",  List of available effective dates will be from LDB price change calendar [Get Pricing Calendar API type = “PRICE”]*/}
                  {/* If Request Type = “Wholesale Price Promotion (WPP)”:,  List of available effective dates will be from LDB WPP calendar [Get Pricing Calendar API type = “PROMOTION”]*/}
                  {/* One specific date per month each of which is associated with one Submission Deadline Date and one End Date*/}
                  {/*Future Dates Only: Only Effective Dates that have not yet passed are available for selection (for any request type)*/}
                  <DateSelectMenu
                    id="effectiveDate"
                    label="Effective Date"
                    ariaLabelledBy="effectiveDate"
                    options={
                      wholesalePromotionRequest
                        ? promotionSelectOptions
                        : priceSelectOptions
                    }
                    onChange={(event: SelectInputType) => {
                      handleSelectInputChange(event, 'effectiveDate');
                    }}
                    value={selectedEffectiveDate}
                    required
                    disabled={disableForm}
                    columnWidth="single"
                  />
                  {/*read only End Date should only be displayed  if the Request Type is WPP and an Effective Date has been selected */}
                  {wholesalePromotionRequest &&
                    formValues.effectiveDate.value.length > 0 && (
                      <TextInput
                        type="text"
                        id="endDate"
                        label="End Date"
                        value={endDate}
                        readOnly
                        columnWidth="single"
                        disabled={disableForm}
                      />
                    )}
                </div>
                <div className={styles.formRow}>
                  {/* PRIME COST/ CASE - CURRENCY INPUT */}
                  {/*Only displayed if Request Type === "Price Change" AND 
              (Product = “Import”) or (Product = “Domestic” AND (not on the list of the products requiring Duty Paid cost as indicated by flag in product data API))
              */}
                  {/*Field validation: Only accept numbers, should allow values up to 2 decimaly places only*/}
                  {/*If Current value exists, pass it into helper text*/}
                  {!wholesalePromotionRequest &&
                  (priceChangeImportProduct ||
                    priceChangeDomesticNotDPCProduct) ? (
                    <CurrencyInput
                      label="Prime Cost/ Case"
                      aria-labelledby="primeCostPerCase"
                      id="primeCostPerCase"
                      columnWidth="single"
                      type="number"
                      cost
                      required
                      placeholder="$"
                      helperText={
                        prime_cost_per_case
                          ? `Current: $${fixedDecimalPlaces(
                              prime_cost_per_case,
                              2
                            )}`
                          : ''
                      }
                      helper
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleTextInputChange(event, 'primeCostPerCase')
                      }
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputBlur(event, 'primeCostPerCase');
                      }}
                      value={inputtedPrimeCostValue?.toString()}
                      disabled={disableForm}
                    />
                  ) : null}
                  {/* DOMESTIC CHARGES/ CASE - CURRENCY INPUT */}
                  {/* Field is ONLY displayed and available for input if product is Import  */}
                  {/*Field validation: Only accept numbers, should allow values up to 2 decimaly places only*/}
                  {/*If Current value exists, pass it into helper text*/}
                  {priceChangeImportProduct && (
                    <CurrencyInput
                      label="Domestic Charges / Case"
                      aria-labelledby="domesticChargesPerCase"
                      id="domesticChargesPerCase"
                      columnWidth="single"
                      type="number"
                      cost
                      placeholder="$"
                      helperText={
                        domestic_charges_per_case != null
                          ? `Current: $${fixedDecimalPlaces(
                              domestic_charges_per_case,
                              2
                            )}`
                          : ''
                      }
                      helper
                      value={inputtedDomesticChargesValue?.toString()}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleTextInputChange(event, 'domesticChargesPerCase')
                      }
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputBlur(event, 'domesticChargesPerCase');
                      }}
                      disabled={disableForm}
                    />
                  )}
                  {/* DUTY PAID COST/ CASE - CURRENCY INPUT */}
                  {/* Field is only displayed and available for input if product is Domestic AND on the list of the products requiring Duty Paid Cost (indicated by flag in product data API)  */}
                  {/*Field validation: Only accept numbers, should allow values up to 2 decimaly places only*/}
                  {/*If Current value exists, pass it into helper text*/}
                  {priceChangeDomesticDPCProduct && (
                    <CurrencyInput
                      label="Duty Paid Cost/ Case"
                      aria-labelledby="dutyPaidCost"
                      id="dutyPaidCost"
                      columnWidth="single"
                      type="number"
                      cost
                      placeholder="$"
                      helperText={
                        duty_paid_cost_per_case
                          ? `Current: $${fixedDecimalPlaces(
                              duty_paid_cost_per_case,
                              2
                            )}`
                          : ''
                      }
                      helper
                      value={inputtedDutyPaidCostValue?.toString()}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleTextInputChange(event, 'dutyPaidCost')
                      }
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputBlur(event, 'dutyPaidCost');
                      }}
                      disabled={disableForm}
                    />
                  )}
                  {/* WHOLESALE PRICE AS OF THE EFFECTIVE DATE - CURRENCY INPUT */}
                  {/* Field is only displayed if Request Type === WPP */}
                  {/* Read Only field - value retrieved from API Call */}
                  {wholesalePromotionRequest && (
                    <div>
                      <CurrencyInput
                        label="Wholesale Price / Selling Unit as of the Effective Date" //EPP_1108 change from "Wholesale Price as of the Effective Date"
                        aria-labelledby="wholesalePriceAsOfEffectiveDate"
                        id="wholesalePriceAsOfEffectiveDate"
                        columnWidth="single"
                        type="number"
                        readOnly
                        helper
                        helperText={
                          regular_price_per_su
                            ? `Current: $${fixedDecimalPlaces(
                                regular_price_per_su,
                                2
                              )}`
                            : ''
                        }
                        value={wholesalePriceEffectiveDate}
                        placeholder="$"
                        disabled={disableForm}
                      />
                      <ReactTooltip
                        anchorId="wholesalePriceAsOfEffectiveDate"
                        aria-live="polite"
                        place="bottom"
                      >
                        <Text element="p" small>
                          {
                            modalCopy.toolTips.wholesalePriceAsOfEffectiveDate //'The Wholesale Price in this field is exclusive of any active promotions'
                          }
                        </Text>
                      </ReactTooltip>
                    </div>
                  )}
                  {/*PROMOTION AMOUNT - CURRENCY INPUT */}
                  {/* Displayed if Request Type = “WPP” */}
                  {/* Field validation: Only accept numbers, should allow values up to 2 decimaly places only */}
                  {wholesalePromotionRequest && (
                    <CurrencyInput
                      label="Promotion Amount"
                      aria-labelledby="promotionAmount"
                      id="promotionAmount"
                      columnWidth="single"
                      type="number"
                      cost
                      placeholder="$"
                      helper
                      helperText={
                        promotion_amount_per_su
                          ? `Current: $${fixedDecimalPlaces(
                              promotion_amount_per_su,
                              2
                            )}`
                          : ''
                      }
                      value={inputtedPromotionAmountValue?.toString()}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleTextInputChange(event, 'promotionAmount')
                      }
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                        handleTextInputBlur(event, 'promotionAmount');
                      }}
                      disabled={disableForm}
                    />
                  )}
                  {/* EST. WHOLESALE PRICE / SELLING UNIT OR PROMOTIONAL WS PRICE (IF WPP) - CURRENCY INPUT */}
                  {/* IF Request Type === "Price Change": Displayed as an input field, Field validation: Only accept numbers, should allow values up to 2 decimaly places only, if Current value exists, pass it into helper text */}
                  <CurrencyInput
                    label="Est. WS Price/ Selling Unit (Promo WS Price if WPP)"
                    aria-labelledby="estWholesalePrice"
                    id="estWholesalePrice"
                    columnWidth="single"
                    type="number"
                    cost
                    placeholder="$"
                    helperText={
                      final_price_per_su
                        ? `Current: $${fixedDecimalPlaces(
                            final_price_per_su,
                            2
                          )}`
                        : ''
                    }
                    helper
                    value={inputtedEstWholesalePriceValue?.toString()}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleTextInputChange(event, 'estWholesalePrice')
                    }
                    onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                      handleTextInputBlur(event, 'estWholesalePrice');
                    }}
                    disabled={
                      selectedRequestType.value ===
                        'Wholesale Price Promotion' ||
                      selectedRequestType.value ===
                        'Wholesale Price Promotion (WPP)' ||
                      disableForm
                    }
                  />
                  {/*HIDDEN FORM INPUTS - only used if no PR of the same type and Effective Date in Queued status exists */}
                  {
                    <>
                      <TextInput
                        type="hidden"
                        label="Reason"
                        id="reason"
                        value={decodeSanitizedValue(inputtedReason)}
                      />
                      <TextInput
                        type="hidden"
                        label="Costing Event Comment"
                        id="costingEventComment"
                        value={decodeSanitizedValue(
                          inputtedCostingEventComment
                        )}
                      />
                    </>
                  }
                  <Button
                    secondary
                    icon={<Calculator />}
                    iconLeft
                    className={styles.buttonCalculate}
                    disabled={
                      invalidCalculateResponse ||
                      formErrorsExist ||
                      disableForm ||
                      disableCalculateBtn
                    }
                    ariaLabel={'Calculate prices'}
                    onClick={() => handleCalculateButtonClick()}
                  />
                </div>
                {invalidCalculateResponse && (
                  <div className={styles.formRow}>
                    <Text element="p" small action bold>
                      {
                        modalCopy.uiValidationErrors.invalidCalculateResponse
                          .description
                      }
                    </Text>
                  </div>
                )}
                <div className={styles.formRowCopy}>
                  <Text element="p" small>
                    {modalCopy.note}
                  </Text>
                </div>
                {showWarnings ? (
                  <div className={styles.buttonWarnings}>
                    <div className={styles.warningContainer}>
                      {showCalculateWarnings &&
                        calculateWarnings &&
                        calculateWarnings.map(
                          (warning: ValidationMessage, idx: number) => {
                            const code = warning.code;
                            if (!warningMessageContent[code]) return null;
                            let description = warningMessageContent[code]
                              ?.description
                              ? warningMessageContent[code].description
                              : warning.message;

                            if (description.includes('{pricePerSU}')) {
                              description = description.replaceAll(
                                '{pricePerSU}',
                                fixedDecimalPlaces(floorPrice, 2).toString()
                              );
                            }

                            return (
                              <Banner
                                key={`${idx}-warning`}
                                type="warning"
                                icon={<ExclamationPoint />}
                                title={warning?.title || undefined}
                                description={description}
                              />
                            );
                          }
                        )}
                      {calculateErrors.length === 0 &&
                        !formErrorsExist &&
                        showUIWarnings &&
                        interfaceWarnings.map(
                          (
                            warning: { title?: string; description: string },
                            idx: number
                          ) => {
                            return (
                              <Banner
                                type="warning"
                                icon={<ExclamationPoint />}
                                title={warning?.title || undefined}
                                description={warning.description}
                                key={`${idx}-warning`}
                              />
                            );
                          }
                        )}
                      {justificationRequired && !hasErrors && (
                        <TextArea
                          id="addNewPRJustification"
                          label={modalCopy.justificationRequired.label}
                          required
                          maxLength={2500}
                          value={justification}
                          onChange={(
                            event: ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleTextInputChange(
                              event,
                              'addNewPRJustification'
                            );
                          }}
                        />
                      )}
                    </div>
                    <div className={styles.buttonContainer}>
                      <Button
                        primary
                        onClick={() => handleConfirmSave()}
                        disabled={justificationRequired && justification === ''}
                      >
                        Confirm & Save
                      </Button>
                      <Button link onClick={() => handleClearModal()}>
                        Go Back
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className={styles.buttonContainer}>
                    <Button
                      primary
                      onClick={() => handleSaveButton('saveAndClose')}
                      disabled={
                        formErrorsExist ||
                        disableCalculateBtn ||
                        calculateErrorsExist ||
                        uiValidationErrors.length > 0
                      }
                    >
                      {modalCopy.buttons[0].label}
                    </Button>
                    <Button
                      secondary
                      onClick={() => handleSaveButton('saveAndNext')}
                      disabled={
                        formErrorsExist ||
                        disableCalculateBtn ||
                        calculateErrorsExist ||
                        uiValidationErrors.length > 0
                      }
                    >
                      {modalCopy.buttons[1].label}
                    </Button>
                    {prDetails && prDetails.id && usePrDetails ? (
                      <>
                        {(calculateErrorsExist ||
                          uiValidationErrors.length > 0) && (
                          <Button
                            link
                            onClick={() => handleClearModal()}
                            className={styles.buttonErrors}
                          >
                            Go Back
                          </Button>
                        )}
                        <Button
                          link
                          iconLeft
                          icon={<GarbageCan />}
                          onClick={() =>
                            deleteSinglePriceRequest(deleteSinglePayload)
                          }
                          className={styles.deleteButton}
                        >
                          {modalCopy.buttons[5].label}
                        </Button>
                      </>
                    ) : calculateErrors.length === 0 && !formErrorsExist ? (
                      <Button
                        link
                        onClick={() => {
                          dispatch(setRefreshDrafts(true));
                          onRequestClose();
                        }}
                        className={styles.discardButton}
                      >
                        {modalCopy.buttons[2].label}
                      </Button>
                    ) : calculateErrorsExist ? (
                      <Button
                        link
                        onClick={() => handleClearModal()}
                        className={styles.buttonErrors}
                      >
                        Go Back
                      </Button>
                    ) : null}
                  </div>
                )}
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewPRNonLDBModal;
