import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { useGetPriceRequestsLogsQuery } from '../../services/PriceRequest';

import { getSessionToken, UserTypeEnum } from '../../helpers';

import Button from '../Button';
import DownloadReverse from '../Icons/DownloadReverse';

const DownloadLogButton = () => {
  const token = getSessionToken();
  const { selectedPriceRequestID } = useSelector(
    (state: RootState) => state.priceRequest
  );

  const { formStatus, priceRequest } = useSelector(
    (state: RootState) => state.priceRequestDetails
  );

  const { isLoading, isError, data, refetch } = useGetPriceRequestsLogsQuery(
    {
      token,
      id: selectedPriceRequestID || ''
    },
    {
      refetchOnMountOrArgChange: true,
      skip: formStatus.user !== UserTypeEnum.INTERNAL
    }
  );

  const handleDownloadClick = () => {
    if (data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `PriceRequest_Audit_Logs_${selectedPriceRequestID}.csv`;
      link.click();
      URL.revokeObjectURL(downloadUrl);
    }
  };

  useEffect(() => {
    refetch();
  }, [priceRequest.status, refetch]);

  if (isError) {
    console.error('An error occurred getting Price Request Audit Log');
    return null;
  }

  return (
    <Button
      secondary
      iconLeft
      icon={<DownloadReverse />}
      onClick={handleDownloadClick}
      disabled={isLoading}
    >
      {isLoading ? 'Loading Audit Log' : 'Download Audit Log'}
    </Button>
  );
};

export default DownloadLogButton;
