import React from 'react';

import Banner from '../../components/Banner';
import ExclamationPoint from '../../components/Icons/ExclamationPoint';

import styles from './FallbackBanner.module.scss';
import copy from '../../config/translations/en-CA.json';
const componentCopy = copy.errorBoundary;

const FallbackBanner = () => {
  return (
    <div className={styles.fallbackContainer}>
      <Banner
        type="error"
        icon={<ExclamationPoint />}
        title={componentCopy.title}
        description={componentCopy.description}
      />
    </div>
  );
};

export default FallbackBanner;
