//TODO refactor to object literal for better performance
export const sortColumnsByHeader = (columnId: string) => {
  switch (columnId) {
    case 'productName':
      return 'product_name';
    case 'sku':
      return 'sku';
    case 'category':
      return 'category';
    case 'subCategory':
      return 'sub_category';
    case 'class':
      return 'class';
    case 'statusCode':
      return 'status_code';
    case 'countryOfExport':
      return 'country_of_export';
    case 'agentVendor':
      return 'agent_vendor_id';
    case 'supplierVendor':
      return 'supplier_vendor_id';
    case 'finalPricePerCase':
      return 'final_price_per_case';
    case 'unitDescription':
      return 'unit_description';
    case 'productSku.product_name':
      return 'productSku_product_name';
    case 'productSku.unit_description':
      return 'productSku_unit_description';
    case 'status':
      return 'status';
    case 'productSku.agentVendor':
      return 'agent_vendor_id';
    case 'productSku.supplierVendor':
      return 'supplier_vendor_id';
    case 'type':
      return 'type';
    case 'final_effective_date':
      return 'final_effective_date';
    case 'final_submission_deadline':
      return 'final_submission_deadline';
    case 'prime_cost_per_case':
      return 'prime_cost_per_case';
    case 'domestic_charges_per_case':
      return 'domestic_charges_per_case';
    case 'duty_paid_cost_per_case':
      return 'duty_paid_cost_per_case';
    case 'promotion_amount_per_su':
      return 'promotion_amount_per_su';
    case 'estimated_price_per_su':
      return 'estimated_price_per_su';
    case 'reason':
      return 'reason';
    case 'justification_for_exception':
      return 'justification_for_exception';
    case 'row':
      return 'row';
    case 'product_name':
      return 'product_name';
    case 'request_type':
      return 'request_type';
    case 'effective_date':
      return 'effective_date';
    case 'currency_label':
      return 'currency_label';
    case 'currency_effective_date':
      return 'currency_effective_date';
    case 'fx_rate':
      return 'fx_rate';
    case 'foreign_prime':
      return 'foreign_prime';
    case 'error_message':
      return 'error_message';
    case 'agentId':
      return 'agent_vendor_id';
    case 'price_per_su':
      return 'price_per_su';
    case 'other_reason':
      return 'other_reason';
    case 'finalPricePerSU':
      return 'final_price_per_su';
    default:
      return 'sku';
  }
};
