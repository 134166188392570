import React from 'react';
import classNames from 'classnames';

import ResourceCardLink from './ResourceCardLink';
import styles from './index.module.scss';
import Heading from '../Heading';
import { render } from '@testing-library/react';

export type ResourceType = {
  fileName: string;
  fileURL: string;
  tagColor?: string;
  tag?: string | null;
};

type ResourceGridProps = {
  resources?: Array<ResourceType>;
  heading?: string | any;
  children?: React.ReactNode;
  twoCols?: boolean;
  columnA?: Array<ResourceType>;
  columnB?: Array<ResourceType>;
};

const getFileValues = (fileName: any) => {
  const splitFileNameArr = fileName.split('_');

  // Sequence value must be numeric.
  // Therefore...
  if (isNaN(splitFileNameArr[0])) {
    // if the first field is not numeric
    // then we assume it is the tag and the sequence value will default to zero.
    // In order to do so, we need to put a zero value in the first position of the array
    splitFileNameArr.unshift('0');
  }

  return {
    sequence: splitFileNameArr[0],
    tag: splitFileNameArr[1],
    title: splitFileNameArr[2],
    description: splitFileNameArr[3]?.includes('.')
      ? splitFileNameArr[3].split('.')[0]
      : splitFileNameArr[3]
  };
};

const renderResources = (resource: ResourceType) => {
  const { sequence, tag, title, description } = getFileValues(
    resource.fileName
  );
  return (
    <ResourceCardLink
      key={sequence + title}
      tag={resource.tag ? resource.tag : tag}
      title={title}
      tagColor={resource.tagColor}
      description={description}
      link={resource.fileURL}
      isDownload={true}
    />
  );
};

const ResourceGrid = ({
  resources,
  heading,
  children,
  twoCols,
  columnA,
  columnB
}: ResourceGridProps) => {
  return (
    <div className={styles.ResourceGridContainer}>
      {heading && (
        <Heading element="h5" heading5 className={styles.resourceHeading}>
          {heading}
        </Heading>
      )}
      <div
        className={classNames(styles.ResourceGrid, {
          [styles.gridContainer_2cols]: twoCols || false
        })}
      >
        {resources &&
          resources.length > 0 &&
          resources.map((resource) => renderResources(resource))}
        {twoCols && (
          <>
            {columnA && (
              <div className={styles.gridContainer_2cols_column}>
                {columnA.map((resource) => renderResources(resource))}
              </div>
            )}
            {columnB && (
              <div className={styles.gridContainer_2cols_column}>
                {columnB.map((resource) => renderResources(resource))}
              </div>
            )}
          </>
        )}
        {children}
      </div>
    </div>
  );
};

export default ResourceGrid;
