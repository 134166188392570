import React from 'react';
import Button from '../Button/index';
import { useAuth } from '../../contexts/AuthenticationProvider';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
const SignInButton = () => {
  const auth = useAuth();

  return (
    <Button secondary onClick={() => auth.handleLogin()}>
      Sign In
    </Button>
  );
};

export default SignInButton;
