import React, { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

import {
  handleKeyDown,
  handlePaste,
  preventIllegalCharacterHandlePaste,
  preventIllegalCharactersHandleKeyDown
} from '../../helpers';

import Text from '../Text';
import Label from '../Label';

import styles from './TextInput.module.scss';
import { ColumnWidthType } from '../../types';

type PropsType = {
  label?: string;
  type?: string;
  placeholder?: string;
  id: string;
  helperText?: string;
  required?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | readonly string[] | undefined;
  message?: string;
  disabled?: boolean;
  helper?: boolean;
  ariaLabelledBy?: string;
  tooltip?: string;
  readOnly?: boolean;
  cost?: boolean;
  maxLengthCharacter?: number;
  columnWidth?: ColumnWidthType;
  labelDisabled?: boolean;
};

const TextInput = ({
  label,
  type,
  placeholder,
  id,
  helperText,
  required,
  medium,
  large,
  xlarge,
  message,
  disabled,
  value,
  helper,
  onChange,
  ariaLabelledBy,
  tooltip,
  readOnly,
  cost,
  maxLengthCharacter,
  columnWidth,
  labelDisabled
}: PropsType) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const name = id;
  const isNumber = type === 'number';

  // Adding the following code to disable scroll on number inputs
  useEffect(() => {
    const numberInputs = document.querySelectorAll(`#${id}[type="number"]`);

    const preventScroll = (event: Event) => {
      event.preventDefault();
    };

    numberInputs.forEach((input) => {
      input.addEventListener('wheel', preventScroll, { passive: false });
    });

    return () => {
      numberInputs.forEach((input) => {
        input.removeEventListener('wheel', preventScroll);
      });
    };
  }, [id]);

  //Adding the below function to ensure only up to 18 characters inputted for number inputs
  const maxLengthCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, maxLength } = event.target;
    if (value.length > maxLength) {
      event.target.value = value.slice(0, maxLength);
    }
  };

  //Adding the below function to split cost field labels into two lines as per hifi designs
  const breakLine = (labelCopy: string) => {
    if (labelCopy.includes('/ Case')) {
      const parts = labelCopy.split('/ Case');
      return (
        <span>
          {parts[0]}
          <br />
          /Case
        </span>
      );
    } else if (labelCopy.includes('Promotion Amount')) {
      const parts = labelCopy.split('Amount');
      return (
        <span>
          {parts[0]}
          <br />
          Amount
        </span>
      );
    } else {
      return labelCopy;
    }
  };

  return (
    <div
      className={classNames(styles.textInputContainer, {
        [styles.textInputContainerMedium]: medium,
        [styles.textInputContainerLarge]: large,
        [styles.textInputContainerXlarge]: xlarge,
        [styles.textInputContainerHidden]: type === 'hidden',
        [styles.textInputContainerSingleColumn]: columnWidth === 'single',
        [styles.textInputContainerDoubleColumn]: columnWidth === 'double'
      })}
    >
      {label && (
        <Label
          className={styles.label}
          htmlFor={id}
          error={!!errors[id]}
          tooltip={tooltip}
          disabled={labelDisabled}
        >
          {breakLine(label)}
        </Label>
      )}
      <input
        type={type || 'text'}
        id={id}
        placeholder={placeholder}
        className={classNames(styles.input, {
          [styles.inputError]: errors[id],
          [styles.inputDisabled]: disabled || readOnly
        })}
        disabled={disabled}
        value={value}
        {...register(name, {
          ...(required ? { required: 'This field is required' } : {})
        })}
        onChange={onChange}
        autoComplete="off"
        aria-labelledby={ariaLabelledBy}
        readOnly={readOnly}
        step="0.01"
        onKeyDown={
          isNumber
            ? handleKeyDown
            : id === 'email'
            ? undefined
            : preventIllegalCharactersHandleKeyDown
        }
        onPaste={
          isNumber
            ? handlePaste
            : id === 'email'
            ? undefined
            : preventIllegalCharacterHandlePaste
        }
        inputMode={isNumber ? 'numeric' : undefined}
        maxLength={
          isNumber && cost
            ? 10
            : maxLengthCharacter !== undefined
            ? maxLengthCharacter
            : undefined
        }
        onInput={isNumber && cost ? maxLengthCheck : undefined}
      />
      {helper && (
        <div className={styles.textContainer}>
          {errors[id] ? (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <Text element="p" small className={styles.helperTextError}>
                  {message}
                </Text>
              )}
            />
          ) : helperText ? (
            <Text element="p" small className={styles.helperText}>
              {helperText}
            </Text>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default TextInput;
