import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTable, useFilters, useRowSelect } from 'react-table';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  setRefreshDrafts,
  setOpenProductDetailsModal,
  setSku,
  setPrevSaveFunction
} from '../../reducers/PriceRequestSlice';
import { updateSkuCardLocation } from '../../helpers';
import { useGetPriceRequestsListQuery } from '../../services/PriceRequest';

import {
  PriceRequest,
  WorkingGroupPrDetails,
  CalendarDate,
  PriceRequestType,
  LocationType
} from '../../types';

import {
  mapPrStatus,
  DRAFT,
  currencyCellText,
  getSessionToken,
  PriceRequestStatusEnum,
  UserTypeEnum,
  dateFormat,
  categorySearch,
  labelString,
  tooltipString,
  getPSTDate,
  decodeSanitizedValue
} from '../../helpers';

import Card from '../Card';
import AdditionSign from '../Icons/AdditionSign';
import Button from '../Button';
import Pill from '../Pill';
import Text from '../Text';
import Heading from '../Heading';
import ChevronRight from '../Icons/ChevronRight';
import ChevronUp from '../Icons/ChevronUp';
import TableTooltip from '../TableTooltip';
import Modal from '../Modal';
import AddNewPRNonLDBModal from '../Modal/AddNewPRNonLDBModal';
import AddNewPRLDBModal from '../Modal/AddNewPRLDBModal';

import styles from './ProductCard.module.scss';

type PropsType = {
  product: {
    product_name: string;
    unit_description: string;
    sku: number;
    status_code: string;
    priceRequest: PriceRequestType[];
    prime_cost_per_case?: number;
    domestic_charges_per_case?: number;
    duty_paid_cost_per_case?: number;
    price_effective_date?: string;
    price_end_date?: string;
    category: string;
    alcohol_percentage: number;
    country_of_export: string;
    price_type?: string;
    promo_type?: string;
    createdAt?: string;
    currency_id: string;
    regular_price_per_su: number;
    promotion_end_date?: string;
    promo_value_per_su?: number;
    final_price_per_su?: number;
  };
  user: string;
  dates: CalendarDate[];
};

type CellInfo = {
  value: string;
  row: any;
};

type RowType = {
  type: string;
  createdAt: string;
  domestic_charges_per_case: number;
  duty_paid_cost_per_case: number;
  price_effective_date: string;
  effective_date: string;
  effective_end_date: string;
  price_type: string;
  prime_cost_per_case: number;
  promo_type: string;
  status: string;
  calendarId: CalendarDate;
  final_price_per_su: number;
};

const ProductCard = ({ product, user, dates }: PropsType) => {
  const dispatch = useDispatch();

  const {
    sku,
    product_name,
    unit_description,
    status_code,
    category,
    alcohol_percentage
  } = product;

  const [showModal, setShowModal] = useState<number | string | undefined>(
    undefined
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentAndDrafts, setCurrentAndDrafts] = useState<any[]>([]);
  const [draftRow, setDraftRow] = useState<WorkingGroupPrDetails | undefined>(
    undefined
  );
  const [showAllPriceRequests, setShowAllPriceRequests] = useState(false);
  const [completeData, setCompleteData] = useState<
    PriceRequestType[] | PriceRequest[]
  >([]);
  const [currentPriceRequestData, setCurrentPriceRequestData] =
    useState<PriceRequestType>({});

  const handleCloseModal = () => {
    dispatch(setRefreshDrafts(true));
    dispatch(setPrevSaveFunction(undefined));
    setShowModal(undefined);
  };

  const handleOpenModal = (sku: number | string | undefined) => {
    updateSkuCardLocation(sku);
    setShowModal(sku);
  };
  //create location state used to determine if the tooltips should render below or above the
  // text based on mouse location in the screen
  const [location, setLocation] = useState<LocationType>('bottom');
  // this callback will be passed into the tooltip component to bring the state from child to parent
  const callback = (state: LocationType) => {
    setLocation(state);
  };

  // By default, the table should only show the price requests that are in Current or Draft status.
  // An additional API call will be made if the user clicks the Show All Future Effective Dates button on the Product Card,
  // That API call will bring in all other types of PR
  useEffect(() => {
    const defaultTablePriceRequests: PriceRequestType[] = [];
    // default state is the current price request followed by the draft prs
    // current PR is the one returned by the /price-request/draft-by-products api call that takes place on the priceRequestPage, the draft prs are any prs held within the priceRequest array on that api call for each sku
    //destructure the current price request from the product to get only the applicable values
    const {
      prime_cost_per_case,
      domestic_charges_per_case,
      duty_paid_cost_per_case,
      price_effective_date,
      price_end_date,
      promo_type,
      createdAt,
      price_type,
      regular_price_per_su,
      promotion_end_date,
      promo_value_per_su,
      final_price_per_su
    } = product;

    //store these variables in a new current price request object
    const currentPriceRequest = {
      id: 0,
      prime_cost_per_case,
      domestic_charges_per_case,
      duty_paid_cost_per_case,
      price_effective_date,
      price_end_date,
      status: 'current',
      promo_type,
      createdAt,
      price_type,
      regular_price_per_su,
      promotion_end_date,
      promotion_amount_per_su: promo_value_per_su,
      final_price_per_su
    };
    setCurrentPriceRequestData(currentPriceRequest);

    //check to confirm the current price object doesn't exist on defaultTablePriceRequests and only add it if it doesn't, this limits the current price from readding on rerenders
    const findPriceObjectOnArray = (currentPrice: any) => {
      const x = defaultTablePriceRequests.findIndex(function (item) {
        return (
          item.createdAt === currentPrice.createdAt &&
          item.effective_date === currentPrice.price_effective_date
        );
      });
      if (x === -1) {
        // if it doesn't exist add this new current pr object to the front of the defaultTablePriceRequestArray
        return defaultTablePriceRequests.unshift(currentPrice);
      } else {
        return defaultTablePriceRequests;
      }
    };
    findPriceObjectOnArray(currentPriceRequest);

    //set the table data to this new array of objects, adding the draft price requests if they exist
    if (product.priceRequest.length > 0) {
      setTableData(defaultTablePriceRequests.concat(product.priceRequest));
      setCurrentAndDrafts(
        defaultTablePriceRequests.concat(product.priceRequest)
      );
    } else {
      setTableData(defaultTablePriceRequests);
      setCurrentAndDrafts(defaultTablePriceRequests);
    }
  }, [product.priceRequest, product]);

  //get the hierarchy data saved to sessionStorage to retrieve the correct category description using the categorySearch helper below in the pill/badge in the product details (left hand side of the modal card. ie turns a numeric category_id into Wine)
  const hierachyData = sessionStorage.getItem('hierachyData');

  // Compare and sort price requests in product card so the current product status if first and then by effective date.
  const comparePriceRequests = (
    a: PriceRequestType,
    b: PriceRequestType
  ): number => {
    // Sort 'current' status price request first
    if (a.status === 'current' && b.status !== 'current') {
      return -1;
    } else if (a.status !== 'current' && b.status === 'current') {
      return 1;
    }
    // Convert effective dates to Date objects
    const dateA = a.final_effective_date
      ? new Date(a.final_effective_date)
      : null;
    const dateB = b.final_effective_date
      ? new Date(b.final_effective_date)
      : null;

    // Sort by effective date
    if (dateA && dateB) {
      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      }
    } else if (dateA && !dateB) {
      return -1;
    } else if (!dateA && dateB) {
      return 1;
    }

    return 0; // Return 0 if both dates are null or undefined
  };

  const token = getSessionToken();

  const queryParams = `filters[0][field]=sku&filters[0][operator]==&filters[0][value]=${sku}&limit=1000`;

  const {
    data: getPriceRequestsQueryData,
    isSuccess,
    refetch
  } = useGetPriceRequestsListQuery(
    {
      queryParams,
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (isSuccess) {
      if (getPriceRequestsQueryData?.count > 0) {
        const items: PriceRequestType[] = getPriceRequestsQueryData?.items;
        let allRequests: PriceRequestType[] = [];
        if (items && items.length > 0) {
          //combined the response items returned from the api call with the current price request, and set as the current data
          allRequests = items.concat(currentPriceRequestData);
        } else {
          //otherwise just insert the current price
          allRequests.push(currentPriceRequestData);
        }
        setCompleteData(allRequests);
      }
    }
  }, [currentPriceRequestData, getPriceRequestsQueryData, isSuccess]);

  // Refetch all PR data when new drafts are created
  // Ensures new draft data is availabe in expanded table
  useEffect(() => {
    if (currentAndDrafts) {
      refetch();
    }
  }, [currentAndDrafts]);

  const filteredData = useMemo(() => {
    const uniqueIds = new Set();
    const filteredArray = completeData.filter((priceRequest) => {
      if (uniqueIds.has(priceRequest.id)) {
        return false; // Skip objects with duplicate IDs
      }

      if (
        priceRequest.status === 'Cancelled' ||
        priceRequest.status === 'Declined'
      )
        return false; // Remove Cancelled||Declined Statuses

      const priceRequestEffectiveDate = new Date(
        getPSTDate(priceRequest.final_effective_date || '').toString()
      );

      if (priceRequestEffectiveDate < new Date(getPSTDate().toString()))
        return false;

      uniqueIds.add(priceRequest.id);
      return true; // Include objects with unique IDs
    });
    return filteredArray;
  }, [completeData]);

  const handleToggleAllEffectiveDates = () => {
    //if the toggle is closed on button click(showAllPriceRequests set to false), pass in the filtered array from above and toggle it to be open
    if (!showAllPriceRequests) {
      setTableData(filteredData.sort(comparePriceRequests));
      setShowAllPriceRequests(true);
    } // if the toggle is open on button click (showAllPriceRequests set to true), close the toggle and reset the table data to just the current and draft price requests
    else {
      const reducePriceRequestData = currentAndDrafts.filter(
        (priceRequest: PriceRequestType) => {
          if (priceRequest.status !== undefined) {
            const status = priceRequest.status.toLowerCase(); // Convert status to lowercase for case-insensitive comparison
            return statusFiltered.includes(status);
          }
        }
      );
      setTableData(reducePriceRequestData.sort(comparePriceRequests));
      setShowAllPriceRequests(false);
    }
  };

  // we will pass the below data into useTable below, so setting it to cachedTableData.
  const data = tableData;

  // We need to dynamically set the columns based on LDB user and Non-LDB user. A/S Review, Cost Only, and Costing Event Comments only for LDB user
  const userType = user;
  const columnsByUser = useCallback((user: string) => {
    if (user === UserTypeEnum.INTERNAL) {
      return [
        {
          Header: 'id',
          accessor: (row: PriceRequest) => row.id,
          show: false
        },

        {
          Header: 'foreign_prime_cost',
          accessor: (row: PriceRequest) => row.foreign_prime_cost,
          Cell: (cellInfo: CellInfo) => {
            return <Pill type={cellInfo.value}>{cellInfo.value}</Pill>;
          }
        },
        {
          Header: 'currency_id',
          accessor: (row: PriceRequest) => row.currency_id,
          Cell: (cellInfo: CellInfo) => {
            return <Pill type={cellInfo.value}>{cellInfo.value}</Pill>;
          }
        },
        {
          Header: 'exchange_rate',
          accessor: (row: PriceRequest) => row.exchange_rate,
          Cell: (cellInfo: CellInfo) => {
            return <Pill type={cellInfo.value}>{cellInfo.value}</Pill>;
          }
        },
        {
          Header: 'fx_rate_date',
          accessor: (row: PriceRequest) => row.fx_rate_date,
          Cell: (cellInfo: CellInfo) => {
            return <Pill type={cellInfo.value}>{cellInfo.value}</Pill>;
          }
        },
        {
          Header: 'email',
          accessor: (row: PriceRequest) => row.email,
          Cell: (cellInfo: CellInfo) => {
            const value = cellInfo.value ? `${cellInfo.value}` : '';
            return (
              <Text element="p" tableCell>
                {value}
              </Text>
            );
          }
        },
        {
          Header: 'PR Status',
          accessor: (row: any) => row.status,
          Cell: (cellInfo: CellInfo) => {
            let statusDisplay = mapPrStatus(cellInfo.value);
            if (
              cellInfo.value === DRAFT ||
              cellInfo.value === 'draft' ||
              cellInfo.value === 'Draft'
            ) {
              statusDisplay = `● ${DRAFT}`;
            }
            return <Pill type={cellInfo.value}>{statusDisplay}</Pill>;
          }
        },
        {
          Header: 'PR Type',
          accessor: (row: RowType) => (row.type ? row.type : row.price_type),
          Cell: (cellInfo: CellInfo) => {
            const priceRequestType = cellInfo.value;
            if (!priceRequestType) return <></>;
            return (
              <Pill
                type="neutral"
                secondary
                pilltooltip={tooltipString(priceRequestType)}
              >
                {labelString(priceRequestType)}
              </Pill>
            );
          }
        },
        {
          Header: 'Effective Date',
          accessor: (row: RowType) =>
            row.calendarId
              ? row.calendarId.effective_date
              : row.effective_date
              ? row.effective_date
              : row.price_effective_date,
          Cell: (cellInfo: CellInfo) => {
            return <Pill type="date">{dateFormat(cellInfo.value)}</Pill>;
          }
        },
        {
          Header: 'End Date',
          accessor: (row: any) => {
            if (!(row.price_type === 'P' || row.type === 'WPP')) return;
            if (row.id === 0) return row.promotion_end_date;
            return row.calendarId
              ? row.calendarId.effective_end_date
              : row.effective_end_date;
          },
          Cell: (cellInfo: CellInfo) => {
            return (
              <span className={styles.endDateContainer}>
                <ChevronRight />
                <Pill type="date">{dateFormat(cellInfo.value)}</Pill>
              </span>
            );
          }
        },
        {
          Header: 'Prime Cost',
          accessor: 'prime_cost_per_case',
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          }
        },
        {
          Header: 'Domestic Charges',
          accessor: 'domestic_charges_per_case',
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          }
        },
        {
          Header: 'Duty Paid Cost',
          accessor: 'duty_paid_cost_per_case',
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          }
        },
        {
          Header: 'Promotion Amount',
          accessor: 'promotion_amount_per_su',
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          }
        },
        {
          Header: 'Est. WS Price',
          accessor: (row: any) => {
            return row.id === 0 ? row.final_price_per_su : row.price_per_su;
          },
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          },
          tipText:
            'Estimated Wholesale Price (Promotional WS Price if WPP) / Selling Unit'
        },
        {
          Header: 'A/S Review',
          accessor: 'a_s_review_fl',
          Cell: (cellInfo: any) => {
            if (cellInfo.cell.row.id === '0') return null;
            const isValueTrue = cellInfo.value;
            return (
              <Pill type={isValueTrue ? 'success' : 'error'}>
                {isValueTrue ? 'Yes' : 'No'}
              </Pill>
            );
          }
        },
        {
          Header: 'Cost Only',
          accessor: 'const_only_fl',
          Cell: (cellInfo: any) => {
            if (cellInfo.cell.row.id === '0') return null;
            const isValueTrue = cellInfo.value;
            return (
              <Pill type={isValueTrue ? 'success' : 'error'}>
                {isValueTrue ? 'Yes' : 'No'}
              </Pill>
            );
          }
        },
        {
          Header: 'reason',
          accessor: 'reason',
          Cell: (cellInfo: { row: any; value: string }) => {
            const value = cellInfo.value ? `${cellInfo.value}` : '---';
            return (
              <Text element="p" tableCell disabled={value === '---'}>
                {value}
              </Text>
            );
          }
        },
        {
          Header: 'Costing Event Comment',
          accessor: 'costing_event_comment',
          Cell: (cellInfo: CellInfo) => {
            const value = cellInfo.value
              ? `${decodeSanitizedValue(cellInfo.value)}`
              : '---';
            return (
              <Text element="p" tableCell disabled={value === '---'}>
                {value}
              </Text>
            );
          }
        }
      ];
    } else {
      return [
        {
          Header: 'id',
          accessor: (row: PriceRequest) => row.id,
          show: false
        },
        {
          Header: 'PR Status',
          accessor: (row: any) => row.status,
          Cell: (cellInfo: CellInfo) => {
            let statusDisplay = mapPrStatus(cellInfo.value);
            if (cellInfo.value === DRAFT) {
              statusDisplay = `● ${DRAFT}`;
            }
            return <Pill type={cellInfo.value}>{statusDisplay}</Pill>;
          }
        },
        {
          Header: 'PR Type',
          accessor: (row: any) => (row.type ? row.type : row.price_type),
          Cell: (cellInfo: CellInfo) => {
            const priceRequestType = cellInfo.value;
            return (
              <Pill
                type="neutral"
                secondary
                pilltooltip={tooltipString(priceRequestType)}
              >
                {labelString(priceRequestType)}
              </Pill>
            );
          }
        },
        {
          Header: 'Effective Date',
          accessor: (row: RowType) =>
            row.calendarId
              ? row.calendarId.effective_date
              : row.effective_date
              ? row.effective_date
              : row.price_effective_date,
          Cell: (cellInfo: CellInfo) => {
            const calendarSubtype =
              cellInfo.row.original.calendarId?.sub_type || null;
            const cellContent =
              calendarSubtype === 'AD_HOC'
                ? 'ASAP'
                : dateFormat(cellInfo.value);
            return <Pill type="date">{cellContent}</Pill>;
          }
        },
        {
          Header: 'End Date',
          accessor: (row: any) => {
            if (!(row.price_type === 'P' || row.type === 'WPP')) return;
            if (row.id === 0) return row.promotion_end_date;
            return row.calendarId
              ? row.calendarId.effective_end_date
              : row.effective_end_date;
          },
          Cell: (cellInfo: CellInfo) => {
            return (
              <span className={styles.endDateContainer}>
                <ChevronRight />
                <Pill type="date">{dateFormat(cellInfo.value)}</Pill>
              </span>
            );
          }
        },
        {
          Header: 'Prime Cost',
          accessor: 'prime_cost_per_case',
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          }
        },
        {
          Header: 'Domestic Charges',
          accessor: 'domestic_charges_per_case',
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          }
        },
        {
          Header: 'Duty Paid Cost',
          accessor: 'duty_paid_cost_per_case',
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          }
        },
        {
          Header: 'Promotion Amount',
          accessor: (row: any) =>
            row.promo_value_per_su
              ? row.promo_value_per_su
              : row.promotion_amount_per_su,
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          }
        },
        {
          Header: 'Est. WS Price',
          accessor: (row: any) => {
            return row.id === 0 ? row.final_price_per_su : row.price_per_su;
          },
          Cell: (cellInfo: CellInfo) => {
            return currencyCellText(cellInfo.value);
          },
          tipText:
            'Estimated Wholesale Price (Promotional WS Price if WPP) / Selling Unit'
        }
      ];
    }
  }, []);

  // set the columns using the columnsByUser function mentioned above
  const columns = useMemo(
    () => columnsByUser(userType),
    [userType, columnsByUser]
  );

  const initialState = {
    hiddenColumns: [
      'id',
      'reason',
      'foreign_prime_cost',
      'currency_id',
      'exchange_rate',
      'fx_rate_date',
      'email'
    ]
  };

  // pass the table data and columns into the options variable which will be passed into useTable below
  const options = {
    data,
    columns,
    initialState
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(options, useFilters, useRowSelect);

  const productStatus = (status_code: string) => {
    if (status_code === '2') {
      return 'Active';
    } else if (status_code === '1') {
      return 'Pending Listing';
    } else if (status_code === '4') {
      return 'Delisted';
    } else {
      return 'Pending Delist';
    }
  };

  // find all drafts and currents
  const statusFiltered = ['current', 'draft', `● Draft`, 'Draft'];
  const filteredPriceRequestData = currentAndDrafts.filter(
    (priceRequest: PriceRequestType) => {
      if (priceRequest.status !== undefined) {
        const status = priceRequest.status.toLowerCase(); // Convert status to lowercase for case-insensitive comparison
        return statusFiltered.includes(status);
      }
    }
  );
  const maxRows = filteredPriceRequestData.length;
  const toggleButtonDisplayed = filteredData.length <= maxRows;

  const handleClickDraft = (
    row: WorkingGroupPrDetails,
    fullPrData: PriceRequest
  ) => {
    if (row['PR Status'] === PriceRequestStatusEnum.DRAFT) {
      const prDetails = {
        ...row,
        justification: fullPrData.justification
      };
      setDraftRow(prDetails);
      handleOpenModal(sku);
    }
  };
  return (
    <Card xlarge>
      <div id={'ProductCard-' + sku} className={styles.productCardContainer}>
        <div
          className={classNames(styles.productWrapper, {
            [styles.productWrapperExternal]: user === UserTypeEnum.EXTERNAL,
            [styles.productWrapperInternal]: user === UserTypeEnum.INTERNAL
          })}
        >
          <div className={styles.productStatus}>
            <Text element="p" bold>
              {sku}
            </Text>
            <Pill type={status_code}>{productStatus(status_code)}</Pill>
          </div>
          <Heading element="h4" heading3 productBlockTitle action>
            {product_name}
          </Heading>
          <div>
            <Text element="p" small>
              {categorySearch(hierachyData, category)}
            </Text>
          </div>
          <div className={styles.productCase}>
            <Text element="p" small>
              {alcohol_percentage ? `${alcohol_percentage}%` : ''}
            </Text>
            <Text element="p" small>
              {unit_description}
            </Text>
          </div>
          <Button
            link
            type="button"
            className={styles.productDetails}
            small
            onClick={() => {
              dispatch(setSku(sku));
              dispatch(setOpenProductDetailsModal(true));
            }}
            id={'view-products-details-link'}
          >
            View Product Details
          </Button>
        </div>
        <div className={styles.productTable}>
          <div
            className={styles.tableContainer}
            data-testid={`product-card-table`}
          >
            <table {...getTableProps()} className={styles.table}>
              <thead className={styles.tableHeaderContainer}>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className={styles.tableRow}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        className={styles.headerCells}
                      >
                        {column.render('Header')}
                        {(headerGroup.headers[index] as any).tipText && (
                          <span
                            className={classNames(styles.tooltipContainer, {
                              [styles.tooltipContainerTop]: location === 'top',
                              [styles.tooltipContainerBottom]:
                                location === 'bottom'
                            })}
                          >
                            <TableTooltip
                              label={
                                (headerGroup.headers[index] as any).tipText
                              }
                              callback={callback}
                            />
                          </span>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={classNames(styles.tableRow, {
                        [styles.tableRowHidden]:
                          !showAllPriceRequests && i > maxRows
                      })}
                      onClick={() => {
                        handleClickDraft(
                          row.values as WorkingGroupPrDetails,
                          row.original
                        );
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.prCell}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div></div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              link
              small
              iconRight
              icon={<ChevronUp />}
              className={classNames(styles.toggleButton, {
                [styles.toggleButtonHidden]: toggleButtonDisplayed,
                [styles.toggleButtonIconTransform]: !showAllPriceRequests
              })}
              onClick={() => handleToggleAllEffectiveDates()}
            >
              {`${
                showAllPriceRequests ? 'Hide ' : 'Show '
              } All Future Effective Dates`}
            </Button>
            {/*when clicked, opens the "Add Price Request" modal for the respective product. Currently only the non-LDB view modal is added below as the LDB modal has not yet been built*/}
            <Button
              primary
              iconRight
              icon={<AdditionSign />}
              onClick={() => {
                setDraftRow(undefined);
                handleOpenModal(sku);
              }}
            >
              Add Price Request
            </Button>
            {user === UserTypeEnum.EXTERNAL ? (
              <span className={styles.modalContainer}>
                <Modal
                  isOpen={showModal === sku}
                  onRequestClose={handleCloseModal}
                  key={sku}
                >
                  <AddNewPRNonLDBModal
                    product={product}
                    dates={dates}
                    prDetails={draftRow}
                    onRequestClose={handleCloseModal}
                    fullData={
                      getPriceRequestsQueryData &&
                      getPriceRequestsQueryData?.items?.length > 0
                        ? getPriceRequestsQueryData?.items
                        : undefined
                    }
                  />
                </Modal>
              </span>
            ) : (
              <span className={styles.modalContainer}>
                <Modal
                  isOpen={showModal === sku}
                  onRequestClose={handleCloseModal}
                  key={sku}
                >
                  <AddNewPRLDBModal
                    product={product}
                    dates={dates}
                    prDetails={draftRow}
                    onRequestClose={handleCloseModal}
                    fullData={
                      getPriceRequestsQueryData &&
                      getPriceRequestsQueryData?.items?.length > 0
                        ? getPriceRequestsQueryData?.items
                        : undefined
                    }
                  />
                </Modal>
              </span>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
