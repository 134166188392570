import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useTable } from 'react-table';
import classNames from 'classnames';

import TableTooltip from '../TableTooltip';

import { csvErrorTableColumns } from './CSVErrorTableColumns';

import { setShowErrorTable } from '../../reducers/CSVBulkUploadSlice';
import Button from '../Button';

import styles from './CSVErrorTable.module.scss';
import Heading from '../Heading';
import ArrowLeft from '../Icons/ArrowLeft';

import copy from '../../config/translations/en-CA.json';
const tableCopy = copy.priceRequest.errorTable;

const CSVErrorTable = () => {
  const dispatch = useDispatch();
  const { errorPriceRequests } = useSelector(
    (state: RootState) => state.csvBulkUpload
  );

  const [location, setLocation] = useState('');

  const sortedRows = [...errorPriceRequests].sort((a, b) => a.row - b.row);

  const options = {
    data: sortedRows,
    columns: csvErrorTableColumns
  };

  const toolTipCallback = (state: string) => {
    setLocation(state);
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(options as any);
  return (
    <div className={styles.csvErrorTable}>
      <div className={styles.csvErrorHeader}>
        <Button
          link
          onClick={() => {
            dispatch(setShowErrorTable(false));
          }}
          icon={<ArrowLeft />}
          iconLeft
        >
          Back
        </Button>
        <Heading element="h2">{tableCopy.pageTitle}</Heading>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <Heading element="h3">{tableCopy.tableHeading}</Heading>
        </div>

        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps()} className={styles.th}>
                    <span className={styles.headerColumnWrapper}>
                      <span className={styles.shortLabel}>
                        {column.render('Header')}
                      </span>
                      {(headerGroup.headers[index] as any).tipText && (
                        <span
                          className={classNames(styles.tooltipContainer, {
                            [styles.tooltipContainerTop]: location === 'top',
                            [styles.tooltipContainerBottom]:
                              location === 'bottom'
                          })}
                        >
                          <TableTooltip
                            label={(headerGroup.headers[index] as any).tipText}
                            callback={toolTipCallback}
                          />
                        </span>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={styles.tableRow}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={styles.cellContainer}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CSVErrorTable;
