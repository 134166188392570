import React from 'react';

export default function ArrowLeft() {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-arrow-left">Arrow Left</title>
      <path
        d="M3.79693 6.66897L14.4165 6.66897C14.9235 6.66897 15.3332 6.22209 15.3332 5.66897C15.3332 5.11584 14.9235 4.66897 14.4165 4.66897L3.79693 4.66897L5.89964 2.37522C6.25773 1.98459 6.25773 1.35022 5.89964 0.959595C5.54155 0.56897 4.96001 0.56897 4.60192 0.959595L0.935072 4.95959C0.576981 5.35022 0.576981 5.98459 0.935072 6.37522L4.60192 10.3752C4.96001 10.7658 5.54155 10.7658 5.89964 10.3752C6.25773 9.98459 6.25773 9.35022 5.89964 8.95959L3.79693 6.66897Z"
        fill="currentColor"
      />
    </svg>
  );
}
