import React from 'react';

export default function ArrowRight() {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-arrow-right">Arrow Right</title>
      <path
        d="M13.7283 6.75264H1.7813C1.21086 6.75264 0.75 6.2499 0.75 5.62764C0.75 5.00537 1.21086 4.50264 1.7813 4.50264H13.7283L11.3627 1.92217C10.9599 1.48271 10.9599 0.769043 11.3627 0.32959C11.7656 -0.109863 12.4198 -0.109863 12.8227 0.32959L16.9479 4.82959C17.3507 5.26904 17.3507 5.98272 16.9479 6.42217L12.8227 10.9222C12.4198 11.3616 11.7656 11.3616 11.3627 10.9222C10.9599 10.4827 10.9599 9.76904 11.3627 9.32959L13.7283 6.75264Z"
        fill="currentColor"
      />
    </svg>
  );
}
