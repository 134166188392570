import React from 'react';

export default function LegendCircle() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-legend-circle">Circle with border</title>
      <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
    </svg>
  );
}
