import { object, string, InferType } from 'yup';
import copy from '../../config/translations/en-CA.json';
import { twoDecimalPointsRegex } from '../../config/constants';

import { emailRegex, maximumEmailCharacters } from '../../config/constants';

const decimalValidation = copy.validations[0].decimal;
const positiveNumberValidation = copy.validations[0].positiveNumber;
const maximumValueValidation = copy.validations[0].maximumValue;
const emailValidation = copy.validations[0].email;

export const schema = object().shape(
  {
    reason: object().shape({
      label: string(),
      value: string().required()
    }),
    exceptionReason: string(),
    justification: string(),
    costingEventComment: string(),
    effectiveDate: object().shape({
      label: string(),
      value: string().required()
    }),
    endDate: string().when('effectiveDate', {
      is: (value: string) => value && value.length > 0,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable()
    }),
    primeCostPerCase: string()
      .nullable()
      .when('primeCostPerCase', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            )
      }),
    dutyPaidCostPerCase: string()
      .nullable()
      .when('dutyPaidCostPerCase', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            )
      }),
    dutyPaidCostPerSU: string().nullable(),
    estWholesalePrice: string()
      .nullable()
      .when('estWholesalePrice', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    pricePerSU: string()
      .nullable()
      .when('pricePerSU', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    wholesalePriceAsOfEffectiveDate: string()
      .nullable()
      .when('wholesalePriceAsOfEffectiveDate', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    promotionAmount: string()
      .nullable()
      .when('promotionAmount', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    domesticChargesPerCase: string()
      .nullable()
      .when('domesticChargesPerCase', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            )
      }),
    currencyCode: object()
      .shape({
        label: string().nullable(),
        value: string().nullable()
      })
      .nullable(),
    fxRateDate: string().nullable(),
    exchangeRate: string().nullable(),
    foreignPrimeCost: string()
      .nullable()
      .when('foreignPrimeCost', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    email: string()
      .nullable()
      .when('email', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .test(
              emailValidation.title,
              emailValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  return emailRegex.test(value);
                }
              }
            )
            .max(maximumEmailCharacters, emailValidation.constraint),
        otherwise: (schema) => schema.nullable().notRequired()
      })
  },
  [
    ['primeCostPerCase', 'primeCostPerCase'],
    ['dutyPaidCostPerCase', 'dutyPaidCostPerCase'],
    ['estWholesalePrice', 'estWholesalePrice'],
    ['pricePerSU', 'pricePerSU'],
    ['wholesalePriceAsOfEffectiveDate', 'wholesalePriceAsOfEffectiveDate'],
    ['promotionAmount', 'promotionAmount'],
    ['domesticChargesPerCase', 'domesticChargesPerCase'],
    ['endDate', 'effectiveDate'],
    ['effectiveDate', 'effectiveDate'],
    ['foreignPrimeCost', 'foreignPrimeCost'],
    ['email', 'email']
  ]
);

export type FormData = InferType<typeof schema>;
