import React from 'react';

export default function ExclamationPoint() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-exclamation-point">Exclamation Point</title>
      <path
        d="M29.6668 15C29.6668 23.102 23.0998 29.6667 15.0002 29.6667C6.90055 29.6667 0.333496 23.102 0.333496 15C0.333496 6.9028 6.90055 0.333374 15.0002 0.333374C23.0998 0.333374 29.6668 6.9028 29.6668 15ZM15.0002 17.957C13.4977 17.957 12.2797 19.175 12.2797 20.6775C12.2797 22.1799 13.4977 23.3979 15.0002 23.3979C16.5026 23.3979 17.7206 22.1799 17.7206 20.6775C17.7206 19.175 16.5026 17.957 15.0002 17.957ZM12.4173 8.1785L12.856 16.2215C12.8766 16.5979 13.1878 16.8925 13.5647 16.8925H16.4357C16.8126 16.8925 17.1238 16.5979 17.1443 16.2215L17.583 8.1785C17.6052 7.77198 17.2815 7.43015 16.8744 7.43015H13.1259C12.7188 7.43015 12.3952 7.77198 12.4173 8.1785Z"
        fill="currentcolor"
      />
    </svg>
  );
}
