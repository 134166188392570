import { KeyboardEvent, ClipboardEvent } from 'react';
import { fetchApiData, handleApiResponse } from './apiHelpers';
import { ValidationMessageType } from '../types/productTypes';
import { PriceRequest } from '../types';
import { PriceRequestDetailsFormFieldValues } from '../reducers/PriceRequestDetailsSlice';

// function to determine what scenario number to assign and whether or not to enable or disabled the calculate button on the add new price request modal for Price Change or New Product Pricing/ Reactivation price request types.

export const priceChangeModalCheck = (
  effectiveDate: string | undefined,
  country_of_export: string | undefined,
  is_cost_dpc: string | undefined,
  inputtedPrimeCostValue: string | number | undefined,
  inputtedEstWholesalePriceValue: string | number | undefined,
  inputtedDomesticChargesValue: string | number | undefined,
  inputtedDutyPaidCostValue: string | number | undefined,
  scenarioCallback: (arg: number) => void,
  disableCallback: (arg: boolean) => void
) => {
  // if no effective date is passed into the function, set button to disabled
  if (!effectiveDate) {
    disableCallback(true);
  } else if (
    //First of three scenarios for requestType === Price Change PC: Duty Paid Cost, LDB Wholesale Price (Domestic products on list requiring DPC)
    //values needed: Duty Paid Cost or Estimated Wholesale Price, product must be domestic and require Duty Paid Cost. These values are taken from product information.
    (country_of_export === 'CA' &&
      is_cost_dpc === 'YES' &&
      inputtedDutyPaidCostValue &&
      !inputtedEstWholesalePriceValue) ||
    (country_of_export === 'CA' &&
      is_cost_dpc === 'YES' &&
      !inputtedDutyPaidCostValue &&
      inputtedEstWholesalePriceValue) ||
    (country_of_export === 'CA' &&
      is_cost_dpc === 'YES' &&
      inputtedDutyPaidCostValue &&
      inputtedEstWholesalePriceValue)
  ) {
    //set the scenario type for the calculate button functionality to 1
    scenarioCallback(1);
    //enable calculate button for either forward or reverse calculation
    disableCallback(false);
  }
  //Second of three scenarios for requestType === Price Change PC
  else if (
    // domestic product and not on the list of products requiring DPC, with primeCostPerCast inputted by user and Estimated Wholesale Price left empty
    (country_of_export === 'CA' &&
      is_cost_dpc === 'NO' &&
      inputtedPrimeCostValue &&
      !inputtedEstWholesalePriceValue) ||
    // domestic product and not on the list of products requiring DPC, with primeCostPerCast left empty and Estimated Wholesale Price inputted by user
    (country_of_export === 'CA' &&
      is_cost_dpc === 'NO' &&
      !inputtedPrimeCostValue &&
      inputtedEstWholesalePriceValue) ||
    // domestic product and not on the list of products requiring DPC, with primeCostPerCast and Estimated Wholesale Price inputted by user
    (country_of_export === 'CA' &&
      is_cost_dpc === 'NO' &&
      inputtedPrimeCostValue &&
      inputtedEstWholesalePriceValue)
  ) {
    //set the scenario type for the calculate button functionality to 2
    scenarioCallback(2);
    // enable calculate button for either forward or reverse calculation
    disableCallback(false);
  }
  // Third of the three scenarios for requestType === Price Change PC: Prime Cost, Domestic Charges, LDB Wholesale (Import Products)
  // values needed: any combination of two of the following: Prime Cost per Case, Domestic Charges per case, Estimated Wholesale Price. Product MUST be an import product
  else if (
    //import product with primeCostPerCase and domesticChargesPerCase both inputted by user and estWholesalePrice left empty
    (country_of_export !== 'CA' &&
      inputtedPrimeCostValue &&
      inputtedDomesticChargesValue &&
      !inputtedEstWholesalePriceValue) ||
    //import product with primeCostPerCase and estWholesalePrice both inputted by user and domesticChargesPerCase left empty
    (country_of_export !== 'CA' &&
      inputtedPrimeCostValue &&
      inputtedEstWholesalePriceValue &&
      !inputtedDomesticChargesValue) ||
    //import product with domesticChargesPerCase and estWholesalePrice both inputted by user and primeCostPerCase left empty
    (country_of_export !== 'CA' &&
      inputtedEstWholesalePriceValue &&
      inputtedDomesticChargesValue &&
      !inputtedPrimeCostValue) ||
    //import product with domesticChargesPerCase and estWholesalePrice and primeCostPerCase all inputted by user
    (country_of_export !== 'CA' &&
      inputtedEstWholesalePriceValue &&
      inputtedDomesticChargesValue &&
      inputtedPrimeCostValue)
  ) {
    //set the scenario type for the calculate button functionality to 4
    scenarioCallback(4);
    // enable calculate button for either forward or reverse calculation
    disableCallback(false);
  } else {
    disableCallback(true);
  }
};

// function to check and apply scenario number 3 and whether or not to enable or disabled the calculate button on the add new price request modal for WPP price request types.
export const wholesalePromotionModalCheck = (
  inputtedPromotionAmountValue: number | undefined,
  selectedEffectiveDate: string | undefined,
  scenarioCallback: (arg: number) => void,
  disableCallback: (arg: boolean) => void
) => {
  //set the scenario type for the calculate button functionality to 3 - because anything that is WPP is scenario 3 for calculate buton
  scenarioCallback(3);
  // One and only scenario for requestType === Wholesale Price Promotion: WPP discount amount, final wholesale price (WPP)
  // value needed: WPP Amount & Effective Date
  if (
    // promotionAmount is inputted && the user has a selected effective date enable the calculate and save buttons
    // if there is no wholesale price as of the effective date, the calculation will error out and show on the UI so only the promotioAmount input cost field is needed to enable the calculate and Save buttons
    inputtedPromotionAmountValue &&
    selectedEffectiveDate
  ) {
    disableCallback(false);
  } else {
    // otherwise disable the calculate button because not enough information has been inputted by the user
    disableCallback(true);
  }
};

export const errorMapping = (
  type: string,
  errorCopy: {
    decimal: {
      title: string;
      constraint: string;
      errorTitle: string;
      description: string;
    };
    positiveNumber: {
      title: string;
      constraint: string;
      errorTitle: string;
      description: string;
    };
    maximumValue: {
      title: string;
      constraint: string;
      errorTitle: string;
      description: string;
    };
    required: {
      title: string;
      constraint: string;
      errorTitle: string;
      description: string;
    };
    email: {
      title: string;
      constraint: string;
      errorTitle: string;
      description: string;
    };
  }
) => {
  const { decimal, positiveNumber, required, email, maximumValue } = errorCopy;
  const error = {
    title: '',
    description: ''
  };
  if (type === decimal.title) {
    error.title = decimal.errorTitle;
    error.description = decimal.description;
  } else if (type === positiveNumber.title) {
    error.title = positiveNumber.errorTitle;
    error.description = positiveNumber.description;
  } else if (type === maximumValue.title) {
    error.title = maximumValue.errorTitle;
    error.description = maximumValue.description;
  } else if (type === email.title) {
    error.title = email.errorTitle;
    error.description = email.description;
  } else {
    error.title = required.errorTitle;
    error.description = required.description;
  }
  return error;
};

// Create save api call function for WPP price request types, it takes in the request body needed for the api call and the type of save function, either Save and Close or Save and Next, which determines whether the modal closes or remains open after the current price request is successfully saved.

export const handleWPPValidation = (
  sku: number,
  inputtedPromotionAmountPerSU: number,
  promoPeriod: { dateFrom: string; dateTo: string },
  loadingCallback: (state: boolean) => void,
  inputtedPromotionAmountValue: number,
  promoAmountCallback: (arg: string) => void,
  calculateErrorsCallback: (arg: ValidationMessageType[]) => void,
  calculateWarningsCallback: (arg: ValidationMessageType[]) => void,
  token: string | null
) => {
  // TODO change requestBody type
  async function validateWPP(accessToken: string | null, requestBody: any) {
    try {
      loadingCallback(true);
      const result = await fetchApiData(
        'price-validation/validate-promotion',
        accessToken,
        'POST',
        requestBody
      );
      const validateWPPResponse = await handleApiResponse(result);
      // if there is a response, update the appropriate input values and update warnings if needed.
      if (validateWPPResponse) {
        const { promotionAmountPerSU, validationMessage } = validateWPPResponse;
        loadingCallback(false);
        if (inputtedPromotionAmountValue !== promotionAmountPerSU) {
          promoAmountCallback(promotionAmountPerSU.toString());
        } else if (validationMessage.length > 0) {
          // if there are validation messages, filter by WARNING and ERROR types, and update the calculateWarnings and calculateErrors arrays;
          // These validation messages are only displayed on save, not the calculate button onclick functionality
          const validationWarnings = validationMessage.filter(
            (message: ValidationMessageType) => message.type === 'WARNING'
          );

          const validationErrors = validationMessage.filter(
            (message: ValidationMessageType) => message.type === 'ERROR'
          );

          calculateWarningsCallback(validationWarnings);
          calculateErrorsCallback(validationErrors);
        }
      }
    } catch (error) {
      console.log(error);
      loadingCallback(false);
    }
  }

  const wppRequestBody = {
    sku,
    promotionAmountPerSU: inputtedPromotionAmountPerSU,
    promoPeriod
  };

  validateWPP(token, wppRequestBody);
};

// helper function to round decimals to two decimal places
export function roundDecimals(number: number) {
  // Check if the number is less than 0.01
  if (number < 0.01) {
    return 0.01;
  }

  const integerPart = Math.floor(number);
  const decimalPart = number - integerPart;

  // Round the decimal part to two decimal places
  const roundedDecimalPart = Math.round(decimalPart * 100) / 100;

  // Return the sum of the integer part and rounded decimal part
  return integerPart + roundedDecimalPart;
}

//helper function to main a set number of decimal places using .toFixed();
//takes in either a string or number value to convert, along with the number of decimal places.
//If the value is a string it converts to a number to apply .toFixed()
export const fixedDecimalPlaces = (
  value: string | number,
  decimalPlaces: number
) => {
  // Convert the value to a number if it's a string
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  // Check if the number is valid
  if (isNaN(numberValue) || numberValue === null) {
    return 'Invalid number';
  }

  // Format the number with 2 decimal places using .toFixed(), inserting the number of decimal places param
  const formattedValue = numberValue?.toFixed(decimalPlaces);

  return formattedValue;
};

// Map Calculate API return data to Create PR keys
interface CalculateAPIInputObject {
  primeCostPerCase: number;
  domesticChargesPerCase: number;
  inBondCostPerCase: number;
  exciseAmountPerCase: number;
  customsAmountPerCase: number;
  dutyPaidCostPerCase: number;
  dutyPaidCostPerSU: number;
  beerDistroChargePerSU: number;
  markupPerSU: number;
  crfPerSU: number;
  pricePerCase: number;
  floorPrice: string;
  pricePerSU: number;
  estimatedPrice: number;
  estimatedPricePerSU: number;
  validationMessage: any;
}

interface PriceRequestOutputObject {
  prime_cost_per_case: number;
  domestic_charges_per_case: number;
  in_bond_cost_per_case: number;
  excise_amount_per_case: number;
  customsAmountPerCase: number;
  duty_paid_cost_per_case: number;
  duty_paid_cost_per_su: number;
  beer_distro_charge_per_su: number;
  markup_per_su: number;
  crf_per_su: number;
  price_per_case: number;
  floor_price: string;
  price_per_su: number;
  estimated_price: number;
  estimated_price_per_su: number;
  validationMessage: any;
}

const keyMapping = new Map<
  keyof CalculateAPIInputObject,
  keyof PriceRequestOutputObject
>([
  ['primeCostPerCase', 'prime_cost_per_case'],
  ['domesticChargesPerCase', 'domestic_charges_per_case'],
  ['inBondCostPerCase', 'in_bond_cost_per_case'],
  ['exciseAmountPerCase', 'excise_amount_per_case'],
  ['customsAmountPerCase', 'customsAmountPerCase'],
  ['dutyPaidCostPerCase', 'duty_paid_cost_per_case'],
  ['dutyPaidCostPerSU', 'duty_paid_cost_per_su'],
  ['beerDistroChargePerSU', 'beer_distro_charge_per_su'],
  ['markupPerSU', 'markup_per_su'],
  ['crfPerSU', 'crf_per_su'],
  ['pricePerCase', 'price_per_case'],
  ['floorPrice', 'floor_price'],
  ['pricePerSU', 'price_per_su'],
  ['estimatedPrice', 'estimated_price'],
  ['estimatedPricePerSU', 'estimated_price_per_su'],
  ['validationMessage', 'validationMessage']
]);

export const mapCalculateKeysToPriceRequest = (
  calculateResponseObject: CalculateAPIInputObject
): PriceRequestOutputObject => {
  const mappedPriceRequestObject: any = {} as PriceRequestOutputObject;

  Object.entries(calculateResponseObject).forEach(([responseKey, value]) => {
    const remappedKey = keyMapping.get(
      responseKey as keyof CalculateAPIInputObject
    );

    if (remappedKey) {
      mappedPriceRequestObject[remappedKey] = value;
    }
  });

  return mappedPriceRequestObject;
};

// used to determine if there is a queued status price request with the same type and effective date
export const hiddenFieldCheck = (
  date: string | undefined,
  type: string | number,
  priceRequests: PriceRequest[] | undefined
) => {
  let isMatchingRequest = false;
  let typeValue: string;
  switch (type) {
    case 'Price Change (PC)':
      typeValue = 'PC';
      break;
    case 'Wholesale Price Promotion (WPP)':
      typeValue = 'WPP';
      break;
    default:
      typeValue = 'NP';
  }
  if (priceRequests !== undefined) {
    isMatchingRequest = priceRequests.some(
      (request) =>
        request.effective_date === date &&
        request.type === typeValue &&
        request.status === 'Queued'
    );
  }
  return isMatchingRequest;
};

// function to check if calculate button should be disabled based on type and inputs
export const disabledCalculateButtonFunction = (
  type: string | undefined,
  fieldValues: PriceRequestDetailsFormFieldValues,
  productIsImport: boolean | undefined,
  productIsDPC: boolean | undefined
) => {
  const {
    primeCostPerCase,
    promotionAmount,
    dutyPaidCostPerCase,
    domesticChargesPerCase,
    pricePerSU
  } = fieldValues;

  //if type is WPP, check for date
  if (type === 'WPP') {
    if (!promotionAmount) {
      return true;
    } else return false;
  } else if (type === 'NP' || type === 'PC') {
    if (!productIsImport && !primeCostPerCase && !pricePerSU && !productIsDPC) {
      // only Prime Cost/Case and Est.Wholesale Price/SU inputs
      return true;
    }

    if (productIsDPC && !dutyPaidCostPerCase && !pricePerSU) {
      // Only Duty Paid Cost/ Case and Est. Wholesale Price/SU Inputs
      return true;
    }

    if (
      !productIsDPC &&
      productIsImport &&
      !primeCostPerCase &&
      !domesticChargesPerCase &&
      !pricePerSU
    ) {
      // Prime Cost/ Case and Domestic Charges/ Case and Est. Wholesale PRice Inputs on screen and all three are empty
      return true;
    }

    if (
      !productIsDPC &&
      productIsImport &&
      !primeCostPerCase &&
      !domesticChargesPerCase &&
      pricePerSU
    ) {
      // Prime Cost/ Case and Domestic Charges/ Case and Est. Wholesale PRice Inputs on screen and prime cost and domestic charges are empty
      return true;
    }

    if (
      !productIsDPC &&
      productIsImport &&
      !primeCostPerCase &&
      domesticChargesPerCase &&
      !pricePerSU
    ) {
      // Prime Cost/ Case and Domestic Charges/ Case and Est. Wholesale PRice Inputs on screen and prime cost and est wholesale are empty
      return true;
    }

    if (
      !productIsDPC &&
      productIsImport &&
      primeCostPerCase &&
      !domesticChargesPerCase &&
      !pricePerSU
    ) {
      // Prime Cost/ Case and Domestic Charges/ Case and Est. Wholesale PRice Inputs on screen and domestic charges and est wholesale are empty
      return true;
    }
  } else return false;
};

//function to trigger focus on a specifc input to trigger validation
export const triggerInputValidation = (focusFunction: () => void) => {
  setTimeout(focusFunction, 0);
};

export const handleKeyDown = (event: KeyboardEvent) => {
  // Prevent the 'e/E/+/-' key from being entered
  const preventCharacters = ['e', 'E', '+', '-'];
  if (preventCharacters.includes(event.key)) {
    event.preventDefault();
  }
};

export const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
  const clipboardData = event.clipboardData;
  const text = clipboardData?.getData('text');
  const preventCharacters = ['e', 'E', '+', '-'];

  if (preventCharacters.some((character) => text?.includes(character))) {
    event.preventDefault();
  }
};

export const handleSkuKeyDown = (event: KeyboardEvent) => {
  // Prevent the 'e/E/+/-/.' key from being entered
  const preventCharacters = ['e', 'E', '+', '-', '.'];
  if (preventCharacters.includes(event.key)) {
    event.preventDefault();
  }
};

export const handleSkuPaste = (event: ClipboardEvent<HTMLInputElement>) => {
  const clipboardData = event.clipboardData;
  const text = clipboardData?.getData('text');
  const preventCharacters = ['e', 'E', '+', '-', '.'];

  if (preventCharacters.some((character) => text?.includes(character))) {
    event.preventDefault();
  }
};

export const getCalculateFields = (scenario: number, fieldValues: any) => {
  const calculateFieldsAndValues = [];

  if (scenario === 2 || scenario === 4) {
    calculateFieldsAndValues.push({
      name: 'primeCostPerCase',
      value: fieldValues.primeCostPerCase
    });
  }

  if (scenario === 4) {
    calculateFieldsAndValues.push({
      name: 'domesticChargesPerCase',
      value: fieldValues.domesticChargesPerCase
    });
  }

  if (scenario !== 3) {
    calculateFieldsAndValues.push({
      name: 'pricePerSU',
      value: fieldValues.pricePerSU
    });
  }

  if (scenario === 1) {
    calculateFieldsAndValues.push({
      name: 'dutyPaidCostPerCase',
      value: fieldValues.dutyPaidCostPerCase
    });
  }

  if (scenario === 3) {
    calculateFieldsAndValues.push({
      name: 'promotionAmount',
      value: fieldValues.promotionAmount
    });
  }

  return calculateFieldsAndValues;
};
