import React from 'react';

import { currencyCellText, dateFormat } from '../../../helpers';

import Pill from '../../Pill/Pill';

export const priceHistoryColumns = [
  {
    Header: 'Effective Date',
    accessor: 'effectiveDate',
    Cell: (cellInfo: any) => {
      return <Pill type="date">{dateFormat(cellInfo.value)}</Pill>;
    }
  },
  {
    Header: 'Prime Cost / Case',
    accessor: 'primeCostPerCase',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Domestic Charges / Case',
    accessor: 'domesticChargesPerCase',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Duty Paid Cost / Case',
    accessor: 'dutyPaidCostPerCase',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Wholesale Price / SU',
    accessor: 'pricePerSU',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Promotion Amount',
    accessor: 'promoValuePerSU',
    Cell: (cellInfo: any) => {
      return currencyCellText(cellInfo.value);
    }
  },
  {
    Header: 'Promotional Wholesale Price / SU',
    accessor: 'promoWSPricePerSu',
    Cell: (cellInfo: any) => {
      const { pricePerSU, promoValuePerSU } = cellInfo.row.values;
      const promoWSPrice = promoValuePerSU
        ? pricePerSU - promoValuePerSU
        : null;
      return currencyCellText(promoWSPrice as any);
    }
  }
];
