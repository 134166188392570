import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import classNames from 'classnames';

import {
  setIsAlertVisible,
  setOpenModal,
  setPriceRequestTable,
  setBanner,
  setFilters
} from '../../reducers/PriceRequestSlice';

import { resetPriceRequestDetails } from '../../reducers/PriceRequestDetailsSlice';

import { useGetPriceRequestsListQuery } from '../../services/PriceRequest';
import { useAuth } from '../../contexts/AuthenticationProvider';

import {
  calculatePaginationDisplay,
  getSessionToken,
  ISearchParams,
  searchObjectToParams
} from '../../helpers';

import { itemsPerPage } from './utils';

import Banner from '../Banner';
import Button from '../Button';
import ChevronDown from '../Icons/ChevronDown';
import ChevronLeft from '../Icons/ChevronLeft';
import ChevronRight from '../Icons/ChevronRight';
import Checkmark from '../Icons/CheckMark';
import ExclamationPoint from '../Icons/ExclamationPoint';
import Heading from '../Heading';
import InfoCircle from '../Icons/InfoCircle';
import LoadingOverlay from '../LoadingOverlay';
import Modal from '../Modal';
import PriceRequestDetailsModal from '../Modal/PriceRequestDetailsModal';
import PriceRequestListTableContent from './PriceRequestListTableContent';
import PriceRequestTableFilters from './PriceRequestTableFilters';

import styles from './PriceRequestListTable.module.scss';

const PriceRequestListTable = () => {
  const dispatch = useDispatch();
  const { user } = useAuth().authState;
  const userEmail = user?.email;
  const token = getSessionToken();

  const { priceRequestTable, openModal, filters, isAlertVisible, banner } =
    useSelector((state: RootState) => state.priceRequest);

  // Removed pageSize, use one from session storage instead
  const { sortBy, sortType, pageNumber, totalPages, totalResults } =
    priceRequestTable;

  const [showFilters, setShowFilters] = useState(true);

  // State variable for page size, use from session or default to 15
  const [pageSize, setPageSize] = useState(
    Number(sessionStorage.getItem('pageSize')) || 15
  );

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const clearBanner = useCallback(() => {
    dispatch(setIsAlertVisible(false));
    dispatch(setBanner(undefined));
  }, [dispatch]);

  useEffect(() => {
    // Reset Table & filters on mount
    dispatch(setFilters([]));
    // Clear banner messages when component unmounts
    return clearBanner();
  }, [clearBanner, dispatch]);

  useEffect(() => {
    if (isAlertVisible) {
      dispatch(
        setBanner({
          title: 'Your Price Requests Have Been Submitted',
          type: 'success',
          description: `A confirmation email will be sent to ${user.email} unless otherwise specified (LDB only)`
        })
      );
    }
  }, [dispatch, isAlertVisible, userEmail]);

  const mapObject: ISearchParams = {
    filters,
    orders: [
      {
        by: sortBy,
        type: sortType
      }
    ],
    limit: pageSize,
    offset: pageNumber * pageSize
  };

  const queryParams = searchObjectToParams(mapObject);

  const { data, isLoading, isError, refetch } = useGetPriceRequestsListQuery(
    {
      queryParams,
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  //Two different onClick functions below outline the changes for next and previous button clicks.
  const paginationNextButtonClick = () => {
    dispatch(
      setPriceRequestTable({
        ...priceRequestTable,
        tableData: [],
        pageSize: itemsPerPage,
        pageNumber: pageNumber + 1
      })
    );
  };

  const paginationPreviousButtonClick = () => {
    dispatch(
      setPriceRequestTable({
        ...priceRequestTable,
        tableData: [],
        pageSize: itemsPerPage,
        pageNumber: pageNumber - 1
      })
    );
  };

  useEffect(() => {
    if (data) {
      const totalPages = Math.ceil(data.count / pageSize);

      dispatch(
        setPriceRequestTable({
          ...priceRequestTable,
          totalPages,
          tableData: data.items,
          totalResults: data.count
        })
      );
    }
  }, [data, dispatch, pageSize]);

  const handleModalClose = () => {
    dispatch(resetPriceRequestDetails());
    refetch();
    dispatch(setOpenModal(false));
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = Number(e.target.value);
    setPageSize(newPageSize);
    sessionStorage.setItem('pageSize', newPageSize.toString());
    dispatch(
      setPriceRequestTable({
        ...priceRequestTable,
        pageNumber: 0
      })
    );
  };

  return (
    <div className={styles.priceRequestListTable}>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          {banner && (
            <Banner
              type={banner.type}
              icon={banner.type === 'info' ? <InfoCircle /> : <Checkmark />}
              title={banner.title}
              description={banner.description}
              closeable
              onClose={clearBanner}
            />
          )}
          <div
            className={styles.tableContainer}
            data-testid="price-request-search-results-table"
          >
            <div
              className={classNames(styles.filterToggleContainer, {
                [styles.filterToggleContainerActive]: showFilters
              })}
            >
              <div className={styles.filterToggleContainer__header}>
                <Button
                  icon={showFilters ? <ChevronDown /> : <ChevronRight />}
                  iconRight
                  link
                  onClick={() => handleShowFilters()}
                >
                  Filters
                </Button>
                <div className={styles.tableButtons}>
                  <div className={styles.buttonsRight}>
                    <div className={styles.itemsPerPageContainer}>
                      Items per page:
                      <select value={pageSize} onChange={handlePageSizeChange}>
                        {[5, 15, 25, 50, 100].map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={styles.pagination}>
                      {calculatePaginationDisplay(
                        pageNumber,
                        pageSize,
                        totalPages,
                        totalResults
                      )}
                      {/* Only show pagination buttons if initial results are more than 15 */}
                      {Number(totalResults) > 15 && (
                        <>
                          <Button
                            pagination
                            icon={<ChevronLeft />}
                            onClick={() => paginationPreviousButtonClick()}
                            disabled={pageNumber === 0}
                          />
                          <Button
                            pagination
                            icon={<ChevronRight />}
                            onClick={() => paginationNextButtonClick()}
                            disabled={pageNumber === totalPages - 1}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {showFilters && <PriceRequestTableFilters />}
            </div>

            {isError ? (
              <div className={styles.searchErrorContainer}>
                <div className={styles.iconContainer}>
                  <ExclamationPoint />
                </div>
                <Heading element="h4" heading4>
                  Error getting Price Requests
                </Heading>
                <p>
                  A server error has occured, please try again or contact
                  support.
                </p>
              </div>
            ) : (
              <PriceRequestListTableContent />
            )}
          </div>
        </>
      )}
      <Modal isOpen={openModal} onRequestClose={handleModalClose} closeable>
        <PriceRequestDetailsModal
          onRequestClose={handleModalClose}
          key={crypto.randomUUID()}
        />
      </Modal>
    </div>
  );
};

export default PriceRequestListTable;
