import React, { useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';

import styles from './index.module.scss';
import copy from '../../config/translations/en-CA.json';

import { getSessionToken } from '../../helpers';
import { useGetAnnouncementQuery } from '../../services/Home';

import Heading from '../Heading';
import Text from '../Text';
import Loader from '../Loader';
import authHelpers from '../../helpers/authHelpers';

const HomeAnnouncements = () => {
  const token = getSessionToken();

  const { data, isLoading, isError, error } = useGetAnnouncementQuery(
    {
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );
  useEffect(() => {
    if (error && (error as any).status === 401) {
      authHelpers.removeSessionStorageItem('authToken');
      window.location.href = authHelpers.LOGOUT_URI;
    }
  }, [error]);

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Heading element="h2" heading2>
          {copy.homePage.announcements.heading}
        </Heading>
        <div className={styles.noResults}>
          <Loader width="30px" />
        </div>
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className={styles.container}>
        <Heading element="h2" heading2 className={styles.sectionHeading}>
          {copy.homePage.announcements.heading}
        </Heading>
        <div className={styles.noResults}>
          <Text element="p" bold small action>
            {copy.homePage.announcements.loadingError}
          </Text>
        </div>
      </div>
    );
  }

  /**
   * Sanitize the html from the api
   * https://www.npmjs.com/package/sanitize-html
   */
  const cleanHtml = sanitizeHtml(data, {
    // place an array of tags here if you want different from the default.
    // e.g. allowedTags: ['p', 'table'], etc....
    // the default allowed tags match the acceptance critera for the announcement content
    transformTags: {
      iframe: 'p',
      date: 'p'
    }
  });

  return (
    <div className={styles.container}>
      <Heading element="h2" heading2 className={styles.sectionHeading}>
        {copy.homePage.announcements.heading}
      </Heading>
      <div
        dangerouslySetInnerHTML={{ __html: cleanHtml }}
        className={styles.announcementContent}
      />
    </div>
  );
};

export default HomeAnnouncements;
