import React, { useState, useEffect, useCallback } from 'react';
import { useWindowDimensions, Size } from '../../helpers/windowDimensions';
import classNames from 'classnames';

import { LocationType } from '../../types/productTypes';

import styles from './index.module.scss';

type PropsType = {
  label: string;
  icon?: React.ReactNode;
  callback: (state: LocationType) => void;
};

interface IMousePosition {
  x: number | undefined;
  y: number | undefined;
}

const TableTooltip = ({ label, icon, callback }: PropsType) => {
  // Depending on where the location of the font with ellipsis is, we should determine if
  // the tooltip should show below or above the text, so the tooltip isn't hidden off screen

  // create base state of position position, set at x: 0, y: 0
  const [mousePos, setMousePos] = useState<IMousePosition>({ x: 0, y: 0 });
  //set base state of location tooltip should be rendered in
  const [location, setLocation] = useState<LocationType>('bottom');
  //grab size from the useWindowDimensions import function. We will get height off of this.
  const size: Size = useWindowDimensions();

  //creating a function that lifts the location state up to the parent through a callback
  // as the span that shows/hides the tooltip is housed and styled within parent component
  // and the location will be used to show it above of below the text
  const handleCallBack = useCallback(
    (location: LocationType) => callback(location),
    [callback]
  );

  useEffect(() => {
    const handleMouseMove: EventListener = (event: MouseEventInit) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    // is the location of the mouse position exists, and the height of the screen exists,
    // divide the two and multiply by 100 to get the position of the mouse relative to the screen size.
    // If the position is greater then 70% set the tooltip location to above the text
    if (mousePos.y && size.height && (mousePos.y / size.height) * 100 > 70) {
      setLocation('top');
      handleCallBack('top');
      // If the position is equal to or less then 70% set the tooltip location to below the text
    } else if (
      mousePos.y &&
      size.height &&
      (mousePos.y / size.height) * 100 <= 70
    ) {
      setLocation('bottom');
      handleCallBack('bottom');
    } else {
      setLocation('bottom');
      handleCallBack('bottom');
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [size.height, mousePos.y, handleCallBack, location]);
  const sizeBigText = 30;

  return (
    <div className={styles.tblTooltip} id={`tblTooltip-${label}`}>
      {icon ? (
        <>
          {icon}
          <p
            className={classNames(styles.tblTooltipLabel, {
              [styles.tblTooltipLabelBigText]: label.length > sizeBigText
            })}
          >
            {label}
          </p>
          <div
            className={classNames(styles.tblTooltipArrow, {
              [styles.tblTooltipArrowTop]: location === 'top',
              [styles.tblTooltipArrowBottom]: location === 'bottom'
            })}
          />
        </>
      ) : (
        <>
          <p
            className={classNames(styles.tblTooltipLabel, {
              [styles.tblTooltipLabelBigText]: label.length > sizeBigText
            })}
          >
            {label}
          </p>
          <div
            className={classNames(styles.tblTooltipArrow, {
              [styles.tblTooltipArrowTop]: location === 'top',
              [styles.tblTooltipArrowBottom]: location === 'bottom'
            })}
          />
        </>
      )}
    </div>
  );
};

export default TableTooltip;
