import { PRSearchFormDataType } from '../../types/productSearchTypes';

export const itemsPerPage = 15;

export const defaultFormValues = {
  type: { label: 'Select...', value: '' },
  sku: null,
  product: '',
  status: { label: 'Select...', value: '' },
  effectiveDate: '',
  listingAgent: { label: 'Select...', value: '0' },
  pricingAgent: { label: 'Select...', value: '0' },
  category: { label: 'Select...', value: '0' },
  subCategory: { label: 'Select...', value: '0' },
  created_by: { label: 'Select...', value: '0' }
};

export const getPriceRequestFilterConditions = (data: PRSearchFormDataType) => {
  const prType = data.type || 0;
  const status = data.status ? data.status.value : '';
  const effectiveDate = data.effectiveDate || '';
  const pricingAgent = data.pricingAgent ? data.pricingAgent.value : 0;
  const listingAgent = data.listingAgent ? data.listingAgent.value : 0;
  const skuNumber = data.sku || 0;
  const productName = data.product.toUpperCase() || '';
  const category = data.category ? data.category.value : 0;
  const subCategory = data.subCategory ? data.subCategory.value : 0;
  const createdBy = data.created_by ? data.created_by.value : '';

  return [
    {
      check: prType.value.length > 0,
      filter: {
        field: 'type',
        operator: '=',
        value: prType.value
      }
    },
    {
      check: skuNumber > 0,
      filter: {
        field: '$productSku.sku$',
        operator: '=',
        value: skuNumber.toString()
      }
    },
    {
      check: productName.length > 0,
      filter: {
        field: '$productSku.product_name$',
        operator: 'CONTAINS',
        value: productName
      }
    },
    {
      check: category > 0,
      filter: {
        field: '$productSku.category$',
        operator: '=',
        value: category
      }
    },
    {
      check: subCategory > 0,
      filter: {
        field: '$productSku.sub_category$',
        operator: '=',
        value: subCategory
      }
    },
    {
      check: status.length > 0,
      filter: {
        field: 'status',
        operator: '=',
        value: status
      }
    },
    {
      check: listingAgent > 0,
      filter: {
        field: '$productSku.supplier$',
        operator: '=',
        value: listingAgent
      }
    },
    {
      check: pricingAgent > 0,
      filter: {
        field: '$productSku.agent$',
        operator: '=',
        value: pricingAgent
      }
    },
    {
      check: effectiveDate !== '',
      filter: {
        field: 'final_effective_date',
        operator: 'CONTAINS',
        value: effectiveDate
      }
    },
    {
      check: createdBy !== '0',
      filter: {
        field: 'created_by',
        operator: '=',
        value: createdBy
      }
    }
  ];
};
