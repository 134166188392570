import React, { useState, ChangeEvent } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { useCreateCommentMutation } from '../../services/PriceRequestComments';

import { getSessionToken } from '../../helpers';

import Loader from '../Loader';
import TextInput from '../TextInput';

import styles from './index.module.scss';

import copy from '../../config/translations/en-CA.json';
const commentsMicroCopy = copy.modals[0].priceRequestDetails.comments[0];

type FormData = {
  newComment: string;
};

type CommentFormProps = {
  id: number | undefined;
};

const CommentForm: React.FC<CommentFormProps> = ({ id }) => {
  const token = getSessionToken();

  const [createComment, { isLoading }] = useCreateCommentMutation();

  const [inputtedComment, setInputtedComment] = useState<string | undefined>(
    ''
  );

  const methods = useForm<FormData>({
    defaultValues: {
      newComment: ''
    }
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inputtedComment) {
      const payload = {
        pr_id: id,
        comment_txt: inputtedComment,
        token
      };
      createComment(payload);
      setInputtedComment('');
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.PriceRequestComments__form}
        onSubmit={handleSubmit}
      >
        <div className={styles.PriceRequestComments__formContent}>
          {isLoading ? (
            <Loader width={'20px'} height={'20px'} />
          ) : (
            <TextInput
              id="newComment"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                setInputtedComment(value);
              }}
              value={inputtedComment}
              placeholder={commentsMicroCopy.addComment}
              maxLengthCharacter={2500}
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default CommentForm;
