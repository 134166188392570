import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TableState } from '../types/TableTypes';
import { itemsPerPage } from '../components/PriceRequestListTable/utils';
import { PriceRequest } from '../types/PriceRequestsTypes';
import { SelectInputType, BannerType, SaveFunctionality } from '../types';
import {
  HierarchyClassType,
  ProductSupplierResponseType
} from '../types/productSearchTypes';
import { ProductDetailsType } from '../types/productTypes';

export interface PriceRequestState {
  hierarchyData: HierarchyClassType[];
  isAlertVisible: boolean;
  numberOfItemsAdded: boolean;
  openModal: boolean;
  effectiveDate: string;
  priceRequestTable: TableState<PriceRequest[]>;
  subCategories: SelectInputType[];
  filters: any[];
  sku: number | undefined;
  selectedPriceRequestID: number | undefined;
  banner: BannerType | undefined;
  bannerNewPriceRequest: BannerType | undefined;
  draftsExist: boolean;
  openDeleteModal: boolean;
  refreshWorkingGroupDrafts: boolean;
  openProductDetailsModal: boolean;
  productDetailsData: ProductDetailsType | undefined;
  openPRModal: boolean;
  productSuppliersList: ProductSupplierResponseType | undefined;
  recentPriceRequestType: { label: string; value: string };
  loadingState: boolean;
  prevSaveFunction: SaveFunctionality;
}

export const initialPriceRequestsTableState: TableState<PriceRequest[]> = {
  tableData: [],
  totalResults: 0,
  pageNumber: 0,
  pageSize: itemsPerPage,
  sortBy: 'status',
  sortType: 'asc',
  totalPages: 0
};

const initialState: PriceRequestState = {
  hierarchyData: [],
  isAlertVisible: false,
  numberOfItemsAdded: false,
  openModal: false,
  effectiveDate: '',
  priceRequestTable: initialPriceRequestsTableState,
  subCategories: [],
  filters: [],
  sku: undefined,
  banner: undefined,
  bannerNewPriceRequest: undefined,
  draftsExist: false,
  openDeleteModal: false,
  selectedPriceRequestID: undefined,
  refreshWorkingGroupDrafts: false,
  openProductDetailsModal: false,
  productDetailsData: undefined,
  openPRModal: false,
  productSuppliersList: undefined,
  recentPriceRequestType: {
    label: 'Price Change (PC)',
    value: 'Supplier Price Change'
  },
  loadingState: false,
  prevSaveFunction: undefined
};

export const priceRequestSlice = createSlice({
  name: 'priceRequest',
  initialState,
  reducers: {
    setHierarchyData: (state, action: PayloadAction<any>) => {
      state.hierarchyData = action.payload;
    },
    setPriceRequestTable: (
      state,
      action: PayloadAction<TableState<PriceRequest[]>>
    ) => {
      state.priceRequestTable = action.payload;
    },
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload;
    },
    setIsAlertVisible: (state, action: PayloadAction<boolean>) => {
      state.isAlertVisible = action.payload;
    },
    setEffectiveDate: (state, action: PayloadAction<string>) => {
      state.effectiveDate = action.payload;
    },
    setSubcategories: (state, action: PayloadAction<SelectInputType[]>) => {
      state.subCategories = action.payload;
    },
    setFilters: (state, action: PayloadAction<any[]>) => {
      state.filters = action.payload;
    },
    setSku: (state, action: PayloadAction<number>) => {
      state.sku = action.payload;
    },
    setSelectedPriceRequestID: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedPriceRequestID = action.payload;
    },
    setBanner: (state, action: PayloadAction<BannerType | undefined>) => {
      state.banner = action.payload;
    },
    setBannerNewPriceRequest: (
      state,
      action: PayloadAction<BannerType | undefined>
    ) => {
      state.bannerNewPriceRequest = action.payload;
    },
    setDraftsExist: (state, action: PayloadAction<boolean>) => {
      state.draftsExist = action.payload;
    },
    setOpenDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.openDeleteModal = action.payload;
    },
    setRefreshDrafts: (state, action: PayloadAction<boolean>) => {
      state.refreshWorkingGroupDrafts = action.payload;
    },
    setOpenProductDetailsModal: (state, action: PayloadAction<boolean>) => {
      state.openProductDetailsModal = action.payload;
    },
    setProductDetailsData: (
      state,
      action: PayloadAction<ProductDetailsType>
    ) => {
      state.productDetailsData = action.payload;
    },
    setOpenPRModal: (state, action: PayloadAction<boolean>) => {
      state.openPRModal = action.payload;
    },
    setProductSuppliersList: (
      state,
      action: PayloadAction<ProductSupplierResponseType>
    ) => {
      state.productSuppliersList = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.loadingState = action.payload;
    },
    setPrevSaveFunction: (state, action: PayloadAction<SaveFunctionality>) => {
      state.prevSaveFunction = action.payload;
    },
    setRecentPriceRequestType: (
      state,
      action: PayloadAction<{
        label: string;
        value: string;
      }>
    ) => {
      state.recentPriceRequestType = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setHierarchyData,
  setPriceRequestTable,
  setOpenModal,
  setIsAlertVisible,
  setEffectiveDate,
  setSubcategories,
  setFilters,
  setSku,
  setBanner,
  setBannerNewPriceRequest,
  setDraftsExist,
  setOpenDeleteModal,
  setSelectedPriceRequestID,
  setRefreshDrafts,
  setOpenProductDetailsModal,
  setProductDetailsData,
  setOpenPRModal,
  setProductSuppliersList,
  setLoadingState,
  setPrevSaveFunction,
  setRecentPriceRequestType
} = priceRequestSlice.actions;

export default priceRequestSlice.reducer;
