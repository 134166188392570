import React from 'react';

import HelmetContainer from '../../components/HelmetContainer/HelmetContainer';
import PriceRequestListTable from '../../components/PriceRequestListTable';
import Heading from '../../components/Heading';
import Layout from '../../components/Layout';
import AddDraftsButton from '../../components/AddDraftsButton';

import copy from '../../config/translations/en-CA.json';

import styles from '../index.module.scss';

const PriceRequestListPage = () => {
  const pageCopy = copy.priceRequestListPage;

  return (
    <Layout>
      <HelmetContainer title={pageCopy.pageTitle} />
      <div className={styles.heading}>
        <Heading element="h2" heading3>
          My Price Requests
        </Heading>
        <div className={styles.buttonsRight}>
          <AddDraftsButton />
        </div>
      </div>
      <PriceRequestListTable />
    </Layout>
  );
};

export default PriceRequestListPage;
