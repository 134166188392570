import React, { useState, useEffect, ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import Label from '../Label';
import Checkmark from '../Icons/CheckMark';

import styles from './index.module.scss';

type PropsType = {
  id: string;
  label: string;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
};

const Checkbox = ({
  id,
  label,
  disabled,
  onChange,
  isChecked = false
}: PropsType) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const { register } = useFormContext();

  const checkboxInputField = register(id);

  const handleAdditionalOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    checkboxInputField.onChange(event);
    setChecked(event.target.checked);
  };

  return (
    <div className={styles.checkboxInputContainer}>
      <Label htmlFor={id} className={styles.label} disabled={disabled}>
        <span
          className={classNames(styles.checkmark, {
            [styles.checkmarkSelected]: checked,
            [styles.checkmarkDisabled]: disabled
          })}
        >
          {checked && <Checkmark />}
        </span>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={handleAdditionalOnChange}
          className={styles.checkboxInput}
          disabled={disabled}
        />
        {label}
      </Label>
    </div>
  );
};

export default Checkbox;
