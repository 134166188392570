import React from 'react';

export default function UploadDocument() {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-upload-document">Upload Document</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9183 3.90759L9.215 1.20431C8.92501 0.914323 8.6592 0.75 8.25 0.75V4.875H12.3726C12.3726 4.4658 12.2083 4.19757 11.9183 3.90759ZM1.54657 0.75H7.08V5.2725C7.08 5.70103 7.42397 6.0525 7.8525 6.0525H12.3726V15.7034C12.3726 16.5573 11.6798 17.25 10.826 17.25H1.54657C0.692736 17.25 0 16.5573 0 15.7034V2.2998C0 1.44596 0.692736 0.753222 1.54657 0.75ZM6.96094 10.8441V14.1344H6.95772C6.95772 14.563 6.61289 14.9079 6.18428 14.9079C5.75566 14.9079 5.41084 14.563 5.41084 14.1344V10.8441L4.41182 11.8431C4.11211 12.146 3.62227 12.1428 3.31934 11.8431C3.01641 11.5434 3.01641 11.0536 3.31934 10.7506L5.63965 8.43032C5.93936 8.12739 6.4292 8.12739 6.73213 8.43032L9.05244 10.7506C9.35537 11.0503 9.35215 11.5402 9.05244 11.8431C8.75274 12.146 8.26289 12.146 7.95996 11.8431L6.96094 10.8441Z"
        fill="currentColor"
      />
    </svg>
  );
}
