import React from 'react';

export default function Checkmark() {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-check-mark">Check Mark</title>
      <path
        d="M7.06031 14.3884L1.10197 8.21424C0.744005 7.84331 0.744005 7.24188 1.10197 6.87091L2.3983 5.52758C2.75627 5.15661 3.33671 5.15661 3.69467 5.52758L7.70849 9.68677L16.3057 0.7782C16.6636 0.407267 17.2441 0.407267 17.602 0.7782L18.8984 2.12153C19.2563 2.49247 19.2563 3.09389 18.8984 3.46486L8.35668 14.3885C7.99868 14.7594 7.41827 14.7594 7.06031 14.3884Z"
        fill="currentColor"
      />
    </svg>
  );
}
