import { IVendor } from '../types/authTypes';

export const extractAgentsFromUser = (
  vendorList: IVendor[],
  agentType: string
) => {
  const agentsArray = vendorList.filter(
    (vendor) => vendor.vendorType === agentType
  );

  if (agentsArray.length > 0) {
    const pricingAgentOptions = agentsArray.map((vendor: IVendor) => {
      const { internalVendorID: internalId, vendorID: externalId } = vendor;
      const idAsString = internalId.toString();

      return {
        label: `${externalId} - ${vendor.vendorName}`,
        value: idAsString
      };
    });
    return pricingAgentOptions;
  }

  return [];
};
