import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type UploadedPriceRequest = {
  row: number;
  sku: number;
  product_name: string;
  unit_description: string;
  request_type: string;
  effective_date: string;
  prime_cost_per_case?: number | string;
  domestic_charges_per_case?: number | string;
  duty_paid_cost_per_case?: number | string;
  promotion_amount_per_su?: number;
  currency_label?: string;
  currency_effective_date?: string;
  foreign_prime_cost_per_case?: number;
  a_s_review_fl?: boolean;
  const_only_fl?: boolean;
  reason?: string;
  costing_event_comment?: string;
  calendar_id?: number | null;
  submission_deadline?: string | null;
  error_message?: string;
};
export interface CSVBulkUploadState {
  parsedCSVData: UploadedPriceRequest[];
  openBulkCSVModal: boolean;
  openParseCSVModal: boolean;
  uploadErrors: { title: string; description: string }[];
  validPriceRequests: UploadedPriceRequest[];
  errorPriceRequests: UploadedPriceRequest[];
  isValidating: boolean;
  showErrorBanner: boolean;
  showErrorTable: boolean;
}

const initialState: CSVBulkUploadState = {
  parsedCSVData: [],
  openBulkCSVModal: false,
  openParseCSVModal: false,
  uploadErrors: [],
  validPriceRequests: [],
  errorPriceRequests: [],
  isValidating: false,
  showErrorBanner: false,
  showErrorTable: false
};

export const CSVBulkUploadSlice = createSlice({
  name: 'csvBulkUpload',
  initialState,
  reducers: {
    setOpenBulkCSVModal: (state, action: PayloadAction<boolean>) => {
      state.openBulkCSVModal = action.payload;
      state.uploadErrors = [];
    },
    transitionToParseCSVModal: (state) => {
      state.openBulkCSVModal = false;
      state.openParseCSVModal = true;
      state.isValidating = true;
    },
    setUploadErrors: (
      state,
      action: PayloadAction<{ title: string; description: string }[]>
    ) => {
      state.uploadErrors = action.payload;
    },
    setData: (state, action: PayloadAction<UploadedPriceRequest[]>) => {
      state.parsedCSVData = action.payload;
    },
    setShowErrorTable: (state, action: PayloadAction<boolean>) => {
      state.showErrorTable = action.payload;
    },
    clearUploadErrors: (state) => {
      state.uploadErrors = [];
    },
    addErrorPriceRequest: (
      state,
      action: PayloadAction<UploadedPriceRequest>
    ) => {
      state.errorPriceRequests = [...state.errorPriceRequests, action.payload];
    },
    addValidPriceRequest: (
      state,
      action: PayloadAction<UploadedPriceRequest>
    ) => {
      state.validPriceRequests = [...state.validPriceRequests, action.payload];
    },
    finishValidation: (state) => {
      state.parsedCSVData = [];
      state.openParseCSVModal = false;
      state.isValidating = false;
      state.showErrorBanner = state.errorPriceRequests.length ? true : false;
    },
    resetValidation: (state) => {
      state.parsedCSVData = [];
      state.validPriceRequests = [];
      state.errorPriceRequests = [];
      state.showErrorBanner = false;
      state.isValidating = false;
      state.showErrorBanner = false;
      state.uploadErrors = [];
    },
    resetState: () => {
      return initialState;
    }
  }
});

export const {
  setOpenBulkCSVModal,
  transitionToParseCSVModal,
  setUploadErrors,
  setData,
  clearUploadErrors,
  addErrorPriceRequest,
  addValidPriceRequest,
  resetState,
  finishValidation,
  resetValidation,
  setShowErrorTable
} = CSVBulkUploadSlice.actions;

export default CSVBulkUploadSlice.reducer;
