import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { useGetPriceRequestCommentsQuery } from '../../services/PriceRequestComments';

import {
  getSessionToken,
  UserTypeEnum,
  DRAFT,
  PROCESSING,
  COMPLETED,
  PENDING
} from '../../helpers';

import CommentForm from './_CommentForm';
import CommentItem from './_CommentItem';
import Heading from '../Heading';
import Loader from '../Loader';
import Text from '../Text';

import styles from './index.module.scss';

import copy from '../../config/translations/en-CA.json';
const commentsMicroCopy = copy.modals[0].priceRequestDetails.comments[0];

const PriceRequestComments: React.FC = () => {
  const token = getSessionToken();

  const { selectedPriceRequestID: id } = useSelector(
    (state: RootState) => state.priceRequest
  );
  const { user: userType, priceRequestStatus } = useSelector(
    (state: RootState) => state.priceRequestDetails.formStatus
  );

  const {
    data: comments,
    isLoading,
    isError
  } = useGetPriceRequestCommentsQuery(
    { id, token },
    {
      refetchOnMountOrArgChange: true
    }
  );

  let content;

  if (isLoading) {
    content = <Loader width={'20px'} height={'20px'} />;
  } else if (isError) {
    content = (
      <Text
        element="p"
        medium
        bold
        className={styles.PriceRequestComments__item}
      >
        {commentsMicroCopy.error}
      </Text>
    );
  } else if (comments?.length === 0) {
    content = (
      <Text
        element="p"
        medium
        bold
        className={styles.PriceRequestComments__item}
      >
        {commentsMicroCopy.noComments}
      </Text>
    );
  } else {
    content = comments?.map((comment) => (
      <CommentItem comment={comment} key={comment.id} userType={userType} />
    ));
  }

  return (
    <div className={styles.PriceRequestComments}>
      <Heading
        element="h5"
        heading5
        className={styles.PriceRequestComments__heading}
      >
        {commentsMicroCopy.heading}
      </Heading>
      {userType === UserTypeEnum.INTERNAL &&
        priceRequestStatus !== DRAFT &&
        priceRequestStatus !== COMPLETED &&
        priceRequestStatus !== PENDING &&
        priceRequestStatus !== PROCESSING && <CommentForm id={id} />}
      {content}
    </div>
  );
};

export default PriceRequestComments;
