/* eslint-disable prettier/prettier */
type PriceRow = { label: string; perCase: string; perSu: string };

type ProductData = {
  [key: string]: number | undefined;
};

const getValueFromSource = (
  mainSource: ProductData,
  fallbackSource: ProductData | undefined,
  key: string
): number | undefined => {
  let fallbackKey = key;
  if (key === 'customsAmountPerCase') {
    fallbackKey = 'customs_amount_per_case';
  }
  if (key === 'foreign_prime_cost') {
    fallbackKey = 'foreign_prime_cost_per_case';
  }

  if (typeof mainSource[key] !== 'undefined' && mainSource[key] !== null) {
    return mainSource[key];
  } else if (
    fallbackSource &&
    typeof fallbackSource[fallbackKey] !== 'undefined' &&
    fallbackSource[fallbackKey] !== null
  ) {
    return fallbackSource[fallbackKey];
  }

  return undefined;
};

/*
EPP-1348 - PRODUCT DETAIL: Price Breakdown showing values not provided by LDB Product Sync
For those values does not provided from LDB Product API, should not be populated on the product detail price break down page.
So the perCase value should be only displaying perCase value , if it is not exist, it should display as --.--, instead of using any other values
Same as for the perSU value should be only displaying perSU value , if it is not exist, it should display as --.--, instead of using any other values

from the comments from Christian & Neha,  
1. If a value is returned as zero, it should be displayed as “$0.00”
2. If a value is returned as blank, it should be displayed as “--.--”
3. Wholesale Price and Promotional Wholesale Price should not display a Per Case value  even when there is a value returned by the API.
   -Those should show as “--.--”,

*/
const createPriceBreakdownRow = (
  packPerCase: number,
  label?: string,
  valuePerCase?: number,
  valuePerSU?: number
) => {
  return {
    label: label || '',
    perCase:
      typeof valuePerCase !== 'undefined' &&
      valuePerCase !== null &&
      label !== 'Wholesale Price' &&
      label !== 'Promotional Wholesale Price'
        ? valuePerCase
        : '--.--',
    perSU:
      typeof valuePerSU !== 'undefined' &&
      valuePerSU !== null &&
      valuePerSU !== 0
        ? valuePerSU
        : '--.--'
  };
};

export const createPriceBreakdownTable = (
  headers: PriceRow[] | undefined,
  tableDataSource: any,
  packPerCase: number,
  fallbackDataSource?: any
) => {
  if (!headers || !tableDataSource) return;

  const priceBreakdownTableData: any = [];

  headers.forEach((header: PriceRow) => {
    const { label, perCase, perSu } = header;

    const perCaseValue = getValueFromSource(
      tableDataSource,
      fallbackDataSource,
      perCase
    );
    const perSUValue = getValueFromSource(
      tableDataSource,
      fallbackDataSource,
      perSu
    );

    const item = createPriceBreakdownRow(
      packPerCase,
      label,
      label === 'Foreign Prime' && tableDataSource?.currencyLabel === 'CAD'
        ? undefined
        : perCaseValue,
      label === 'Foreign Prime' && tableDataSource?.currencyLabel === 'CAD'
        ? undefined
        : perSUValue
    );

    priceBreakdownTableData.push(item);
  });

  return priceBreakdownTableData;
};
