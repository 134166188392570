import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

import { setShowErrorTable } from '../../reducers/CSVBulkUploadSlice';

import Heading from '../Heading';
import Text from '../Text/Text';
import ErrorAlert from '../Icons/ErrorAlert';

import styles from '../Banner/index.module.scss';

import copy from '../../config/translations/en-CA.json';
const bannerCopy = copy.banners.bulkUploadError;

const CSVErrorBanner = () => {
  const dispatch = useDispatch();
  const { errorPriceRequests } = useSelector(
    (state: RootState) => state.csvBulkUpload
  );

  const errorCount = errorPriceRequests.length;
  const errorPlural = errorCount > 1;

  return (
    <div
      className={`${styles.bannerContainer} ${styles.bannerContainerErrorOutline}`}
    >
      <div className={styles.bannerIcon}>
        <ErrorAlert />
      </div>
      <div className={styles.bannerContent}>
        <Heading element="h3" heading3 className={styles.bannerHeading}>
          {bannerCopy.title
            .replace('{isAre}', errorPlural ? 'are' : 'is')
            .replace('{errorCount}', errorCount.toString())
            .replace('{errorPlural}', errorPlural ? 's' : '')}
        </Heading>
        <div
          className={styles.underline}
          onClick={() => {
            dispatch(setShowErrorTable(true));
          }}
        >
          <Text element="p">
            {bannerCopy.viewAction.replace(
              '{errorPlural}',
              errorPlural ? 's' : ''
            )}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default CSVErrorBanner;
