import {
  combineReducers,
  configureStore,
  PreloadedState
} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  Persistor,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import priceRequestReducer from './reducers/PriceRequestSlice';
import workingGroupReducer from './reducers/WorkingGroupSlice';
import priceRequestDetailsReducer from './reducers/PriceRequestDetailsSlice';
import csvBulkUploadReducer from './reducers/CSVBulkUploadSlice';
import productPriceHistoryReducer from './reducers/ProductPriceHistorySlice';
import productSearchReducer from './reducers/ProductSearchSlice';
import ActivityMonitorReducer from './reducers/ActivityMonitorSlice';

import { tokenApi } from './services/Token';
import { hierarchyApi } from './services/Hierachy';
import { productsApi } from './services/Products';
import { priceRequestApi } from './services/PriceRequest';
import { calendarApi } from './services/Calendar';
import { commentsApi } from './services/PriceRequestComments';
import { currencyApi } from './services/Currency';
import { calculateApi } from './services/Calculate';
import { countryApi } from './services/Country';
import { homeApi } from './services/Home';
import { priceComponentsApi } from './services/PriceComponents';
import ActivityMonitorMiddleWare from './middleware/ActivityMonitorMiddleware';

const rootReducer = combineReducers({
  priceRequest: priceRequestReducer,
  priceRequestDetails: priceRequestDetailsReducer,
  workingGroup: workingGroupReducer,
  csvBulkUpload: csvBulkUploadReducer,
  productPriceHistory: productPriceHistoryReducer,
  productSearch: productSearchReducer,
  activityMonitor: ActivityMonitorReducer,
  [tokenApi.reducerPath]: tokenApi.reducer,
  [priceRequestApi.reducerPath]: priceRequestApi.reducer,
  [hierarchyApi.reducerPath]: hierarchyApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [calendarApi.reducerPath]: calendarApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [currencyApi.reducerPath]: currencyApi.reducer,
  [calculateApi.reducerPath]: calculateApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [priceComponentsApi.reducerPath]: priceComponentsApi.reducer
});

const rootPersistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['workingGroup', 'productSearch']
};

const persistedReducer = persistReducer<RootState>(
  rootPersistConfig,
  rootReducer
);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat([
        priceRequestApi.middleware,
        hierarchyApi.middleware,
        productsApi.middleware,
        calendarApi.middleware,
        commentsApi.middleware,
        currencyApi.middleware,
        calculateApi.middleware,
        countryApi.middleware,
        homeApi.middleware,
        priceComponentsApi.middleware,
        tokenApi.middleware,
        ActivityMonitorMiddleWare
      ]),
    preloadedState
  });

  const persistor = persistStore(store);

  return { store, persistor } as { store: typeof store; persistor: Persistor };
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof configureStore>;
export type AppDispatch = AppStore['dispatch'];
