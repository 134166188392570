import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type PropsType = {
  children: React.ReactNode;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  className?: string;
};

const Card = ({
  children,
  small,
  medium,
  large,
  xlarge,
  className
}: PropsType) => {
  return (
    <div className={classNames(className, styles.cardContainer)}>
      <div
        className={classNames(styles.cardWrapper, {
          [styles.cardWrapperSmall]: small,
          [styles.cardWrapperMedium]: medium,
          [styles.cardWrapperLarge]: large,
          [styles.cardWrapperXlarge]: xlarge
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
