/**
 * Returns a formatted string of the currently displayed results range out of total results
 *
 * @param currentPage - the current page
 * @param pageSize - number of items per page
 * @param totalPages - total pages
 * @param totalResults - total results
 * @returns Returns '###-### of ###'
 */

// Most results won't be a clean multiple of pageSize. This checks if the results
// shown are on the last page and replaces the end of the range with the totalResults number, e.g., 300 - 303
export const calculatePaginationDisplay = (
  currentPage: number,
  pageSize: number,
  totalPages: number,
  totalResults: number
) => {
  const itemRangeStart = currentPage * pageSize + 1;
  const itemRangeEnd = (currentPage + 1) * pageSize;
  const isLastPage = currentPage >= totalPages - 1;

  if (totalResults === 0) return;

  return (
    <p>
      {itemRangeStart} - {isLastPage ? totalResults : itemRangeEnd} of{' '}
      {totalResults}
    </p>
  );
};
