import React from 'react';

export default function PaperAirplane() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-paper-airplane">Submit</title>
      <path
        d="M16.9929 0.851282C17.1955 0.9986 17.2783 1.19502 17.2415 1.44056L14.8844 15.5831C14.8537 15.7612 14.7555 15.8993 14.5898 15.9975C14.5038 16.0466 14.4087 16.0711 14.3043 16.0711C14.2368 16.0711 14.1632 16.0558 14.0834 16.0251L9.9124 14.3217L7.68421 17.0379C7.57372 17.1791 7.42333 17.2497 7.23304 17.2497C7.15324 17.2497 7.08572 17.2374 7.03048 17.2129C6.91385 17.1699 6.82024 17.0978 6.74965 16.9965C6.67906 16.8952 6.64377 16.7832 6.64377 16.6604V13.447L14.599 3.69637L4.75625 12.2132L1.11932 10.7216C0.892203 10.6357 0.769438 10.4669 0.751023 10.2152C0.738746 9.96969 0.836959 9.78861 1.04566 9.67198L16.3668 0.832867C16.4589 0.777622 16.5571 0.75 16.6614 0.75C16.7842 0.75 16.8947 0.783761 16.9929 0.851282Z"
        fill="currentcolor"
      />
    </svg>
  );
}
