import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './TextLink.module.scss';

type PropsType = {
  route: string;
  children: string | React.ReactNode;
  disabled?: boolean;
  className?: string;
  icon?: React.ReactNode;
  primary?: boolean;
  secondary?: boolean;
  ariaLabel?: string;
  button?: boolean;
  iconLeft?: boolean;
  iconRight?: boolean;
  small?: boolean;
  external?: boolean;
  onClick?: () => void;
};
const TextLink = ({
  route,
  children,
  disabled,
  className,
  icon,
  iconLeft,
  iconRight,
  primary,
  secondary,
  ariaLabel,
  button,
  small,
  external,
  onClick
}: PropsType) => {
  return (
    <Link
      onClick={onClick}
      to={route || '/'}
      className={classNames(className, styles.textLink, {
        [styles.textLinkDisabled]: disabled,
        [styles.textLinkPrimary]: primary,
        [styles.textLinkSecondary]: secondary,
        [styles.textLinkButton]: button,
        [styles.textLinkButtonDisabled]: button && disabled,
        [styles.textLinkSmall]: small,
        [styles.textLinkButtonSecondary]: button && secondary,
        [styles.textLinkButtonSecondaryDisabled]:
          button && secondary && disabled
      })}
      aria-label={`Link: ${ariaLabel}`}
      aria-live="polite"
      target={external ? "'_blank'" : undefined}
    >
      {icon && iconLeft && <span className={styles.iconLeft}>{icon}</span>}
      {children}
      {icon && iconRight && <span className={styles.iconRight}>{icon}</span>}
    </Link>
  );
};

export default TextLink;
