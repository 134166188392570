import React, { useEffect, useState } from 'react';
import { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';

import {
  setPriceRequest,
  setBannerDetails
} from '../../../reducers/PriceRequestDetailsSlice';

import { useGetPriceRequestsListQuery } from '../../../services/PriceRequest';

import {
  getSessionToken,
  searchObjectToParams,
  ISearchParams,
  UserTypeEnum
} from '../../../helpers';

import DownloadLogButton from '../../DownloadLogButton';
import Heading from '../../Heading';
import LoadingOverlay from '../../LoadingOverlay';
import PriceRequestComments from '../../PriceRequestComments';
import PriceRequestDetailsForm from '../../PriceRequestDetailsForm';
import ProductDetailsHeader from '../../ProductDetailsHeader';
import ErrorAlert from '../../Icons/ErrorAlert';

import copy from '../../../config/translations/en-CA.json';

import styles from './PriceRequestDetailsModal.module.scss';

import PriceRequestDetailsTabs from '../../PriceRequestDetailsTabs/PriceRequestDetailsTabs';

import { PriceRequest } from '../../../types';
import Banner from '../../Banner/Banner';

type PriceRequestDetailsModalProps = {
  onRequestClose: () => void;
};

const modalCopy = copy.modals[0].priceRequestDetails;

const PriceRequestDetailsModal: React.FC<PriceRequestDetailsModalProps> = ({
  onRequestClose
}) => {
  const token = getSessionToken();
  const dispatch = useDispatch();

  const { formStatus, banner } = useSelector(
    (state: RootState) => state.priceRequestDetails
  );

  const { selectedPriceRequestID } = useSelector(
    (state: RootState) => state.priceRequest
  );

  const [isLoading, setIsLoading] = useState(true);

  const mapObject: ISearchParams = {
    filters: [
      {
        field: 'id',
        operator: '=',
        value: selectedPriceRequestID || ''
      }
    ],
    orders: [
      {
        by: 'id',
        type: 'asc'
      }
    ],
    limit: 1,
    offset: 0
  };

  const queryParams = searchObjectToParams(mapObject);

  const {
    data,
    isLoading: queryLoading,
    isError,
    isSuccess,
    refetch: refetchPRData
  } = useGetPriceRequestsListQuery(
    {
      queryParams,
      token
    },
    { refetchOnMountOrArgChange: true, skip: !selectedPriceRequestID }
  );

  useEffect(() => {
    if (selectedPriceRequestID) {
      refetchPRData();
    }
  }, [refetchPRData, selectedPriceRequestID]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setPriceRequest(data.items[0] as PriceRequest));
      setIsLoading(false);
    }
  }, [isSuccess, data, dispatch]);

  if (isError) {
    return <p>{modalCopy.error}</p>;
  }

  if (!selectedPriceRequestID) return null;

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div className={styles.priceRequestDetailsModalContainer}>
          <div className={styles.priceRequestDetailsModalContainer__upper}>
            <Heading element="h3" heading3 productBlockTitle>
              {modalCopy.title}
            </Heading>
            <ProductDetailsHeader />
          </div>
          <div className={styles.priceRequestDetailsModalContainer__lower}>
            <div
              className={styles.priceRequestDetailsModalContainer__lowerLeft}
            >
              <PriceRequestComments />
            </div>
            <div
              className={styles.priceRequestDetailsModalContainer__lowerRight}
            >
              {banner && (
                <Banner
                  type={banner.type}
                  title={banner.title}
                  description={banner.description}
                  icon={banner.type === 'error' ? <ErrorAlert /> : ''}
                  closeable
                  onClose={() => {
                    dispatch(setBannerDetails(undefined));
                  }}
                />
              )}
              <PriceRequestDetailsForm
                onRequestClose={onRequestClose}
                refetchPRData={refetchPRData}
              />
              <div className={styles.priceRequestDetailsModalContainer__tabs}>
                <PriceRequestDetailsTabs />
              </div>
              {formStatus.user === UserTypeEnum.INTERNAL && (
                <div
                  className={styles.priceRequestDetailsModalContainer__actions}
                >
                  <DownloadLogButton />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PriceRequestDetailsModal;
