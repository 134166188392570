import React from 'react';

export default function ArrowDownload() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1861 9.79422C16.6046 9.35494 16.6046 8.64155 16.1861 8.20226L10.8286 2.57946C10.4101 2.14018 9.73037 2.14018 9.31182 2.57946C8.89327 3.01874 8.89327 3.73214 9.31182 4.17142L12.8444 7.87544H2.57149C1.97882 7.87544 1.5 8.37798 1.5 9C1.5 9.62202 1.97882 10.1246 2.57149 10.1246H12.841L9.31517 13.8286C8.89662 14.2679 8.89662 14.9813 9.31517 15.4205C9.73372 15.8598 10.4134 15.8598 10.832 15.4205L16.1894 9.79773L16.1861 9.79422Z"
        fill="currentColor"
      />
    </svg>
  );
}
