import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { CreatedBy } from '../../types';

import {
  UserTypeEnum,
  dateFormat,
  PriceRequestStatusEnum
} from '../../helpers';

import Text from '../Text';

import styles from './submittedByTab.module.scss';

import copy from '../../config/translations/en-CA.json';

const tabCopy = copy.modals[0].priceRequestDetails.tabs.submittedBy;
const { INTERNAL } = UserTypeEnum;
const { DRAFT } = PriceRequestStatusEnum;
const statusDisablesSubmittedByContent = [DRAFT];

const SubmittedByTab = () => {
  const {
    submittedBy,
    agentId,
    submitted_on,
    status: priceRequestStatus
  } = useSelector((state: RootState) => state.priceRequestDetails.priceRequest);

  const vendorIdOriginal = agentId?.vendor_id || '';
  const vendorNameOriginal = agentId?.name || '';

  const clearSubmittedBy = statusDisablesSubmittedByContent.includes(
    priceRequestStatus as PriceRequestStatusEnum
  );

  if (clearSubmittedBy) {
    return (
      <div className={styles.submittedByContainer}>
        <div className={styles.section}></div>
      </div>
    );
  }

  if (!submittedBy) {
    return (
      <div className={styles.submittedByContainer}>
        <div className={styles.section}></div>
      </div>
    );
  }

  const { first_name, last_name, type, phone_number, email } =
    submittedBy as CreatedBy; //AB#9392 If no one submitted the PR, it should not display anything. createdBy should not be used in this tab.

  return (
    <div className={styles.submittedByContainer}>
      <div className={styles.section}>
        <Text element="p" small light className={styles.section__heading}>
          {tabCopy.headers.user}
        </Text>
        <Text element="span" bold>
          {first_name} {last_name}
        </Text>
        <Text element="span">
          {type === INTERNAL ? tabCopy.internalEmail : email}
        </Text>
        <Text element="span">
          {type === INTERNAL ? tabCopy.internalPhone : phone_number}
        </Text>
      </div>
      <div className={styles.section}>
        <Text element="p" small light className={styles.section__heading}>
          {tabCopy.headers.organization}
        </Text>
        <Text element="span">
          {type === INTERNAL
            ? tabCopy.internalOrganization
            : `${vendorIdOriginal} - ${vendorNameOriginal}`}
        </Text>
      </div>
      <div className={styles.section}>
        <Text element="p" small light className={styles.section__heading}>
          {tabCopy.headers.on}
        </Text>
        {submitted_on && <Text element="span">{dateFormat(submitted_on)}</Text>}
      </div>
    </div>
  );
};

export default SubmittedByTab;
