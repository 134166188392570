import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { priceHistoryColumns } from './PriceHistoryTableColumns';
import { PriceHistoryType } from '../../../types/productSearchTypes';

import Button from '../../Button';
import ChevronUp from '../../Icons/ChevronUp';
import LoadingOverlay from '../../LoadingOverlay';

import styles from './ProductDetailsModal.module.scss';

type PropsType = {
  loading: boolean;
};

const PriceHistoryTab = ({ loading }: PropsType) => {
  const [showMore, setShowMore] = useState(false);
  const [tableData, setTableData] = useState<any>([]);

  const { productPriceHistory } = useSelector(
    (state: RootState) => state.productPriceHistory
  );

  // The table is sorted by effective date in a descending order (the most recent effective date at the top). This function is to be used to ensure the product's price history is listed in this order.
  const compareEffectiveDateDescending = (
    a: PriceHistoryType,
    b: PriceHistoryType
  ) => {
    const dateA = new Date(a.effectiveDate);
    const dateB = new Date(b.effectiveDate);

    if (dateA > dateB) {
      return -1;
    } else if (dateA < dateB) {
      return 1;
    } else {
      return 0;
    }
  };

  // Sort the array in descending order using the custom compareEffectiveDateDescending
  const sortedProductHistoryArray = [...productPriceHistory];
  const orderArrayByEffectiveDates = sortedProductHistoryArray.sort(
    compareEffectiveDateDescending
  );

  const shortenedArray = orderArrayByEffectiveDates.slice(0, 5);
  const fullArray = orderArrayByEffectiveDates;

  useEffect(() => {
    setTableData(shortenedArray);
  }, [productPriceHistory]);

  const data = tableData;

  const options = {
    data: data,
    columns: priceHistoryColumns
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options);

  const toggleButtonDisplayed = orderArrayByEffectiveDates.length > 5;

  const toggleShowMore = () => {
    //if the toggle is closed on button click (showMore set to false), pass in the full array of price historys and toggle it to be open
    if (!showMore) {
      setTableData(fullArray);
      setShowMore(true);
    } // if the toggle is open on button click (showMore set to true), close the toggle and reset the table data to just the first 5 price history rows
    else {
      setTableData(shortenedArray);
      setShowMore(false);
    }
  };

  return (
    <div>
      {loading && <LoadingOverlay />}
      <div className={styles.priceHistoryUpper}>
        <div className={styles.productHistoryTable}>
          <div data-testid={`price-history-table`}>
            <table {...getTableProps()} className={styles.tableContainer}>
              <thead className={styles.tableHeader}>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className={styles.headerRow}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        className={styles.headerCells}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={classNames(styles.bodyRow, {
                        [styles.bodyRowHidden]:
                          !showMore && i > fullArray.length
                      })}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.cellContainer}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {toggleButtonDisplayed && (
              <div className={styles.buttonContainer}>
                <Button
                  link
                  small
                  iconRight
                  icon={<ChevronUp />}
                  onClick={() => toggleShowMore()}
                  className={classNames(styles.toggleButton, {
                    [styles.toggleButtonHidden]: !toggleButtonDisplayed,
                    [styles.toggleButtonIconTransform]: !showMore
                  })}
                >
                  {!showMore ? 'Show More' : 'Show Less'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceHistoryTab;
