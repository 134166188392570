import React from 'react';

import Heading from '../Heading';
import TextLink from '../TextLink';
import ArrowDownload from '../Icons/ArrowDownload';

import heroBg from '../../assets/home-hero-bg.jpeg';

import styles from './index.module.scss';

import copy from '../../config/translations/en-CA.json';
const heroCopy = copy.homePage.hero;

const HomeHero = () => (
  <div className={styles.homeHero}>
    <img src={heroBg} alt={heroCopy.imgAlt} className={styles.heroBg} />
    <div className={styles.heroContainer}>
      <Heading element="h1" heading1 className={styles.heroHeading}>
        {heroCopy.heading}
      </Heading>
      <div className={styles.heroButtonsContainer}>
        <TextLink
          button
          secondary
          className={styles.heroButton}
          route="/price-request-list"
          iconRight
          icon={<ArrowDownload />}
        >
          {heroCopy.myPRLink}
        </TextLink>
        <TextLink
          button
          secondary
          route="/price-request"
          className={styles.heroButton}
          iconRight
          icon={<ArrowDownload />}
        >
          {heroCopy.createPRLink}
        </TextLink>
      </div>
    </div>
  </div>
);

export default HomeHero;
