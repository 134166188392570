import React from 'react';

export default function NextArrow() {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-next-arrow">Arrow</title>
      <path
        d="M2.8126 1.77251C2.8126 1.20693 2.35174 0.749999 1.7813 0.749999C1.21086 0.749999 0.75 1.20693 0.75 1.77251L0.75 4.84004C0.75 6.53357 2.13581 7.90756 3.8439 7.90756L13.7283 7.90756L11.3627 10.2529C10.9599 10.6524 10.9599 11.301 11.3627 11.7004C11.7656 12.0999 12.4198 12.0999 12.8227 11.7004L16.9479 7.6104C17.3507 7.21098 17.3507 6.56233 16.9479 6.16291L12.8227 2.07287C12.4198 1.67345 11.7656 1.67345 11.3627 2.07287C10.9599 2.47229 10.9599 3.12094 11.3627 3.52036L13.7283 5.86255L3.8439 5.86255C3.27346 5.86255 2.8126 5.40561 2.8126 4.84004L2.8126 1.77251Z"
        fill="currentColor"
      />
    </svg>
  );
}
