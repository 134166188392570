import React from 'react';

export default function GarbageCan() {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-garbage-can">Delete</title>
      <path
        d="M0.166992 2.68884L0.166992 1.98702C0.166992 1.65365 0.431706 1.38546 0.760742 1.38546L3.53158 1.38546L3.76413 0.91674C3.86309 0.711206 4.06842 0.583374 4.29355 0.583374L7.12129 0.583374C7.34642 0.583374 7.55176 0.711206 7.65319 0.91674L7.88574 1.38546L10.6566 1.38546C10.9856 1.38546 11.2503 1.65365 11.2503 1.98702V2.68884C11.2503 2.85427 11.1167 2.98962 10.9535 2.98962L0.463867 2.98962C0.300586 2.98962 0.166992 2.85427 0.166992 2.68884ZM10.4587 4.09249L10.4587 12.2136C10.4587 12.8778 9.92676 13.4167 9.27116 13.4167L2.14616 13.4167C1.49056 13.4167 0.958659 12.8778 0.958659 12.2136L0.958659 4.09249C0.958659 3.92706 1.09225 3.79171 1.25553 3.79171L10.1618 3.79171C10.3251 3.79171 10.4587 3.92706 10.4587 4.09249ZM3.72949 5.79692C3.72949 5.57634 3.55137 5.39587 3.33366 5.39587C3.11595 5.39587 2.93783 5.57634 2.93783 5.79692L2.93783 11.4115C2.93783 11.6321 3.11595 11.8125 3.33366 11.8125C3.55137 11.8125 3.72949 11.6321 3.72949 11.4115L3.72949 5.79692ZM6.10449 5.79692C6.10449 5.57634 5.92637 5.39587 5.70866 5.39587C5.49095 5.39587 5.31283 5.57634 5.31283 5.79692V11.4115C5.31283 11.6321 5.49095 11.8125 5.70866 11.8125C5.92637 11.8125 6.10449 11.6321 6.10449 11.4115V5.79692ZM8.47949 5.79692C8.47949 5.57634 8.30137 5.39587 8.08366 5.39587C7.86595 5.39587 7.68783 5.57634 7.68783 5.79692V11.4115C7.68783 11.6321 7.86595 11.8125 8.08366 11.8125C8.30137 11.8125 8.47949 11.6321 8.47949 11.4115L8.47949 5.79692Z"
        fill="currentcolor"
      />
    </svg>
  );
}
