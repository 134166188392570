import React from 'react';

import HelmetContainer from '../../components/HelmetContainer/HelmetContainer';
import Heading from '../../components/Heading';
import Layout from '../../components/Layout';
import ProductSearchTable from '../../components/ProductSearchTable';

import copy from '../../config/translations/en-CA.json';

import styles from '../index.module.scss';

const ProductSearchPage = () => {
  const pageCopy = copy.productSearchPage;

  return (
    <Layout>
      <HelmetContainer title={pageCopy.pageTitle} />
      <div className={styles.heading}>
        <Heading element="h2" heading3>
          Product Search
        </Heading>
      </div>
      <ProductSearchTable />
    </Layout>
  );
};

export default ProductSearchPage;
