import React, { useState, ChangeEvent, DragEvent, useRef } from 'react';

import Text from '../Text/Text';
import UploadFile from '../Icons/UploadFile';

import styles from './CSVUpload.module.scss';
import copy from '../../config/translations/en-CA.json';
import Banner from '../Banner';
import ExclamationPoint from '../Icons/ExclamationPoint';
interface UploadFormProps {
  setUserUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const UploadForm: React.FC<UploadFormProps> = ({ setUserUploadedFile }) => {
  const modalCopy = copy.modals[0].uploadCSVModal.upload;
  const [dragActive, setDragActive] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer?.files[0]) {
      const selectedFile = e.dataTransfer.files[0];
      setUserUploadedFile(selectedFile);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setUserUploadedFile(selectedFile);
    }
  };

  const handleSelectFileClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      className={styles.formFileUpload}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        type="file"
        id="input-file-upload"
        className={styles.inputFileUpload}
        multiple={false}
        onChange={handleChange}
        ref={inputRef}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={`${styles.labelFileUpload} ${
          dragActive ? styles.dragActive : ''
        }`}
      >
        <UploadFile />
        <br />
        <button className={styles.uploadButton} onClick={handleSelectFileClick}>
          {modalCopy.heading}
          <Text element="p">{modalCopy.subHeading}</Text>
        </button>
        <Text element="p" tiny light>
          {modalCopy.support}
        </Text>
        <br />
        <Banner
          type={'warning'}
          description={modalCopy.warning}
          icon={<ExclamationPoint />}
        />
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          className={styles.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};

export default UploadForm;
