import React from 'react';

import Text from '../Text';

interface GridItemProps {
  title: string;
  value: string | number;
  className?: string;
}

const ProductDetailsGridItem: React.FC<GridItemProps> = ({
  className,
  title,
  value
}) => {
  return (
    <div className={className}>
      <Text element="span" tiny light upperCase>
        {title}
      </Text>
      <Text element="p" tooltip={value?.toString()}>
        {value}
      </Text>
    </div>
  );
};

export default ProductDetailsGridItem;
