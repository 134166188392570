import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { setBanner } from '../../reducers/PriceRequestSlice';
import { setWorkingGroupProducts } from '../../reducers/WorkingGroupSlice';

import { useGetPriceRequestsListQuery } from '../../services/PriceRequest';

import {
  getSessionToken,
  ISearchParams,
  searchObjectToParams,
  PriceRequestStatusEnum
} from '../../helpers';

import Button from '../Button';
import Text from '../Text';
import ArrowRight from '../Icons/ArrowRight';

import 'react-tooltip/dist/react-tooltip.css';

import copy from '../../config/translations/en-CA.json';
const addDraftsToWorkingGroupButtonCopy = copy.buttons.addDraftsToWorkingGroup;
const addDraftsToWorkingGroupBannerCopy = copy.banners.addDraftsToWorkingGroup;

const AddDraftsButton = () => {
  const token = getSessionToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { workingGroupProducts } = useSelector(
    (state: RootState) => state.workingGroup
  );

  const { filters } = useSelector((state: RootState) => state.priceRequest);

  const hasNonDraftStatusFilter = filters.some(
    (item) => item.field === 'status' && item.value !== 'Draft'
  );

  const getDraftsFilters = [...filters];

  const draftStatusFilter = {
    field: 'status',
    operator: '=',
    value: PriceRequestStatusEnum.DRAFT
  };

  getDraftsFilters.push(draftStatusFilter);

  const mapObject: ISearchParams = {
    filters: getDraftsFilters,
    orders: [
      {
        by: 'sku',
        type: 'asc'
      }
    ],
    limit: 1000,
    offset: 0
  };

  const queryParams = searchObjectToParams(mapObject);

  const { data, isLoading, isError } = useGetPriceRequestsListQuery(
    {
      queryParams,
      token
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const handleClick = () => {
    const skuArray = data?.items.map((item: any) => item.sku);

    if (skuArray && skuArray?.length > 0 && !hasNonDraftStatusFilter) {
      dispatch(setWorkingGroupProducts([...workingGroupProducts, ...skuArray]));
      navigate('/price-request');
    } else {
      dispatch(
        setBanner({
          title: addDraftsToWorkingGroupBannerCopy.title,
          type: 'info',
          description: addDraftsToWorkingGroupBannerCopy.description
        })
      );
    }
  };

  if (isError) {
    console.error('Error getting drafts for working group');
  }

  return (
    <div id="addDraftsButton">
      <Button
        iconRight
        primary
        onClick={handleClick}
        disabled={isError}
        icon={<ArrowRight />}
      >
        {isLoading
          ? addDraftsToWorkingGroupButtonCopy.loading
          : addDraftsToWorkingGroupButtonCopy.title}
      </Button>
      <ReactTooltip
        anchorId="addDraftsButton"
        aria-live="polite"
        place="bottom"
      >
        <Text element="p" small>
          {addDraftsToWorkingGroupButtonCopy.tooltip}
        </Text>
      </ReactTooltip>
    </div>
  );
};

export default AddDraftsButton;
