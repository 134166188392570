import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UploadedPriceRequest } from '../reducers/CSVBulkUploadSlice';
import { triggerActivityMonitorReset } from '../reducers/ActivityMonitorSlice';

import { fetchApiData, handleApiResponse, getSessionToken } from '../helpers';

import { ProductType } from '../types/productTypes';

import copy from '../config/translations/en-CA.json';

const modalCopy = copy.modals[0].parseCSVModal;
const errorMessages = modalCopy.validationErrors;

interface SkuLevelValidationProps {
  getUserAccessibleSkus: (skus: number[]) => Promise<ProductType[]>;
  handleAddErrorPriceRequest: (
    priceRequest: UploadedPriceRequest,
    error_message: string
  ) => void;
}

const useSkuLevelValidation = ({
  getUserAccessibleSkus,
  handleAddErrorPriceRequest
}: SkuLevelValidationProps) => {
  const dispatch = useDispatch();
  const token = getSessionToken();

  const checkSkuExists = async (sku: number) => {
    dispatch(triggerActivityMonitorReset(true));
    const result = await fetchApiData(
      `products/sku-exists/${sku}`,
      token,
      'GET'
    ).catch((error) => {
      throw new Error(error);
    });

    return await handleApiResponse(result);
  };

  return useCallback(
    async (data: UploadedPriceRequest[]) => {
      const validator = {
        isNumber: (sku: number) => {
          return typeof sku === 'number';
        },
        isValidStatus: (status: string) => {
          return status !== '2' && status !== '3';
        }
      };

      const skuValidatedPriceRequests: UploadedPriceRequest[] = [];
      const validSkus: number[] = [];
      const errorSkus: number[] = [];

      // iterate over uploaded data to initially validate
      // that submitted SKUs are formatted properly
      data.forEach((row: UploadedPriceRequest) => {
        if (validator.isNumber(row.sku)) {
          validSkus.push(row.sku);
          skuValidatedPriceRequests.push(row);
        } else {
          errorSkus.push(row.sku);
          handleAddErrorPriceRequest(row, errorMessages.skuNotRecognized);
        }
      });

      // Determine if SKU exists to set correct error message
      // Add inaccessible/invalid SKUs to error list
      const handleInaccessibleSkus = async (product: UploadedPriceRequest) => {
        const { sku } = product;
        const skuExists = await checkSkuExists(sku);
        const errorMessage = skuExists
          ? errorMessages.skuNotAuthorized
          : errorMessages.skuNotRecognized;
        handleAddErrorPriceRequest(product, errorMessage);
        errorSkus.push(sku);
      };

      // Call GetUserAccessibleSkus (draft-by-products)
      // with list of format validated skus
      try {
        const skuResponseProducts = await getUserAccessibleSkus(validSkus);

        // Compare response object with request SKU and filter
        // out SKUs the user does not have access too
        const invalidAccessProducts = skuValidatedPriceRequests.filter(
          (product: UploadedPriceRequest) => {
            return !skuResponseProducts.some(
              (responseItem: ProductType) => responseItem.sku === product.sku
            );
          }
        );

        await Promise.all(invalidAccessProducts.map(handleInaccessibleSkus));

        // Validate Product status
        const invalidStatusProducts = skuResponseProducts.filter(
          (product: ProductType) => {
            return validator.isValidStatus(product.status_code);
          }
        );

        // Filter requested SKU list for invalid status products
        // add invalid SKUs to error list
        invalidStatusProducts.forEach((product: ProductType) => {
          const invalidStatusPriceRequest = skuValidatedPriceRequests.filter(
            (priceRequest) => priceRequest.sku === product.sku
          );
          invalidStatusPriceRequest.forEach((invalidPriceRequest) => {
            errorSkus.push(invalidPriceRequest.sku);
            handleAddErrorPriceRequest(
              invalidPriceRequest,
              errorMessages.invalidProductStatus
            );
          });
        });

        // Final comparison between request SKUs and error SKUs
        const validatedPriceRequests = skuValidatedPriceRequests.filter(
          (priceRequest: UploadedPriceRequest) => {
            return !errorSkus.includes(priceRequest.sku);
          }
        );

        // Return SKU Validate Price requests
        return validatedPriceRequests;
      } catch (error) {
        // If no accessible skus are available the draft-by-product api returns
        // 404. Assuming this is the case, we can add all SKUs to the error list
        // console.error('Error fetching product data:', error);

        await Promise.all(
          skuValidatedPriceRequests.map(handleInaccessibleSkus)
        );

        return [];
      }
    },
    [getUserAccessibleSkus, handleAddErrorPriceRequest]
  );
};

export default useSkuLevelValidation;
