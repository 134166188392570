import React, { StrictMode } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { setupStore } from './store';

import AuthenticationProvider from './contexts/AuthenticationProvider';

import AuthRoutes from './components/AuthRoutes';

import PriceRequestListPage from './pages/PriceRequestListPage';
import PriceRequestPage from './pages/PriceRequestPage';
import ProductSearchPage from './pages/ProductSearchPage';

import HomePage from './pages/homePage';
import ActivityMonitor from './components/ActivityMonitor';

const { store, persistor } = setupStore();

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthenticationProvider>
            <StrictMode>
              <BrowserRouter>
                <Routes>
                  <Route path={'/'} element={<HomePage />} />
                  <Route element={<AuthRoutes />}>
                    <Route
                      path={'/price-request-list'}
                      element={<PriceRequestListPage />}
                    />
                    <Route
                      path={'/price-request'}
                      element={<PriceRequestPage />}
                    />
                    <Route
                      path={'/product-search'}
                      element={<ProductSearchPage />}
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
              <ActivityMonitor />
            </StrictMode>
          </AuthenticationProvider>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
