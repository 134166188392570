import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';
import {
  PriceRequestSearchResponse,
  PriceRequest,
  CreatedByResponse,
  ValidatePromotion,
  ValidatePromotionResponse,
  ApproveDeclinePRBody
} from '../types/PriceRequestsTypes';
import { PriceRequestType, ProductType } from '../types/productTypes';

type QueryParams = {
  queryParams?: string;
  token?: string | null;
  id?: string | number | null;
};

type Token = { token: string | null };

type DraftByProductsPayload = {
  requestBody: {
    skus: string[] | undefined;
  };
  token: string | null;
};

type SubmitAllWorkingGroupPayload = {
  requestBody: {
    prIds: (number | undefined)[] | undefined;
  };
  token: string | null;
};

type AgentSupplierReviewPayload = {
  requestBody: {
    id: number | undefined;
  };
  token: string | null;
};

type RequestBodyPayload = {
  requestBody: PriceRequestType;
  token: string | null;
};

type RequestPayloadGetBySku = {
  skus: string[];
  token: string | null;
};

type RequestBodyValidatePromotionPayload = {
  body: ValidatePromotion;
  token: string | null;
};

type ApproveDeclinePayload = {
  body: ApproveDeclinePRBody;
  token: string | null;
};

type simpleMessageResponse = {
  msg: string;
};

type mutateSinglePayload = {
  prID: number | undefined;
  token: string | null;
};

export const priceRequestApi = createApi({
  reducerPath: 'priceRequestApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  endpoints: (builder) => ({
    getPriceRequestsList: builder.query<
      PriceRequestSearchResponse,
      QueryParams
    >({
      query: ({ queryParams, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `price-request/search?${queryParams}`
      })
    }),
    getPriceRequestCreatedByOptions: builder.query<CreatedByResponse[], Token>({
      query: ({ token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `/price-request/created-by`
      })
    }),
    submitAllWorkingGroupPriceRequests: builder.mutation<
      PriceRequestType,
      SubmitAllWorkingGroupPayload
    >({
      query: ({ requestBody, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: 'price-request/working-group-submit',
        body: requestBody
      })
    }),
    submitAgentSupplierReview: builder.mutation<
      PriceRequestType,
      AgentSupplierReviewPayload
    >({
      query: ({ requestBody, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: 'price-request/as-review',
        body: requestBody
      })
    }),
    deletePriceRequestGroup: builder.mutation<
      PriceRequestType,
      DraftByProductsPayload
    >({
      query: ({ requestBody, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'DELETE',
        url: `price-request/skus`,
        body: requestBody,
        responseHandler: (response) => response.text()
      })
    }),
    getDraftPriceRequests: builder.mutation<
      ProductType[],
      DraftByProductsPayload
    >({
      query: ({ requestBody, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: 'price-request/draft-by-products',
        body: requestBody
      })
    }),
    createDraftPriceRequest: builder.mutation<
      PriceRequestType,
      RequestBodyPayload
    >({
      query: ({ requestBody, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: 'price-request/create',
        body: requestBody
      })
    }),
    validatePromotionRequest: builder.mutation<
      ValidatePromotionResponse,
      RequestBodyValidatePromotionPayload
    >({
      query: ({ body, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: 'price-validation/validate-promotion',
        body
      })
    }),
    getPriceRequestsLogs: builder.query<string, QueryParams>({
      query: ({ id, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
          'Cache-Control':
            'no-store, no-cache, must-revalidate, proxy-revalidate',
          Pragma: 'no-cache',
          Expires: '0'
        },
        url: `price-request/logs/download/${id}?_=${Date.now()}`,
        responseHandler: (response) => response.text()
      })
    }),
    approvePriceRequest: builder.mutation<
      simpleMessageResponse,
      ApproveDeclinePayload
    >({
      query: ({ body, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: 'price-request/approve',
        body
      })
    }),
    declinePriceRequest: builder.mutation<
      simpleMessageResponse,
      ApproveDeclinePayload
    >({
      query: ({ body, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: 'price-request/decline',
        body
      })
    }),
    deleteSinglePriceRequest: builder.mutation<
      PriceRequestType,
      mutateSinglePayload
    >({
      query: ({ prID, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'DELETE',
        url: `price-request/${prID}`
      })
    }),
    cancelSinglePriceRequest: builder.mutation<
      simpleMessageResponse,
      mutateSinglePayload
    >({
      query: ({ prID, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: 'price-request/cancel',
        body: {
          id: prID
        }
      })
    }),
    updateDraftPriceRequest: builder.mutation<
      PriceRequestType,
      RequestBodyPayload
    >({
      query: ({ requestBody, token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        method: 'POST',
        url: `price-request/update`,
        body: requestBody
      })
    }),
    getPriceRequestBySku: builder.query<PriceRequest[], RequestPayloadGetBySku>(
      {
        query: ({ skus, token }) => ({
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`
          },
          url: `price-request/search-by-sku/${skus}`
        })
      }
    )
  })
});

export const {
  useGetPriceRequestsListQuery,
  useGetPriceRequestCreatedByOptionsQuery,
  useDeletePriceRequestGroupMutation,
  useSubmitAllWorkingGroupPriceRequestsMutation,
  useSubmitAgentSupplierReviewMutation,
  useGetDraftPriceRequestsMutation,
  useCreateDraftPriceRequestMutation,
  useValidatePromotionRequestMutation,
  useGetPriceRequestsLogsQuery,
  useApprovePriceRequestMutation,
  useDeclinePriceRequestMutation,
  useGetPriceRequestBySkuQuery,
  useUpdateDraftPriceRequestMutation,
  useDeleteSinglePriceRequestMutation,
  useCancelSinglePriceRequestMutation
} = priceRequestApi;
