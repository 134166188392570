import React from 'react';

export default function AdditionSign() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-addition-sign">Addition Sign</title>
      <path
        d="M16.0714 7.23214H10.7679V1.92857C10.7679 1.27778 10.2401 0.75 9.58929 0.75H8.41071C7.75992 0.75 7.23214 1.27778 7.23214 1.92857V7.23214H1.92857C1.27778 7.23214 0.75 7.75992 0.75 8.41071V9.58929C0.75 10.2401 1.27778 10.7679 1.92857 10.7679H7.23214V16.0714C7.23214 16.7222 7.75992 17.25 8.41071 17.25H9.58929C10.2401 17.25 10.7679 16.7222 10.7679 16.0714V10.7679H16.0714C16.7222 10.7679 17.25 10.2401 17.25 9.58929V8.41071C17.25 7.75992 16.7222 7.23214 16.0714 7.23214Z"
        fill="currentColor"
      />
    </svg>
  );
}
