import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { useDispatch } from 'react-redux';

import authHelpers from '../helpers/authHelpers';
import { fetchApiData, handleApiResponse, getSessionToken } from '../helpers';
import { setProductSuppliersList } from '../reducers/PriceRequestSlice';
import { useGetProductSuppliersListQuery } from '../services/Products';
import { useTokenMutation } from '../services/Token';
import { IAuthState, IAuthenticationContextData } from '../types/authTypes';

const unAuthenticatedDefaultState: IAuthState = {
  isAuthenticated: false,
  isLoading: false,
  token: null,
  isError: false,
  user: {
    firstName: '',
    lastName: '',
    email: '',
    userObjectID: '',
    type: '',
    vendorList: []
  }
};

const AuthenticationContextDefaultValue: IAuthenticationContextData = {
  authState: unAuthenticatedDefaultState,
  handleLogin: () => null,
  handleLogout: () => null
};

export const AuthenticationContext = createContext<IAuthenticationContextData>(
  AuthenticationContextDefaultValue
);

// Hook wrapper for auth context
export const useAuth = () => {
  return useContext(AuthenticationContext);
};

// Get/Generate sessionUuid, used for tracking user sessions
// across multiple tabs and blocklisting JWT in BE
const sessionUuid = authHelpers.getUuid();
const clearUuid = authHelpers.clearUuid;

// Authentication Context
const useAuthenticationContextValue = (): IAuthenticationContextData => {
  const [authState, setAuthState] = useState<IAuthState>(
    unAuthenticatedDefaultState
  );

  const [exchangeCodeRequest] = useTokenMutation();

  // Login Handler
  // Create PKCE codes and redirect to BCLDB
  const handleLogin = useCallback((): void => {
    sessionStorage.clear();
    setAuthState((prevState) => {
      return {
        ...prevState,
        isLoading: true
      };
    });
    const pkceQueryString = new URLSearchParams(
      authHelpers.createPkceQueryParams()
    ).toString();
    const newUrl = `${authHelpers.AUTH_ENDPOINT}?${pkceQueryString}`;

    window.location.href = newUrl;
  }, []);

  // Logout Handler
  const handleLogout = useCallback(async (token: string): Promise<void> => {
    const requestBody = { session_uuid: sessionUuid };

    try {
      const response = await fetchApiData(
        `api/logout`,
        token,
        'POST',
        requestBody
      );
      const data = await handleApiResponse(response);

      setAuthState({
        ...unAuthenticatedDefaultState
      });
    } catch (error) {
      console.error(error);
    } finally {
      clearUuid();
      sessionStorage.clear();
      window.history.pushState({}, '', '/');
      window.location.href = authHelpers.LOGOUT_URI;
    }
  }, []);

  const getToken = useCallback(async (): Promise<string | null> => {
    let authToken = sessionStorage.getItem('authToken');

    if (!authToken || authToken === 'undefined') {
      authHelpers.removeSessionStorageItem('authToken');
      await exchangeCodeForToken();
      authToken = sessionStorage.getItem('authToken');
    }

    return authToken;
  }, []);

  const exchangeCodeForToken = useCallback(async (): Promise<void> => {
    const code = new URLSearchParams(window.location.search).get('code');
    const pkceInfo = JSON.parse(sessionStorage.getItem('pkceInfo') ?? 'null');

    if (!code || !pkceInfo) return;

    const requestBody = {
      code,
      code_verifier: pkceInfo.code_verifier,
      session_uuid: sessionUuid
    };

    await exchangeCodeRequest({ requestBody })
      .then((result: any) => {
        if (result.data) {
          const { token } = result.data;
          authHelpers.removeSessionStorageItem('pkceInfo');
          authHelpers.addSessionStorageItem('authToken', token);
        }
      })
      .catch((error) => {
        setAuthState({
          ...unAuthenticatedDefaultState,
          isError: true
        });
      });
  }, [exchangeCodeRequest, sessionUuid]);

  const getUserData = useCallback(async (token: string | null) => {
    if (!token) return false;

    try {
      const response = await fetchApiData(`user/profile`, token, 'GET', '');

      const userData = await handleApiResponse(response);

      if (userData && userData?.error) {
        throw new Error('Failed to get User');
      }

      const { firstName, lastName, email, userObjectID, type, vendorList } =
        userData;

      return {
        firstName,
        lastName,
        email,
        userObjectID,
        type,
        vendorList
      };
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Authenticate Application
  const authenticateApp = useCallback(async () => {
    const authToken = await getToken();
    const authError = new URLSearchParams(window.location.search).get('error');

    // Already authenticated - return
    if (authState.isAuthenticated && authToken) return;

    if (authError) {
      setAuthState({
        ...unAuthenticatedDefaultState,
        isError: true
      });

      return;
    }

    if (authToken) {
      const tokenExpired = authHelpers.checkTokenExpiry(authToken);

      if (tokenExpired) authHelpers.redirectToLogin();

      const user = await getUserData(authToken);

      if (user) {
        setAuthState((prevState) => {
          return {
            ...prevState,
            isAuthenticated: true,
            isLoading: false,
            isError: false,
            token: authToken,
            user
          };
        });

        authHelpers.clearUrlParams();
      }
    } else {
      handleLogin();
    }
  }, [authState.isAuthenticated, getToken, handleLogin, getUserData]);

  useEffect(() => {
    authenticateApp();
  }, [authenticateApp]);

  return {
    authState,
    handleLogin,
    handleLogout
  };
};

type PropsType = {
  children: React.ReactNode;
};

const AuthenticationProvider = ({ children }: PropsType) => {
  const AuthenticationContextValue = useAuthenticationContextValue();
  const dispatch = useDispatch();
  const { authState } = AuthenticationContextValue;
  const authToken = authState.isAuthenticated ? getSessionToken() : null;

  // Create a list of options of all of the listing agents the user is able to view,
  // this is using the 'products/suppliers' api call that has access control.
  // LDB users will see all suppliers
  const {
    data: getProductSuppliersData,
    isSuccess: getProductSuppliersIsSuccess
  } = useGetProductSuppliersListQuery(
    { token: String(authToken) },
    { refetchOnMountOrArgChange: true, skip: !authToken }
  );

  useEffect(() => {
    if (getProductSuppliersIsSuccess) {
      dispatch(setProductSuppliersList(getProductSuppliersData));
    }
  }, [getProductSuppliersIsSuccess]);

  return (
    <AuthenticationContext.Provider value={AuthenticationContextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
