// this will take an object like sampleData below and create search params

export interface IFilters {
  field: string;
  operator: string;
  value: string | number | string[];
}

export interface IOrders {
  by: string;
  type: string;
}

export interface ISearchParams {
  filters: IFilters[];
  orders: IOrders[];
  limit: number;
  offset: number;
}

export const searchObjectToParams = (searchObject: ISearchParams) => {
  const p = new URLSearchParams();
  // get all the top level object keys and for each of those create entries for them
  const entries = Object.entries(searchObject);
  entries.forEach((entry) => {
    // return fast idf the entry is either limit or offset
    const [key, value] = entry;
    if (key === 'limit' || key === 'offset') {
      p.append(key, value);
      return;
    }
    if (!(value instanceof Array)) {
      // something is wrong. bail
      return;
    }
    //   if we are here we have an array of objects
    value.forEach((val, index) => {
      // get the filter or order params
      const data = Object.entries(val);
      data.forEach((d: any) => {
        // add the key to the search params
        const [dKey, dVal] = d;
        const searchKey = `${key}[${index}][${dKey}]`;
        p.append(searchKey, dVal);
      });
    });
  });
  return p.toString();
};
