import React, {
  forwardRef,
  useEffect,
  useRef,
  MouseEventHandler,
  ChangeEventHandler
} from 'react';
import classNames from 'classnames';

import Checkmark from '../Icons/CheckMark';

import styles from './index.module.scss';

interface IIndeterminateInputProps {
  indeterminate?: boolean;
  disabled?: boolean;
  isSelected?: boolean;
  sku: string;
  id?: string;
  dataTestId?: string;
  checked?: boolean;
  onClick?: MouseEventHandler<HTMLInputElement> | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}

// This component is modelled after the Indeterminate Checkbox example from the Row Selection
// React Table docs found here: https://react-table-v7.tanstack.com/docs/examples/row-selection

// This creates the ability to for single and multi-row (or in our case all table rows) selection in our tables
// You can find an example of this in the ProductSearchTable component, where this component is used in the useTable react hook declaration, inserting checkboxes in the first column header and cells for the ability to select and add products to the working group.

const useCombinedRefs = (
  ...refs: Array<React.Ref<HTMLInputElement> | React.MutableRefObject<null>>
): React.MutableRefObject<HTMLInputElement | null> => {
  const targetRef = useRef(null);

  useEffect(() => {
    refs.forEach(
      (ref: React.Ref<HTMLInputElement> | React.MutableRefObject<null>) => {
        if (!ref) return;

        if (typeof ref === 'function') {
          ref(targetRef.current);
        } else {
          ref.current = targetRef.current;
        }
      }
    );
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IIndeterminateInputProps
>(
  (
    {
      indeterminate,
      disabled,
      sku,
      id,
      isSelected,
      dataTestId,
      checked,
      onClick,
      onChange,
      ...rest
    },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return (
      <div className={styles.checkboxContainer}>
        <label className={styles.container}>
          <input
            type="checkbox"
            disabled={disabled}
            className={styles.checkbox}
            ref={combinedRef}
            id={id}
            data-testid={dataTestId}
            onChange={onChange}
            onClick={onClick}
            checked={checked}
            {...rest}
          />
          <span
            className={classNames(styles.checkmark, {
              [styles.checkmarkSelected]: checked,
              [styles.checkmarkDisabled]: disabled
            })}
          >
            {checked && <Checkmark />}
          </span>
        </label>
      </div>
    );
  }
);

export default IndeterminateCheckbox;
