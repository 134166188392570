import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import Header from '../Header';
import Footer from '../Footer';
import FallbackBanner from '../FallbackBanner';

import styles from './Layout.module.scss';

type PropTypes = {
  children: React.ReactNode;
};

const Layout = ({ children }: PropTypes) => {
  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  return (
    <div className={styles.layoutContainer}>
      <Header />
      <ErrorBoundary fallback={<FallbackBanner />}>
        <main className={styles.layoutWrapper}>{children}</main>
      </ErrorBoundary>
      <Footer />
    </div>
  );
};

export default Layout;
