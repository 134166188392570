// This file holds all of the Product Search Table form's search query functionality for select inputs

// Import/ Domestic Select Options
export const ImportSelectOptions = [
  {
    label: 'Domestic',
    value: 'CA'
  },
  {
    label: 'Import',
    value: 'Other'
  }
];

// Product Status Select Options based on user type
// Two user types INTERNAL (Internal LDB Stuff/ User),EXTERNAL_B2B (External User Agent/ Supplier)
// There are other Read Only INTERNAL and EXTERNAL_B2B roles in the LDB Technical Documentation for Versett 1.0.2
// But these types are highlighted by LDB to confirm if needed by their team internally
// Status 4 = Delisted should not show at any time
// the below function
export const ProductStatusSelectOptions = (userType: string) => {
  let options = [];
  if (userType === 'EXTERNAL_B2B') {
    options = [
      {
        label: 'Active',
        value: '2'
      },
      {
        label: 'Pending Delist',
        value: '3'
      }
    ];
    return options;
  } else {
    options = [
      {
        label: 'Pending Listing',
        value: '1'
      },
      {
        label: 'Active',
        value: '2'
      },
      {
        label: 'Pending Delist',
        value: '3'
      }
    ];
    return options;
  }
};

//generic select options for all of the rest of the select inputs:
export const selectOptions = [
  {
    label: 'Select',
    value: ''
  }
];

export const defaultSelectNumber = { label: 'Select...', value: '0' };
export const defaultSelectString = { label: 'Select...', value: '' };
