import React from 'react';

export default function UploadFile() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.4309 4.28206L53.0426 13.8937C54.0737 14.9248 54.6579 15.8785 54.6579 17.3334L39.9998 17.3334V2.66675C41.4548 2.66675 42.3999 3.25101 43.4309 4.28206ZM35.8398 2.66675H16.1654C13.1296 2.6782 10.6665 5.14126 10.6665 8.17713V55.8345C10.6665 58.8703 13.1296 61.3334 16.1654 61.3334H49.159C52.1949 61.3334 54.6579 58.8703 54.6579 55.8345V21.5201H38.5865C37.0628 21.5201 35.8398 20.2704 35.8398 18.7467V2.66675Z"
        fill="currentColor"
      />
      <g clipPath="url(#clip0_2530_145268)">
        <path
          d="M38.2856 36.4287H33.5713V31.7144C33.5713 31.1359 33.1021 30.6667 32.5236 30.6667H31.476C30.8975 30.6667 30.4284 31.1359 30.4284 31.7144V36.4287H25.7141C25.1356 36.4287 24.6665 36.8978 24.6665 37.4763V38.5239C24.6665 39.1024 25.1356 39.5715 25.7141 39.5715H30.4284V44.2858C30.4284 44.8643 30.8975 45.3334 31.476 45.3334H32.5236C33.1021 45.3334 33.5713 44.8643 33.5713 44.2858V39.5715H38.2856C38.864 39.5715 39.3332 39.1024 39.3332 38.5239V37.4763C39.3332 36.8978 38.864 36.4287 38.2856 36.4287Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2530_145268">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(24 30)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
