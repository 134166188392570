import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

import Text from '../Text';
import Label from '../Label';

import styles from './index.module.scss';
import {
  preventIllegalCharacterHandlePaste,
  preventIllegalCharactersHandleKeyDown
} from '../../helpers';

type PropsType = {
  label?: string;
  placeholder?: string;
  id: string;
  helperText?: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string | number | readonly string[] | undefined;
  message?: string;
  disabled?: boolean;
  helper?: boolean;
  ariaLabelledBy?: string;
  tooltip?: string;
  readOnly?: boolean;
  maxLength?: number;
};

const TextArea = ({
  label,
  placeholder,
  id,
  helperText,
  required,
  message,
  disabled,
  value,
  helper,
  onChange,
  ariaLabelledBy,
  tooltip,
  readOnly,
  maxLength
}: PropsType) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const name = id;

  return (
    <div className={styles.textAreaContainer}>
      {label && (
        <Label
          className={styles.label}
          htmlFor={id}
          error={!!errors[id]}
          tooltip={tooltip}
        >
          {label}
        </Label>
      )}
      <textarea
        id={id}
        className={classNames(styles.textarea, {
          [styles.textareaError]: errors[id],
          [styles.textareaDisabled]: disabled || readOnly
        })}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        {...register(name, {
          ...(required ? { required: 'This field is required' } : {})
        })}
        onChange={onChange}
        onKeyDown={preventIllegalCharactersHandleKeyDown}
        onPaste={preventIllegalCharacterHandlePaste}
        aria-labelledby={ariaLabelledBy}
        readOnly={readOnly}
        maxLength={maxLength}
        required={required}
      ></textarea>
      {helper && (
        <div className={styles.textContainer}>
          {errors[id] ? (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <Text element="p" small className={styles.helperTextError}>
                  {message}
                </Text>
              )}
            />
          ) : helperText ? (
            <Text element="p" small className={styles.helperText}>
              {helperText}
            </Text>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default TextArea;
