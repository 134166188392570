import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

import { resetState } from '../../../reducers/CSVBulkUploadSlice';

import CSVUpload from '../../CSVUpload';
import Heading from '../../Heading';
import Modal from '../index';

import styles from './UploadModal.module.scss';

import copy from '../../../config/translations/en-CA.json';

const UploadModal = () => {
  const modalCopy = copy.modals[0].uploadCSVModal;
  const dispatch = useDispatch();
  const { openBulkCSVModal } = useSelector(
    (state: RootState) => state.csvBulkUpload
  );

  return (
    <Modal
      isOpen={openBulkCSVModal}
      onRequestClose={() => dispatch(resetState())}
      closeable
    >
      <div className={styles.uploadModalContainer}>
        <Heading element="h3">{modalCopy.title}</Heading>
        <CSVUpload />
      </div>
    </Modal>
  );
};

export default UploadModal;
