import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFilters, useTable } from 'react-table';
import { sortColumnsByHeader } from '../../helpers';
import {
  setOpenModal,
  setPriceRequestTable,
  setSku,
  setSelectedPriceRequestID
} from '../../reducers/PriceRequestSlice';
import { RootState } from '../../store';
import ArrowDown from '../Icons/ArrowDown';
import ArrowUp from '../Icons/ArrowUp';
import TableTooltip from '../TableTooltip';
import { priceRequestColumns } from './PriceRequestListsTableColumns';

import styles from './PriceRequestListTable.module.scss';
import { itemsPerPage } from './utils';

export default function PriceRequestListTableContent() {
  const { priceRequestTable } = useSelector(
    (state: RootState) => state.priceRequest
  );
  const { sortBy, sortType } = priceRequestTable;
  const dispatch = useDispatch();
  const { tableData } = priceRequestTable;
  const [location, setLocation] = useState('');

  const options = {
    data: tableData,
    columns: priceRequestColumns
  };

  const toolTipCallback = (state: string) => {
    setLocation(state);
  };

  const handleSortButton = (sortBy: string) => {
    if (sortBy !== 'selection') {
      const columnSortBy = sortColumnsByHeader(sortBy);
      dispatch(
        setPriceRequestTable({
          ...priceRequestTable,
          sortBy: columnSortBy,
          pageSize: itemsPerPage,
          pageNumber: 0,
          tableData: [],
          sortType: sortType === 'asc' ? 'desc' : 'asc'
        })
      );
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(options as any, useFilters);
  return (
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th
                {...column.getHeaderProps()}
                className={classNames(styles.th, {
                  [styles.thSelected]: sortColumnsByHeader(column.id) === sortBy
                })}
                onClick={() => handleSortButton(column.id)}
              >
                <span className={styles.headerColumnWrapper}>
                  <span className={styles.shortLabel}>
                    {column.render('Header')}
                  </span>
                  {sortColumnsByHeader(column.id) === sortBy &&
                  sortType === 'asc' ? (
                    <div className={styles.arrowAsc}>
                      <ArrowUp />
                    </div>
                  ) : sortColumnsByHeader(column.id) === sortBy &&
                    sortType === 'desc' ? (
                    <div className={styles.arrowDesc}>
                      <ArrowDown />
                    </div>
                  ) : null}

                  {(headerGroup.headers[index] as any).tipText && (
                    <span
                      className={classNames(styles.tooltipContainer, {
                        [styles.tooltipContainerTop]: location === 'top',
                        [styles.tooltipContainerBottom]: location === 'bottom'
                      })}
                    >
                      <TableTooltip
                        label={(headerGroup.headers[index] as any).tipText}
                        callback={toolTipCallback}
                      />
                    </span>
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map((cell) => {
                return (
                  <td
                    className={styles.cellContainer}
                    {...cell.getCellProps()}
                    onClick={
                      cell.column.id !== 'selection'
                        ? () => {
                            dispatch(setSku((row.original as any).sku));
                            dispatch(
                              setSelectedPriceRequestID(
                                (row.original as any).id
                              )
                            );
                            dispatch(setOpenModal(true));
                          }
                        : undefined
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
