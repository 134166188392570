import React from 'react';

import {
  getSessionToken,
  productStatusSearch,
  searchObjectToParams,
  ISearchParams
} from '../../helpers';

import Text from '../Text';
import ProductDetailsGridItem from './_ProductDetailsGridItem';

import copy from '../../config/translations/en-CA.json';

import styles from './ProductDetailsHeader.module.scss';
import { useGetProductsQuery } from '../../services/Products';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useGetCountriesQuery } from '../../services/Country';

type GridItem = {
  title: string;
  value: string | number;
};

const ProductDetails: React.FC = () => {
  const token = getSessionToken();

  const productDetailsHeadersCopy = copy.modals[0].productDetailsHeader;

  const { sku } = useSelector((state: RootState) => state.priceRequest);
  const mapObject: ISearchParams = {
    filters: [
      {
        field: 'sku',
        operator: '=',
        value: sku || ''
      }
    ],
    orders: [
      {
        by: 'sku',
        type: 'asc'
      }
    ],
    limit: 15,
    offset: 0
  };

  // Get country list from the country service
  const { data: countries } = useGetCountriesQuery({ token: String(token) });

  const queryParams = searchObjectToParams(mapObject);

  const { data, isLoading, isError } = useGetProductsQuery(
    {
      queryParams,
      token: String(token)
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  if (!data || isLoading) {
    return null;
  }
  const { items } = data;
  if (isError || items.length === 0) {
    return (
      <Text element="p" bold small action>
        {productDetailsHeadersCopy.error}
      </Text>
    );
  }

  const {
    productName,
    statusCode,
    agentVendor,
    supplierVendor,
    unitDescription,
    category,
    subCategory: productSubCategory,
    alcoholPercentage: productAlcoholPercentage,
    countryOfOrigin: productCountryOfOrigin,
    countryOfExport: productCountryOfExport
  } = data && data.items[0];

  const gridItems: GridItem[] = [
    {
      title: productDetailsHeadersCopy.productStatus,
      value: (statusCode && productStatusSearch(statusCode)) || ''
    },
    { title: productDetailsHeadersCopy.subCategory, value: productSubCategory },
    {
      title: productDetailsHeadersCopy.countryOfOrigin,
      value:
        countries?.find((item) => item.countryCode === productCountryOfOrigin)
          ?.description || productCountryOfOrigin
    },
    {
      title: productDetailsHeadersCopy.pricingAgent,
      value: `${agentVendor.vendor_id} - ${agentVendor.name}`
    },
    {
      title: productDetailsHeadersCopy.alcoholPercentage,
      value: productAlcoholPercentage
    },
    {
      title: productDetailsHeadersCopy.productClass,
      value: data?.items[0].class ? String(data?.items[0].class) : ''
    },
    {
      title: productDetailsHeadersCopy.countryOfExport,
      value:
        countries?.find((item) => item.countryCode === productCountryOfExport)
          ?.description || productCountryOfExport
    },
    {
      title: productDetailsHeadersCopy.listingAgent,
      value: `${supplierVendor.vendor_id} - ${supplierVendor.name}`
    }
  ];

  return (
    <div className={styles.productDetails}>
      <div className={styles.productDetails__heading}>
        <Text element="p" bold>
          {sku}
        </Text>
        <Text element="p" bold action>
          {productName}
        </Text>
        <Text element="p" small>
          {category}
        </Text>
        <Text element="p" small>
          {unitDescription}
        </Text>
      </div>
      <div className={styles.productDetails__grid}>
        {gridItems.map((item: GridItem, idx) => {
          const { title, value } = item;
          return (
            <ProductDetailsGridItem
              key={`${idx}-${title}`}
              title={title}
              value={value}
              className={styles.productDetails__gridItem}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductDetails;
