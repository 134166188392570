import React, { createContext, useState } from 'react';

interface CurrencyCode {
  currency_id: string;
  currency_short_name: string;
  currency_full_name: string;
}

interface CurrencyContextData {
  currencies: CurrencyCode[];
  addCurrencies: (currencyData: CurrencyCode[]) => void;
}

const CurrencyContextDefaultValue: CurrencyContextData = {
  currencies: [],
  addCurrencies: () => null
};

export const CurrencyContext = createContext<CurrencyContextData>(
  CurrencyContextDefaultValue
);

type PropsType = {
  children: React.ReactNode;
};

const useCurrencyProductsContextValue = (): CurrencyContextData => {
  const [currencies, setCurrencies] = useState<CurrencyCode[]>([]);

  // add product(s) to the current working group
  const addCurrencies = (currencyData: CurrencyCode[]) => {
    if (currencies.length === 0) {
      setCurrencies(currencyData);
    }
  };

  return {
    currencies,
    addCurrencies
  };
};

const CurrencyProvider = ({ children }: PropsType) => {
  const CurrencyContextValue = useCurrencyProductsContextValue();
  return (
    <CurrencyContext.Provider value={CurrencyContextValue}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
