import React from 'react';

export default function ChevronUp() {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-chevron-up">Chevron Up</title>
      <path
        d="M6.40805 0.245705L11.081 4.73742C11.3063 4.95405 11.3063 5.30526 11.081 5.52187L10.5359 6.04576C10.311 6.26202 9.94633 6.26244 9.72081 6.04668L5.99999 2.48693L2.27919 6.04671C2.05367 6.26246 1.68904 6.26205 1.46405 6.04578L0.919028 5.5219C0.693657 5.30526 0.693657 4.95405 0.919028 4.73744L5.59195 0.245728C5.8173 0.0290968 6.18268 0.029097 6.40805 0.245705Z"
        fill="currentColor"
      />
    </svg>
  );
}
