import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { triggerActivityMonitorReset } from '../reducers/ActivityMonitorSlice';

import { dateFormat, fetchApiData, handleApiResponse } from '../helpers';

import { CalendarDate } from '../types/productTypes';

type EffectiveDateOption = {
  label: string;
  value: string;
  calendarId: number;
  submissionDeadline: string;
};

type PriceRequestEffectiveDateOptions = {
  effectiveDateOptions: EffectiveDateOption[];
  effectiveDates: CalendarDate[];
};

export function useEffectiveDates(accessToken: string | null) {
  const dispatch = useDispatch();
  const [effectiveDates, setEffectiveDates] = useState<CalendarDate[] | null>(
    null
  );

  const [priceChangeAdHocOption, setPriceChangeAdHocOption] =
    useState<EffectiveDateOption | null>();
  const [promotionAdHocOption, setPromotionAdHocOption] =
    useState<EffectiveDateOption | null>();

  useEffect(() => {
    async function getCalendarDates(accessToken: string | null) {
      try {
        dispatch(triggerActivityMonitorReset(true));
        const calendarDatesRequest = await fetchApiData(
          'calendar-dates',
          accessToken,
          'GET'
        );
        const calendarDatesResponse = await handleApiResponse(
          calendarDatesRequest
        );
        if (calendarDatesResponse) {
          setEffectiveDates(calendarDatesResponse);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (accessToken) {
      getCalendarDates(accessToken);
    }
  }, [accessToken, dispatch]);

  const cachedDates = useMemo(() => effectiveDates, [effectiveDates]);

  useEffect(() => {
    if (!cachedDates) return;

    const adhocPromotionOption = cachedDates.filter(
      (date: CalendarDate) =>
        date.type === 'PROMOTION' && date.subType === 'AD_HOC'
    );

    const adhocPriceOption = cachedDates.filter(
      (date: CalendarDate) => date.type === 'PRICE' && date.subType === 'AD_HOC'
    );

    const asapPriceOption = adhocPriceOption[0]
      ? {
          label: 'ASAP',
          value: adhocPriceOption[0]?.effectiveDate,
          submissionDeadline: adhocPriceOption[0]?.submissionDeadLine,
          disabled: false,
          calendarId: adhocPriceOption[0]?.id
        }
      : null;

    const asapPromotionOption = adhocPromotionOption[0]
      ? {
          label: 'ASAP',
          value: adhocPromotionOption[0]?.effectiveDate,
          submissionDeadline: adhocPromotionOption[0]?.submissionDeadLine,
          disabled: false,
          calendarId: adhocPromotionOption[0]?.id
        }
      : null;

    setPriceChangeAdHocOption(asapPriceOption);
    setPromotionAdHocOption(asapPromotionOption);
  }, [cachedDates]);

  const dateToSelectOption = useCallback((date: CalendarDate) => {
    return {
      label: dateFormat(date.effectiveDate),
      value: date.effectiveDate.toString(),
      calendarId: date.id,
      submissionDeadline: date.submissionDeadLine
    };
  }, []);

  const highlightedEffectiveDates = useMemo(() => {
    if (cachedDates) {
      const regularEffectiveDates = cachedDates.filter(
        (date) => date.subType !== 'AD_HOC'
      );
      return regularEffectiveDates.map((date: CalendarDate) => {
        return dateFormat(date.effectiveDate);
      });
    }
    return null;
  }, [cachedDates]);

  const effectiveDatesOptions = useMemo(() => {
    if (cachedDates) {
      return cachedDates.map((date: CalendarDate) => {
        return dateToSelectOption(date);
      });
    }
    return null;
  }, [cachedDates, dateToSelectOption]);

  const getPriceRequestEffectiveDateOptions = useCallback(
    (
      prDateType: 'PRICE' | 'PROMOTION'
    ): PriceRequestEffectiveDateOptions | undefined => {
      if (cachedDates) {
        const effectiveDates: CalendarDate[] = cachedDates.sort(
          (a: CalendarDate, b: CalendarDate) =>
            new Date(a.effectiveDate) > new Date(b.effectiveDate) ? 1 : -1
        );

        const effectiveDateOptions = effectiveDates
          .filter(
            (date) => date.type === prDateType && date.subType === 'REGULAR'
          )
          .map((date: CalendarDate) => {
            return dateToSelectOption(date);
          });

        if (prDateType === 'PRICE') {
          if (priceChangeAdHocOption) {
            effectiveDateOptions.push(priceChangeAdHocOption);
          }
        } else {
          if (promotionAdHocOption) {
            effectiveDateOptions.push(promotionAdHocOption);
          }
        }

        return {
          effectiveDateOptions,
          effectiveDates
        };
      }

      return undefined;
    },
    [
      priceChangeAdHocOption,
      promotionAdHocOption,
      cachedDates,
      dateToSelectOption
    ]
  );

  return {
    effectiveDates,
    highlightedEffectiveDates,
    effectiveDatesOptions,
    getPriceRequestEffectiveDateOptions
  };
}
