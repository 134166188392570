import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
  ChangeEvent
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

import { AuthenticationContext } from '../../contexts/AuthenticationProvider';

import { useForm, FormProvider } from 'react-hook-form';
import { useTable, useFilters, useRowSelect, usePagination } from 'react-table';

import { uniqueArray } from '../../helpers/uniqueArray';

import {
  ImportSelectOptions,
  ProductStatusSelectOptions,
  defaultSelectNumber,
  defaultSelectString
} from '../../helpers/productSearchQueries';

import {
  searchObjectToParams,
  ISearchParams
} from '../../helpers/searchObjectToParams';

import {
  SearchFormDataType,
  HierarchyClassType,
  CategoryType,
  SubCategoryType,
  vendorType,
  CellPropsContent
} from '../../types/productSearchTypes';

import { sortColumnsByHeader } from '../../helpers/sortColumnsByHeader';
import { currencyFormat } from '../../helpers/currencyFormat';
import { fetchApiData, handleApiResponse } from '../../helpers/apiHelpers';
import { getSessionToken } from '../../helpers/getSessionToken';
import { LocationType } from '../../types/productTypes';

import {
  setProductSearchFilters,
  resetProductSearchFilters,
  setPersistedSearch,
  setConfirmSearchModalOpen
} from '../../reducers/ProductSearchSlice';

import {
  setOpenProductDetailsModal,
  setSku,
  setLoadingState
} from '../../reducers/PriceRequestSlice';

import {
  setWorkingGroupProducts,
  setSelectedProducts
} from '../../reducers/WorkingGroupSlice';

import { setProductPriceHistory } from '../../reducers/ProductPriceHistorySlice';

import classNames from 'classnames';

import Heading from '../Heading';
import Button from '../Button';
import Card from '../Card';
import Search from '../Icons/Search';
import AdditionSign from '../Icons/AdditionSign';
import ChevronLeft from '../Icons/ChevronLeft';
import ChevronRight from '../Icons/ChevronRight';
import LoadingOverlay from '../LoadingOverlay';
import Pill from '../Pill';
import Text from '../Text';
import TableTooltip from '../TableTooltip';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import ExclamationPoint from '../Icons/ExclamationPoint';
import CallToAction from '../CallToAction';
import Banner from '../Banner';
import Checkmark from '../Icons/CheckMark';
import ArrowRight from '../Icons/ArrowRight';
import ArrowDown from '../Icons/ArrowDown';
import ArrowUp from '../Icons/ArrowUp';
import TextLink from '../TextLink';
import FilterTextInput from '../FilterTextInput';
import SelectInput from '../SelectInput/SelectInput';
import Modal from '../Modal';
import ProductDetailsModal from '../Modal/ProductDetailsModal';
import ConfirmProductSearchModal from '../Modal/ConfirmProductSearchModal';

import styles from './index.module.scss';
import { OptionsType, SelectInputType } from '../../types';
import { isEqual } from 'lodash';
import { UserTypeEnum } from '../../helpers';
import { triggerActivityMonitorReset } from '../../reducers/ActivityMonitorSlice';

const ProductSearchTable = () => {
  const { authState } = useContext(AuthenticationContext);

  //Using redux, get access to the working group related pieces of state
  const { workingGroupProducts, selectedProducts } = useSelector(
    (state: RootState) => state.workingGroup
  );

  const { openProductDetailsModal, productSuppliersList, loadingState } =
    useSelector((state: RootState) => state.priceRequest);

  const { productSearchFilters, persistedSearch, confirmSearchModalOpen } =
    useSelector((state: RootState) => state.productSearch);

  // get access to the useDispatch hook
  const dispatch = useDispatch();

  // Grab user data from the AuthenticationContext
  const user = authState.user;
  const userType = user.type;

  const defaultFormValues = {
    sku: productSearchFilters.sku,
    product: productSearchFilters.product,
    hierarchyClass: productSearchFilters.hierarchyClass,
    status: productSearchFilters.status,
    listingAgent: productSearchFilters.listingAgent,
    pricingAgent: productSearchFilters.pricingAgent,
    category: productSearchFilters.category,
    subCategory: productSearchFilters.subCategory,
    domesticImportFlag: productSearchFilters.domesticImportFlag
  };

  const clearFormValues = {
    sku: null,
    product: '',
    hierarchyClass: defaultSelectNumber,
    status: defaultSelectString,
    listingAgent: defaultSelectNumber,
    pricingAgent: defaultSelectNumber,
    category: defaultSelectNumber,
    subCategory: defaultSelectNumber,
    domesticImportFlag: defaultSelectString
  };

  // set the original default values to all the form inputs so react-hook-forms has
  // something to compare the values to
  const methods = useForm<SearchFormDataType>({
    defaultValues: defaultFormValues
  });

  useEffect(() => {
    // This effect runs whenever the location (URL) changes to dispatch the setSelectedProducts reducer and ensure selected products are cleared
    dispatch(setSelectedProducts([]));
  }, []);

  // The below functions are to retrieve the list of categories from our API and turn them into select values for the category, sub-category and class dropdowns

  const [hierarchyData, setHierarchyData] = useState<any[]>([]);
  //retrieve access token to be used in api calls
  const token = getSessionToken();

  useEffect(() => {
    async function getHierarchyInfo(accessToken: string | null) {
      try {
        dispatch(triggerActivityMonitorReset(true));
        const result = await fetchApiData(`hierarchy`, accessToken, 'GET');
        const hierarchyResponse = await handleApiResponse(result);
        if (hierarchyResponse) {
          setHierarchyData(hierarchyResponse);
          sessionStorage.setItem(
            'hierachyData',
            JSON.stringify(hierarchyResponse)
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (token) {
      getHierarchyInfo(token);
    }
  }, [token, user, user.userObjectID]);

  //Create new array of table data and set to empty array
  const [tableData, setTableData] = useState<any[]>([]);

  // Create initial state of products returned to 0.
  const [totalProductResults, setTotalProductResults] = useState(0);

  //initial state for whether the "no results found" error message is displayed
  const [searchErrorMsg, setSearchErrorMsg] = useState(false);

  //initial state for whether or not the grey search banner with instructions is shows below form. Set to true on page load.
  const [showSearchInstructions, setShowSearchInstructions] = useState(true);

  //initial state for whether or not to show add products success/error banner
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  // string for alert banner to notify how many new products were added
  const [numberOfProductsAdded, setNumberOfProductsAdded] = useState('');

  // below values are used within our search query for pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(
    () => Number(sessionStorage.getItem('pageSize')) || 15
  ); // Default to user's selection in current session or 15

  //create location state used to determine if the tooltips should render below or above the
  // text based on mouse location in the screen
  const [location, setLocation] = useState<LocationType>('');
  // this callback will be passed into the tooltip component to bring the state from child to parent
  const callback = (state: LocationType) => {
    setLocation(state);
  };

  //to capture results above multiple of pageSize (in this case 15)
  const [totalPages, setTotalPages] = useState(0);

  // local State to help capture search's selected products
  const [selectedSearchProducts, setSelectedSearchProducts] = useState<
    string[]
  >([]);

  const data = tableData;

  // Outline all of the table column headers with the exception of the checkbox header, that will be coded later within the useTable root hook. Each column includes a header aka the label text displayed, the accessor to match the column with the data, how the cells within that column should be rendered and a custom tipText used to pull in the text for the tool tips.
  const columns = useMemo(
    () => [
      {
        Header: 'SKU #',
        accessor: 'sku',
        Cell: (props: CellPropsContent) => {
          return (
            <Text
              element="p"
              bold
              small
              tableCell
              disabled={workingGroupProducts.includes(props.value)}
              tooltip={props.value}
            >
              {props.value}
            </Text>
          );
        }
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
        Cell: (props: { value: string; cell: any }) => {
          return (
            <Text
              element="p"
              bold
              action
              tableCell
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
              tooltip={props.value}
            >
              {props.value}
            </Text>
          );
        }
      },
      {
        Header: 'Product Status',
        accessor: 'statusCode',
        Cell: (props: { value: string; cell: any }) => {
          let typeCheck = '';
          if (props.value === '1') {
            typeCheck = 'Pending Listing';
          } else if (props.value === '2') {
            typeCheck = 'Active';
          } else if (props.value === '3') {
            typeCheck = 'Pending Delist';
          } else {
            typeCheck = 'Delisted';
          }

          return (
            <Pill
              type={props.value}
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
            >
              {typeCheck}
            </Pill>
          );
        }
      },
      {
        Header: 'Pricing Agent',
        accessor: 'agentVendor',
        Cell: (props: { value: vendorType; cell: any }) => {
          const id = props.value.vendor_id;
          const name = props.value.name;
          const concatenatedValue = `${id} - ${name}`;
          return (
            <div>
              <Text
                element="p"
                tableCell
                disabled={workingGroupProducts.includes(
                  props.cell.row.values.sku
                )}
                tooltip={concatenatedValue}
              >
                {concatenatedValue}
              </Text>
            </div>
          );
        }
      },
      {
        Header: 'Listing Agent',
        accessor: 'supplierVendor',
        Cell: (props: { value: vendorType; cell: any }) => {
          const id = props.value.vendor_id;
          const name = props.value.name;
          const concatenatedValue = `${id} - ${name}`;
          return (
            <Text
              element="p"
              tableCell
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
              tooltip={concatenatedValue}
            >
              {concatenatedValue}
            </Text>
          );
        }
      },
      {
        Header: 'Category',
        accessor: 'category',
        Cell: (props: { value: string; cell: any }) => {
          return (
            <Text
              element="p"
              tableCell
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
              tooltip={props.value}
            >
              {props.value}
            </Text>
          );
        }
      },
      {
        Header: 'Sub-Category',
        accessor: 'subCategory',
        Cell: (props: { value: string; cell: any }) => {
          return (
            <Text
              element="p"
              tableCell
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
              tooltip={props.value}
            >
              {props.value}
            </Text>
          );
        }
      },
      {
        Header: 'Class',
        accessor: 'class',
        Cell: (props: { value: string; cell: any }) => {
          return (
            <Text
              element="p"
              tableCell
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
              tooltip={props.value}
            >
              {props.value}
            </Text>
          );
        }
      },
      {
        Header: 'Import/ Domestic',
        accessor: 'countryOfExport',
        Cell: (props: { value: string; cell: any }) => {
          return (
            <Text
              element="p"
              tableCell
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
              tooltip={props.value}
            >
              {props.value === 'CA' ? 'Domestic' : 'Import'}
            </Text>
          );
        }
      },
      {
        Header: 'Case Configuration',
        accessor: 'unitDescription',
        Cell: (props: { value: string; cell: any }) => {
          return (
            <Text
              element="p"
              tableCell
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
              tooltip={props.value}
            >
              {props.value}
            </Text>
          );
        }
      },
      {
        Header: 'Current WS Price',
        accessor: 'finalPricePerSU',
        Cell: (props: { value: string; cell: any }) => {
          return (
            <Text
              element="p"
              tableCell
              disabled={workingGroupProducts.includes(
                props.cell.row.values.sku
              )}
              tooltip={currencyFormat(props.value).toString()}
            >
              {currencyFormat(props.value)}
            </Text>
          );
        },
        tipText: 'Current Wholesale Price'
      }
    ],
    [workingGroupProducts]
  );

  const tableStateReducer = (newState: any, action: any) => {
    if (action.type === 'deselectAllRows') {
      return { ...newState, selectedRowIds: {} };
    }
    return newState;
  };

  const options = {
    data,
    columns,
    autoResetSelectedRows: false,
    getRowId: (row: any) => row.sku as string,
    stateReducer: tableStateReducer
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { selectedRowIds },
    dispatch: tableDispatch
  } = useTable(options, useFilters, usePagination, useRowSelect, (hooks) => {
    // Set the very first column of the table with checkboxes in the column cells
    hooks.visibleColumns.push((columns, rows) => {
      return [
        {
          id: 'selection',
          Header: ({
            getToggleAllRowsSelectedProps,
            toggleAllRowsSelected,
            isAllPageRowsSelected
          }) => {
            // create an array of all product skus in the results table
            const skus = rows.instance.data.map((row: any) => {
              return row.sku;
            });
            //declare function to compare the product skus array with working group products array to
            // outline if the select all checkbox input should be disabled
            const compareArrays = (arr: string[], target: string[]) =>
              target.every((v) => arr.includes(v));
            const productCheck = compareArrays(workingGroupProducts, skus);

            return (
              <div className={styles.divContainer}>
                <IndeterminateCheckbox
                  {...getToggleAllRowsSelectedProps()}
                  isSelected={isAllPageRowsSelected}
                  sku="0"
                  id={'all'}
                  checked={isAllPageRowsSelected}
                  onClick={() => {
                    toggleAllRowsSelected(!isAllPageRowsSelected);
                  }}
                  disabled={productCheck}
                />
              </div>
            );
          },
          Cell: ({ row }) => {
            return (
              <div className={styles.divContainer}>
                <IndeterminateCheckbox
                  isSelected={row.isSelected}
                  sku={row.values.sku}
                  id={row.id}
                  dataTestId={`checkbox-${row.values.sku}`}
                  checked={
                    row.isSelected ||
                    isRowDisabled(workingGroupProducts, row.values.sku)
                  }
                  onChange={row.getToggleRowSelectedProps().onChange}
                  disabled={isRowDisabled(workingGroupProducts, row.values.sku)}
                />
              </div>
            );
          },
          tipText: 'All'
        },
        ...columns
      ];
    });
  });

  // Check for changes to selectedRowIds which we get from react-table
  // combine those with the selected products context and update the local state
  useEffect(() => {
    const currentSelectionOfProducts: any =
      Object.keys(selectedRowIds).map(Number);
    const combinedArray = selectedProducts.concat(currentSelectionOfProducts);
    const filteredSelectedProducts = combinedArray.filter(
      (el) => !workingGroupProducts.includes(el)
    );
    setSelectedSearchProducts(uniqueArray(filteredSelectedProducts));
  }, [workingGroupProducts, selectedRowIds, selectedProducts]);

  //The below section groups together all of the form functionality for onSubmit, searching the product database using the API calls, clearing the form, pagination and sorting.
  // create and set state of formValue to empty default values;
  const [formValues, setFormValues] =
    useState<SearchFormDataType>(defaultFormValues);

  // refactored out API POST call to use across onSubmit, paginationNextButtonClick, paginationPreviousButtonClick
  const getProductsList = useCallback(
    async (queryParams: string) => {
      if (token) {
        dispatch(triggerActivityMonitorReset(true));
        const ms = Date.now();
        const endpoint = `products/search?${queryParams}&_="${ms}`;
        const result = await fetchApiData(endpoint, token, 'GET');
        const response = await handleApiResponse(result);
        return response;
      }
    },
    [token]
  );

  // function called using form data, pageNumber, sortType and sortBy as parameters, utilizes getProductsList
  // first create states to manage sortType and sortBy, these will be used changed in the column header sorting functionality of the epp-92 see search results task. Set these to the default values of "asc" and "sku"
  const [sortType, setSortType] = useState('asc');
  const [sortBy, setSortBy] = useState('sku');

  const searchProducts = useCallback(
    async (
      data: SearchFormDataType,
      pageNumber: number,
      sortType: string,
      sortBy: string
    ) => {
      // setFormValues state to the latest version of data searched, this will be used for pagination functions.
      setFormValues(data);
      const productName = data.product.toUpperCase() || '';
      const skuNumber = data.sku || 0;
      const category = data.category ? data.category.value : 0;
      const subCategory = data.subCategory ? data.subCategory.value : 0;
      const hierarchyClass = data.hierarchyClass
        ? data.hierarchyClass.value
        : 0;
      const status = data.status ? data.status.value : '';
      const listingAgent = data.listingAgent ? data.listingAgent.value : 0;
      const pricingAgent = data.pricingAgent ? data.pricingAgent.value : 0;
      const domesticImportFlag =
        data.domesticImportFlag && data.domesticImportFlag.value === 'CA'
          ? '='
          : data.domesticImportFlag && data.domesticImportFlag.value === 'Other'
          ? '!='
          : '';

      const mapObject: ISearchParams = {
        filters: [],
        orders: [
          {
            by: sortBy,
            type: sortType
          }
        ],
        limit: pageSize,
        offset: pageNumber * pageSize
      };

      const { filters } = mapObject;

      if (skuNumber > 0) {
        filters.push({
          field: 'sku',
          operator: '=',
          value: skuNumber.toString()
        });
      }

      if (productName.length > 0) {
        filters.push({
          field: 'product_name',
          operator: 'CONTAINS',
          value: productName
        });
      }

      if (Number(category) > 0) {
        filters.push({
          field: 'category',
          operator: '=',
          value: category
        });
      }

      if (Number(subCategory) > 0) {
        filters.push({
          field: 'sub_category',
          operator: '=',
          value: subCategory
        });
      }

      if (Number(hierarchyClass) > 0) {
        filters.push({
          field: 'class',
          operator: '=',
          value: hierarchyClass
        });
      }

      if (status.length > 0) {
        filters.push({
          field: 'status_code',
          operator:
            status === '1' && userType === UserTypeEnum.EXTERNAL ? '!=' : '=',
          value: status
        });
        // EPP-1536 because when we reset status filter will be removed entirely, then external users can see all products,
        // added below condition to set the status filter after clearing the search filter
      } else if (userType === UserTypeEnum.EXTERNAL) {
        filters.push({
          field: 'status_code',
          operator: 'IN',
          value: ['2', '3']
        });
      }

      if (Number(listingAgent) > 0) {
        filters.push({
          field: 'supplier',
          operator: '=',
          value: listingAgent
        });
      }

      if (Number(pricingAgent) > 0) {
        filters.push({
          field: 'agent',
          operator: '=',
          value: pricingAgent
        });
      }

      if (domesticImportFlag !== '') {
        filters.push({
          field: 'country_of_export',
          operator: domesticImportFlag,
          value: 'CA'
        });
      }

      const queryParams = searchObjectToParams(mapObject);

      getProductsList(queryParams)
        .then((response) => {
          const productList = response;
          dispatch(setLoadingState(false));
          if (productList.count === 0 || productList.count === undefined) {
            setSearchErrorMsg(true);
            setTableData([]);
            if (productList.count === 0 || productList.count === undefined) {
              setTotalProductResults(0);
            }
            return;
          }

          setTotalProductResults(productList.count);
          setSearchErrorMsg(false);

          if (productList.count === '1' || productList.count === '1') {
            setTableData([productList.items]);
          } else {
            setTableData(productList.items);
            const multiplesOfPageSize = productList.count / pageSize;
            setTotalPages(Math.ceil(multiplesOfPageSize));
          }
        })
        .catch((_error) => {
          dispatch(setLoadingState(false));
          setSearchErrorMsg(true);
          setTableData([]);
          setTotalProductResults(0);
        });
    },
    [getProductsList, pageSize, totalProductResults]
  );

  const resetLocalSearchState = () => {
    setSubcatOptions([]);
    setHierarchyClassOptions([]);
    dispatch(setSelectedProducts([]));
    dispatch(resetProductSearchFilters());
    dispatch(setConfirmSearchModalOpen(false));
    tableDispatch({ type: 'deselectAllRows' });
    setSearchErrorMsg(false);
    setTableData([]);
  };

  const confirmSearch = useCallback(() => {
    updateSearchResults(productSearchFilters);
    resetLocalSearchState();
  }, [productSearchFilters]);

  // Function used to clear the product search form input values
  const clearForm = () => {
    resetLocalSearchState();
    // set the inputs to their defaultValues outlined in the useForm() hook above
    methods.reset({ ...clearFormValues });
    //clear the saved formValues, and subcategory and class options states back to default values
    setInputtedProductName(clearFormValues.product);
    setInputtedSku(clearFormValues.sku);
    setSelectedCategoryValue(clearFormValues.category);
    setSelectedSubCategoryValue(clearFormValues.subCategory);
    setSelectedClassValue(clearFormValues.hierarchyClass);
    setSelectedPricingAgentValue(clearFormValues.pricingAgent);
    setSelectedListingAgentValue(clearFormValues.listingAgent);
    setSelectedStatusValue(clearFormValues.status);
    setSelectedImportValue(clearFormValues.domesticImportFlag);
    setFormValues(clearFormValues);
    // clear the sortBy and sortType values to default of "sku" and "asc"
    setSortBy('sku');
    setSortType('asc');
    if (selectedSearchProducts.length > 0) {
      dispatch(setConfirmSearchModalOpen(true));
    } else {
      updateSearchResults(clearFormValues);
    }
  };

  //This is the search products form onSubmit functionality
  const onSubmit = (data: SearchFormDataType) => {
    dispatch(setProductSearchFilters(data));
    if (selectedSearchProducts.length > 0) {
      dispatch(setConfirmSearchModalOpen(true));
    } else {
      updateSearchResults(data);
    }
  };

  const updateSearchResults = (searchData: SearchFormDataType) => {
    //Remove the success banner if currently showing
    setIsAlertVisible(false);
    //Empty the table data
    setTableData([]);
    // Remove the search instructions banner
    setShowSearchInstructions(false);
    //Show loading state
    dispatch(setLoadingState(true));
    //Reset the page results starting point to 0
    const resetPage = 0;
    setPageNumber(resetPage);
    // Set persisted search
    dispatch(setPersistedSearch(true));
    searchProducts(searchData, resetPage, sortType, sortBy);
  };

  useEffect(() => {
    if (persistedSearch) {
      updateSearchResults(productSearchFilters);
    }
  }, []);

  // Pagination Functionality
  // Each page of results can have up to 15 products.
  // Each next or previous button click retriggers the api call, pulling more or previous data,
  // and updating pagination results numbers.
  // Need to disable next button if on last page of results
  // Need to disable previous button if on first page of results

  //Two different onClick functions below outline the changes for next and previous button clicks.

  const paginationNextButtonClick = (data: SearchFormDataType) => {
    setTableData([]);
    dispatch(setLoadingState(true));
    const increasePage = pageNumber + 1;
    setPageNumber(increasePage);
    searchProducts(data, pageNumber + 1, sortType, sortBy);
  };

  const paginationPreviousButtonClick = (data: SearchFormDataType) => {
    setTableData([]);
    dispatch(setLoadingState(true));
    const decreasePage = pageNumber - 1;
    setPageNumber(decreasePage);
    searchProducts(data, pageNumber - 1, sortType, sortBy);
  };

  useEffect(() => {
    const multiplesOfPageSize = totalProductResults / pageSize;
    setTotalPages(Math.ceil(multiplesOfPageSize));
  }, [pageSize, totalProductResults]);

  //Add Products to Working Group button onClick functionality
  const handleAddProductsButtonClick = () => {
    // // add the selected rows array to the current working group products array,
    // // store this list in a new array.
    const currentSelectionOfProducts: any =
      Object.keys(selectedRowIds).map(Number);
    const combinedArray = workingGroupProducts.concat(
      currentSelectionOfProducts
    );

    // We need to ensure the current working group has no duplicates. To do this a new array is
    // created and then we check for duplicates in the combinedArray and each of those values only once.
    const newSkus = uniqueArray(combinedArray);

    // Then we set the workingGroupProducts array stored in root state to this new array of unique sku numbers.
    dispatch(setWorkingGroupProducts(newSkus));

    // Also update the selectedProducts state with this new array
    dispatch(setSelectedProducts(newSkus));

    // find the difference between the selected products and the working group products
    const toRemove = new Set([...workingGroupProducts]);
    const difference = selectedSearchProducts.filter(
      (sku: string) => !toRemove.has(sku)
    );

    // update the number of products that the success alert banner will show
    setNumberOfProductsAdded(Math.abs(difference.length).toString());

    // Set the success alert banner to visible
    setIsAlertVisible(true);

    // update selectedSearchProducts local state to empty array
    // this will disable the Add Selected Products button again
    setTimeout(() => {
      setSelectedSearchProducts([]);
    }, 200);

    // Clear the success banner and the number of products added after 3 seconds
    setTimeout(() => {
      setIsAlertVisible(false);
      setNumberOfProductsAdded('');
    }, 3000);
  };

  // This function checks to see if a products sku is within the working group context array
  const isRowDisabled = (workingGroupProducts: string[], sku: string) => {
    return workingGroupProducts.includes(sku);
  };

  //this function checks to see if a products sku is within the selected products context array
  const isRowSelected = (selectedProducts: string[], sku: string) => {
    return selectedProducts.includes(sku);
  };

  // create empty array state for subcategory and class select values
  const [subcatOptions, setSubcatOptions] = useState<OptionsType[]>([]);
  const [hierarchyClassOptions, setHierarchyClassOptions] = useState<
    OptionsType[]
  >([]);

  // Now that we have the data, map through the categories to create the list of default select option list for categories.
  const categorySelectOptions = hierarchyData.map((option) => {
    return {
      label: option.description,
      value: option.hierarchy_id
    };
  });

  //create list of ALL sub_categories user has access to for default subCategory dropdown
  //first map over hierarchy, returning all subcategory objects into new array
  const subCategoriesByCategory = hierarchyData.map((items: CategoryType) => {
    return items.sub_categories;
  });

  //flatten the subcategories array and return only the descriptions and hiearchy_ids as the labels and values. This is the default subcategory select options dropdown info
  const subcategorySelectOptions = subCategoriesByCategory
    .flat(1)
    .map((subcat: SubCategoryType) => {
      return {
        label: subcat.description,
        value: subcat.hierarchy_id
      };
    });

  //create the list of ALL classes user has access to for default class dropdown
  const hierarchyClassesBySubCategory = subCategoriesByCategory
    .flat(1)
    .map((subcat: SubCategoryType) => {
      return subcat.class;
    });
  //flatten the classes array and return only the descriptions and hiearchy_ids as the labels and values. This is the default classselect options dropdown info
  const hierarchyClassSelectOptions = hierarchyClassesBySubCategory
    .flat(1)
    .map((hierarchyClassItem: HierarchyClassType) => {
      return {
        label: hierarchyClassItem.description,
        value: hierarchyClassItem.hierarchy_id
      };
    });

  const [selectedCategoryValue, setSelectedCategoryValue] = useState(
    productSearchFilters.category
  );

  const [selectedSubCategoryValue, setSelectedSubCategoryValue] = useState(
    productSearchFilters.subCategory
  );

  const [selectedClassValue, setSelectedClassValue] = useState(
    productSearchFilters.hierarchyClass
  );

  //handle the category, subcategory and class select input changes.
  const handleCategoryChange = (event: SelectInputType) => {
    const { label, value } = event;
    methods.setValue('category', {
      label: label,
      value: value
    });
    setSelectedCategoryValue({
      label: label,
      value: value
    });

    methods.setValue('subCategory', {
      label: 'Select...',
      value: '0'
    });
    setSelectedSubCategoryValue({
      label: 'Select...',
      value: '0'
    });

    methods.setValue('hierarchyClass', {
      label: 'Select...',
      value: '0'
    });

    setSelectedClassValue({
      label: 'Select...',
      value: '0'
    });

    const subcategoriesByUpdatedCategory = subCategoriesByCategory
      .flat(1)
      .filter(
        (subcat: SubCategoryType) =>
          subcat.parent_id.toString() === value.toString()
      );
    //map over the results and create an updated array of objects that hole the description and hierarchy id of all of these subcategories
    const updatedSubcategorySelectOptions = subcategoriesByUpdatedCategory.map(
      (subcat: SubCategoryType) => {
        return {
          label: subcat.description,
          value: subcat.hierarchy_id
        };
      }
    );
    //update the subcatOptions state to be this new list.
    setSubcatOptions(updatedSubcategorySelectOptions);

    //Filter the classes select options to be only those that belong within the subcategories
    //create the list of classes the user now has access to for updated class dropdown
    const hierarchyClassesByNewSubcategory = subcategoriesByUpdatedCategory
      .flat(1)
      .map((subCat: SubCategoryType) => {
        return subCat.class;
      });

    const updatedHierarchyClassSelectOptions = hierarchyClassesByNewSubcategory
      .flat(1)
      .map((hierarchyClassItem: HierarchyClassType) => {
        return {
          label: hierarchyClassItem.description,
          value: hierarchyClassItem.hierarchy_id
        };
      });
    //update the hierarchyClassOptions state to be this new list.
    setHierarchyClassOptions(updatedHierarchyClassSelectOptions);
  };

  const handleSubcategoryChange = (event: SelectInputType) => {
    const { label, value } = event;
    methods.setValue('subCategory', {
      value: value,
      label: label
    });

    setSelectedSubCategoryValue({
      value: value,
      label: label
    });

    methods.setValue('hierarchyClass', {
      label: 'Select...',
      value: '0'
    });

    setSelectedClassValue({
      label: 'Select...',
      value: '0'
    });

    //filter the class options by the new subcategory parent id
    //create the list of ALL classes user has access to for default class dropdown
    const hierarchyClassesByNewSubcategory = hierarchyClassesBySubCategory
      .flat(1)
      .filter(
        (hierarchyClassItem: HierarchyClassType) =>
          hierarchyClassItem.parent_id.toString() === value.toString()
      );

    const updatedHierarchyClassSelectOptions =
      hierarchyClassesByNewSubcategory.map(
        (hierarchyClassItem: HierarchyClassType) => {
          return {
            label: hierarchyClassItem.description,
            value: hierarchyClassItem.hierarchy_id
          };
        }
      );
    //update the hierarchyClassOptions state to be this new list.
    setHierarchyClassOptions(updatedHierarchyClassSelectOptions);
  };

  const handleClassChange = (event: SelectInputType) => {
    const { label, value } = event;
    methods.setValue('hierarchyClass', {
      value: value,
      label: label
    });
    setSelectedClassValue({
      value: value,
      label: label
    });
  };

  const [selectedStatusValue, setSelectedStatusValue] = useState(
    productSearchFilters.status
  );

  const handleStatusChange = (event: SelectInputType) => {
    const { label, value } = event;
    methods.setValue('status', {
      value: value,
      label: label
    });
    setSelectedStatusValue({
      value: value,
      label: label
    });
  };

  const [selectedImportValue, setSelectedImportValue] = useState(
    productSearchFilters.domesticImportFlag
  );

  const handleDomesticImportChange = (event: SelectInputType) => {
    const { label, value } = event;
    methods.setValue('domesticImportFlag', {
      value: value,
      label: label
    });
    setSelectedImportValue({
      value: value,
      label: label
    });
  };

  const [selectedListingAgentValue, setSelectedListingAgentValue] = useState(
    productSearchFilters.listingAgent
  );

  const handleListingAgentChange = (event: SelectInputType) => {
    const { label, value } = event;
    methods.setValue('listingAgent', {
      value: value,
      label: label
    });
    setSelectedListingAgentValue({
      value: value,
      label: label
    });
  };

  const [selectedPricingAgentValue, setSelectedPricingAgentValue] = useState(
    productSearchFilters.pricingAgent
  );

  const handlePricingAgentChange = (event: SelectInputType) => {
    const { label, value } = event;
    methods.setValue('pricingAgent', {
      value: value,
      label: label
    });
    setSelectedPricingAgentValue({
      value: value,
      label: label
    });
  };

  // state and function for the onchange management of text inputs on the form
  const [inputtedSku, setInputtedSku] = useState<number | null>(
    productSearchFilters.sku
  );
  const [inputtedProductName, setInputtedProductName] = useState('');

  const handleTextInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    inputId: 'sku' | 'product'
  ) => {
    const { value } = event.target;

    if (inputId === 'sku') {
      return setInputtedSku(Number(value));
    }

    return setInputtedProductName(value);
  };

  const listingAgentOptions = productSuppliersList
    ? [...productSuppliersList]
        .sort((a, b) => a.vendor_id - b.vendor_id)
        .map((vendor) => {
          const internalId = vendor.id;
          const externalId = vendor.vendor_id;
          const idAsString = internalId.toString();
          return {
            label: `${externalId} - ${vendor.name}`,
            value: idAsString
          };
        })
    : [
        {
          label: '',
          value: ''
        }
      ];

  // create a list of options of all of the pricing agents the user is able to view
  // pricing agents are vendors with vendorType === "AGENT";
  const pricingAgentsArray = user.vendorList.filter(
    (vendor) => vendor.vendorType === 'AGENT'
  );
  const pricingAgentOptions =
    pricingAgentsArray &&
    pricingAgentsArray
      .sort((a, b) => a.vendorID - b.vendorID)
      .map((vendor) => {
        const internalId = vendor.internalVendorID;
        const externalId = vendor.vendorID;
        const idAsString = internalId.toString();
        return {
          label: `${externalId} - ${vendor.vendorName}`,
          value: idAsString
        };
      });

  const handleSortButton = (
    data: SearchFormDataType,
    sortType: string,
    sortBy: string
  ) => {
    const columnSortBy = sortColumnsByHeader(sortBy);
    let columnSortType = '';
    setSortBy(columnSortBy);
    if (sortType === 'asc') {
      columnSortType = 'desc';
      setSortType('desc');
    } else if (sortType === 'desc') {
      columnSortType = 'asc';
      setSortType('asc');
    }
    setTableData([]);
    dispatch(setLoadingState(true));
    setPageNumber(0);
    searchProducts(data, 0, columnSortType, columnSortBy);
  };

  const closeProductDetailsModal = () => {
    dispatch(setOpenProductDetailsModal(false));
    dispatch(setProductPriceHistory([]));
  };

  // Handle page size change and save selection to current session
  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(e.target.value);
    setPageSize(newSize);
    setPageNumber(0);
    sessionStorage.setItem('pageSize', newSize.toString());
  };

  // Update page size immediately upon clicking a size option
  useEffect(() => {
    // Prevents search trigger on the first render
    if (!showSearchInstructions) {
      dispatch(setLoadingState(true));
      searchProducts(formValues, pageNumber, sortType, sortBy);
    }
  }, [pageSize]);

  return (
    <div className={styles.productSearchTable}>
      {loadingState && <LoadingOverlay content="Searching Products" />}
      <Card xlarge>
        <FormProvider {...methods}>
          <form className={styles.filterContainer}>
            <div className={styles.input}>
              <FilterTextInput
                label="SKU #"
                aria-labelledby="sku"
                id="sku"
                type="number"
                placeholder=""
                value={inputtedSku ? inputtedSku : undefined}
                min="0"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleTextInputChange(event, 'sku');
                }}
              />
            </div>
            <div className={styles.input}>
              <FilterTextInput
                label="Product Name"
                aria-labelledby="product"
                id="product"
                type="text"
                value={inputtedProductName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleTextInputChange(event, 'product');
                }}
              />
            </div>
            <div className={styles.input}>
              <SelectInput
                label="Category"
                ariaLabelledBy="category"
                id="category"
                options={categorySelectOptions}
                onChange={handleCategoryChange}
                value={selectedCategoryValue}
              />
            </div>
            <div className={styles.input}>
              <SelectInput
                label="Sub-Category"
                ariaLabelledBy="subCategory"
                id="subCategory"
                options={
                  subcatOptions.length > 0
                    ? subcatOptions
                    : subcategorySelectOptions
                }
                onChange={handleSubcategoryChange}
                value={selectedSubCategoryValue}
              />
            </div>
            <div className={styles.input}>
              <SelectInput
                label="Class"
                ariaLabelledBy="class"
                id="hierarchyClass"
                options={
                  selectedCategoryValue.value === '0' &&
                  selectedSubCategoryValue.value === '0'
                    ? hierarchyClassSelectOptions
                    : hierarchyClassOptions
                }
                onChange={handleClassChange}
                value={selectedClassValue}
              />
            </div>
            <div className={styles.input}>
              <SelectInput
                label="Import/Domestic"
                ariaLabelledBy="domesticImportFlag"
                id="domesticImportFlag"
                options={ImportSelectOptions}
                onChange={handleDomesticImportChange}
                value={selectedImportValue}
              />
            </div>
            <div className={styles.input}>
              <SelectInput
                label="Product Status"
                ariaLabelledBy="status"
                id="status"
                options={ProductStatusSelectOptions(userType)}
                onChange={handleStatusChange}
                value={selectedStatusValue}
              />
            </div>
            <div className={styles.input}>
              <SelectInput
                label="Pricing Agent"
                ariaLabelledBy="pricingAgent"
                id="pricingAgent"
                options={pricingAgentOptions}
                onChange={handlePricingAgentChange}
                value={selectedPricingAgentValue}
              />
            </div>
            <div className={styles.input}>
              <SelectInput
                label="Listing Agent"
                ariaLabelledBy="listingAgent"
                id="listingAgent"
                options={listingAgentOptions}
                onChange={handleListingAgentChange}
                value={selectedListingAgentValue}
              />
            </div>
            <div className={styles.inputButtons}>
              <Button
                children="Search"
                icon={<Search />}
                iconLeft
                submit
                primary
                onClick={methods.handleSubmit(onSubmit)}
                id="search-products-button"
              />
              <Button
                link
                onClick={clearForm}
                disabled={isEqual(methods.getValues(), clearFormValues)}
                id="clear-button"
              >
                Clear
              </Button>
            </div>
          </form>
        </FormProvider>
      </Card>

      {showSearchInstructions && (
        <CallToAction orientation="below">
          Enter criteria to search for products
        </CallToAction>
      )}
      {tableData.length > 0 && (
        <>
          {isAlertVisible && (
            <span className={styles.alertBanner}>
              <Banner
                icon={<Checkmark />}
                title={`${numberOfProductsAdded} Products have been added to the New Price Request`}
                type="success"
              />
            </span>
          )}
          <div
            className={styles.tableContainer}
            data-testid="product-search-results-table"
          >
            <div className={styles.tableButtons}>
              <div className={styles.buttonsLeft}>
                <Button
                  children="Add Selected Products"
                  disabled={selectedSearchProducts.length === 0}
                  icon={<AdditionSign />}
                  iconLeft
                  primary
                  onClick={() => handleAddProductsButtonClick()}
                  id={'add-selected-products-button'}
                />
                <TextLink
                  route="/price-request"
                  iconRight
                  icon={<ArrowRight />}
                  secondary
                >
                  View New Price Request
                </TextLink>
              </div>
              <div className={styles.buttonsRight}>
                <div className={styles.itemsPerPageContainer}>
                  Items per page:
                  <select value={pageSize} onChange={handlePageSizeChange}>
                    {[5, 15, 25, 50, 100].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.pagination}>
                  {/* Most results won't be a clean multiple of pageSize. This checks if results */}
                  {/* shown are last page of the rounded up page, and replaces end of range */}
                  {/* with totalProductResults number ie 300 - 303 */}
                  {pageNumber < totalPages - 1 ? (
                    <p>
                      {pageNumber * pageSize + 1} -{' '}
                      {(pageNumber + 1) * pageSize} of {totalProductResults}
                    </p>
                  ) : (
                    <p>
                      {pageNumber * pageSize + 1} - {totalProductResults} of{' '}
                      {totalProductResults}
                    </p>
                  )}
                  {/* Only show pagination buttons if initial results are more than 15 */}
                  {Number(totalProductResults) > pageSize && (
                    <>
                      <Button
                        pagination
                        icon={<ChevronLeft />}
                        onClick={() =>
                          paginationPreviousButtonClick(formValues)
                        }
                        disabled={pageNumber === 0}
                      />
                      <Button
                        pagination
                        icon={<ChevronRight />}
                        onClick={() => paginationNextButtonClick(formValues)}
                        disabled={pageNumber === totalPages - 1}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <table {...getTableProps()} className={styles.table}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        className={classNames(styles.th, {
                          [styles.thSelected]:
                            column.id !== 'selection' &&
                            sortColumnsByHeader(column.id) === sortBy
                        })}
                        onClick={
                          column.id !== 'selection'
                            ? () =>
                                handleSortButton(
                                  formValues,
                                  sortType,
                                  column.id
                                )
                            : () => false
                        }
                      >
                        {' '}
                        <span className={styles.headerColumnWrapper}>
                          <span
                            className={
                              column.id !== 'subCategory'
                                ? styles.shortLabel
                                : undefined
                            }
                          >
                            {column.render('Header')}
                          </span>
                          {column.id ===
                          'selection' ? null : sortColumnsByHeader(
                              column.id
                            ) === sortBy && sortType === 'asc' ? (
                            <div className={styles.arrowAsc}>
                              <ArrowUp />
                            </div>
                          ) : sortColumnsByHeader(column.id) === sortBy &&
                            sortType === 'desc' ? (
                            <div className={styles.arrowDesc}>
                              <ArrowDown />
                            </div>
                          ) : null}

                          {(headerGroup.headers[index] as any).tipText && (
                            <span
                              className={classNames(styles.tooltipContainer, {
                                [styles.tooltipContainerTop]:
                                  location === 'top',
                                [styles.tooltipContainerBottom]:
                                  location === 'bottom'
                              })}
                            >
                              <TableTooltip
                                label={
                                  (headerGroup.headers[index] as any).tipText
                                }
                                callback={callback}
                              />
                            </span>
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  // check to see if the product is already in the working group.
                  // if yes, this row should be disabled
                  const checkRowDisabled = isRowDisabled(
                    workingGroupProducts,
                    row.values.sku
                  );
                  //check to see if row is selected and added to the selectedProducts waiting to be added to working group
                  const checkRowSelected = isRowSelected(
                    selectedProducts,
                    row.values.sku
                  );
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={classNames(styles.tableRow, {
                        [styles.tableRowSelected]: row.isSelected,
                        [styles.tableRowDisabled]: checkRowDisabled
                      })}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className={styles.cellContainer}
                            {...cell.getCellProps()}
                            /* If not a selection cell containing a checkbox that has its own onClick functionality */
                            /* use the cell onclick to open the product details modal */
                            onClick={
                              cell.column.id !== 'selection'
                                ? () => {
                                    dispatch(setSku((row.values as any).sku));
                                    dispatch(setOpenProductDetailsModal(true));
                                  }
                                : undefined
                            }
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div></div>
          </div>
        </>
      )}
      {searchErrorMsg && (
        <div className={styles.searchErrorContainer}>
          <div className={styles.iconContainer}>
            <ExclamationPoint />
          </div>
          <Heading element="h4" heading4>
            No results found.
          </Heading>
          <p>We can't find any products matching your search.</p>
        </div>
      )}
      <Modal
        isOpen={openProductDetailsModal}
        onRequestClose={closeProductDetailsModal}
        closeable
      >
        <ProductDetailsModal />
      </Modal>
      <Modal
        isOpen={confirmSearchModalOpen}
        onRequestClose={() => dispatch(setConfirmSearchModalOpen(false))}
        alert
        closeable
      >
        <ConfirmProductSearchModal
          onConfirmSearch={confirmSearch}
          onCancelSearch={() => dispatch(setConfirmSearchModalOpen(false))}
        />
      </Modal>
    </div>
  );
};

export default ProductSearchTable;
