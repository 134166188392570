import React from 'react';

import SubmittedByTab from './_SubmittedByTab';
import PriceRequestBreakdownTab from './_PriceRequestBreakdownTab';
import Tabs from '../Tabs';
import PriceRequestPriceHistoryTab from './_PriceRequestPriceHistoryTab ';

import copy from '../../config/translations/en-CA.json';

const tabsCopy = copy.modals[0].priceRequestDetails.tabs;

const tabs = [
  {
    title: tabsCopy.submittedBy.label,
    content: <SubmittedByTab />
  },
  {
    title: tabsCopy.priceBreakDown.label,
    content: <PriceRequestBreakdownTab />
  },
  {
    title: tabsCopy.priceRequestHistory.label,
    content: <PriceRequestPriceHistoryTab />
  }
];

const PriceRequestDetailsTabs = () => {
  return <Tabs tabValues={tabs} ariaLabel="Price Request Details Tabs" />;
};

export default PriceRequestDetailsTabs;
