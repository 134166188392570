import React, {
  useEffect,
  ChangeEvent,
  useState,
  useCallback,
  useMemo
} from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useAuth } from '../../contexts/AuthenticationProvider';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectiveDates } from '../../hooks/useEffectiveDates';

import {
  setFormStatus,
  setInputValue,
  setInitialInputValues,
  selectIsDisabled,
  prIsCancellable,
  PriceRequestDetailsFormFieldValues,
  setBannerDetails
} from '../../reducers/PriceRequestDetailsSlice';

import { setLoadingState } from '../../reducers/PriceRequestSlice';
import { setBanner } from '../../reducers/PriceRequestSlice';
import { triggerActivityMonitorReset } from '../../reducers/ActivityMonitorSlice';

import { useCalculateMutation } from '../../services/Calculate';

import {
  useGetCurrencyQuery,
  useGetExchangeRateQuery
} from '../../services/Currency';

import { getPSTDate, convertToPSTDateOnlyFormat } from '../../helpers';

import {
  useApprovePriceRequestMutation,
  useDeclinePriceRequestMutation,
  useDeleteSinglePriceRequestMutation,
  useCancelSinglePriceRequestMutation,
  useUpdateDraftPriceRequestMutation,
  useSubmitAllWorkingGroupPriceRequestsMutation,
  useValidatePromotionRequestMutation,
  useSubmitAgentSupplierReviewMutation
} from '../../services/PriceRequest';

import {
  getSessionToken,
  currencyFormat,
  fixedDecimalPlaces,
  UserTypeEnum,
  formatNumericDate,
  PriceRequestStatusEnum,
  scrollModalToTop,
  errorMapping,
  getCalculateFields,
  mapPriceRequestReason,
  extractOtherReason,
  dateFormat,
  getReasonObjectByValue,
  disabledCalculateButtonFunction,
  appendReasonCostingComment,
  mapPrAbbreviationToName,
  getReasonOptions,
  fetchApiData,
  getWholesalePrice,
  handleApiResponse,
  isSameDate,
  isPassedDeadline,
  decodeSanitizedValue
} from '../../helpers';

import Button from '../Button';
import SelectInput from '../SelectInput/SelectInput';
import Pill from '../Pill';
import Banner from '../Banner';
import TextInput from '../TextInput';
import CurrencyInput from '../CurrencyInput';
import TextArea from '../TextArea';
import Calculator from '../Icons/Calculator';
import DateSelectMenu from '../DateSelectMenu';
import CalendarDatePicker from '../CalendarDatePicker/CalendarDatePicker';
import Checkbox from '../Checkbox';
import PaperAirplane from '../Icons/PaperAirplane';
import Text from '../Text';
import GarbageCan from '../Icons/GarbageCan';
import Cancel from '../Icons/Cancel';
import Checkmark from '../Icons/CheckMark';
import CloseIcon from '../Icons/CloseIcon';
import Edit from '../Icons/Edit';
import ExclamationPoint from '../Icons/ExclamationPoint';
import ErrorAlert from '../Icons/ErrorAlert';
import LoadingOverlay from '../LoadingOverlay';

import { schema, FormData } from './_schema';

import 'react-tooltip/dist/react-tooltip.css';
import styles from './index.module.scss';

import {
  PriceRequest,
  SelectInputDateType,
  SelectInputType,
  ValidationMessage,
  CalendarWarning,
  CalendarDate,
  BannerType
} from '../../types';

import copy from '../../config/translations/en-CA.json';
const modalCopy = copy.modals[0].priceRequestDetails;
const genericErrorMessageTitle = modalCopy.errorGeneric;

const COST_FIELDS: (keyof PriceRequestDetailsFormFieldValues)[] = [
  'primeCostPerCase',
  'dutyPaidCostPerCase',
  'domesticChargesPerCase',
  'promotionAmount',
  'pricePerSU'
];

type PriceRequestDetailsFormProps = {
  onRequestClose: () => void;
  refetchPRData: () => void;
};

const {
  DRAFT,
  PROCESSING,
  LDB_REVIEW,
  AS_REVIEW,
  CANCELLED,
  QUEUED,
  ACCEPTED,
  DECLINED
} = PriceRequestStatusEnum;

const { EXTERNAL, INTERNAL } = UserTypeEnum;

const pstDateNextDay = convertToPSTDateOnlyFormat(getPSTDate(undefined, 1));

const PriceRequestDetailsForm: React.FC<PriceRequestDetailsFormProps> = ({
  onRequestClose,
  refetchPRData
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth().authState;
  const token = getSessionToken();

  const { formStatus, fieldValues, priceRequest } = useSelector(
    (state: RootState) => state.priceRequestDetails
  );

  const { selectedPriceRequestID, loadingState } = useSelector(
    (state: RootState) => state.priceRequest
  );

  const effectiveDateIsDisabled = useSelector(selectIsDisabled);

  const { sku, productSku: priceRequestProduct } = priceRequest;

  const {
    user: userType,
    productIsPromotion,
    productIsDPC,
    productIsImport
  } = formStatus;

  const isInternal = userType === INTERNAL;

  const { type: priceRequestType, status: priceRequestStatus } = priceRequest;

  const { getPriceRequestEffectiveDateOptions, highlightedEffectiveDates } =
    useEffectiveDates(token);

  const { effectiveDateOptions, effectiveDates } =
    getPriceRequestEffectiveDateOptions(
      priceRequestType === 'WPP' ? 'PROMOTION' : 'PRICE'
    ) ?? { effectiveDateOptions: [], filteredDates: [] };

  const { data: currencyOptions } = useGetCurrencyQuery(
    { token },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const [
    calculate,
    {
      isLoading: isLoadingCalculate,
      isSuccess: isSuccessCalculate,
      isError: isErrorCalculate,
      error: errorCalculate,
      data: calculateCostsResponse
    }
  ] = useCalculateMutation();

  const [
    validatePromotion,
    {
      isLoading: isLoadingValidatePromotion,
      isSuccess: isSuccessValidatePromotion,
      isError: isErrorValidatePromotion,
      error: errorValidatePromotion,
      data: validatePromotionResponse
    }
  ] = useValidatePromotionRequestMutation();

  const [
    updatePriceRequest,
    {
      isSuccess: isSuccessUpdatePriceRequest,
      isLoading: isLoadingUpdatePriceRequest,
      isError: isErrorUpdatePriceRequest,
      data: updatePriceRequestResponse,
      error: errorUpdatePriceRequest
    }
  ] = useUpdateDraftPriceRequestMutation();

  const [
    submitPriceRequest,
    {
      isSuccess: isSuccessSubmitPR,
      isLoading: isLoadingSubmitPR,
      isError: isErrorSubmitPR,
      error: errorSubmitPR
    }
  ] = useSubmitAllWorkingGroupPriceRequestsMutation();

  const [
    submitAgentSupplierReview,
    {
      isSuccess: isSuccessAsReview,
      data: asReviewResponse,
      isLoading: isLoadingAsReview,
      isError: isErrorAsReview,
      error: errorAsReview
    }
  ] = useSubmitAgentSupplierReviewMutation();

  const [
    approvePriceRequest,
    {
      isLoading: isApproveLoading,
      isSuccess: isApproveSuccess,
      isError: isApproveError,
      error: approveError
    }
  ] = useApprovePriceRequestMutation();

  const [
    declinePriceRequest,
    {
      isLoading: isDeclineLoading,
      isSuccess: isDeclineSuccess,
      isError: isDeclineError,
      error: declineError
    }
  ] = useDeclinePriceRequestMutation();

  const [
    deleteSinglePriceRequest,
    { isSuccess: isSuccessDelete, isError: isErrorDelete, error: errorDelete }
  ] = useDeleteSinglePriceRequestMutation();

  useEffect(() => {
    if (isSuccessDelete) {
      onRequestClose();
    }
  }, [isSuccessDelete]);

  const [
    cancelSinglePriceRequest,
    {
      isLoading: isCancelLoading,
      isSuccess: isCancelSuccess,
      isError: isCancelError,
      error: cancelError
    }
  ] = useCancelSinglePriceRequestMutation();

  const { data: getExchangeRateResponse } = useGetExchangeRateQuery(
    {
      token,
      currencyLabel: fieldValues.currencyCode?.value
        ? [fieldValues.currencyCode.value]
        : undefined,
      fxDate: fieldValues.fxRateDate
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !(fieldValues.currencyCode && fieldValues.fxRateDate)
    }
  );

  // Base States for Warnings and Errors
  const [calculateWarnings, setCalculateWarnings] = useState<
    ValidationMessage[]
  >([]);
  const [calculateErrors, setCalculateErrors] = useState<ValidationMessage[]>(
    []
  );
  const [calendarWarnings, setCalendarWarnings] = useState<CalendarWarning[]>(
    []
  );
  const [uiValidationWarnings, setUiValidationWarnings] = useState<
    CalendarWarning[]
  >([]);
  const [uiValidationErrors, setUiValidationErrors] = useState<
    CalendarWarning[]
  >([]);

  const [invalidCalculateResponse, setInvalidCalculateResponse] =
    useState(false);

  // States used in Warning/Error control flow
  const [floorPrice, setFloorPrice] = useState(0);

  const [prevEstWSPrice, setPrevEstWSPrice] = useState<
    string | null | undefined
  >(undefined);

  const [
    justificationRequiredCalculationWarning,
    setJustificationRequiredCalculationWarning
  ] = useState<boolean>(false);

  const [
    justificationRequiredCalendarWarning,
    setJustificationRequiredCalendarWarning
  ] = useState<boolean>(false);

  const clearAllWarningsErrors = () => {
    setCalculateWarnings([]);
    setCalculateErrors([]);
    setCalendarWarnings([]);
    setUiValidationWarnings([]);
    setUiValidationErrors([]);
    setJustificationRequiredCalculationWarning(false);
    setJustificationRequiredCalendarWarning(false);
  };

  const statusDisablesForm = [ACCEPTED, PROCESSING];

  const disableEntireForm =
    statusDisablesForm.includes(priceRequestStatus as PriceRequestStatusEnum) ||
    calculateWarnings.length > 0 ||
    uiValidationWarnings.length > 0 ||
    uiValidationErrors.length > 0 ||
    (priceRequestStatus === LDB_REVIEW && formStatus.user === EXTERNAL);

  const [justCalculate, setJustCalculate] = useState<boolean>(true);

  const [sendAgentSupplierReview, setSendAgentSupplierReview] =
    useState<boolean>(false);

  const [sendApprovePr, setSendApprovePr] = useState<boolean>(false);

  const [showOtherReason, setShowOtherReason] = useState(false);
  const [inputtedOtherReason, setInputtedOtherReason] = useState<
    string | undefined
  >();

  useEffect(() => {
    const showOther = priceRequest?.reason?.includes('Other') || false;
    const inputtedOther = priceRequest?.reason
      ? extractOtherReason(priceRequest.reason)
      : '';

    setShowOtherReason(showOther);
    setInputtedOtherReason(inputtedOther);
  }, [priceRequest]);

  const setupInitialFieldValues = (prData: any) => {
    const {
      duty_paid_cost_per_case,
      duty_paid_cost_per_su,
      justification,
      prime_cost_per_case,
      promotion_amount_per_su,
      reason,
      final_effective_date,
      calendar_id,
      calendarId,
      final_submission_deadline,
      final_effective_end_date,
      currency_id,
      fx_rate_date,
      foreign_prime_cost,
      costing_event_comment,
      const_only_fl,
      domestic_charges_per_case,
      price_per_su,
      email,
      aces_number,
      estimated_price_per_su,
      other_reason,
      beer_distro_charge_per_su,
      crf_per_su,
      customsAmountPerCase,
      excise_amount_per_case,
      floor_price,
      in_bond_cost_per_case,
      markup_per_su,
      price_per_case
    } = prData;

    const calendarSubtype = calendarId?.sub_type || null;
    const effectiveDateLabel =
      calendarSubtype === 'AD_HOC' ? 'ASAP' : dateFormat(final_effective_date);

    const endDateValue =
      final_effective_end_date || calendarId?.effective_end_date;

    const prFieldValues = {
      primeCostPerCase: fixedDecimalPlaces(Number(prime_cost_per_case) || 0, 2),
      domesticChargesPerCase: fixedDecimalPlaces(
        Number(domestic_charges_per_case),
        2
      ),
      promotionAmount: fixedDecimalPlaces(
        Number(promotion_amount_per_su) || 0,
        2
      ),
      dutyPaidCostPerCase: fixedDecimalPlaces(
        Number(duty_paid_cost_per_case) || 0,
        2
      ),
      dutyPaidCostPerSU: fixedDecimalPlaces(
        Number(duty_paid_cost_per_su) || 0,
        2
      ),
      estWholesalePrice: fixedDecimalPlaces(
        Number(estimated_price_per_su) || 0,
        2
      ),
      pricePerSU: fixedDecimalPlaces(Number(price_per_su) || 0, 2),
      reason: {
        label: mapPriceRequestReason(reason, 'label'),
        value: mapPriceRequestReason(reason, 'value', costing_event_comment)
      },
      exceptionReason: other_reason,
      justification: justification,
      effectiveDate: {
        label: effectiveDateLabel,
        value: final_effective_date,
        calendarId: calendar_id,
        submissionDeadline: final_submission_deadline || null
      },
      endDate: endDateValue || undefined,
      costingEventComment: costing_event_comment,
      aces_number: aces_number,
      costOnly: const_only_fl,
      currencyCode: { label: currency_id, value: currency_id },
      fxRateDate: fx_rate_date && formatNumericDate(fx_rate_date),
      foreignPrimeCost: fixedDecimalPlaces(Number(foreign_prime_cost) || 0, 2),
      calculatedExchangeRate:
        getExchangeRateResponse?.currencyValue[0]?.currency.value, //ab10797 , change according to data of new service response
      email: email,
      beerDistroChargePerSU: beer_distro_charge_per_su,
      crfPerSU: crf_per_su,
      customsAmountPerCase: customsAmountPerCase,
      exciseAmountPerCase: excise_amount_per_case,
      floorPrice: floor_price,
      inBondCostPerCase: in_bond_cost_per_case,
      markupPerSU: markup_per_su,
      pricePerCase: price_per_case
    };

    dispatch(
      setInitialInputValues(prFieldValues as PriceRequestDetailsFormFieldValues)
    );

    dispatch(setFormStatus({ isInit: true }));

    return prFieldValues;
  };

  const initialFieldValues = useMemo(
    () => setupInitialFieldValues(priceRequest),
    [priceRequest]
  );

  const priceChangeRequest =
    priceRequestType === 'PC' || priceRequestType === 'NP';

  // Request type is Supplier Price Change
  const wholesalePromotionRequest = priceRequestType === 'WPP';

  // PR is New Product Request
  const isNewProductPriceRequest = priceRequestType === 'NP';

  // PR is New Product Request in AS_REVIEW and opened by the Agent
  const isNPagentReview =
    isNewProductPriceRequest && !isInternal && priceRequestStatus === AS_REVIEW;

  // A NEW Product PR in !A/S Review needs to be assigned the
  // current AD_HOC ASAP date when viewed/submitted by the Agent
  useEffect(() => {
    if (!isNPagentReview) return;

    const asapOption = effectiveDateOptions.find(
      (item) => item.label === 'ASAP'
    );

    if (!asapOption) return;

    const { label, value, calendarId, submissionDeadline } = asapOption;

    if (asapOption && fieldValues.effectiveDate?.calendarId !== calendarId) {
      dispatch(
        setInputValue({
          fieldName: 'effectiveDate',
          value: {
            label,
            value,
            calendarId,
            submissionDeadline
          }
        })
      );
    }
  }, [isNPagentReview, effectiveDateOptions]);

  const hasWarnings = calculateWarnings.length > 0;
  const hasCalendarWarnings = calendarWarnings.length > 0;
  const hasUiValidationWarnings = uiValidationWarnings.length > 0;
  const hasUIWarnings =
    hasWarnings || hasCalendarWarnings || hasUiValidationWarnings;

  const prWithWarningSubmittedToConfirm = hasUIWarnings && !sendApprovePr;
  const justificationRequired =
    justificationRequiredCalculationWarning ||
    justificationRequiredCalendarWarning;

  //the below variables pull in the appropriate warning and errors copy from the translations file based on whether it is a price change request or a wpp
  const warningMessageContent: {
    [key: string]: {
      title: string;
      description: string;
      type: string;
      justificationRequired: boolean;
      exceptionReason?: string;
    };
  } = priceChangeRequest
    ? modalCopy.apiValidation.priceChange.warnings
    : modalCopy.apiValidation.promotionValidation.warnings;

  const errorMessageContent: {
    [key: string]: {
      title: string;
      description: string;
      type: string;
      justificationRequired: boolean;
      exceptionReason?: string;
    };
  } = priceChangeRequest
    ? modalCopy.apiValidation.priceChange.errors
    : modalCopy.apiValidation.promotionValidation.errors;

  useEffect(() => {
    if (sku) {
      dispatch(
        setFormStatus({
          user: user.type,
          priceRequestType: priceRequestType,
          priceRequestStatus: priceRequestStatus,
          productSku: sku,
          productIsPromotion: priceRequestType === 'WPP',
          productIsDPC:
            priceRequestProduct?.is_cost_dpc.toLowerCase() === 'yes',
          productIsImport: priceRequestProduct?.country_of_export !== 'CA'
        })
      );
    }
  }, [sku, priceRequestStatus]);

  const [isLoadingWSEffectiveDate, setIsLoadingWSEffectiveDate] =
    useState(false);

  // Call Calculate API on modal open after the initial form values have been set
  useEffect(() => {
    if (!formStatus.isInit) return;
    if (
      formStatus.productSku &&
      !isLoadingWSEffectiveDate &&
      !asReviewResponse
    ) {
      handleCalculate();
    }
  }, [formStatus, isLoadingWSEffectiveDate]);

  const showCancel = useSelector(prIsCancellable);

  const setEstWholesalePrice = (value: string | undefined) => {
    dispatch(
      setInputValue({
        fieldName: 'estWholesalePrice',
        value
      })
    );
    methods.setValue('estWholesalePrice', value);
  };

  // api call to get the wholesale price based on pr type of Wholesale Price Promotion and an effective date being selected. Only for WPP preice requests

  useEffect(() => {
    if (productIsPromotion) {
      setIsLoadingWSEffectiveDate(true);

      if (!fieldValues.effectiveDate?.value) return;

      const transactionDate = formatNumericDate(
        fieldValues.effectiveDate.value,
        true
      );

      const priceBody = {
        transactionDate,
        sku: [sku]
      };

      if (token) {
        //pass in the token, price body and callback fxn to the getWholesalePrice fxn to make the api call
        getWholesalePrice({
          accessToken: token,
          data: priceBody,
          callbackFunction: setEstWholesalePrice,
          loadingCallbackFunction: (error) => {
            setIsLoadingWSEffectiveDate(false);
            if (error === 'Product Not Priced') {
              setCalculateErrors([
                {
                  title:
                    modalCopy.apiValidation.promotionValidation.errors[3].title,
                  code: '3',
                  message:
                    modalCopy.apiValidation.promotionValidation.errors[3]
                      .description,
                  type: 'ERROR'
                }
              ]);
            } else if (error) {
              setCalculateErrors([
                {
                  code: 'error',
                  message: 'An Error has occurred please try again.',
                  type: 'ERROR'
                }
              ]);
            }
          }
        });
      }
    }
  }, [fieldValues.effectiveDate, sku, productIsPromotion, token]);

  const defaultFormValues = {
    ...initialFieldValues
  };

  // pass in the schema and the default values
  const methods = useForm<FormData>({
    resolver: yupResolver<any>(schema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues
  });

  //Get access to the list of errors on the form.
  const modalFormErrors = methods.formState.errors;

  //check that no form errors exist in the modal
  const [formErrorsExist, setFormErrorsExist] = useState(false);

  useEffect(() => {
    if (modalFormErrors.constructor !== Object) return;
    setFormErrorsExist(Object.keys(modalFormErrors).length > 0);
  }, [Object.keys(modalFormErrors).length]);

  const currencyErrors = [
    methods.getFieldState('foreignPrimeCost').invalid,
    methods.getFieldState('exchangeRate').invalid,
    methods.getFieldState('currencyCode').invalid,
    methods.getFieldState('fxRateDate').invalid
  ];
  const currencyErrorExists = currencyErrors.includes(true);

  const [endDateError, setEndDateError] = useState(false);

  const handleTextInputBlur = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputId:
      | 'primeCostPerCase'
      | 'dutyPaidCostPerCase'
      | 'domesticChargesPerCase'
      | 'promotionAmount'
      | 'estWholesalePrice'
      | 'foreignPrimeCost'
      | 'pricePerSU'
  ) => {
    let { value } = event.target;
    let editValue = null;
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numberValue)) {
      return;
    } else {
      editValue = fixedDecimalPlaces(numberValue, 2);
    }

    if (editValue !== null && editValue?.length > 0) {
      value = editValue;
    } else {
      return;
    }
    dispatch(
      setInputValue({
        fieldName: inputId as keyof PriceRequestDetailsFormFieldValues,
        value
      })
    );

    if (
      inputId === 'primeCostPerCase' ||
      inputId === 'domesticChargesPerCase' ||
      inputId === 'dutyPaidCostPerCase'
    ) {
      methods.clearErrors();
      const emptyValue = '';
      dispatch(
        setInputValue({
          fieldName: 'pricePerSU',
          value: emptyValue
        })
      );
    }

    if (inputId === 'promotionAmount') {
      clearAllWarningsErrors();
      methods.clearErrors();
      const emptyValue = '';
      dispatch(
        setInputValue({
          fieldName: 'pricePerSU',
          value: emptyValue
        })
      );
    }

    if (COST_FIELDS.includes(inputId)) {
      COST_FIELDS.forEach((fieldName) => {
        const fieldValue = fieldValues[fieldName];
        if (Number(fieldValue) < 0) {
          dispatch(
            setInputValue({
              fieldName,
              value: ''
            })
          );
          methods.setValue(fieldName as any, '');
          setInvalidCalculateResponse(false);
        }
      });

      methods.clearErrors();
    }
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputId: keyof PriceRequestDetailsFormFieldValues
  ) => {
    let { value } = event.target;

    //Allows "." to be enter at the front of value, and adding 0 to it to avoid Warning message on example like ".75"
    if (
      value.charAt(0) === '.' &&
      event.target.className?.includes('CurrencyInput')
    ) {
      methods.setValue(inputId as any, value.replace('.', '0.'));
      value = value.replace('.', '0.');
    } else {
      methods.setValue(inputId as any, value);
    }

    methods.setValue(inputId as any, value);

    dispatch(
      setInputValue({
        fieldName: inputId as keyof PriceRequestDetailsFormFieldValues,
        value
      })
    );

    if (inputId === 'costOnly') {
      dispatch(
        setInputValue({
          fieldName: 'costOnly',
          value: (event.target as HTMLInputElement).checked
        })
      );
      return;
    }

    if (inputId === 'asReview') {
      dispatch(
        setInputValue({
          fieldName: 'asReview',
          value: (event.target as HTMLInputElement).checked
        })
      );
      return;
    }

    if (inputId === 'justification') {
      setCalculateErrors([]);
    }

    if (
      inputId === 'primeCostPerCase' ||
      inputId === 'domesticChargesPerCase' ||
      inputId === 'dutyPaidCostPerCase'
    ) {
      methods.clearErrors();
      const emptyValue = '';
      dispatch(
        setInputValue({
          fieldName: 'pricePerSU',
          value: emptyValue
        })
      );
    }

    if (inputId === 'promotionAmount') {
      clearAllWarningsErrors();
      methods.clearErrors();
      const emptyValue = '';
      dispatch(
        setInputValue({
          fieldName: 'pricePerSU',
          value: emptyValue
        })
      );
    }

    if (COST_FIELDS.includes(inputId)) {
      COST_FIELDS.forEach((fieldName) => {
        const fieldValue = fieldValues[fieldName];
        if (Number(fieldValue) < 0) {
          dispatch(
            setInputValue({
              fieldName,
              value: ''
            })
          );
          methods.setValue(fieldName as any, '');
          setInvalidCalculateResponse(false);
        }
      });

      methods.clearErrors();
    }
  };

  const handleDateSelectInputChange = (
    input: SelectInputDateType,
    inputId: 'effectiveDate'
  ) => {
    const { value, label, calendarId, submissionDeadline } = input;
    const effectiveDateObj = effectiveDates?.filter(
      (obj) => obj.id === calendarId
    );
    const endDateValue =
      effectiveDateObj && effectiveDateObj[0]?.effectiveEndDate
        ? effectiveDateObj[0].effectiveEndDate
        : null;

    methods.setValue(
      inputId,
      {
        value: value,
        label: label
      },
      { shouldValidate: true }
    );

    switch (inputId) {
      case 'effectiveDate':
        clearAllWarningsErrors();

        dispatch(
          setInputValue({
            fieldName: inputId as keyof PriceRequestDetailsFormFieldValues,
            value: {
              label,
              value,
              calendarId,
              submissionDeadline
            }
          })
        );

        if (endDateValue) {
          dispatch(
            setInputValue({
              fieldName: 'endDate',
              value: endDateValue
            })
          );

          methods.setValue('endDate', endDateValue, { shouldValidate: false });
        }

        dispatch(
          setInputValue({
            fieldName: 'pricePerSU',
            value: undefined
          })
        );

        methods.setValue('pricePerSU', undefined, { shouldValidate: false });

        break;
    }
  };

  const handleSelectInputChange = (input: SelectInputType, inputId: any) => {
    if (input != null) {
      const { value, label } = input;
      methods.setValue(
        inputId,
        {
          value,
          label
        },
        { shouldValidate: true }
      );

      dispatch(
        setInputValue({
          fieldName: inputId as keyof PriceRequestDetailsFormFieldValues,
          value: input
        })
      );

      switch (inputId) {
        case 'reason':
          if (value !== 'OTHER') {
            setShowOtherReason(false);
            dispatch(
              setInputValue({
                fieldName: 'costingEventComment',
                value
              })
            );
          } else {
            setInputtedOtherReason(fieldValues.reason?.label);
            setShowOtherReason(true);
          }
          break;
      }
    } else {
      // Value is Null, clear reason drop down and associated fields
      if (inputId === 'reason') {
        dispatch(
          setInputValue({
            fieldName: 'reason',
            value: {
              label: '',
              value: ''
            }
          })
        );
        dispatch(
          setInputValue({
            fieldName: 'costingEventComment',
            value: ''
          })
        );
        setInputtedOtherReason('');
        setShowOtherReason(false);
        methods.setValue('costingEventComment', '');
        methods.setValue('reason', {
          value: '',
          label: ''
        });
      }
    }
  };

  useEffect(() => {
    //create an API call to fetch currency code data using the /currency GET endpoint
    // needs the token stored in sessionStorage for the Authorization Bearer token
    async function getExchangeRate(
      accessToken: string | null,
      queryParams: string
    ) {
      try {
        dispatch(triggerActivityMonitorReset(true));
        setLoadingState(true);
        const result = await fetchApiData(
          `currency-fxrate?${queryParams}`,
          accessToken,
          'GET'
        );
        const exchangeRateResponse = await handleApiResponse(result);
        // if there is a response, update the currency context with the response.
        if (exchangeRateResponse) {
          const rate = exchangeRateResponse?.currencyValue[0]?.currency.value; //ab10797 , change according to data of new service response
          const rateDate =
            exchangeRateResponse?.currencyValue[0]?.effectiveDate;
          dispatch(
            setInputValue({
              fieldName: 'calculatedExchangeRate',
              value: rate
            })
          );
          handleCalendarDatePickerInput(rateDate, 'fxRateDate');
        }
      } catch (error) {
        handleBanner(error, 'Error fetching exchange rate');
        dispatch(
          setInputValue({
            fieldName: 'calculatedExchangeRate',
            value: ''
          })
        );
      } finally {
        setLoadingState(false);
      }
    }
    if (fieldValues.currencyCode && fieldValues.fxRateDate) {
      const currencyArray = [];
      currencyArray.push(fieldValues.currencyCode.value);
      const queryParams = `effectiveDate=${fieldValues.fxRateDate}&currencyLabel=${currencyArray}`;

      if (token) {
        getExchangeRate(token, queryParams);
      }
    }
  }, [fieldValues.currencyCode, fieldValues.fxRateDate, token]);

  const handleCalendarDatePickerInput = (
    date: string,
    inputId: keyof PriceRequestDetailsFormFieldValues
  ) => {
    let value: any = formatNumericDate(date);

    switch (inputId) {
      case 'effectiveDate': {
        clearAllWarningsErrors();

        const matchedLDBCalendar = effectiveDates!.filter(
          (effectiveDate: CalendarDate) => {
            const prCalendarType =
              priceRequestType === 'WPP' ? 'PROMOTION' : 'PRICE';
            const isDateMatch = isSameDate(effectiveDate.effectiveDate, date);
            const isPrTypeMatch = effectiveDate.type === prCalendarType;
            return isDateMatch && isPrTypeMatch;
          }
        );

        const labelValue = formatNumericDate(
          matchedLDBCalendar[0]?.effectiveDate || date
        );

        const calendarId = matchedLDBCalendar[0]?.id || null;
        const submissionDeadline = matchedLDBCalendar[0]
          ? formatNumericDate(matchedLDBCalendar[0].submissionDeadLine)
          : null;

        value = {
          label: labelValue,
          value: labelValue,
          calendarId,
          submissionDeadline
        };

        // Reset the 'endDate', 'pricePerSU', 'estWholesalePrice'
        // value when the effectiveDate is changed
        const fieldsToReset = ['endDate', 'pricePerSU', 'estWholesalePrice'];
        fieldsToReset.forEach((field) => {
          dispatch(
            setInputValue({
              fieldName: field as keyof PriceRequestDetailsFormFieldValues,
              value: ''
            })
          );
          methods.setValue(field as keyof FormData, '');
        });
        break;
      }
      case 'endDate': {
        methods.clearErrors();
        setEndDateError(false);
        break;
      }
    }

    dispatch(
      setInputValue({
        fieldName: inputId,
        value
      })
    );

    methods.setValue(inputId as any, value);
  };

  //onClick functionality for the calculator button as part of the Import product currency information
  // Prime Cost/ Case = Exchange Rate * Foreign Prime Cost
  const handleCalculatePrimeCost = () => {
    const foreignPrimeCostValue = fieldValues.foreignPrimeCost;
    const calculatedExchangeRate = fieldValues.calculatedExchangeRate;
    if (calculatedExchangeRate && foreignPrimeCostValue) {
      const primeCost = fixedDecimalPlaces(
        Number(calculatedExchangeRate * Number(foreignPrimeCostValue)) || 0,
        2
      ).toString();
      dispatch(
        setInputValue({
          fieldName: 'primeCostPerCase',
          value: primeCost
        })
      );

      return methods.setValue('primeCostPerCase', primeCost, {
        shouldValidate: false
      });
    }
  };

  const validateCalculateFields = () => {
    const scenario = getScenario();
    const minFieldRequirements = scenario === 4 ? 2 : 1;
    const calculateFields = getCalculateFields(scenario, fieldValues);

    const fieldsWithValue = calculateFields.filter((field) => {
      if (field.name === 'promotionAmount') {
        return field.value > 0;
      }

      return field.value || field.value === 0;
    });

    if (fieldsWithValue.length < minFieldRequirements) {
      calculateFields.forEach((field) => {
        if (!field.value) {
          methods.setError(field.name as keyof FormData, {
            type: 'error',
            message: `At least ${minFieldRequirements} Calculation field${
              minFieldRequirements > 1 ? 's' : ''
            } must be entered`
          });
        } else if (field.name === 'promotionAmount') {
          if (Number(field.value) === 0) {
            methods.setError(field.name as keyof FormData, {
              type: 'error',
              message: `Promotion Amount must be greater than 0.`
            });
          }
        }
      });
      return false;
    }

    return true;
  };

  // EPP-1588
  // Conditional field rendering issue causes endDate to not handle validation correctly.
  // This is a stop gap until the issue is resolved.
  const endDateValidation = () => {
    if (!wholesalePromotionRequest) return;
    if (!fieldValues.endDate) {
      setEndDateError(true);
      methods.setError('endDate', {
        type: 'error',
        message: 'End Date is required'
      });
    }
  };

  const handleSaveAndClose = () => {
    endDateValidation();

    const calcFieldsValid = validateCalculateFields();

    if (formErrorsExist || !calcFieldsValid) return;

    if (
      hasUIWarnings &&
      (justificationRequiredCalculationWarning ||
        justificationRequiredCalendarWarning) &&
      (!fieldValues.justification || fieldValues.justification === '')
    ) {
      setCalculateErrors([
        {
          message:
            modalCopy.uiValidationWarnings.missingJustification.description,
          type: 'ERROR',
          code: '0'
        }
      ]);
      methods.setFocus('justification');
      return;
    }
    setJustCalculate(false);

    handleCalculate();
  };

  useEffect(() => {
    if (isSuccessUpdatePriceRequest && updatePriceRequestResponse) {
      const statusShouldProccessAsReview = [DRAFT, LDB_REVIEW, QUEUED];

      if (
        sendAgentSupplierReview &&
        priceRequestStatus &&
        statusShouldProccessAsReview?.includes(
          priceRequestStatus as PriceRequestStatusEnum
        )
      ) {
        submitAgentSupplierReview({
          requestBody: {
            id: priceRequest?.id
          },
          token
        });

        return;
      }

      if (sendApprovePr) {
        const body = {
          id: selectedPriceRequestID ? selectedPriceRequestID : 0
        };
        approvePriceRequest({ token, body });

        return;
      }

      submitPriceRequest({
        requestBody: {
          prIds: [priceRequest?.id]
        },
        token
      });
    }
  }, [
    isSuccessUpdatePriceRequest,
    priceRequest?.id,
    updatePriceRequestResponse,
    sendApprovePr,
    selectedPriceRequestID
  ]);

  const handleSuccessAndCloseModal = () => {
    const bannerBody: BannerType = {
      title: 'Your Price Request has been Submitted.',
      description: `A confirmation email will be sent to ${user.email} unless otherwise specified (LDB only)`,
      type: 'success'
    };

    if (sendApprovePr) {
      const submittedByEmail = priceRequest.submittedBy?.email;
      const emailOverride = fieldValues.email
        ? fieldValues.email
        : priceRequest.email
        ? priceRequest.email
        : 'N/A';
      bannerBody.title = 'The Price Request has been approved.';
      bannerBody.description = `A confirmation email will be sent to the original submitter (${submittedByEmail}), if the submitter was an Agent / Supplier; or, the email override (${emailOverride}), if the submitter was LDB.`;
    }

    dispatch(setBanner(bannerBody));
    onRequestClose();
  };

  useEffect(() => {
    if (isSuccessSubmitPR || isApproveSuccess) {
      handleSuccessAndCloseModal();
    }
  }, [isSuccessSubmitPR, isApproveSuccess]);

  useEffect(() => {
    if (isSuccessAsReview) {
      refetchPRData();
      setSendAgentSupplierReview(false);
    }
  }, [isSuccessAsReview]);

  const handleApprovePR = () => {
    setJustCalculate(false);
    setSendApprovePr(true);
    handleSaveAndClose();
  };

  const handleDeclinePR = () => {
    const body = {
      id: selectedPriceRequestID ? selectedPriceRequestID : 0
    };
    declinePriceRequest({ token, body });
  };

  useEffect(() => {
    if (isDeclineSuccess) {
      refetchPRData();
      scrollModalToTop();
    }
  }, [isDeclineSuccess]);

  useEffect(() => {
    if (isCancelSuccess) {
      refetchPRData();
      onRequestClose();
    }
  }, [isCancelSuccess, refetchPRData]);

  const handleBanner = (error: any | undefined, title: string) => {
    dispatch(
      setBannerDetails({
        title,
        description: 'An Error has occurred',
        type: 'error'
      })
    );
  };

  useEffect(() => {
    dispatch(setBannerDetails(undefined));

    if (isCancelError) {
      handleBanner(cancelError, 'Error cancelling Price Request');
    }

    if (isApproveError) {
      handleBanner(
        approveError,
        'Error updating Price Request approval status'
      );
    }

    if (isDeclineError) {
      handleBanner(
        declineError,
        'Error updating Price Request approval status'
      );
    }
    if (isErrorUpdatePriceRequest) {
      handleBanner(errorUpdatePriceRequest, 'Error updating Price Request');
    }

    if (isErrorSubmitPR) {
      handleBanner(errorSubmitPR, 'Error submitting Price Request');
    }

    if (isErrorAsReview) {
      handleBanner(
        errorAsReview,
        'Error submitting Price Request for A/S Review'
      );
    }

    if (isErrorValidatePromotion) {
      handleBanner(errorValidatePromotion, 'Error validating promotion');
    }

    if (isErrorCalculate) {
      handleBanner(errorCalculate, 'Error calculating');
    }

    if (isErrorDelete) {
      handleBanner(errorDelete, 'Error deleting Price Request');
    }
  }, [
    isCancelError,
    isApproveError,
    isDeclineError,
    isErrorUpdatePriceRequest,
    isErrorSubmitPR,
    isErrorValidatePromotion,
    isErrorCalculate,
    isErrorAsReview,
    isErrorDelete
  ]);

  const getScenario = (): number => {
    // Scenario 3: Wholesale Promotion Request
    if (wholesalePromotionRequest) {
      return 3;
    }

    // Scenario 1: Domestic DPC Product
    if (productIsDPC) {
      return 1;
    }

    // Scenario 2: Domestic NON-DPC Product
    if (!productIsImport && !productIsDPC) {
      return 2;
    }

    // Scenario 4: Import Product
    return 4;
  };

  // This function is for the calculate button.
  const handleCalculate = () => {
    const calcFieldsValid = validateCalculateFields();
    if (!calcFieldsValid) return;
    //reformat the date ISO format to YYYY-DD-MM to fit the structure of the api request body
    const formattedEffectiveDate = formatNumericDate(
      fieldValues.effectiveDate?.value || '',
      false
    );
    const formattedEndDate = formatNumericDate(
      fieldValues.endDate || '',
      !isInternal,
      !isInternal
    );

    // if the request type is WPP, we are just calculating the subtraction of estWholesalePrice - promotionAmount to populate Est. WS  Promotional Price
    if (wholesalePromotionRequest) {
      if (fieldValues.estWholesalePrice && fieldValues.promotionAmount) {
        const estimatedWPP =
          Number(fieldValues.estWholesalePrice) -
          Number(fieldValues.promotionAmount);
        const formattedEstimatedWPP = fixedDecimalPlaces(estimatedWPP, 2);

        // Check if current EstWSPrice matches returned value
        // Used to trigger EstWSPrice Change Warning in validatePromotionIsSuccess
        const currentWPP = methods.getValues('pricePerSU');
        setPrevEstWSPrice(currentWPP);

        dispatch(
          setInputValue({
            fieldName: 'pricePerSU',
            value: formattedEstimatedWPP
          })
        );

        methods.setValue('pricePerSU', formattedEstimatedWPP);
        methods.trigger('pricePerSU');

        if (estimatedWPP < 0) {
          setInvalidCalculateResponse(true);
          return;
        }

        if (sku && fieldValues.effectiveDate && fieldValues.endDate) {
          validatePromotion({
            body: {
              sku: sku || 0,
              promotionAmountPerSU: Number(fieldValues.promotionAmount),
              promoPeriod: {
                dateFrom: formattedEffectiveDate,
                dateTo: formattedEndDate
              }
            },
            token
          });
        }
      }
    } else {
      // if the request type is not WPP, we need to use the /calculate api to retrieve one of several different numbers
      // this is based on the direction of the calculation as well as the scenario type and the inputted values
      // first create an object holding all potential inputted values that exist on the form, along with the sku and scenario type number
      const requestProperties = {
        sku: sku ?? 0,
        effectiveDate: formattedEffectiveDate,
        dutyPaidCostPerCase: fieldValues.dutyPaidCostPerCase,
        pricePerSU: fieldValues.pricePerSU,
        primeCostPerCase: fieldValues.primeCostPerCase,
        domesticChargesPerCase: fieldValues.domesticChargesPerCase?.toString(),
        scenario: getScenario()
      };

      if (token) {
        calculate({ requestBody: requestProperties, token });
      }
    }
  };

  const checkUiValidationErrors = () => {
    if (justCalculate) return false;
    const validationErrorMessages = [];
    const productAgent = priceRequestProduct?.agent;
    const priceRequestAgent = priceRequest.agentId?.id;
    const agentChangeErrorText = modalCopy.uiValidationErrors.agentChange;

    if (
      productAgent &&
      priceRequestAgent &&
      productAgent !== priceRequestAgent
    ) {
      validationErrorMessages.push(agentChangeErrorText);
    }

    setUiValidationErrors(validationErrorMessages);

    return validationErrorMessages.length > 0;
  };

  const checkCalendarWarnings = () => {
    if (
      (formStatus.user === INTERNAL && sendAgentSupplierReview) ||
      !fieldValues.effectiveDate ||
      isNPagentReview
    )
      return false;

    const isASAP = fieldValues.effectiveDate?.label === 'ASAP';

    const isPastDeadline = isPassedDeadline(
      fieldValues.effectiveDate.submissionDeadline || ''
    );

    const calendarWarningMessages = [];

    if (isPastDeadline && !isASAP) {
      const pastDeadlineMessage = {
        title: 'Late Submission',
        description:
          "The Submission Deadline for this Effective Date has passed. Late Submissions require a justification and business impact statement for BCLDB review and may not be approved. To proceed, please enter this information below then click 'Confirm & Save'.",
        exceptionReason: 'Late Submission'
      };
      calendarWarningMessages.push(pastDeadlineMessage);
    }

    if (isASAP) {
      const asapMessage = {
        title: 'ASAP Request',
        description:
          "Requesting an Effective Date outside the Dates and Deadlines Calendar requires a justification and business impact statement for BCLDB review and may not be approved. To proceed, please enter this information below then click 'Confirm & Save'.",
        exceptionReason: 'ASAP'
      };
      calendarWarningMessages.push(asapMessage);
    }

    if (calendarWarningMessages.length > 0) {
      setJustificationRequiredCalendarWarning(true);
    }

    if (!justCalculate) {
      setCalendarWarnings(calendarWarningMessages);
    }

    return calendarWarningMessages.length > 0;
  };

  const checkUiWarnings = (
    prevEstPrice: number,
    currentEstPrice?: number | null
  ) => {
    if (
      sendAgentSupplierReview ||
      invalidCalculateResponse ||
      currentEstPrice === Number(prevEstPrice) ||
      currentEstPrice === undefined ||
      currentEstPrice === null
    ) {
      return false;
    }

    const priceChangeWarning = wholesalePromotionRequest
      ? modalCopy.uiValidationWarnings.estimatedWSPriceChangeWPP
      : modalCopy.uiValidationWarnings.estimatedWSPriceChange;

    if (!justCalculate) {
      setUiValidationWarnings((prevWarnings) => [
        ...prevWarnings,
        priceChangeWarning
      ]);
    }

    return true;
  };

  const setCalculatedValues = (calculatedValues: any) => {
    const {
      pricePerSU,
      dutyPaidCostPerCase,
      primeCostPerCase,
      domesticChargesPerCase
    } = calculatedValues;

    // Set floorPrice - used in dynamic warning message text
    pricePerSU && setFloorPrice(pricePerSU);

    // Update display fields
    const displayFields: { key: keyof FormData; value: any }[] = [
      {
        key: 'dutyPaidCostPerCase',
        value: fixedDecimalPlaces(dutyPaidCostPerCase, 2)
      },
      {
        key: 'primeCostPerCase',
        value: fixedDecimalPlaces(primeCostPerCase, 2)
      },
      {
        key: 'domesticChargesPerCase',
        value: fixedDecimalPlaces(domesticChargesPerCase, 2)
      },
      { key: 'pricePerSU', value: fixedDecimalPlaces(pricePerSU, 2) }
    ];

    displayFields.forEach(({ key, value }) =>
      methods.setValue(key, value, { shouldValidate: true })
    );

    // Update fieldValues state with all values returned from calculate API
    const keysToRemove = ['effectiveDate', 'validationMessage', 'sku'];

    //AB#9119 Update fieldValues to string values returned from calculate API, avoid the numeric value Zero go down to boolean logic and interpreted wrongly.
    const keysToConvert = [
      'domesticChargesPerCase',
      'primeCostPerCase',
      'pricePerSU'
    ];
    Object.keys(calculatedValues)
      .filter((item) => !keysToRemove.includes(item))
      .forEach((item) => {
        dispatch(
          setInputValue({
            fieldName: item as keyof PriceRequestDetailsFormFieldValues,
            value: keysToConvert.includes(item)
              ? fixedDecimalPlaces(calculatedValues[item], 2)?.toString()
              : calculatedValues[item]
          })
        );
      });
  };

  // This function is used to check if there are any calculation warnings which require
  // justification based on their warning codes.
  const checkJustificationRequired = (warningCode: string) =>
    warningMessageContent[warningCode]?.justificationRequired ?? false;

  const setValidationMessages = (validationMessage: ValidationMessage[]) => {
    const validationWarnings: ValidationMessage[] = [];
    const validationErrors: ValidationMessage[] = [];
    const warningJustificationStatus = [];
    let hasCalculationErrors = false;

    for (const message of validationMessage) {
      if (message.type === 'WARNING') {
        const warningRequiresJustification = checkJustificationRequired(
          message.code
        );
        warningJustificationStatus.push(warningRequiresJustification);
        validationWarnings.push(message);
      } else if (message.type === 'ERROR') {
        hasCalculationErrors = true;
        validationErrors.push(message);
      }
    }

    if (!justCalculate) {
      const warningsRequireJustification = warningJustificationStatus.some(
        (item) => item
      );
      setCalculateWarnings(validationWarnings);
      setCalculateErrors(validationErrors);
      setJustificationRequiredCalculationWarning(warningsRequireJustification);
    }

    return hasCalculationErrors;
  };

  // We only check for calculation warnings here, since calendar warnings are validated
  // with justification if needed in handleSaveAndClose(), prior to calling this function.
  const [proceedWithApproval, toggleProceedWithApproval] = useState(false);
  const checkSubmitWithWarnings = useCallback(() => {
    const { justification } = fieldValues;
    //check proceedWithApproval - if false ie upon first approval button click don't allow to proceed
    //  on confirm and save button click proceedWithApproval set to true, now that proceedWithApproval is true, can continue with rest of the check.
    if (!proceedWithApproval) {
      return false;
    } else
      return (
        Boolean(justification) ||
        (hasUIWarnings && !justificationRequiredCalculationWarning)
      );
  }, [
    proceedWithApproval,
    hasUIWarnings,
    justificationRequiredCalculationWarning
  ]);

  useEffect(() => {
    if (!isSuccessCalculate || !calculateCostsResponse) return;
    const prevPricePerSu = Number(fieldValues.pricePerSU);
    setCalculatedValues(calculateCostsResponse);

    const calculatedValuesToValidate = [
      calculateCostsResponse.pricePerSU,
      calculateCostsResponse.domesticChargesPerCase,
      calculateCostsResponse.dutyPaidCostPerCase,
      calculateCostsResponse.primeCostPerCase
    ];

    if (calculatedValuesToValidate.some((value) => value < 0)) {
      setInvalidCalculateResponse(true);
      return;
    }

    clearAllWarningsErrors();

    const hasValidationErrors: any = checkUiValidationErrors();
    if (hasValidationErrors) return;

    const { validationMessage, pricePerSU } = calculateCostsResponse;
    const hasCalendarWarnings = checkCalendarWarnings();
    const hasUiWarnings = checkUiWarnings(prevPricePerSu, pricePerSU);

    if (
      (validationMessage?.length > 0 || hasCalendarWarnings || hasUiWarnings) &&
      !sendAgentSupplierReview
    ) {
      let hasCalculationErrors = false;

      if (validationMessage?.length > 0) {
        hasCalculationErrors = setValidationMessages(validationMessage);
      }

      const shouldSubmitWithWarnings = checkSubmitWithWarnings();

      if (!shouldSubmitWithWarnings || hasCalculationErrors) return;
    }

    updatePriceRequestData(calculateCostsResponse);
  }, [isSuccessCalculate, calculateCostsResponse]);

  useEffect(() => {
    if (!isSuccessValidatePromotion || !validatePromotionResponse) return;
    clearAllWarningsErrors();

    const { validationMessage } = validatePromotionResponse;
    const hasCalendarWarnings = checkCalendarWarnings();
    const hasUiWarnings = checkUiWarnings(
      Number(prevEstWSPrice),
      Number(fieldValues.pricePerSU)
    );

    const hasValidationErrors: any = checkUiValidationErrors();

    if (hasValidationErrors) return;

    if (
      (validationMessage?.length > 0 || hasCalendarWarnings || hasUiWarnings) &&
      !sendAgentSupplierReview
    ) {
      let hasCalculationErrors = false;
      if (validationMessage?.length > 0) {
        hasCalculationErrors = setValidationMessages(validationMessage);
      }

      const shouldSubmitWithWarnings = checkSubmitWithWarnings();

      if (!shouldSubmitWithWarnings || hasCalculationErrors) return;
    }

    updatePriceRequestData(null);
  }, [isSuccessValidatePromotion, validatePromotionResponse]);

  const updatePriceRequestData = (calculatedValues?: any) => {
    if (justCalculate) return;
    const reasonValue = fieldValues.reason?.label || '';

    const costingCommentValue = fieldValues.costingEventComment || '';
    //EPP-1371 - the reason should only include "New Product" and should not include "Supplier Price Change" (you cannot change price on something that doesn't have a price yet).
    //The appending logic is only applicable when PR type is PC, not when the type is NP.
    const isPC = priceRequestType === 'PC';
    const { updatedReason, updatedCostingEventComment } =
      appendReasonCostingComment(
        formStatus.user,
        isPC,
        formStatus.priceRequestStatus,
        reasonValue,
        inputtedOtherReason,
        costingCommentValue
      );
    const concatExceptionOtherReason: any = [];

    if (
      fieldValues?.exceptionReason !== null &&
      fieldValues?.exceptionReason !== undefined
    ) {
      const tempReasonList = fieldValues?.exceptionReason.split(';');
      tempReasonList?.forEach((reason) => {
        concatExceptionOtherReason.push(reason);
      });
    }

    if (calculateWarnings?.length > 0 || calendarWarnings?.length > 0) {
      calendarWarnings?.forEach((warning) => {
        const { exceptionReason } = warning;
        exceptionReason && concatExceptionOtherReason.push(exceptionReason);
      });

      calculateWarnings?.forEach((warning) => {
        const { code } = warning;
        const { exceptionReason } = warningMessageContent[code];
        exceptionReason && concatExceptionOtherReason.push(exceptionReason);
      });
    }

    const filteredConcatExceptionOtherReason = Array.from(
      new Set(concatExceptionOtherReason)
    )
      .filter(Boolean)
      .join('; ');

    const {
      beerDistroChargePerSU = wholesalePromotionRequest
        ? undefined
        : fieldValues.beerDistroChargePerSU,
      crfPerSU = wholesalePromotionRequest ? undefined : fieldValues.crfPerSU,
      customsAmountPerCase = wholesalePromotionRequest
        ? undefined
        : fieldValues.customsAmountPerCase,
      domesticChargesPerCase = wholesalePromotionRequest
        ? undefined
        : fieldValues.domesticChargesPerCase,
      dutyPaidCostPerCase = wholesalePromotionRequest
        ? undefined
        : fieldValues.dutyPaidCostPerCase,
      dutyPaidCostPerSU = wholesalePromotionRequest
        ? undefined
        : fieldValues.dutyPaidCostPerSU,
      exciseAmountPerCase = wholesalePromotionRequest
        ? undefined
        : fieldValues.exciseAmountPerCase,
      floorPrice = wholesalePromotionRequest
        ? undefined
        : fieldValues.floorPrice,
      inBondCostPerCase = wholesalePromotionRequest
        ? undefined
        : fieldValues.inBondCostPerCase,
      markupPerSU = wholesalePromotionRequest
        ? undefined
        : fieldValues.markupPerSU,
      pricePerCase = wholesalePromotionRequest
        ? undefined
        : fieldValues.pricePerCase,
      pricePerSU = fieldValues.pricePerSU,
      primeCostPerCase = wholesalePromotionRequest
        ? undefined
        : fieldValues.primeCostPerCase,
      fxRateDate = wholesalePromotionRequest
        ? undefined
        : fieldValues.fxRateDate,
      foreignPrimeCost = wholesalePromotionRequest
        ? undefined
        : fieldValues.foreignPrimeCost
    } = calculatedValues ?? {};

    const formattedEffectiveDate = fieldValues.effectiveDate?.value
      ? formatNumericDate(fieldValues.effectiveDate?.value)
      : undefined;
    const formattedEndDate = fieldValues.endDate
      ? formatNumericDate(fieldValues.endDate)
      : undefined;

    let payload: PriceRequest = {
      price_per_su: Number(pricePerSU),
      estimated_price_per_su: Number(fieldValues.estWholesalePrice),
      duty_paid_cost_per_case: Number(dutyPaidCostPerCase),
      duty_paid_cost_per_su: Number(dutyPaidCostPerSU),
      prime_cost_per_case: Number(primeCostPerCase),
      domestic_charges_per_case: Number(domesticChargesPerCase),
      justification: fieldValues.justification,
      other_reason: filteredConcatExceptionOtherReason,
      aces_number: Number(fieldValues.aces_number) || null,
      warning_json: JSON.stringify([...calculateWarnings, ...calendarWarnings]),
      error_json: JSON.stringify([...calculateErrors]),
      id: priceRequest.id,
      reason: updatedReason,
      costing_event_comment: updatedCostingEventComment,
      const_only_fl: fieldValues.costOnly,
      a_s_review_fl: false,
      justification_required_fl:
        justificationRequiredCalculationWarning ||
        justificationRequiredCalendarWarning,
      effective_date: formattedEffectiveDate,
      effective_end_date: formattedEndDate,
      calendar_id: Number(fieldValues.effectiveDate?.calendarId) || undefined,
      promotion_amount_per_su: Number(fieldValues.promotionAmount) || undefined,
      in_bond_cost_per_case: Number(inBondCostPerCase),
      excise_amount_per_case: Number(exciseAmountPerCase),
      customsAmountPerCase: Number(customsAmountPerCase),
      beer_distro_charge_per_su: Number(beerDistroChargePerSU),
      markup_per_su: Number(markupPerSU),
      crf_per_su: Number(crfPerSU),
      price_per_case: Number(pricePerCase),
      floor_price: floorPrice,
      fx_rate_date: fxRateDate,
      currency_id: fieldValues.currencyCode?.value
        ? fieldValues.currencyCode.value
        : null,
      exchange_rate: Number(fieldValues.calculatedExchangeRate),
      foreign_prime_cost: Number(foreignPrimeCost)
    };

    if (!isInternal) {
      delete payload['effective_date'];
      delete payload['effective_end_date'];
      delete payload['currency_id'];
      delete payload['fx_rate_date'];
      delete payload['exchange_rate'];
      delete payload['foreign_prime_cost'];
    }

    if (isInternal) {
      delete payload['calendar_id'];
      if (sendAgentSupplierReview) {
        delete payload['warning_json'];
        delete payload['error_json'];
      }
    }

    if (fieldValues.email) {
      payload = Object.assign({ ...payload }, { email: fieldValues.email });
    }

    const newPriceRequestPayload = {
      requestBody: payload,
      token
    };

    updatePriceRequest(newPriceRequestPayload);
  };

  const handleClearModal = () => {
    setSendApprovePr(false);
    setEndDateError(false);
    setSendAgentSupplierReview(false);
    clearAllWarningsErrors();
  };

  const statusSubmitEnabledInternal = [
    DRAFT,
    QUEUED,
    CANCELLED,
    DECLINED,
    AS_REVIEW
  ];
  const statusSubmitEnabledExternal = [
    DRAFT,
    QUEUED,
    CANCELLED,
    DECLINED,
    AS_REVIEW
  ];

  const submitEnabled =
    userType === INTERNAL
      ? statusSubmitEnabledInternal?.includes(
          priceRequestStatus as PriceRequestStatusEnum
        )
      : statusSubmitEnabledExternal?.includes(
          priceRequestStatus as PriceRequestStatusEnum
        );

  const isInLdbReviewStatus = priceRequestStatus === LDB_REVIEW;

  // Define a union type for all valid field names
  type CurrencyFieldName =
    | 'foreignPrimeCost'
    | 'exchangeRate'
    | 'currencyCode'
    | 'fxRateDate'
    | 'primeCostPerCase'
    | 'dutyPaidCostPerCase'
    | 'domesticChargesPerCase'
    | 'estWholesalePrice'
    | 'promotionAmount';

  const fieldsToCheck: CurrencyFieldName[] = [
    'foreignPrimeCost',
    'exchangeRate',
    'currencyCode',
    'fxRateDate',
    'primeCostPerCase',
    'dutyPaidCostPerCase',
    'domesticChargesPerCase',
    'estWholesalePrice',
    'promotionAmount'
  ];

  const currencyCostErrors: boolean[] = fieldsToCheck.map(
    (fieldName: CurrencyFieldName) => methods.getFieldState(fieldName).invalid
  );

  const disableCalculateButton =
    currencyCostErrors.includes(true) ||
    disabledCalculateButtonFunction(
      priceRequestType,
      fieldValues,
      productIsImport,
      productIsDPC
    );

  const showJustification =
    ((justificationRequiredCalculationWarning ||
      justificationRequiredCalendarWarning) &&
      hasUIWarnings) ||
    priceChangeRequest ||
    fieldValues.justification ||
    (fieldValues.exceptionReason && isInternal);

  const hasErrors =
    Object.values(modalFormErrors).length > 0 ||
    calculateErrors.length > 0 ||
    uiValidationErrors.length > 0 ||
    formErrorsExist;

  const asReviewButton = (
    <Button
      warningDark
      onClick={() => {
        setJustCalculate(false);
        setSendAgentSupplierReview(true);
        handleSaveAndClose();
      }}
      iconLeft
      icon={<Edit />}
      disabled={hasErrors}
    >
      A/S Review
    </Button>
  );

  const isLoading =
    loadingState ||
    isLoadingCalculate ||
    isLoadingSubmitPR ||
    isCancelLoading ||
    isApproveLoading ||
    isDeclineLoading ||
    isLoadingUpdatePriceRequest ||
    isLoadingValidatePromotion ||
    isLoadingWSEffectiveDate ||
    isLoadingAsReview;

  const displayPricePerSuHelperText =
    fieldValues.pricePerSU !== initialFieldValues.pricePerSU &&
    !isLoadingWSEffectiveDate;

  return (
    <>
      {isLoading ? <LoadingOverlay /> : null}
      <div className={styles.prDetailsForm}>
        <FormProvider {...methods}>
          {modalFormErrors &&
            Object.values(modalFormErrors).map((error: any, index: number) => {
              let errorType;
              if (error.type) {
                errorType = error.type;
              } else {
                errorType = error.value.type;
              }
              const errorCopy = errorMapping(errorType, modalCopy.validations);
              return (
                <Banner
                  key={`${index}-error`}
                  type="error"
                  icon={<ErrorAlert />}
                  title={errorCopy.title}
                  description={errorCopy.description}
                />
              );
            })}
          {uiValidationErrors &&
            uiValidationErrors.map((error, index) => {
              return (
                <Banner
                  key={`${index}-error`}
                  type="error"
                  icon={<ExclamationPoint />}
                  title={error.title}
                  description={
                    isInternal && error.description_internal
                      ? error.description_internal
                      : error.description
                  }
                />
              );
            })}
          {calculateErrors.length > 0 &&
            calculateErrors?.map((error, index) => {
              const { title: defaultTitle, description: defaultDescription } =
                errorMessageContent[error?.code] || {};

              const title = defaultTitle || genericErrorMessageTitle;
              let description = defaultDescription || error.message;

              if (description.includes('{pricePerSU}')) {
                description = description.replaceAll(
                  '{pricePerSU}',
                  floorPrice.toString()
                );
              }

              return (
                <Banner
                  type="error"
                  icon={<ExclamationPoint />}
                  title={title}
                  description={description}
                  key={`${index}-error`}
                />
              );
            })}
          <div className={styles.prDetailsForm__row}>
            <Pill type="neutral" secondary xlarge>
              {(priceRequestType &&
                mapPrAbbreviationToName(priceRequestType)) ??
                ''}
            </Pill>
            <Pill type={priceRequestStatus ?? ''} xlarge>
              {priceRequestStatus ?? ''}
            </Pill>
          </div>

          <div className={styles.prDetailsForm__row}>
            <div
              className={classNames(
                styles.prDetailsForm__fields,
                styles.prDetailsForm__fieldsDate
              )}
            >
              {isInternal ? (
                <CalendarDatePicker
                  label="Effective Date"
                  id="effectiveDate"
                  highlightedDates={highlightedEffectiveDates}
                  value={fieldValues.effectiveDate?.value || ''}
                  onChange={(date: string) => {
                    handleCalendarDatePickerInput(date, 'effectiveDate');
                  }}
                  minDate={new Date().toString()}
                  disabled={disableEntireForm}
                />
              ) : (
                <DateSelectMenu
                  options={effectiveDateOptions.filter(
                    (date) => date.value >= pstDateNextDay
                  )}
                  value={fieldValues.effectiveDate}
                  label="Effective Date"
                  id="effectiveDate"
                  ariaLabelledBy="effectiveDate"
                  disabled={
                    disableEntireForm ||
                    effectiveDateIsDisabled ||
                    isNPagentReview
                  }
                  onChange={(event: SelectInputType) => {
                    handleDateSelectInputChange(event, 'effectiveDate');
                  }}
                />
              )}
              {productIsPromotion &&
                (userType === EXTERNAL ? (
                  <TextInput
                    type="text"
                    id="endDate"
                    value={dateFormat(fieldValues.endDate, false)}
                    label="End Date"
                    disabled
                  />
                ) : (
                  <CalendarDatePicker
                    isError={endDateError}
                    id="endDate"
                    value={fieldValues.endDate || ''}
                    label="End Date"
                    minDate={fieldValues.effectiveDate?.value || ''}
                    dateShift={2}
                    onChange={(date: string) => {
                      handleCalendarDatePickerInput(date, 'endDate');
                    }}
                    disabled={
                      disableEntireForm ||
                      fieldValues.effectiveDate?.label === ''
                    }
                  />
                ))}
            </div>
          </div>

          {isInternal && priceRequestType !== 'WPP' && productIsImport && (
            <div
              className={classNames(
                styles.prDetailsForm__row,
                styles.prDetailsForm__rowBg,
                {
                  [styles.prDetailsForm__rowBgError]: currencyErrorExists
                }
              )}
            >
              <div className={styles.prDetailsForm__fields}>
                <SelectInput
                  label="Currency Code"
                  id="currencyCode"
                  options={currencyOptions ? currencyOptions : []}
                  ariaLabelledBy="currencyCode"
                  value={
                    fieldValues.currencyCode || {
                      label: 'Select...',
                      value: ''
                    }
                  }
                  onChange={(event: SelectInputType) =>
                    handleSelectInputChange(event, 'currencyCode')
                  }
                  disabled={disableEntireForm || false}
                  helper
                />

                <CalendarDatePicker
                  label="FX Rate Date"
                  id="fxRateDate"
                  value={fieldValues.fxRateDate ? fieldValues.fxRateDate : ''}
                  onChange={(date: string) => {
                    handleCalendarDatePickerInput(date, 'fxRateDate');
                  }}
                  disabled={disableEntireForm}
                  helper
                  showDropdowns={true}
                />

                <TextInput
                  label="Exchange Rate"
                  id="exchangeRate"
                  type="text"
                  disabled
                  value={fieldValues.calculatedExchangeRate}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event, 'calculatedExchangeRate');
                  }}
                  helper
                />

                <CurrencyInput
                  label="Foreign Prime Cost"
                  id="foreignPrimeCost"
                  type="number"
                  value={fieldValues.foreignPrimeCost}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event, 'foreignPrimeCost');
                  }}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    handleTextInputBlur(event, 'foreignPrimeCost');
                  }}
                  disabled={disableEntireForm}
                  helper
                  cost
                />

                <Button
                  secondary
                  iconLeft
                  icon={<Calculator />}
                  onClick={() => handleCalculatePrimeCost()}
                  ariaLabel={'Calculate foreign prime cost'}
                  disabled={disableEntireForm || formErrorsExist}
                  padHelper
                >
                  Prime
                </Button>
              </div>
            </div>
          )}
          <div className={styles.prDetailsForm__row}>
            <div className={styles.prDetailsForm__fields}>
              {!productIsDPC && priceChangeRequest && (
                <CurrencyInput
                  id="primeCostPerCase"
                  aria-labelledby="primeCostPerCase"
                  value={fieldValues.primeCostPerCase}
                  large
                  type="number"
                  cost
                  placeholder="$"
                  label="Prime Cost/Case"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event, 'primeCostPerCase');
                  }}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    handleTextInputBlur(event, 'primeCostPerCase');
                  }}
                  helperText={
                    fieldValues.primeCostPerCase !==
                    initialFieldValues.primeCostPerCase
                      ? `Previous ${currencyFormat(
                          initialFieldValues.primeCostPerCase
                        )}`
                      : ''
                  }
                  helper
                  disabled={
                    disableEntireForm ||
                    Number(fieldValues?.foreignPrimeCost) > 0
                  }
                />
              )}
              {priceChangeRequest && productIsImport && (
                <CurrencyInput
                  id="domesticChargesPerCase"
                  aria-labelledby="domesticChargesPerCase"
                  value={fieldValues.domesticChargesPerCase}
                  large
                  type="number"
                  cost
                  placeholder="$"
                  label="Domestic Charges/Case"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event, 'domesticChargesPerCase');
                  }}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    handleTextInputBlur(event, 'domesticChargesPerCase');
                  }}
                  helperText={
                    fieldValues.domesticChargesPerCase !==
                    initialFieldValues.domesticChargesPerCase
                      ? `Previous ${currencyFormat(
                          initialFieldValues.domesticChargesPerCase
                        )}`
                      : ''
                  }
                  helper
                  disabled={disableEntireForm}
                />
              )}
              {priceChangeRequest && productIsDPC && (
                <>
                  <CurrencyInput
                    type="number"
                    cost
                    placeholder="$"
                    id="dutyPaidCostPerCase"
                    value={fieldValues.dutyPaidCostPerCase}
                    label="Duty Paid Cost/Case"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, 'dutyPaidCostPerCase');
                    }}
                    onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                      handleTextInputBlur(event, 'dutyPaidCostPerCase');
                    }}
                    helperText={
                      fieldValues.dutyPaidCostPerCase !==
                      initialFieldValues.dutyPaidCostPerCase
                        ? `Previous ${currencyFormat(
                            initialFieldValues.dutyPaidCostPerCase
                          )}`
                        : ''
                    }
                    helper
                    disabled={disableEntireForm}
                  />
                </>
              )}
              {productIsPromotion && (
                <div>
                  <CurrencyInput
                    type="number"
                    label="Wholesale Price / Selling Unit as of the Effective Date" //EPP_1108 change from "Wholesale Price as of the Effective Date"
                    aria-labelledby="estWholesalePrice"
                    id="estWholesalePrice"
                    value={fieldValues.estWholesalePrice}
                    disabled
                    helper
                  />
                  <ReactTooltip
                    anchorId="estWholesalePrice"
                    aria-live="polite"
                    place="bottom"
                  >
                    <Text element="p" small>
                      {
                        modalCopy.toolTips.wholesalePriceAsOfEffectiveDate //'The Wholesale Price in this field is exclusive of any active promotions'
                      }
                    </Text>
                  </ReactTooltip>
                </div>
              )}
              {productIsPromotion && (
                <CurrencyInput
                  type="number"
                  cost
                  id="promotionAmount"
                  ariaLabelledBy="promotionAmount"
                  large
                  placeholder="$"
                  value={fieldValues.promotionAmount}
                  label="Promotional Amount"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event, 'promotionAmount');
                  }}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    handleTextInputBlur(event, 'promotionAmount');
                  }}
                  helperText={
                    fieldValues.promotionAmount !==
                    initialFieldValues.promotionAmount
                      ? `Previous ${currencyFormat(
                          initialFieldValues.promotionAmount
                        )}`
                      : ''
                  }
                  helper
                  disabled={disableEntireForm}
                />
              )}

              <CurrencyInput
                type="number"
                cost
                placeholder="$"
                id="pricePerSU"
                value={fieldValues.pricePerSU}
                label={
                  productIsPromotion
                    ? 'Promotional WS Price'
                    : 'Est. Wholesale Price / SU'
                }
                helperText={
                  displayPricePerSuHelperText
                    ? `Previous ${currencyFormat(
                        initialFieldValues.pricePerSU
                      )}`
                    : ''
                }
                helper
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event, 'pricePerSU');
                }}
                onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                  handleTextInputBlur(event, 'pricePerSU');
                }}
                disabled={
                  disableEntireForm ||
                  fieldValues.costOnly ||
                  productIsPromotion
                }
              />
              <Button
                secondary
                iconLeft
                icon={<Calculator />}
                onClick={() => {
                  setJustCalculate(true);
                  handleCalculate();
                }}
                className={styles.prDetailsForm__squareButton}
                disabled={
                  disableEntireForm ||
                  fieldValues.costOnly ||
                  disableCalculateButton
                }
              />
            </div>
          </div>

          {invalidCalculateResponse && (
            <div className={styles.formRow}>
              <Text element="p" small action bold>
                {
                  modalCopy.uiValidationErrors.invalidCalculateResponse
                    .description
                }
              </Text>
              <br />
            </div>
          )}

          <div className={styles.prDetailsForm__alignBottom}>
            <Text element="p" small>
              {modalCopy.note}
            </Text>
            <Banner
              type="info"
              description={modalCopy.calculateBanner}
              icon={<Calculator />}
              small
              alignment={'center'}
              calculator
            />
          </div>
          {isInternal && (
            <div className={styles.prDetailsForm__rowBg}>
              <div className={styles.prDetailsForm__row}>
                <div className={styles.prDetailsForm__fields}>
                  {/*Cost Only Checkbox - disabled if Request Type === Wholesale Price Promotion */}
                  <Checkbox
                    label="Cost Only"
                    id="costOnly"
                    isChecked={fieldValues.costOnly}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, 'costOnly');
                    }}
                    disabled={disableEntireForm || productIsPromotion}
                  />
                </div>
              </div>
              <div className={styles.prDetailsForm__row}>
                <div className={styles.prDetailsForm__fields}>
                  <TextInput
                    type="number"
                    label="ACES #"
                    id="aces_number"
                    value={fieldValues.aces_number || ''}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, 'aces_number');
                    }}
                    disabled={disableEntireForm}
                  />
                  {/*Costing Event Comment - only included if Request Type !== Wholesale Price Promotion */}
                  {!wholesalePromotionRequest && (
                    <div id="costingEventCommentContainer">
                      <TextInput
                        type="text"
                        label="Costing Event Comment"
                        id="costingEventComment"
                        value={decodeSanitizedValue(
                          fieldValues.costingEventComment
                        )}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          handleInputChange(event, 'costingEventComment');
                        }}
                        disabled={disableEntireForm}
                      />
                      <ReactTooltip
                        anchorId="costingEventCommentContainer"
                        aria-live="polite"
                        place="bottom"
                      >
                        <Text element="p" small>
                          {modalCopy.toolTips.costingEventComment}
                        </Text>
                      </ReactTooltip>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.prDetailsForm__rowLastRow}>
                <div className={styles.prDetailsForm__fields}>
                  <TextInput
                    type="text"
                    label="Email Notification Override"
                    id="email"
                    ariaLabelledBy="Email Notification Override"
                    value={fieldValues.email}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, 'email');
                    }}
                    helper
                    disabled={disableEntireForm}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={
              showJustification
                ? `${styles.prDetailsForm__row} ${styles.prDetailsForm__reason}`
                : ''
            }
          >
            {!hasErrors &&
            (hasWarnings || hasCalendarWarnings || hasUiValidationWarnings) ? (
              <div>
                {hasWarnings &&
                  calculateWarnings.map((warning, index) => {
                    const code = warning.code;
                    if (!warningMessageContent[code]) return null;
                    const description =
                      warningMessageContent[code] &&
                      warningMessageContent[code].description.includes(
                        '{pricePerSU}'
                      )
                        ? warningMessageContent[code].description.replaceAll(
                            '{pricePerSU}',
                            `${fixedDecimalPlaces(
                              Number(floorPrice) || 0,
                              2
                            ).toString()}`
                          )
                        : warningMessageContent[code].description;
                    return (
                      <Banner
                        type="warning"
                        icon={<ExclamationPoint />}
                        title={warning?.title || undefined}
                        description={description}
                        key={`${index}-warning`}
                      />
                    );
                  })}
                {hasCalendarWarnings &&
                  calendarWarnings.map((warning, index) => {
                    return (
                      <Banner
                        type="warning"
                        icon={<ExclamationPoint />}
                        title={warning?.title || undefined}
                        description={warning.description}
                        key={`${index}-calendar-warning`}
                      />
                    );
                  })}
                {hasUiValidationWarnings &&
                  uiValidationWarnings.map((warning, index) => {
                    return (
                      <Banner
                        type="warning"
                        icon={<ExclamationPoint />}
                        title={warning.title}
                        description={warning.description}
                        key={`${index}-ui-validation-warning`}
                      />
                    );
                  })}
              </div>
            ) : null}

            {priceRequest.type !== 'WPP' ? (
              <div className={styles.prDetailsForm__reason_row}>
                <SelectInput
                  options={getReasonOptions(
                    isNewProductPriceRequest,
                    methods.formState.defaultValues?.reason
                  )}
                  value={getReasonObjectByValue(fieldValues.reason)}
                  disabled={userType !== INTERNAL || disableEntireForm}
                  label="Reason"
                  id="reason"
                  ariaLabelledBy="reason"
                  onChange={(event: SelectInputType) =>
                    handleSelectInputChange(event, 'reason')
                  }
                  isClearable
                />
                {showOtherReason && (
                  <TextInput
                    type="text"
                    value={decodeSanitizedValue(inputtedOtherReason)}
                    label="Other Reason"
                    id="otherReason"
                    ariaLabelledBy="otherReason"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      setInputtedOtherReason(value);
                    }}
                    disabled={userType !== INTERNAL || disableEntireForm}
                  />
                )}
                <TextInput
                  type="text"
                  label="Exception Reason"
                  value={decodeSanitizedValue(fieldValues.exceptionReason)}
                  id="exceptionReason"
                  ariaLabelledBy="exceptionReason"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event, 'exceptionReason');
                  }}
                  disabled={true}
                />
              </div>
            ) : (
              isInternal &&
              fieldValues.exceptionReason && (
                <div className={styles.prDetailsForm__reason_row}>
                  <TextInput
                    type="text"
                    value={decodeSanitizedValue(fieldValues.exceptionReason)}
                    label="Exception Reason"
                    id="exceptionReason"
                    ariaLabelledBy="exceptionReason"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, 'exceptionReason');
                    }}
                    disabled={true}
                  />
                </div>
              )
            )}

            {((justificationRequiredCalculationWarning ||
              justificationRequiredCalendarWarning) &&
              hasUIWarnings) ||
            priceChangeRequest ||
            fieldValues.justification ? (
              <div className={styles.justification}>
                <TextArea
                  id="jusitification"
                  label={
                    justificationRequired && prWithWarningSubmittedToConfirm
                      ? modalCopy.justificationRequired.label
                      : prWithWarningSubmittedToConfirm
                      ? modalCopy.justificationNotRequired.label
                      : 'Business Impact Statement for Exception Request'
                  }
                  required={
                    justificationRequiredCalculationWarning ||
                    justificationRequiredCalendarWarning
                  }
                  maxLength={2500}
                  value={decodeSanitizedValue(fieldValues.justification)}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                    handleInputChange(event, 'justification');
                  }}
                  disabled={
                    (justificationRequiredCalculationWarning ||
                      justificationRequiredCalendarWarning) &&
                    hasUIWarnings
                      ? false
                      : disableEntireForm ||
                        !(
                          priceRequestStatus === AS_REVIEW ||
                          priceRequestStatus === DRAFT
                        )
                  }
                />
                <Text element="p" bold small>
                  {modalCopy.justificationNote}
                </Text>
              </div>
            ) : null}
          </div>

          <div className={styles.prDetailsForm__actions}>
            <Button
              primary
              onClick={() => {
                toggleProceedWithApproval(hasUIWarnings ? true : false);
                setJustCalculate(false);
                handleSaveAndClose();
              }}
              iconLeft
              icon={calculateWarnings.length === 0 && <PaperAirplane />}
              disabled={
                (!sendApprovePr && (!submitEnabled || hasErrors)) ||
                (isInLdbReviewStatus && !sendApprovePr) ||
                hasErrors
              }
            >
              {!hasUIWarnings
                ? 'Submit'
                : sendApprovePr
                ? 'Confirm & Approve'
                : 'Confirm & Submit'}
            </Button>
            {userType === INTERNAL &&
              (priceRequestStatus === DRAFT || priceRequestStatus === QUEUED) &&
              asReviewButton}
            {(hasUIWarnings || calculateErrors.length > 0) && (
              <Button
                link
                onClick={() => handleClearModal()}
                className={styles.buttonBack}
              >
                Go Back
              </Button>
            )}
            {priceRequestStatus === LDB_REVIEW &&
            isInternal &&
            !hasUIWarnings ? (
              <>
                <Button
                  success
                  onClick={() => {
                    handleApprovePR();
                  }}
                  iconLeft
                  icon={<Checkmark />}
                  disabled={hasErrors}
                >
                  Approve
                </Button>
                {asReviewButton}
                <Button
                  warning
                  onClick={() => {
                    handleDeclinePR();
                  }}
                  iconLeft
                  icon={<CloseIcon />}
                  disabled={hasErrors}
                >
                  Decline
                </Button>
              </>
            ) : (
              ''
            )}
            {!hasUIWarnings && !hasUiValidationWarnings && (
              <div id={styles.closeButton}>
                {Object.values(modalFormErrors).length === 0 &&
                  calculateErrors.length === 0 && (
                    <Button link onClick={onRequestClose} noPad>
                      Close
                    </Button>
                  )}
              </div>
            )}

            {priceRequestStatus === DRAFT && (
              <div id="deletePriceRequest">
                <Button
                  link
                  icon={<GarbageCan />}
                  iconLeft
                  onClick={() => {
                    deleteSinglePriceRequest({
                      prID: priceRequest.id,
                      token
                    });
                  }}
                  id={'delete-pr-button'}
                >
                  Delete
                </Button>
                <ReactTooltip
                  anchorId="deletePriceRequest"
                  aria-live="polite"
                  place="top"
                >
                  <Text element="p" small>
                    {`Permanently delete\n this draft request`}
                  </Text>
                </ReactTooltip>
              </div>
            )}

            {showCancel ? (
              <div id="cancelPriceRequest">
                <Button
                  link
                  icon={<Cancel />}
                  iconLeft
                  onClick={() => {
                    cancelSinglePriceRequest({
                      prID: priceRequest.id,
                      token
                    });
                    refetchPRData();
                  }}
                  noPad
                  id={'cancel-pr-button'}
                >
                  Cancel PR
                </Button>
                <ReactTooltip
                  anchorId="cancelPriceRequest"
                  aria-live="polite"
                  place="top"
                >
                  <Text element="p" small>
                    {`Cancel price request.\n PR will not be processed.`}
                  </Text>
                </ReactTooltip>
              </div>
            ) : null}
          </div>
        </FormProvider>
      </div>
    </>
  );
};

export default PriceRequestDetailsForm;
