import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { StylesConfig, createFilter } from 'react-select';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

import { OptionsType, ColumnWidthType } from '../../types';

import Label from '../Label';
import Text from '../Text';

import { ColorToken } from '../../styles/designTokens';
import styles from './SelectInput.module.scss';

type PropsType = {
  label: string;
  id: string;
  options: OptionsType[];
  required?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  helperText?: string;
  ariaLabelledBy: string;
  value?: OptionsType | null;
  onChange?: (event: any) => void;
  disabled?: boolean;
  isClearable?: boolean;
  helper?: boolean;
  columnWidth?: ColumnWidthType | null;
};

const SelectInput = ({
  label,
  id,
  options,
  medium,
  large,
  xlarge,
  helperText,
  required,
  ariaLabelledBy,
  value,
  onChange,
  disabled,
  isClearable,
  helper,
  columnWidth
}: PropsType) => {
  const methods = useFormContext();
  const {
    formState: { errors }
  } = useFormContext();

  const {
    backgroundHighlight,
    colorWhite,
    textBlack,
    textParagraph,
    textRed,
    backgroundMediumGrey,
    textPlaceholder,
    borderFocus
  } = ColorToken;

  const name = id;
  const focusedStyle = '0px 0px 5px 0px #4a90e2';

  // eslint-disable-next-line
  const customStyles: StylesConfig<OptionsType, false> = {
    control: (
      provided: any,
      state: {
        isFocused: boolean;
      }
    ) => ({
      ...provided,
      // value: '',
      backgroundColor: disabled ? backgroundHighlight : colorWhite,
      borderColor: disabled
        ? backgroundHighlight
        : errors[id]
        ? textRed
        : state.isFocused
        ? borderFocus
        : backgroundMediumGrey,
      borderWidth: '1px',
      borderRadius: '2px',
      fontFamily: 'Helvetica Neue',
      color: disabled ? textParagraph : textBlack,
      minWidth: 'inherit',
      boxshadow: state.isFocused ? focusedStyle : 'none',
      outline: 'none',
      boxShadow: state.isFocused ? focusedStyle : '0px',
      '&:hover': {
        borderColor: borderFocus
      }
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: disabled ? textPlaceholder : textParagraph,
      padding: '10px'
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '9px 10px',
      fontFamily: 'Helvetica Neue',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px'
    }),
    singleValue: (provided: any, { data }: any) => {
      const color = disabled
        ? textParagraph
        : data.label === 'Select...'
        ? textPlaceholder
        : textBlack;
      return {
        ...provided,
        color
      };
    },
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: colorWhite
    }),
    menu: (provided: any) => ({
      ...provided,
      fontFamily: 'Helvetica Neue',
      zIndex: 1,
      top: 35,
      minWidth: '200px',
      overflowX: 'hidden',
      wordBreak: 'break-word'
    }),
    menuList: (provided: any) => ({ ...provided, zIndex: 1 }),
    input: (provided: any) => ({
      ...provided,
      paddingTop: '0',
      paddingBottom: '0',
      margin: '0'
    })
  };

  return (
    <div
      className={classNames(styles.selectInputContainer, {
        [styles.selectInputContainerMedium]: medium,
        [styles.selectInputContainerLarge]: large,
        [styles.selectInputContainerXlarge]: xlarge,
        [styles.selectInputContainerSingleColumn]: columnWidth === 'single',
        [styles.selectInputContainerDoubleColumn]: columnWidth === 'double'
      })}
    >
      <Label id={id} htmlFor={ariaLabelledBy} error={!!errors[id]}>
        {label}
      </Label>
      <Controller
        name={id}
        control={methods.control}
        rules={{ ...(required ? { required: 'This field is required' } : {}) }}
        render={({ fieldState: { error } }) => (
          <>
            <Select
              options={options}
              classNamePrefix={styles.input}
              styles={customStyles}
              aria-labelledby={id}
              inputId={ariaLabelledBy}
              data-testId={`${id}-selectInput`}
              id={`${id}-controller`}
              value={value}
              onChange={onChange}
              isDisabled={disabled}
              isClearable={!value?.value ? false : isClearable}
              filterOption={createFilter({
                matchFrom: 'any',
                stringify: (option) => `${option.label}`
              })}
            />
            {helper && (
              <div className={styles.textContainer}>
                {errors[id] ? (
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                      <Text
                        element="p"
                        small
                        className={styles.helperTextError}
                      >
                        {message}
                      </Text>
                    )}
                  />
                ) : helperText ? (
                  <Text element="p" small className={styles.helperText}>
                    {helperText}
                  </Text>
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default SelectInput;
