import moment from 'moment-timezone';

// CONSTANTS
const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
const INVALID_DATE = 'Invalid date';
const INVALID_DATE_FORMAT = '-- -- ----';
const NUMERIC_DATE_FORMAT = 'YYYY-MM-DD';
const PST_TIMEZONE = 'America/Los_Angeles';

/**
 * Default Date Formating - Returns a string formatted date.
 *
 * @param dateString - date string to format
 * @param returnInvalid - boolean to return -- -- ---- if invalid date
 * @returns A string DD MMM YYYY or -- -- ----
 *
 */
export const dateFormat = (
  dateString: string | undefined,
  returnInvalid = true
): string => {
  if (dateString === undefined && returnInvalid) return INVALID_DATE_FORMAT;

  const formattedDate = moment
    .tz(dateString, PST_TIMEZONE)
    .format(DEFAULT_DATE_FORMAT);

  if (formattedDate === INVALID_DATE)
    return returnInvalid ? INVALID_DATE_FORMAT : '';

  return formattedDate;
};

/**
 * Returns a string formatted date.
 *
 * @param dateString - date string to format
 * @returns A string YYYY-MM-DD
 *
 */
export const formatNumericDate = (
  dateString: string,
  usePST = false,
  useUTC = false
) => {
  const dateMoment = moment(dateString);

  if (useUTC) {
    dateMoment.utc();
  }

  if (usePST) {
    dateMoment.tz(PST_TIMEZONE);
  }

  const formattedDate = dateMoment.format(NUMERIC_DATE_FORMAT);
  return formattedDate;
};

/**
 * Returns a moment object of the current time in PST.
 * @param dateString - date string to format
 * @param additionalDays - number of days to add to dateString
 * @returns moment object
 *
 */
export const getPSTDate = (
  dateString?: string | undefined,
  additionalDays?: number
) => {
  const inputDateMoment = dateString ? moment(dateString) : moment();

  if (additionalDays) {
    inputDateMoment.add(additionalDays, 'days');
  }

  const currentPSTDate = inputDateMoment.clone().tz(PST_TIMEZONE);

  return currentPSTDate;
};

/**
 * JS Date in matching current PST Date/Time
 *
 * @param dateMoment - moment.Moment
 * @returns JS Date Obj
 *
 */
export const convertToPSTDateObject = (dateMoment: moment.Moment) => {
  return moment
    .utc(moment.tz(dateMoment, PST_TIMEZONE).format('YYYY-MM-DDTHH:mm:ss'))
    .toDate();
};

/**
 * JS Date in matching current PST Date/Time in format 'YYYY-MM-DD' for showing the next effective Day
 *
 * @param dateMoment - moment.Moment
 * @returns JS Date Obj
 *
 */
export const convertToPSTDateOnlyFormat = (dateMoment: moment.Moment) => {
  return moment.tz(dateMoment, PST_TIMEZONE).format('YYYY-MM-DD'); // this function is returning the PST/PDT date in format 'YYYY-MM-DD' for showing the next effective Day
};
/**
 * Returns a boolean value comparing a deadline vs current time.
 *
 * @param dateString - date string to format
 * @returns boolean
 *
 */
export const isPassedDeadline = (dateString: string) => {
  const currentDate = getPSTDate();
  const deadline = getPSTDate(dateString);

  return currentDate.isAfter(deadline, 'day');
};

export const isSameDate = (date1: string, date2: string) => {
  return moment(date1).isSame(date2, 'day');
};
