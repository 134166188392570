import React from 'react';

export default function Calendar() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-calendar">Calendar</title>
      <path
        d="M4.00016 0.666748C4.50719 0.666748 4.91683 1.07638 4.91683 1.58341V2.50008H8.5835V1.58341C8.5835 1.07638 8.99313 0.666748 9.50016 0.666748C10.0072 0.666748 10.4168 1.07638 10.4168 1.58341V2.50008H11.7918C12.5509 2.50008 13.1668 3.11597 13.1668 3.87508V5.25008H0.333496V3.87508C0.333496 3.11597 0.949382 2.50008 1.7085 2.50008H3.0835V1.58341C3.0835 1.07638 3.49313 0.666748 4.00016 0.666748ZM0.333496 6.16675H13.1668V13.9584C13.1668 14.7175 12.5509 15.3334 11.7918 15.3334H1.7085C0.949382 15.3334 0.333496 14.7175 0.333496 13.9584V6.16675ZM2.62516 8.00008C2.37308 8.00008 2.16683 8.20633 2.16683 8.45841V11.2084C2.16683 11.4605 2.37308 11.6667 2.62516 11.6667H5.37516C5.62725 11.6667 5.8335 11.4605 5.8335 11.2084V8.45841C5.8335 8.20633 5.62725 8.00008 5.37516 8.00008H2.62516Z"
        fill="currentColor"
      />
    </svg>
  );
}
