import React from 'react';

export default function Document() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8576 1.07058L13.2605 3.47349C13.5183 3.73125 13.6644 3.96968 13.6644 4.33341L9.99984 4.33341V0.666748C10.3636 0.666748 10.5999 0.812813 10.8576 1.07058ZM8.95984 0.666748H4.04124C3.28227 0.669612 2.6665 1.28538 2.6665 2.04434V13.9587C2.6665 14.7176 3.28227 15.3334 4.04124 15.3334H12.2896C13.0486 15.3334 13.6644 14.7176 13.6644 13.9587V5.38008H9.6465C9.26559 5.38008 8.95984 5.06766 8.95984 4.68675V0.666748Z"
        fill="#212121"
      />
    </svg>
  );
}
