import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';
import { SelectInputType } from '../types';

type Currency = {
  currency_full_name: string;
  currency_id: string;
  currency_short_name: string;
};

type CurrencyDataResponse = Currency[];

//ab10797, update according to currency-fxRate api change
type CurrencyFxRateInfo = {
  effectiveDate: string;
  currency: CurrencyFxInfo;
};

type CurrencyFxInfo = {
  value: string;
  currencyLabel: string;
};
//ab10797, update according to currency-fxRate api change
type CurrencyFxData = {
  currencyValue: CurrencyFxRateInfo[];
};

type QueryParams = { queryParams?: string; token: string | null };

type CurrencyFxParams = {
  currencyLabel: string[] | undefined;
  fxDate?: string;
  token: string | null;
};

export const currencyApi = createApi({
  reducerPath: 'currencyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  endpoints: (builder) => ({
    getCurrency: builder.query<SelectInputType[], QueryParams>({
      query: ({ token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: 'currency'
      }),
      transformResponse: (response: CurrencyDataResponse) => {
        if (response.length > 0) {
          const currencyOptions = response.map((currency: Currency) => {
            return { label: currency.currency_id, value: currency.currency_id };
          });

          // Sort the currencyOptions alphabetically by label
          currencyOptions.sort((a, b) => a.label.localeCompare(b.label));

          return currencyOptions;
        }

        return [];
      }
    }),
    getExchangeRate: builder.query<CurrencyFxData, CurrencyFxParams>({
      query: ({ currencyLabel, fxDate, token }) => {
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`
          },
          url: `currency-fxrate?effectiveDate=${fxDate}&currencyLabel=${currencyLabel}`
        };
      }
    })
  })
});

export const { useGetCurrencyQuery, useGetExchangeRateQuery } = currencyApi;
