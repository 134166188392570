import { PriceRequestOptions } from '../types/productSearchTypes';

import {
  DRAFT,
  PROCESSING,
  LDB_REVIEW,
  AS_REVIEW,
  CANCELLED,
  QUEUED,
  ACCEPTED,
  DECLINED
} from '../helpers';

// Price Request Type Search Options
export const priceRequestTypeSelectOptions: PriceRequestOptions[] = [
  { label: 'Price Change', value: 'PC' },
  { label: 'Wholesale Price Promotion (WPP)', value: 'WPP' },
  {
    label: 'New Product Pricing / Reactivation',
    value: 'NP'
  }
].sort((a, b) => a.label.localeCompare(b.label));

// Price Request Status Search Options
export const priceRequestStatusSelectOptions = (userType: string) => {
  const options: PriceRequestOptions[] = [
    { label: AS_REVIEW, value: AS_REVIEW },
    { label: ACCEPTED, value: ACCEPTED },
    { label: CANCELLED, value: CANCELLED },
    { label: DECLINED, value: DECLINED },
    { label: DRAFT, value: DRAFT },
    { label: LDB_REVIEW, value: LDB_REVIEW },
    { label: PROCESSING, value: PROCESSING },
    { label: QUEUED, value: QUEUED }
  ];

  //In post - MVP work, completed will be added to LDB user's drop down options
  // if (userType === UserTypeEnum.INTERNAL) {
  //   options.push({
  //     label: COMPLETED,
  //     value: COMPLETED
  //   });
  // }

  return options;
};
