import React from 'react';

import Loader from '../Loader';
import Heading from '../Heading';
import styles from './index.module.scss';

type PropsType = {
  content?: string;
};

const LoadingOverlay = ({ content }: PropsType) => {
  return (
    <div className={styles.overlayContainer} data-testid={'loading-overlay'}>
      <Loader height="56px" width="56" />
      {content && (
        <Heading element="h3" heading3 className={styles.loaderTitle}>
          {content}
        </Heading>
      )}
    </div>
  );
};

export default LoadingOverlay;
