import classNames from 'classnames';

import styles from './index.module.scss';

type PropTypes = {
  children: string | React.ReactNode;
  tagColor?: string;
};

function Tag({ children, tagColor }: PropTypes) {
  return (
    <span
      className={classNames(styles.ResourceCardTag, {
        [styles.tagBlue]: tagColor === 'blue',
        [styles.tagOrange]: tagColor === 'orange',
        [styles.tagGreen]: tagColor === 'green',
        [styles.tagRed]: tagColor === 'red'
      })}
    >
      {children}
    </span>
  );
}

export default Tag;
