import { CategoryType } from '../types/productSearchTypes';

// Take in the hierarchy data, and a category hierarchy_id, and find the matching string name for the category.
export const categorySearch = (
  hierarchyData: string | null,
  categoryHierarchyId: string
) => {
  if (hierarchyData) {
    const category = JSON.parse(hierarchyData).find((obj: CategoryType) => {
      return obj.hierarchy_id === Number(categoryHierarchyId);
    });
    if (category) {
      return category.description;
    }
  }
};
