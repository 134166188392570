import React from 'react';

export default function ChevronDown() {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="icon-chevron-down">Chevron Down</title>
      <path
        d="M6.47536 7.91613L0.467322 2.14104C0.177559 1.86251 0.177559 1.41095 0.467322 1.13246L1.16806 0.458888C1.45733 0.180837 1.92615 0.180302 2.2161 0.457699L7.00002 5.03456L11.7839 0.457699C12.0738 0.180302 12.5427 0.180837 12.8319 0.458888L13.5327 1.13246C13.8224 1.41098 13.8224 1.86254 13.5327 2.14104L7.52467 7.91613C7.2349 8.19462 6.76513 8.19462 6.47536 7.91613Z"
        fill="currentColor"
      />
    </svg>
  );
}
