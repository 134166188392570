import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Banner from '../Banner/Banner';

import UploadForm from './_UploadForm';
import UploadConfirmation from './_UploadConfirmation';
import ErrorAlert from '../Icons/ErrorAlert';

import styles from './CSVUpload.module.scss';

const CSVUpload: React.FC = () => {
  const [userUploadedFile, setUserUploadedFile] = useState<File | null>(null);

  const { uploadErrors } = useSelector(
    (state: RootState) => state.csvBulkUpload
  );

  return (
    <>
      {uploadErrors && (
        <div className={styles.errorContainer}>
          {uploadErrors.map((error, idx) => (
            <Banner
              type="error"
              title={error.title}
              description={error.description}
              key={idx}
              icon={<ErrorAlert />}
            />
          ))}
        </div>
      )}
      {!userUploadedFile ? (
        <UploadForm setUserUploadedFile={setUserUploadedFile} />
      ) : (
        <UploadConfirmation
          setUserUploadedFile={setUserUploadedFile}
          userUploadedFile={userUploadedFile}
        />
      )}
    </>
  );
};

export default CSVUpload;
