import React from 'react';
import { Comment } from '../../types';

import { UserTypeEnum, dateFormat } from '../../helpers';

import Text from '../Text';

import styles from './index.module.scss';

type CommentProps = {
  comment: Comment;
  userType: string | undefined;
};

const CommentItem: React.FC<CommentProps> = ({ comment, userType }) => {
  const { createdAt, comment_txt, createdBy } = comment;
  const { first_name, last_name } = createdBy;

  const comment_date = new Date(createdAt).toISOString();

  return (
    <div className={styles.PriceRequestComments__item}>
      <div className={styles.PriceRequestComments__item_heading}>
        <Text element="span" small bold>
          {dateFormat(comment_date)}
        </Text>
        {userType === UserTypeEnum.INTERNAL && (
          <Text element="span" small>
            {`${first_name} ${last_name}`}
          </Text>
        )}
      </div>
      <Text element="p" medium>
        {comment_txt}
      </Text>
    </div>
  );
};

export default CommentItem;
