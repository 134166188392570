import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useAuth } from '../../../contexts/AuthenticationProvider';

import {
  resetState,
  addErrorPriceRequest,
  addValidPriceRequest,
  UploadedPriceRequest,
  finishValidation
} from '../../../reducers/CSVBulkUploadSlice';
import { setWorkingGroupProducts } from '../../../reducers/WorkingGroupSlice';
import { triggerActivityMonitorReset } from '../../../reducers/ActivityMonitorSlice';

import { useGetFutureCalendarDatesQuery } from '../../../services/Calendar';
import { useCreateDraftPriceRequestMutation } from '../../../services/PriceRequest';
import { useGetCurrencyQuery } from '../../../services/Currency';

import useFieldValidation from '../../../hooks/useFieldValidation';
import useSkuLevelValidation from '../../../hooks/useSkuLevelValidation';

import { PriceRequest, CalendarDate } from '../../../types';

import {
  getSessionToken,
  fetchApiData,
  handleApiResponse,
  UserTypeEnum,
  mapCalculateKeysToPriceRequest,
  formatNumericDate,
  getPSTDate
} from '../../../helpers';

import CancelPane from './_CancelPane';
import ProgressPane from './_ProgressPane';
import Modal from '../index';

import styles from './ParseCSVModal.module.scss';

import copy from '../../../config/translations/en-CA.json';
const modalCopy = copy.modals[0].parseCSVModal;
const errorMessages = modalCopy.validationErrors;

const BULK_UPLOADUUID_KEY = 'bulkUploadUUID';

const ParseCSVModal = () => {
  const { user } = useAuth().authState;
  const token = getSessionToken();
  const dispatch = useDispatch();

  const { openParseCSVModal, parsedCSVData } = useSelector(
    (state: RootState) => state.csvBulkUpload
  );

  // Get Currency Code options to validate against
  const { data: currencyOptions } = useGetCurrencyQuery(
    { token },
    {
      refetchOnMountOrArgChange: true
    }
  );

  // Get Future Calendar dates to validate against
  const { data: calendarData } = useGetFutureCalendarDatesQuery(
    { token },
    {
      refetchOnMountOrArgChange: true
    }
  );

  // Price Request Create Mutation
  const [createPriceRequest] = useCreateDraftPriceRequestMutation();

  const [userCancelRequest, setUserCancelRequest] = useState<boolean>();
  const [UUID, setUUID] = useState<string>('');
  const [pcDates, setPcDates] = useState<CalendarDate[]>([]);
  const [wppDates, setWppDates] = useState<CalendarDate[]>([]);
  const [userAccessibleProducts, setUserAccessibleProducts] = useState<any[]>(
    []
  );
  const [skusValidated, setSkusValidated] = useState<boolean>(false);
  const [skuValidPRs, setSkuValidPRs] = useState<UploadedPriceRequest[]>();
  const [processedRows, setProcessedRows] = useState<number>(0);
  const abortControllersRef = useRef<AbortController[]>([]);

  // Add invalid PR's to state with error message
  const handleAddErrorPriceRequest = useCallback(
    (priceRequest: UploadedPriceRequest, error_message: string) => {
      const newErrorPriceRequest = {
        ...priceRequest,
        error_message
      };
      dispatch(addErrorPriceRequest(newErrorPriceRequest));
    },
    [dispatch]
  );

  // Call Draft-by-products, verifies user has access to SKU
  // and returns the relevant product data for use in validation
  const getUserAccessibleSkus = useCallback(
    async (skus: number[]) => {
      const controller = new AbortController();
      abortControllersRef.current.push(controller);
      const requestBody = {
        skus
      };
      dispatch(triggerActivityMonitorReset(true));
      const result = await fetchApiData(
        'price-request/draft-by-products',
        token,
        'POST',
        requestBody,
        controller.signal
      ).catch((error) => {
        throw new Error(error);
      });

      const response = await handleApiResponse(result);
      setUserAccessibleProducts(response);
      return response;
    },
    [token]
  );

  // Helper function for extracting product info from
  // the Draft-by-product response
  const getProductInfo = useCallback(
    (sku: number) => {
      return userAccessibleProducts.find((product) => product.sku === sku);
    },
    [userAccessibleProducts]
  );

  // Call FXRate for PR's with currency data
  // to populate exchage_rate field
  const getExchangeRate = useCallback(
    async ({
      currency_label,
      currency_effective_date
    }: UploadedPriceRequest) => {
      const controller = new AbortController();
      abortControllersRef.current.push(controller);
      dispatch(triggerActivityMonitorReset(true));
      const result = await fetchApiData(
        `currency-fxrate?effectiveDate=${currency_effective_date}&currencyLabel=["${currency_label}"]`,
        token,
        'GET',
        null,
        controller.signal
      ).catch((error) => {
        throw new Error(error);
      });

      const { currencyValue } = await handleApiResponse(result); //ab10797 , change according to data of new service response
      return currencyValue;
    },
    [token]
  );

  const choosePayloadValue = (value1: unknown, value2: unknown) => {
    return typeof value1 === 'number' && !isNaN(value1) ? value1 : value2;
  };

  const getCalculatedValues = useCallback(
    async (
      priceRequest: UploadedPriceRequest,
      effective_end_date: string | null,
      validationErrors: any
    ) => {
      dispatch(triggerActivityMonitorReset(true));
      const controller = new AbortController();
      abortControllersRef.current.push(controller);

      let result = null;

      const {
        sku,
        prime_cost_per_case,
        effective_date: effectiveDate,
        domestic_charges_per_case,
        duty_paid_cost_per_case,
        request_type,
        promotion_amount_per_su
      } = priceRequest;

      const {
        country_of_export,
        is_cost_dpc,
        prime_cost_per_case: productPrimeCost,
        domestic_charges_per_case: productDomesticCharges,
        duty_paid_cost_per_case: productDutyCost
      } = getProductInfo(sku);

      const isImportProduct = country_of_export !== 'CA';

      if (request_type === 'PC') {
        let scenario = 2;
        if (isImportProduct) {
          scenario = 4;
        } else if (is_cost_dpc.toUpperCase() === 'YES') {
          scenario = 1;
        }

        const primeCostPerCase = choosePayloadValue(
          prime_cost_per_case,
          productPrimeCost
        );

        const domesticChargesPerCase = choosePayloadValue(
          domestic_charges_per_case,
          productDomesticCharges
        );

        const dutyPaidCostPerCase = choosePayloadValue(
          duty_paid_cost_per_case,
          productDutyCost
        );

        result = await fetchApiData(
          `calculate`,
          token,
          'POST',
          {
            sku,
            effectiveDate,
            primeCostPerCase,
            domesticChargesPerCase,
            dutyPaidCostPerCase,
            scenario
          },
          controller.signal
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response?.statusText || '');
            }
            return response.json();
          })
          .then((data) => {
            return mapCalculateKeysToPriceRequest(data);
          });
      } else if (request_type === 'WPP') {
        const wholesalePriceEffectiveDate = await fetchApiData(
          `getPrice`,
          token,
          'POST',
          {
            sku: [sku.toString()],
            transactionDate: effectiveDate
          },
          controller.signal
        ).then((response) => {
          if (!response.ok) {
            throw new Error(response?.statusText || '');
          }
          return response.json();
        });
        const { pricePerSU } = wholesalePriceEffectiveDate.prices[0];
        const validatePromotion = await fetchApiData(
          `price-validation/validate-promotion`,
          token,
          'POST',
          {
            sku: sku,
            promotionAmountPerSU: promotion_amount_per_su,
            promoPeriod: {
              dateFrom: formatNumericDate(effectiveDate),
              dateTo: effective_end_date
                ? formatNumericDate(effective_end_date)
                : formatNumericDate(getPSTDate(effectiveDate, 1).toString())
            }
          },
          controller.signal
        ).then((response) => {
          if (!response.ok) {
            throw new Error(response?.statusText || '');
          }
          return response.json();
        });

        const price_per_su = pricePerSU - (promotion_amount_per_su || 0);

        result = {
          price_per_su,
          validationMessage: validatePromotion?.validationMessage || null,
          estimated_price_per_su: pricePerSU || undefined
        };
      }

      return result;
    },
    [getProductInfo, token]
  );

  // Initial SKU level validation
  const skuLevelValidation = useSkuLevelValidation({
    getUserAccessibleSkus,
    handleAddErrorPriceRequest
  });

  // fieldValidator contains all the field level validation logic
  const fieldValidator = useFieldValidation({
    pcDates,
    wppDates,
    getProductInfo,
    user,
    currencyOptions
  });

  // Trigger startValidation and setup calendar data
  useEffect(() => {
    const startValidation = async () => {
      const bulkUploadUUID = crypto.randomUUID();
      setUUID(bulkUploadUUID);
      sessionStorage.setItem(BULK_UPLOADUUID_KEY, bulkUploadUUID);
      setSkuValidPRs([]);
      skuLevelValidation(parsedCSVData)
        .then((skuValidatedPriceRequests) => {
          setSkusValidated(true);
          setSkuValidPRs(skuValidatedPriceRequests);
        })
        .catch((error) => {
          console.error('Validation error:', error);
        });
    };

    if (calendarData) {
      calendarData.forEach((calendarItem: any) => {
        if (calendarItem.type === 'PRICE') {
          setPcDates((prevDates) => [...prevDates, calendarItem]);
        }
        if (calendarItem.type === 'PROMOTION') {
          setWppDates((prevDates) => [...prevDates, calendarItem]);
        }
      });
    }

    if (parsedCSVData.length && calendarData) {
      startValidation();
    }
  }, [parsedCSVData, calendarData, skuLevelValidation]);

  // Field Validation control structure
  // Iterate over all Price Requests and associated fields
  useEffect(() => {
    const fieldValidation = async (
      data: UploadedPriceRequest[] | undefined,
      signal: AbortSignal
    ) => {
      const validator = fieldValidator();
      if (!data) return;

      for (const row of data) {
        const sessionUUID = sessionStorage.getItem(BULK_UPLOADUUID_KEY);

        if (sessionUUID !== UUID) {
          break;
        }

        const validationErrors = [];
        let calendar_id = undefined;
        let effective_end_date = null;
        let submission_deadline = null;
        let exchange_rate = null;
        let exchange_rate_date = null;
        let calculatedValues = null;
        let isValidPRType = true;
        let isValidPReffectiveDate = true;

        if (!validator.isValidPRType(row)) {
          validationErrors.push(errorMessages.isValidPRType);
          isValidPRType = false;
        }

        if (isValidPRType) {
          if (!validator.isValidEffectiveDateRequired(row)) {
            validationErrors.push(errorMessages.isValidEffectiveDateRequired);
            isValidPReffectiveDate = false;
          }

          if (user.type === UserTypeEnum.INTERNAL) {
            // INTERAL VALIDATION TRACK
            const { isValidEffectiveDate, matchedEffectiveDate } =
              validator.isValidEffectiveDateInternal(row);

            if (isValidEffectiveDate) {
              const { effectiveEndDate } = matchedEffectiveDate || {};

              effective_end_date = effectiveEndDate;
            } else {
              validationErrors.push(errorMessages.isValidEffectiveDateInternal);
              isValidPReffectiveDate = false;
            }

            if (!validator.isValidCurrencyCode(row)) {
              validationErrors.push(errorMessages.isValidCurrencyCode);
            }

            if (!validator.isValidFXDateValue(row)) {
              validationErrors.push(errorMessages.isValidFXDateValue);
            }

            if (!validator.isValidForeignPrimeValue(row)) {
              validationErrors.push(errorMessages.isValidForeignPrimeValue);
            }

            if (!validator.isValidFXDateFormat(row)) {
              validationErrors.push(errorMessages.generic);
            }

            if (!validator.isValidForeignPrimeFormat(row)) {
              validationErrors.push(errorMessages.generic);
            }

            if (!validator.isValidAllFxFields(row)) {
              validationErrors.push(errorMessages.generic);
            }
          } else {
            // EXTERNAL VALIDATION TRACK
            const { isValidEffectiveDate, matchedEffectiveDate } =
              validator.isValidEffectiveDateExternal(row);
            if (isValidEffectiveDate) {
              const { id, effectiveEndDate, submissionDeadLine } =
                matchedEffectiveDate;
              calendar_id = id;
              submission_deadline = submissionDeadLine;
              effective_end_date = effectiveEndDate;
            } else {
              validationErrors.push(errorMessages.isValidEffectiveDateExternal);
              isValidPReffectiveDate = false;
            }

            if (
              submission_deadline &&
              !validator.isValidSubmissionDeadline(submission_deadline)
            ) {
              validationErrors.push(errorMessages.isValidSubmissionDeadline);
            }
          }

          if (!validator.internalOnlyFieldsValid(row)) {
            validationErrors.push(errorMessages.internalOnlyFieldsValid);
          }

          if (!validator.isValidPrimeCostRequired(row)) {
            validationErrors.push(errorMessages.isValidPrimeCostRequired);
          }

          if (!validator.isValidPrimeCostEmpty(row)) {
            validationErrors.push(errorMessages.isValidPrimeCostEmpty);
          }

          if (!validator.isValidDomesticChargesRequired(row)) {
            validationErrors.push(errorMessages.isValidDomesticChargesRequired);
          }

          if (!validator.isValidDomesticChargesEmpty(row)) {
            validationErrors.push(errorMessages.isValidDomesticChargesEmpty);
          }

          if (!validator.isValidDutyPaidCostRequired(row)) {
            validationErrors.push(errorMessages.isValidDutyPaidCostRequired);
          }

          if (!validator.isValidDutyPaidCostEmpty(row)) {
            validationErrors.push(errorMessages.isValidDutyPaidCostEmpty);
          }

          if (!validator.isValidPromotionAmountRequired(row)) {
            validationErrors.push(errorMessages.isValidPromotionAmountRequired);
          }

          if (!validator.isValidPromotionAmountEmpty(row)) {
            validationErrors.push(errorMessages.isValidPromotionAmountEmpty);
          }

          // Finished Field validation
          // Call FxRate API if fields exist (INTERNAL)
          if (
            row.currency_label &&
            row.currency_effective_date &&
            validator.isValidCurrencyCode(row) &&
            validator.isValidFXDateValue(row) &&
            validator.isValidFXDateFormat(row)
          ) {
            try {
              const currencyValue = await getExchangeRate(row); //ab10797 , change according to data of new service response
              exchange_rate = currencyValue[0]?.currency.value;
              exchange_rate_date = currencyValue[0]?.effectiveDate;
            } catch (error) {
              validationErrors.push(errorMessages.apiError);
            }
          }

          // Call calculate API
          if (isValidPReffectiveDate) {
            try {
              calculatedValues = await getCalculatedValues(
                row,
                effective_end_date,
                validationErrors
              );
            } catch (error: any) {
              if (error.message.includes('aborted')) {
                break; // instead of return, it should break the for loop. and continue to add validated SKUs to working group
              } else {
                // EPP-1618
                // Assume any caught error is related to ref 56 and not an API issue
                validationErrors.push(errorMessages.isCalculateWarning);
              }
            }

            if (calculatedValues?.validationMessage) {
              validationErrors.push(errorMessages.isCalculateWarning);
            }
          }
        } // Finished Field Validation for VALID PR TYPE

        const allPriceRequestValues = {
          ...row,
          calendar_id,
          submission_deadline,
          effective_end_date,
          exchange_rate,
          ...calculatedValues
        };

        if (!validationErrors[0]) {
          const {
            sku,
            request_type: type,
            promotion_amount_per_su,
            effective_date,
            reason,
            costing_event_comment,
            currency_label,
            currency_effective_date,
            foreign_prime_cost_per_case,
            const_only_fl,
            a_s_review_fl
          } = row;

          const requestBody: PriceRequest = {
            sku,
            type,
            calendar_id,
            effective_date: calendar_id ? undefined : effective_date,
            effective_end_date: calendar_id
              ? undefined
              : effective_end_date
              ? formatNumericDate(effective_end_date)
              : undefined,
            submission_deadline: calendar_id
              ? undefined
              : submission_deadline
              ? submission_deadline
              : undefined,
            exchange_rate: exchange_rate ? Number(exchange_rate) : undefined,
            currency_id: currency_label ? currency_label : undefined,
            fx_rate_date: exchange_rate_date //ab10797 , change according to data of new service response
              ? exchange_rate_date
              : currency_effective_date
              ? currency_effective_date
              : undefined,
            foreign_prime_cost: foreign_prime_cost_per_case
              ? foreign_prime_cost_per_case
              : undefined,
            reason:
              !reason && user.type === UserTypeEnum.EXTERNAL && type === 'PC'
                ? 'Supplier Price Change'
                : reason,
            const_only_fl,
            a_s_review_fl,
            costing_event_comment:
              !costing_event_comment &&
              user.type === UserTypeEnum.EXTERNAL &&
              type === 'PC'
                ? 'PCR'
                : costing_event_comment,
            warning_json: JSON.stringify(
              calculatedValues?.validationMessage ?? []
            ),
            other_reason: undefined,
            promotion_amount_per_su,
            ...calculatedValues
          };

          dispatch(addValidPriceRequest(allPriceRequestValues));
          try {
            await createPriceRequest({
              requestBody,
              token
            }).unwrap();
          } catch (error) {
            handleAddErrorPriceRequest(row, errorMessages.apiError);
          }
        } else {
          handleAddErrorPriceRequest(row, validationErrors[0]);
        }
        setProcessedRows((prev) => prev + 1);

        //listen to the signal abort event and break the for loop.
        if (signal.aborted) {
          break;
        }
      }

      // Add Valid SKUs to working group
      const validatedSkusWorkingGroup = skuValidPRs
        ? skuValidPRs.map((item) => item.sku.toString())
        : [];
      dispatch(setWorkingGroupProducts(validatedSkusWorkingGroup));

      dispatch(finishValidation());
    };

    // Trigger field validation once SKU validation is returned
    if (skusValidated) {
      if (skuValidPRs?.length) {
        const controller = new AbortController();
        abortControllersRef.current.push(controller);
        fieldValidation(skuValidPRs, controller.signal).catch((error) => {
          if (error.message.includes('AbortError')) {
            console.log('User cancelled processing');
          } else {
            console.error(error);
          }
        });
      } else {
        // Handle no valid SKUs
        dispatch(finishValidation());
      }
    }
  }, [
    dispatch,
    fieldValidator,
    getExchangeRate,
    handleAddErrorPriceRequest,
    skuValidPRs,
    skusValidated,
    user.type,
    getProductInfo,
    getCalculatedValues,
    createPriceRequest,
    token,
    UUID
  ]);

  // Reset modal state on visibility toggle
  useEffect(() => {
    setUserCancelRequest(false);
    setSkuValidPRs([]);
    setProcessedRows(0);
    setSkusValidated(false);
  }, [openParseCSVModal, parsedCSVData]);

  const handleCancel = () => {
    sessionStorage.removeItem(BULK_UPLOADUUID_KEY);
    abortControllersRef.current.forEach((controller) => {
      return controller.abort();
    });
    //dispatch(resetState();
    //calling dispatch(resetState()) in cancel button click will result in losing the ErrorPriceRequest list in Rudex state
    //resetState() will be called in every upload / submit / delete button clicks
  };

  return (
    <Modal
      isOpen={openParseCSVModal}
      onRequestClose={() => {
        setUserCancelRequest(true);
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      alert={userCancelRequest}
      information={!userCancelRequest}
    >
      <div className={styles.parseCSVModalContainer}>
        {userCancelRequest ? (
          <CancelPane
            handleCancel={handleCancel}
            handleCancelToggle={setUserCancelRequest}
          />
        ) : (
          <ProgressPane
            handleCancelToggle={setUserCancelRequest}
            progressTotal={skuValidPRs?.length}
            progressAmount={processedRows}
            totalRows={parsedCSVData.length}
          />
        )}
      </div>
    </Modal>
  );
};

export default ParseCSVModal;
