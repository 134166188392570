import { bool, string, object, InferType } from 'yup';

import {
  maximumEmailCharacters,
  emailRegex,
  twoDecimalPointsRegex
} from '../../../config/constants';

import copy from '../../../config/translations/en-CA.json';
const modalCopy = copy.modals[0].addNewPriceRequestLDB;
const positiveNumberValidation = modalCopy.validations.positiveNumber;
const maximumValueValidation = modalCopy.validations.maximumValue;
const emailValidation = modalCopy.validations.email;
const decimalValidation = modalCopy.validations.decimal;

//Define the validation schema using Yup, for all of the inputs on the form.
// use .when for conditional validation and .test to create custom validation tests
// Pass in any input names at the bottom that have cylical dependency on each other ie whenever .when is used to apply validation
export const schema = object().shape(
  {
    requestType: object().shape({
      label: string(),
      value: string()
    }),
    effectiveDate: string(),
    endDate: string().when('requestType', {
      is: (optionValue: any) => {
        const { value } = optionValue;
        return value && value === 'Wholesale Price Promotion';
      },
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable()
    }),
    primeCostPerCase: string()
      .nullable()
      .when('primeCostPerCase', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable()
      }),
    dutyPaidCost: string()
      .nullable()
      .when('dutyPaidCost', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    estWholesalePrice: string()
      .nullable()
      .when('estWholesalePrice', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    wholesalePriceAsOfEffectiveDate: string()
      .nullable()
      .when('wholesalePriceAsOfEffectiveDate', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    promotionAmount: string()
      .nullable()
      .when('promotionAmount', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    domesticChargesPerCase: string()
      .nullable()
      .when('domesticChargesPerCase', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    currencyCode: object().shape({
      label: string().nullable(),
      value: string().nullable()
    }),
    reason: object().shape({
      label: string(),
      value: string()
    }),
    fxRateDate: string(),
    exchangeRate: string(),
    agentSupplierReview: bool(),
    costOnly: bool(),
    costingEventComment: string(),
    foreignPrimeCost: string()
      .nullable()
      .when('foreignPrimeCost', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .matches(twoDecimalPointsRegex, decimalValidation.constraint)
            .test(
              positiveNumberValidation.title,
              positiveNumberValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const number = parseFloat(value);
                  return number >= 0;
                }
              }
            )
            .test(
              maximumValueValidation.title,
              maximumValueValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  const numericValue = parseFloat(value.replace(',', ''));
                  return !isNaN(numericValue) && numericValue < 10000000.0;
                }
              }
            ),
        otherwise: (schema) => schema.nullable()
      }),
    otherReasonSpecification: string(),
    addNewPRJustification: string().nullable(),
    email: string()
      .nullable()
      .when('email', {
        is: (value: string) => value,
        then: (schema) =>
          schema
            .test(
              emailValidation.title,
              emailValidation.constraint,
              (value: string | undefined | null) => {
                if (value && value !== undefined) {
                  return emailRegex.test(value);
                }
              }
            )
            .max(maximumEmailCharacters, emailValidation.constraint),
        otherwise: (schema) => schema.nullable().notRequired()
      })
  },
  [
    ['primeCostPerCase', 'primeCostPerCase'],
    ['dutyPaidCost', 'dutyPaidCost'],
    ['estWholesalePrice', 'estWholesalePrice'],
    ['wholesalePriceAsOfEffectiveDate', 'wholesalePriceAsOfEffectiveDate'],
    ['promotionAmount', 'promotionAmount'],
    ['domesticChargesPerCase', 'domesticChargesPerCase'],
    ['endDate', 'effectiveDate'],
    ['foreignPrimeCost', 'foreignPrimeCost'],
    ['otherReasonSpecification', 'reason'],
    ['reason', 'requestType'],
    ['addNewPRJustification', 'addNewPRJustification'],
    ['email', 'email']
  ]
);

export type FormData = InferType<typeof schema>;
