import React, { ChangeEvent, useEffect, WheelEvent } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

import { handleSkuKeyDown, handleSkuPaste } from '../../helpers';

import Text from '../Text';
import Label from '../Label';

import styles from '../TextInput/TextInput.module.scss';

type PropsType = {
  label?: string;
  type?: string;
  placeholder?: string;
  id: string;
  helperText?: string;
  required?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  min?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | readonly string[] | undefined;
  message?: string;
  disabled?: boolean;
  helper?: boolean;
  ariaLabelledBy?: string;
  tooltip?: string;
  readOnly?: boolean;
};

const FilterTextInput = ({
  label,
  type,
  placeholder,
  id,
  helperText,
  required,
  medium,
  large,
  xlarge,
  message,
  disabled,
  value,
  helper,
  min,
  onChange,
  ariaLabelledBy,
  tooltip,
  readOnly
}: PropsType) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const name = id;
  const isNumber = type === 'number';

  // Adding the following code to disable scroll on number inputs
  useEffect(() => {
    const numberInputs = document.querySelectorAll(`#${id}[type="number"]`);

    const preventScroll = (event: Event) => {
      event.preventDefault();
    };

    numberInputs.forEach((input) => {
      input.addEventListener('wheel', preventScroll, { passive: false });
    });

    return () => {
      numberInputs.forEach((input) => {
        input.removeEventListener('wheel', preventScroll);
      });
    };
  }, [id]);

  return (
    <div
      className={classNames(styles.textInputContainer, {
        [styles.textInputContainerMedium]: medium,
        [styles.textInputContainerLarge]: large,
        [styles.textInputContainerXlarge]: xlarge,
        [styles.textInputContainerHidden]: type === 'hidden'
      })}
    >
      {label && (
        <Label
          className={styles.label}
          htmlFor={id}
          error={!!errors[id]}
          tooltip={tooltip}
        >
          {label}
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={value || ''}
        rules={required ? { required: 'This field is required' } : {}}
        render={({ field }) => (
          <input
            type={type || 'text'}
            id={id}
            placeholder={placeholder}
            className={classNames(styles.input, {
              [styles.inputError]: errors[id],
              [styles.inputDisabled]: disabled || readOnly
            })}
            disabled={disabled}
            value={field.value || ''}
            min={min}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              field.onChange(e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
            onWheel={(e: WheelEvent<HTMLInputElement>) => {
              if (type === 'number') {
                e.preventDefault(); // Prevent scrolling on number inputs
              }
            }}
            aria-labelledby={ariaLabelledBy}
            readOnly={readOnly}
            onKeyDown={isNumber ? handleSkuKeyDown : undefined}
            onPaste={isNumber ? handleSkuPaste : undefined}
            inputMode={isNumber ? 'numeric' : undefined}
          />
        )}
      />
      {helper && (
        <div className={styles.textContainer}>
          {errors[id] ? (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <Text element="p" small className={styles.helperTextError}>
                  {message}
                </Text>
              )}
            />
          ) : helperText ? (
            <Text element="p" small className={styles.helperText}>
              {helperText}
            </Text>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterTextInput;
