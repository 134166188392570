import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

import { useDeletePriceRequestGroupMutation } from '../../../services/PriceRequest';
import { resetWorkingGroupProducts } from '../../../reducers/WorkingGroupSlice';
import { resetState as resetCSVUploadState } from '../../../reducers/CSVBulkUploadSlice';

import {
  setDraftsExist,
  setOpenDeleteModal,
  setBannerNewPriceRequest
} from '../../../reducers/PriceRequestSlice';

import { getSessionToken } from '../../../helpers';

import Heading from '../../Heading';
import Text from '../../Text';
import ErrorAlertLarge from '../../Icons/ErrorAlertLarge';
import Button from '../../Button';

import copy from '../../../config/translations/en-CA.json';

import styles from './DeleteModal.module.scss';

type PropsType = {
  title: string;
  description: string;
};

const DeleteModal = ({ title, description }: PropsType) => {
  const modalCopy = copy.modals[0].deleteModal;
  const token = getSessionToken();

  const { workingGroupProducts } = useSelector(
    (state: RootState) => state.workingGroup
  );

  const { draftsExist } = useSelector((state: RootState) => state.priceRequest);

  const dispatch = useDispatch();

  //create request body for the delete action
  const deleteAllBody = {
    requestBody: {
      skus: workingGroupProducts
    },
    token
  };

  const [deletePriceRequestGroup, { isSuccess, isError }] =
    useDeletePriceRequestGroupMutation();

  const resetWorkingGroupState = () => {
    dispatch(resetWorkingGroupProducts());
    dispatch(setDraftsExist(false));
    dispatch(setOpenDeleteModal(false));
    dispatch(resetCSVUploadState());
  };

  const handleDeletion = () => {
    if (draftsExist) {
      deletePriceRequestGroup(deleteAllBody);
    } else {
      resetWorkingGroupState();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      resetWorkingGroupState();
    }
    if (isError) {
      //set the error banner content to the delete all error messaging
      dispatch(
        setBannerNewPriceRequest({
          title: `${modalCopy.deleteAll.errorBanner.title}`,
          type: 'error',
          description: `${modalCopy.deleteAll.errorBanner.description}`
        })
      );
      clearBanner();
      dispatch(setOpenDeleteModal(false));
    }
  }, [isSuccess, isError]);

  const clearBanner = () => {
    setTimeout(() => {
      //clear the error banner messaging
      dispatch(setBannerNewPriceRequest(undefined));
    }, 3000);
  };
  return (
    <div className={styles.deleteModalContainer}>
      <div className={styles.iconContainer}>
        <ErrorAlertLarge />
      </div>
      <div className={styles.contentContainer}>
        <Heading element="h3" heading3>
          {title}
        </Heading>
        <Text element="p">{description}</Text>
      </div>
      <div className={styles.buttonContainer}>
        <Button link onClick={() => dispatch(setOpenDeleteModal(false))}>
          {modalCopy.buttons.cancel}
        </Button>
        <Button primary onClick={handleDeletion}>
          {modalCopy.buttons.delete}
        </Button>
      </div>
    </div>
  );
};

export default DeleteModal;
