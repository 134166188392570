import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, headers } from '../helpers';
import { HierarchyClassType } from '../types/productSearchTypes';

type QueryParams = { azureUniqueID: string; token: string };

export const hierarchyApi = createApi({
  reducerPath: 'hierarchyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getHierachy: builder.query<HierarchyClassType[], QueryParams>({
      query: ({ token }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        },
        url: `hierarchy`
      })
    })
  })
});

export const { useGetHierachyQuery } = hierarchyApi;
