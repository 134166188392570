import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Text from '../Text';

import styles from './Pill.module.scss';

import {
  PriceRequestStatusEnum,
  PriceRequestStatus
} from '../../helpers/priceRequestStatus';

const {
  DRAFT,
  PROCESSING,
  LDB_REVIEW,
  AS_REVIEW,
  COMPLETED,
  CANCELLED,
  PENDING,
  QUEUED,
  ACCEPTED,
  DECLINED
} = PriceRequestStatusEnum;

type PropsType = {
  children: string;
  type: string;
  secondary?: boolean;
  large?: boolean;
  xlarge?: boolean;
  bold?: boolean;
  disabled?: boolean;
  pilltooltip?: string;
};

const Pill = ({
  children,
  type,
  secondary,
  large,
  xlarge,
  bold,
  disabled,
  pilltooltip
}: PropsType) => {
  const [pillType, setPillType] = useState('');
  const [pillContent, setPillContent] = useState(children);

  useEffect(() => {
    if (type === 'ldb-review') {
      setPillContent('LDB-Review');
    } else if (type === 'draft' && children !== `● Draft`) {
      setPillContent(`● Draft`);
    } else {
      setPillContent(children);
    }
  }, [children, type]);

  useEffect(() => {
    const pillStatus = (type: string) => {
      const statusMap = new Map<PriceRequestStatus, string>([
        [ACCEPTED, 'success'],
        [AS_REVIEW, 'warning'],
        [CANCELLED, 'error'],
        [COMPLETED, 'success'],
        [DECLINED, 'error'],
        [DRAFT, 'active'],
        [LDB_REVIEW, 'warning'],
        [PENDING, 'warning'],
        [PROCESSING, 'warning'],
        [QUEUED, 'success'],
        ['1', 'error'],
        ['2', 'success'],
        ['3', 'warning'],
        ['active', 'success'],
        ['current', 'neutral'],
        ['no', 'error'],
        ['pending delist', 'error'],
        ['yes', 'success'],
        ['completed', 'success'],
        ['a/s review', 'warning'],
        ['cancelled', 'error'],
        ['declined', 'error'],
        ['Draft', 'active'],
        ['draft', 'active'],
        ['Pending', 'warning'],
        ['pending', 'warning'],
        ['queued', 'success'],
        ['ldb-review', 'warning']
      ]);
      return statusMap.get(type) || type;
    };
    setPillType(pillStatus(type));
  }, [type]);

  return (
    <div
      className={classNames(styles.pillContainer, {
        [styles.pillContainerSuccess]: pillType === 'success',
        [styles.pillContainerError]: pillType === 'error',
        [styles.pillContainerActive]: pillType === 'active',
        [styles.pillContainerInactive]: pillType === 'inactive',
        [styles.pillContainerWarning]: pillType === 'warning',
        [styles.pillContainerNeutral]: pillType === 'neutral',
        [styles.pillContainerDate]: pillType === 'date',
        [styles.pillContainerInformation]: pillType === 'information',
        [styles.pillContainerSuccessSecondary]:
          pillType === 'success' && secondary,
        [styles.pillContainerErrorSecondary]: pillType === 'error' && secondary,
        [styles.pillContainerActiveSecondary]:
          pillType === 'active' && secondary,
        [styles.pillContainerWarningSecondary]:
          pillType === 'warning' && secondary,
        [styles.pillContainerInactiveSecondary]:
          pillType === 'inactive' && secondary,
        [styles.pillContainerNeutralSecondary]:
          pillType === 'neutral' && secondary,
        [styles.pillContainerDateSecondary]: pillType === 'date' && secondary,
        [styles.pillContainerInformationSecondary]:
          pillType === 'information' && secondary,
        [styles.pillContainerLarge]: large,
        [styles.pillContainerXLarge]: xlarge,
        [styles.pillContainerDisabled]: disabled
      })}
    >
      <Text
        element="p"
        small
        bold={secondary || bold}
        className={styles.text}
        pilltooltip={pilltooltip}
      >
        {pillContent}
      </Text>
    </div>
  );
};

export default Pill;
