import React, { useEffect, useState, useRef, LegacyRef } from 'react';
import classNames from 'classnames';

import { renderTextWithLineBreaks } from '../../helpers';

import { LocationType } from '../../types/productTypes';

import TableTooltip from '../TableTooltip';

import styles from './Text.module.scss';

type PropsType = {
  element: 'p' | 'span' | 'div' | 'strong';
  intro?: boolean;
  bold?: boolean;
  medium?: boolean;
  small?: boolean;
  tiny?: boolean;
  screenReaderOnly?: boolean;
  id?: string;
  children: React.ReactNode;
  labelledby?: string;
  className?: string;
  table?: boolean;
  action?: boolean;
  dark?: boolean;
  light?: boolean;
  upperCase?: boolean;
  tableCell?: boolean;
  disabled?: boolean;
  alignRight?: boolean;
  tooltip?: string;
  pilltooltip?: string;
};

const Text = ({
  element: Component,
  intro,
  bold,
  dark,
  light,
  small,
  tiny,
  medium,
  screenReaderOnly,
  id,
  children,
  labelledby,
  className,
  table,
  action,
  tableCell,
  disabled,
  alignRight,
  tooltip,
  pilltooltip,
  upperCase
}: PropsType) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  //create location state used to determine if the tooltips should render below or above the
  // text based on mouse location in the screen
  const [location, setLocation] = useState<LocationType>('bottom');
  // this callback will be passed into the tooltip component to bring the state from child to parent
  const callback = (state: LocationType) => {
    setLocation(state);
  };

  const textElementRef: LegacyRef<HTMLParagraphElement> | undefined =
    useRef(null);

  useEffect(() => {
    const element = textElementRef.current;
    if (element) {
      setIsOverflow(element.scrollWidth >= element.clientWidth);
    }
  }, []);

  return (
    <div className={styles.textContainer}>
      <Component
        id={id}
        aria-labelledby={labelledby}
        className={classNames(className, styles.text, {
          [styles.textBold]: bold,
          [styles.textDark]: dark,
          [styles.textLight]: light,
          [styles.textIntro]: intro,
          [styles.textSmall]: small,
          [styles.textMedium]: medium,
          [styles.textTiny]: tiny,
          [styles.textTable]: table,
          [styles.textScreenReaderOnly]: screenReaderOnly,
          [styles.textAction]: action,
          [styles.textActionDisabled]: action && disabled,
          [styles.textTableCell]: tableCell,
          [styles.textTableCellDisabled]: tableCell && disabled,
          [styles.textAlignRight]: alignRight,
          [styles.textUpperCase]: upperCase
        })}
        ref={textElementRef}
      >
        {renderTextWithLineBreaks(children)}
      </Component>
      {tooltip && isOverflowed && tooltip.length > 0 && (
        <span
          className={classNames(styles.tooltipContainer, {
            [styles.tooltipContainerTop]: location === 'top',
            [styles.tooltipContainerBottom]: location === 'bottom'
          })}
        >
          <TableTooltip label={tooltip} callback={callback} />
        </span>
      )}
      {pilltooltip && (
        <span
          className={classNames(styles.tooltipContainer, {
            [styles.tooltipContainerTop]: location === 'top',
            [styles.tooltipContainerBottom]: location === 'bottom'
          })}
        >
          <TableTooltip label={pilltooltip} callback={callback} />
        </span>
      )}
    </div>
  );
};

export default Text;
