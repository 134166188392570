import React from 'react';

const InfoCircle = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99967 21.1667C15.0628 21.1667 19.1663 17.0632 19.1663 12C19.1663 6.93689 15.0628 2.83337 9.99967 2.83337C4.93652 2.83337 0.833008 6.93689 0.833008 12C0.833008 17.0632 4.93652 21.1667 9.99967 21.1667ZM8.56738 14.8646H9.42676V12.573H8.56738C8.09115 12.573 7.70801 12.1898 7.70801 11.7136C7.70801 11.2373 8.09115 10.8542 8.56738 10.8542H10.2861C10.7624 10.8542 11.1455 11.2373 11.1455 11.7136V14.8646H11.432C11.9082 14.8646 12.2913 15.2478 12.2913 15.724C12.2913 16.2002 11.9082 16.5834 11.432 16.5834H8.56738C8.09115 16.5834 7.70801 16.2002 7.70801 15.724C7.70801 15.2478 8.09115 14.8646 8.56738 14.8646ZM9.99967 9.70837C9.36588 9.70837 8.85384 9.19633 8.85384 8.56254C8.85384 7.92875 9.36588 7.41671 9.99967 7.41671C10.6335 7.41671 11.1455 7.92875 11.1455 8.56254C11.1455 9.19633 10.6335 9.70837 9.99967 9.70837Z"
        fill="#2264E5"
      />
    </svg>
  );
};

export default InfoCircle;
