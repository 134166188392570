// This file contains functionality to detext the dimensions of the window
// This information is currently being used within the TableTooltip component to determine if the tooltip should render above or below the text, to help the tooltip doesn't render outside of the viewport

import { useState, useEffect } from 'react';

// Define general type for useWindowSize hook, which includes width and height
export interface Size {
  width: number | undefined;
  height: number | undefined;
}

export const useWindowDimensions = (): Size => {
  // create base undefined state of height and width of windowSize
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined
  });
  // function to call on window resize to set the windowSize state with the new values
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
